import React from 'react'
import { Reset } from 'styled-reset'

/* Contexts ===================================================================================== */
import { GlobalProvider } from './global-provider'

/* Components =================================================================================== */
import Router from './router'

/* Types ======================================================================================== */
import GlobalStyle from './styles/global-style'

import { normalize, setupPage } from 'csstips'

/* Material UI ======================================================================================== */
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { blue, blueGrey, grey } from '@material-ui/core/colors'

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1400,
    },
  },
  palette: {
    primary: {
      main: blueGrey[500],
    },
    secondary: {
      main: blue[500],
    },
  },
  typography: {
    fontSize: 12,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#f2f4f8',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: grey[400],
          },
          '&::-webkit-scrollbar-track': {
            background: grey[300],
          },
        },
      },
    },
    MuiList: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        '& li:focus': {
          outline: 0,
        },
      },
    },
    MuiIconButton: {
      label: {
        width: 'inherit',
        '& svg:not(.MuiSvgIcon-root)': {
          width: 'inherit',
          height: 'inherit',
        },
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '50px',
        minWidth: 'auto',
      },
      contained: {
        backgroundColor: '#ffffff',
      },
    },
    MuiContainer: {
      maxWidthLg: {
        // [theme.breakpoints.up('lg')]: {
        //   maxWidth: 1200,
        // },
        // Note that you can customize other properties here, like padding, color, .etc.
      },
    },
  },
})

/* <App /> ====================================================================================== */
function App() {
  normalize()
  setupPage('#root')

  return (
    <GlobalProvider>
      <Reset />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </GlobalProvider>
  )
}

export default App
