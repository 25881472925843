import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { CloseCircleStyled } from './close-circle.styled'

const CloseCircle: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <CloseCircleStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M23.2487 23.2487L15.9997 15.9997M15.9997 15.9997L8.7508 8.75077M15.9997 15.9997L8.75079 23.2487M23.2487 8.75077L18.8284 13.1711"
        className="stroke draw-stroke-path path-1"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M28.1223 9.00117C31.9877 15.6961 29.6938 24.257 22.9988 28.1223C16.3039 31.9877 7.74302 29.6938 3.87767 22.9988C0.0123209 16.3039 2.30619 7.74302 9.00117 3.87767C13.9858 0.999804 20.0047 1.53614 24.3499 4.76108"
        className="stroke draw-stroke-path path-2"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </CloseCircleStyled>
  )
}

export default CloseCircle
