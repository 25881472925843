import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
import { BoxProps } from './box.component'
import { CSSProperties } from 'styled-components'
type ClassNames = {
  component: string
  /**
   * Base class for box
   */
  base: string

  flexed?: string

  /**
   * Themed class for box
   */
  themed: string

  /**
   * Boxed class for box
   */
  boxed: string
}

type ClassProps = Omit<BoxProps, 'style' | 'children' | 'className'>

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const {
    alt,
    baseline = 'none',
    fullWidth,
    fullHeight,
    border,
    borderContrast = 'medium',
    shadow,
    shadowContrast = 'medium',
    rounded,
    centerX,
    centerY,
    centerAbsoluteX,
    centerAbsoluteY,
    alignLeft,
    alignRight,
    alignCenterX,
    alignCenterY,
    alignBottom,
    alignTop,
    spaceBetween,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    row,
    column,
    flex,
    relative,
    sticky,
    scroll,
    onlyContrast,
  } = props

  const component = style({
    display: 'block',
  })

  const base = style()

  const flexStyleObject: any = {}

  if (flex) {
    flexStyleObject.display = 'flex'
  }

  if (row) {
    flexStyleObject.display = 'flex'
    flexStyleObject.flexDirection = 'column'
  }

  if (column) {
    flexStyleObject.display = 'flex'
    flexStyleObject.flexDirection = 'row'
  }

  const flexed = style(flexStyleObject)

  const themed = style({
    background: theme.getBaseline(baseline, alt).color.light,
    color: theme.getBaseline(baseline, alt).contrast.medium,
  })

  const centeredStyle: any = {}

  if (centerX) {
    centeredStyle.marginTop = 0
    centeredStyle.marginBottom = 0
    centeredStyle.marginLeft = 'auto'
    centeredStyle.marginRight = 'auto'
  }

  if (centerY) {
    centeredStyle.marginTop = 'auto'
    centeredStyle.marginBottom = 'auto'
  }

  if (centerAbsoluteX) {
    centeredStyle.position = 'absolute'
    centeredStyle.left = '50%'
    centeredStyle.right = '50%'
    centeredStyle.transform = 'translateX(-50%)'
  }

  if (centerAbsoluteY) {
    centeredStyle.position = 'absolute'
    centeredStyle.top = '50%'
    centeredStyle.bottom = '50%'
    if (centeredStyle.transform) {
      centeredStyle.transform += ' translateY(-50%)'
    }
  }

  if (alignCenterY) {
    centeredStyle.display = 'flex'

    if (row) {
      centeredStyle.justifyContent = 'center'
    } else {
      centeredStyle.alignItems = 'center'
    }
  }

  if (alignCenterX) {
    centeredStyle.display = 'flex'
    if (row) {
      centeredStyle.alignItems = 'center'
    } else {
      centeredStyle.justifyContent = 'center'
    }
  }

  if (alignLeft) {
    centeredStyle.display = 'flex'
    centeredStyle.justifyContent = 'flex-start'
  }

  if (alignRight) {
    centeredStyle.display = 'flex'
    centeredStyle.justifyContent = 'flex-end'
  }

  if (spaceBetween) {
    centeredStyle.display = 'flex'
    centeredStyle.justifyContent = 'space-between'
  }

  if (alignTop) {
    centeredStyle.display = 'flex'
    centeredStyle.alignItems = 'flex-start'
  }

  if (alignBottom) {
    centeredStyle.display = 'flex'
    centeredStyle.alignItems = 'flex-end'
  }

  let position = ''

  if (relative) {
    position = 'relative'
  }

  if (sticky) {
    position = 'sticky'
  }

  const boxed = style({
    width: fullWidth ? '100%' : '',
    height: fullHeight ? '100%' : '',
    boxShadow: shadow ? `1px 1px 10px 1px ${theme.boxes.shadow[shadowContrast]}` : '',
    border: border ? `1px solid ${theme.boxes.border[borderContrast]}` : '',
    borderRadius: rounded ? 8 : 0,
    overflow: scroll ? 'auto' : '',
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    position,
    transition: theme.transition.medium,
    transitionProperty: 'margin-left, padding-top, width, height, background',
    ...centeredStyle,
  })

  return {
    component,
    base,
    flexed,
    themed,
    boxed,
  }
}

/* Export ======================================================================================= */
export const boxClass = new StyleClass<ClassNames, ClassProps>(getNames)
