import React from 'react'
import styled from 'styled-components'

export const Line = styled.div<{ horiz?: boolean; baseline?: string; pad?: number }>`
  background: rgb(220, 220, 220);
  ${({ horiz, pad = 0 }) => {
    if (horiz) {
      return `
        width: 1px;
        height: ${pad ? `calc(100% - ${pad * 2}px)` : '100%'};
        display: inline;
        margin: ${pad}px 0;
      `
    }

    return `
      height: 1px;
      width: ${pad ? `calc(100% - ${pad * 2}px)` : '100%'};
      display: inline-block;
      margin: 0 ${pad}px;
    `
  }}
`
