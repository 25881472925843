import { createStyles, makeStyles, Theme  } from '@material-ui/core/styles'
import { grey, blueGrey } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwTableCell: {
      width: `${(props: any) => props.width * 100}%`,
    },
    wwwTableContainer: {
      overflowX: 'unset',
      boxShadow: `0px 0px 0px 1px ${blueGrey[100]}`,
      '& .MuiTableCell-root': {
        borderBottomColor: blueGrey[100]
      }
    },
  }),
)

export default useStyles
