import React, { useEffect, FC, useContext } from 'react'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

/* Routes ======================================================================================= */
import routes from '..'

/* Hooks ======================================================================================== */
import useAxios, { parseStates } from '../../../hooks/use-axios'
import useCounter from '../../../hooks/use-counter'
import useValue from '../../../hooks/use-value'
import useFetch from '../../../hooks/use-fetch'

/* Components =================================================================================== */
import AppLayout from '../layout'
import { Search } from '../../../modules/search'
import { TOOLBAR } from '../../../modules/app-layout/app-layout.constants'
import { Icon } from '../../../modules/icons'
// import { Box } from '../../../modules/box/box.component'
// import { Grid } from '../../../modules/grid/grid'
// import { GridItem } from '../../../modules/grid/grid-item'
// import SET from '../../../styles/set'
// import { Text } from '../../../modules/text/text.component'
// import { Heading } from '../../../modules/text/heading.component'
// import { Circle } from '../../../modules/circle'
// import { Button } from '../../../modules/button/'
import { APIWrapperContext } from '../../../api-wrapper'
import { DEFAULT_SEARCH } from './app.constants'
import { formatDate } from '../../../components/date-field'
import { flat } from '../../../share/obj-equal'
import { TutorialsModal } from '../../../components/tutorials/modal'

/* Material UI ======================================================================================== */
// Core
import { grey } from '@material-ui/core/colors'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

// Lab
import Alert from '@material-ui/lab/Alert'

/* <App /> ====================================================================================== */
const App: FC<{ match: any }> = ({ match }) => {
  const { orgId } = match.params
  const { layout, schema } = useContext(APIWrapperContext)

  const [entries, $entries] = useFetch(
    {
      data: [],
    },
    {
      url: `${process.env.REACT_APP_API}/${orgId}/schema/5f9975e3f023ef7660562486/entries`,
      search: DEFAULT_SEARCH,
    },
  )

  useEffect(() => {
    if (entries.loaded) {
      $entries.get()
    }
  }, [entries.loaded])

  return (
    <AppLayout.render
      {...layout}
      content={
        <Box>
          <Helmet>
            <title>JAMS | Dashboard</title>
          </Helmet>
          <Container maxWidth="xl">
            <Box mt={4} mb={4} p={3} borderColor={grey[300]} border={1} bgcolor="#ffffff">
              <Typography variant="h6">
                <Box marginBottom={1}>
                  Recent Activity
                  {entries.payload.data.length > 0
                    ? ' (' + entries.payload.data.length + ')'
                    : ' (0)'}
                </Box>
              </Typography>
              {entries.payload.data.length > 0 ? (
                <List dense disablePadding>
                  {entries.payload.data &&
                    entries.payload.data.map((entry: any, key: number) => {
                      const { schema, updatedAt, createdAt, parent, body } = entry
                      const flattenEntry = flat(entry)
                      return (
                        <ListItem key={key} disableGutters>
                          <Box display="block">
                            <Typography variant="subtitle1">
                              <Box display="block" fontWeight="fontWeightMedium">
                                {formatDate(new Date(updatedAt || createdAt))}
                              </Box>
                            </Typography>
                            <Link
                              component={RouterLink}
                              to={(() => {
                                return `${body[1].replace('schema', 'table')}`
                              })()}
                            >
                              {`${body[0]} at ${parent['General Information'].projectName || '-'}`}
                            </Link>
                          </Box>
                        </ListItem>
                      )
                    })}
                </List>
              ) : (
                <Alert severity="info">No recent activity</Alert>
              )}
            </Box>

            {/* <Box baseline="none" shadow className={SET.classes(SET.p16, SET.mb16)}>
                <Heading size="xs">Activity</Heading>
              </Box> */}
            {/* <Box
              baseline="none"
              className={SET.p16}
              style={{
                width: 'calc(33.3333% - 8px)',
              }}
            >
              <Box baseline="none" shadow className={SET.classes(SET.p16, SET.mb16)}>
                <Heading size="xs">Something</Heading>
              </Box>
            </Box>
            <Box
              baseline="none"
              className={SET.p16}
              style={{
                width: 'calc(33.3333% - 8px)',
              }}
            >
              <Box baseline="none" shadow className={SET.classes(SET.p16, SET.mb16)}>
                <Heading size="xs">Activities</Heading>
              </Box>
            </Box> */}
          </Container>
          <TutorialsModal
            match={match}
            name={schema.payload ? schema.payload.name : { plural: '', singular: '' }}
          />
        </Box>
      }
    />
  )
}

export default withRouter(App)
