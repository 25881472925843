import React, { FC, useEffect, useContext } from 'react'
import { classes } from 'typestyle'
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom'

/* Config ======================================================================================= */

/* Hooks ======================================================================================== */
import useAxios, { parseStates } from '../../../hooks/use-axios'
import useObject, { UseObjectCallbacks } from '../../../hooks/use-object'

/* Components =================================================================================== */
import AppLayout from '../layout'
import { Icon } from '../../../modules/icons'
import { APIWrapperContext } from '../../../api-wrapper'
import useFetch from '../../../hooks/use-fetch'
import { Text } from '../../../modules/text/text.component'
import { Button } from '../../../modules/button'
import { NoAuth } from '../../../components/no-auth'
import { TutorialsModal } from '../../../components/tutorials/modal'

/* Material UI ======================================================================================== */
// Core
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'

/* <Tutorials /> ================================================================================= */
const Tutorials: FC<RouteComponentProps> = ({ match, history }) => {
  const { schemaId, orgId } = match.params as any
  const { layout, schema } = useContext(APIWrapperContext)
  const styleClasses = useStyles()

  /**
   * Tutorials Schema API
   */
  const [schemaTutorials, $schemaTutorials] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>({ count: 0, data: [], page: 1, totalPage: 1 })

  const [generalTutorials, $generalTutorials] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>({ count: 0, data: [], page: 1, totalPage: 1 })

  //duplicate tutorials
  // filter
  useEffect(() => {
    if (schemaTutorials.loaded) {
      $schemaTutorials.get({
        url: `${process.env.REACT_APP_API}/admin/schema/60807c6bfd2b770018647d80/entries`,
        search: {
          searchStrict: {
            category: 'Schema',
          },
        },
      })
    }
  }, [schemaTutorials.loaded])

  useEffect(() => {
    if (generalTutorials.loaded) {
      $generalTutorials.get({
        url: `${process.env.REACT_APP_API}/admin/schema/60807c6bfd2b770018647d80/entries`,
        search: {
          searchStrict: {
            category: 'General',
          },
        },
      })
    }
  }, [generalTutorials.loaded])

  const tutorialMap = (tutorial: any, index: any) => {
    return (
      <Box key={index} p={2} mt={1} mb={1} bgcolor="#ffffff">
        <Link
          className={styleClasses.wwwTitleLink}
          target="_blank"
          href={tutorial.videoUrl}
          color="secondary"
        >
          <Typography variant="h5">
            <Box fontWeight="fontWeightMedium">{tutorial.title}</Box>
          </Typography>
          <Icon.ArrowRight size="sm" />
        </Link>
        <Typography>{tutorial.description}</Typography>
      </Box>
    )
  }
  return (
    <AppLayout.render
      {...layout}
      content={
        <>
          <Box bgcolor="#ffffff" pt={4} pb={3}>
            <Container maxWidth="xl">
              <Typography variant="h4">
                <Box fontWeight="fontWeightMedium">Tutorials</Box>
              </Typography>
            </Container>
          </Box>
          <Box pt={3} pb={3}>
            <Container maxWidth="xl">
              {generalTutorials &&
                generalTutorials.payload.data.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Typography variant="h6">
                        <Box color={grey[700]} pt={2} pb={1}>
                          {item.category}
                        </Box>
                      </Typography>
                      {item.tutorials.map((tutorial: any, index: any) => {
                        return tutorialMap(tutorial, index)
                      })}
                    </React.Fragment>
                  )
                })}
              {schemaTutorials &&
                schemaTutorials.payload.data.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Typography variant="h6">
                        <Box color={grey[700]} pt={2} pb={1}>
                          {item.tablePageTitle}
                        </Box>
                      </Typography>
                      {item.tutorials.map((tutorial: any, index: any) => {
                        return tutorialMap(tutorial, index)
                      })}
                    </React.Fragment>
                  )
                })}
            </Container>
          </Box>
        </>
      }
    />
  )
}

export default withRouter(Tutorials)

/* Styled Components ============================================================================ */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwSectionHeader: {
      color: theme.palette.grey[700],
    },
    wwwTitleLink: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        flexGrow: 1,
      },
    },
    wwwTutorialContentBox: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '&:last-child': {
        borderBottom: '0',
      },
    },
  }),
)
