/**
 * SelectComponent
 *
 * Write CSS with following as reference
 *  <Field> // from withField()
 *    <FieldContainer className='www-tfc'>
 *      <input className='www-tfi'/>
 *    </FieldContainer>
 *  </Field>
 */
import React, { useRef, useEffect, FC } from 'react'
import { classes } from 'typestyle'

import useBoolean from '../../hooks/use-boolean'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'
import { FormActions } from '../form/index'

/* Styles ======================================================================================= */
// import { selectClass } from './select.class'
// import SET from '../../styles/set'

/* Components =================================================================================== */
// import { Float } from '../float'
// import { Box } from '../box/box.component'
// import { Text } from '../text/text.component'
import { Icon } from '../icons'
// import { Button } from '../button'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'
import useValue from '../../hooks/use-value'
// import { fieldClass } from '../field/field.class'

/* Material UI ======================================================================================== */
import { Select as MaterialSelect } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'
import useStyles from './select.styles'

type SelectProps = {
  allowEmpty?: boolean
  baseline?: ComponentBaselineType
  options?: any
  renderInitialValue?: (value: any) => any
  renderOption?: (option: any) => any
  renderValue?: (value: any) => any
  focused?: boolean
} & FieldProps &
  MaterialProps

type MaterialProps = {
  fullWidth?: boolean
}
/* <SelectComponent /> ======================================================================= */
/**  See bottom for wrapped component */
const SelectComponent: React.FC<FieldContextProps & SelectProps> = props => {
  const {
    allowEmpty,
    // baseline = 'background',
    dispatch,
    name,
    label,
    onBlur,
    onChange,
    onFocus,
    options,
    renderInitialValue,
    renderOption,
    renderValue,
    // size = 'small',
    field = {
      focused: undefined,
    },
    fullWidth = true,
    helperText,
  } = props
  const inputRef = useRef(null)
  const [value, $value] = useValue(props.value || '')
  const [focused, $focused] = useValue(props.focused || false)
  const [isChanged, $isChanged] = useBoolean(false)

  const classes = useStyles()
  /* Field ClassNames --------------------------------------------------------------------------- */
  // const { fieldSized } = fieldClass.setProps({
  //   size: props.size,
  // })

  /* ClassNames --------------------------------------------------------------------------------- */
  // const { base, themed, proped, option, placeholder } = selectClass.setProps({ baseline })

  useEffect(() => {
    if (dispatch) {
      dispatch({ type: FormActions.FIELD, param: { name, value: { validate: props.validate } } })
    }
  }, [])

  useEffect(() => {
    $value.set(props.value)
  }, [props.value])

  useEffect(() => {
    $value.set(props.formValue || '')
  }, [props.formValue])

  useEffect(() => {
    $focused.set(field.focused)
  }, [field.focused])

  useEffect(() => {
    if (renderInitialValue && options.length > 0) {
      if (value) {
        handle('change')(renderInitialValue(value))
      }
    }
  }, [options])

  /* Event Handlers ----------------------------------------------------------------------------- */
  const handle: (type: string, event?: any) => any = type => {
    switch (type) {
      case 'change':
        return (item: any) => {
          if (onChange) {
            onChange(item)
          }

          if (dispatch) {
            dispatch({ type: FormActions.SET, param: { name, value: item } })
            dispatch({ type: FormActions.BLUR, param: { name } })
          } else {
            $focused.set(false)
          }

          $value.set(item)
        }
      case 'focus':
        return () => {
          if (onFocus) {
            onFocus()
          }

          if (dispatch) {
            dispatch({ type: FormActions.FOCUS, param: { name } })
          } else {
            $focused.set(true)
          }
          $isChanged.set(true)
        }

      case 'blur':
        return () => {
          if (onBlur) {
            onBlur()
          }

          if (dispatch) {
            dispatch({ type: FormActions.BLUR, param: { name } })
          } else {
            $focused.set(false)
          }
          $isChanged.set(false)
        }
      case 'close':
        return () => {
          // console.log('test')
          $isChanged.set(false)
        }
      default:
        throw new Error('Unexpected event handler')
    }
  }

  // console.log(isChanged)
  return (
    // <div className={classes('www-select', base, fieldSized[size], themed)} ref={inputRef}>
    <FormControl variant="outlined" fullWidth={fullWidth} size="small">
      {label ? <InputLabel>{label}</InputLabel> : ''}
      <MaterialSelect
        className={classes.root}
        value={value}
        renderValue={renderValue}
        inputProps={{
          onClick: handle('focus'),
          onFocus: handle('focus'),
          onBlur: handle('blur'),
          ref: inputRef,
        }}
        onClose={handle('close')}
        label={label}
        // displayEmpty={allowEmpty}
        IconComponent={() => (
          <Box p="15px" className={`icon-wrap ${isChanged ? classes.animateToArrowUp : null}`}>
            <Icon.ArrowDown size="sm" />
          </Box>
        )}
        autoWidth
        // open
      >
        {options.map((item: any, key: number) => {
          return (
            <MenuItem
              onClick={() => {
                handle('change')(item)
              }}
              key={key}
              value={item}
            >
              {renderOption ? renderOption(item) : item}
            </MenuItem>
          )
          // return renderOption ? (
          //   renderOption(item)
          // ) : (
          //   <MenuItem
          //     onClick={() => {
          //       console.log(item)
          //       handle('change')(item)
          //     }}
          //     key={key}
          //     value={item}
          //   >
          //     {item}
          //   </MenuItem>
          // )
        })}
      </MaterialSelect>
      {helperText ? <FormHelperText variant="filled">{helperText}</FormHelperText> : null}
      {/* Options ------------------------------------------------------------------------------ */}
      {/* <Float
        ref={inputRef}
        anchorPosition={'bottom left'}
        dialogPosition={'top left'}
        close={handle('blur')}
        open={focused}
        fullWidth={true}
      >
        <Box baseline="surface" shadow fullWidth maxHeight={200} scroll>
          {options.map((item: any, key: number) => {
            return (
              <Box
                className={classes(SET.p8, option)}
                onClick={() => {
                  handle('change')(item)
                }}
                alignCenterY
                key={key}
              >
                {renderOption ? (
                  renderOption(item)
                ) : (
                  <Text size="md" style={{ whiteSpace: 'nowrap' }}>
                    {item}
                  </Text>
                )}
              </Box>
            )
          })}
        </Box>
      </Float> */}
      {/* Value and Buttons -------------------------------------------------------------------- */}
      {/* <Box
        alignCenterY
        spaceBetween
        fullHeight
        fullWidth
        onClick={handle('focus')}
        baseline="surface"
        className={focused && proped.focused}
      >
        <Box
          className={classes(SET.pl8, SET.pr8)}
          alignCenterY
          fullHeight
          fullWidth
          spaceBetween
          baseline="surface"
          tag={<button type="button" />}
          onFocus={handle('focus')}
          // onBlur={handle('blur')}
        >
          {(() => {
            if (value) {
              if (renderValue) {
                return renderValue(value)
              }

              return (
                <Text size={'md'} style={{ whiteSpace: 'nowrap' }}>
                  {value}
                </Text>
              )
            }

            return (
              <Text size={'md'} className={placeholder} style={{ whiteSpace: 'nowrap' }}>
                {props.placeholder}
              </Text>
            )
          })()}
        </Box>
        <Box onClick={handle('focus')} alignCenterY fullHeight baseline={'surface'}>
          {value && allowEmpty && (
            <>
              <Box alignCenterY fullHeight baseline={'surface'}>
                <Button
                  baseline={'surface'}
                  icon={<Icon.CloseCircle size={'xs'} />}
                  size={'xs'}
                  square
                  onClick={(e: any) => {
                    e.stopPropagation()
                    e.preventDefault()

                    handle('change')('')
                  }}
                />
              </Box>
            </>
          )}
          <Button icon={<Icon.ArrowDown size={'xs'} />} size={'xs'} square baseline="surface" />
        </Box>
      </Box> */}
    </FormControl>
  )
}

// export const Select: FC<SelectProps> = withField(SelectComponent)
export const Select: FC<SelectProps> = withField(SelectComponent)
