import React, { FC, useEffect } from 'react'

/* Hooks =================================================================================== */
import useBoolean from '../../hooks/use-boolean'
import useObject from '../../hooks/use-object'
import useFetch from '../../hooks/use-fetch'

/* Components =================================================================================== */
import { Icon } from '../../modules/icons'
import { classes } from 'typestyle'
import { useStyles } from './modal.styled'

/* Material UI =================================================================================== */
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Fab from '@material-ui/core/Fab'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'

export const TutorialsModal: FC<{
  className?: any
  name?: any
  match: any
}> = props => {
  const {
    className,
    match,
    name = {
      singular: '',
      plural: '',
    },
  } = props
  const [tutorialData, $tutorialData] = useObject<any>({})
  const [generalTutorialData, $generalTutorialData] = useObject<any>({})
  const [openModal, $openModal] = useBoolean(false)
  const styleClasses = useStyles()

  const { schemaId, orgId } = match.params as any
  /**
   * Tutorials Schema API
   */
  const [tutorials, $tutorials] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>({ count: 0, data: [], page: 1, totalPage: 1 })

  const [generalTutorials, $generalTutorials] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>({ count: 0, data: [], page: 1, totalPage: 1 })

  // filter
  useEffect(() => {
    if (schemaId && tutorials.loaded && tutorials.payload) {
      $tutorials.get({
        url: `${process.env.REACT_APP_API}/admin/schema/60807c6bfd2b770018647d80/entries`,
        search: {
          searchStrict: {
            schemaPage: schemaId,
          },
        },
      })
    }
  }, [schemaId, tutorials.loaded])

  useEffect(() => {
    if (generalTutorials.loaded) {
      $generalTutorials.get({
        url: `${process.env.REACT_APP_API}/admin/schema/60807c6bfd2b770018647d80/entries`,
        search: {
          searchStrict: {
            category: 'General',
          },
        },
      })
    }
  }, [generalTutorials.loaded])

  useEffect(() => {
    if (tutorials.payload.data.length > 0) {
      tutorials.payload.data.map(item => {
        if (item.schemaPage === schemaId) {
          $tutorialData.set(item)
        }
      })
    }
  }, [tutorials.payload.data])

  useEffect(() => {
    if (generalTutorials.payload.data.length > 0) {
      generalTutorials.payload.data.map(item => {
        if (item.category === 'General') {
          $generalTutorialData.set(item)
        }
      })
    }
  }, [generalTutorials.payload.data])

  const tutorialHandleOpen = () => {
    $openModal.set(true)
  }

  const tutorialHandleClose = () => {
    $openModal.set(false)
  }

  const tutorialContentTemplate = (tutorialContent: any, index: number) => {
    return (
      <Box pt={2} pb={2} key={index} className={styleClasses.wwwTutorialContentBox}>
        {tutorialContent.videoUrl && (
          <Link
            className={styleClasses.wwwTitleLink}
            target="_blank"
            href={tutorialContent.videoUrl}
            color="secondary"
          >
            <Typography variant="h5">
              <Box fontWeight="fontWeightMedium">{tutorialContent.title}</Box>
            </Typography>
            <Icon.ArrowRight size="sm" />
          </Link>
        )}
        {tutorialContent.description && (
          <>
            <Typography>{tutorialContent.description}</Typography>
          </>
        )}
      </Box>
    )
  }

  return (
    <>
      {(tutorialData || generalTutorialData) &&
        (Object.keys(tutorialData).length > 0 || Object.keys(generalTutorialData).length > 0) && (
          <>
            <Fab
              size="small"
              className={classes(styleClasses.wwwTutorialButton, className)}
              variant="extended"
              onClick={tutorialHandleOpen}
            >
              <Icon.Help style={{ marginRight: '8px' }} />
              Tutorial
            </Fab>
            <Dialog open={openModal} onClose={tutorialHandleClose} fullWidth>
              <DialogTitle className={styleClasses.wwwDialogTitle}>
                <Typography variant="h6">{`${
                  tutorialData.tutorials && tutorialData.tutorials.length > 0
                    ? name?.singular
                    : 'General'
                } Tutorials`}</Typography>
                <IconButton
                  className={styleClasses.wwwDialogClose}
                  edge="end"
                  color="inherit"
                  onClick={tutorialHandleClose}
                >
                  <Icon.Close size="lg" />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {tutorialData.tutorials &&
                  tutorialData.tutorials.length > 0 &&
                  tutorialData.tutorials.map((item: any, index: any) => {
                    return tutorialContentTemplate(item, index)
                  })}
                {generalTutorialData.tutorials && (
                  <>
                    {tutorialData.tutorials && tutorialData.tutorials.length > 0 && (
                      <Typography variant="h6" className={styleClasses.wwwSectionHeader}>
                        <Box pt={2}>General</Box>
                      </Typography>
                    )}
                    {generalTutorialData.tutorials.length > 0 &&
                      generalTutorialData.tutorials.map((item: any, index: any) => {
                        return tutorialContentTemplate(item, index)
                      })}
                  </>
                )}
              </DialogContent>
              <DialogActions className={styleClasses.wwwDialogActions}>
                <Link href={`/${orgId}/tutorials`}>View All</Link>
              </DialogActions>
            </Dialog>
          </>
        )}
    </>
  )
}
