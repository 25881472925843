import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { color } from 'csx'

import { FormContext } from '../form'
import { Text } from '../text'
import useBoolean from '../../hooks/use-boolean'

export type FieldProps = {
  className?: string
  compact?: boolean
  data?: string
  disableError?: boolean
  fullwidth?: boolean
  halfwidth?: boolean
  label?: string
  name?: string
  focused?: boolean
  onChange?: (data: any, form?: any) => void
  onFocus?: () => void
  onBlur?: () => void
  validate?: any
  value?: any
  placeholder?: string
  defaultValue?: any
  style?: React.CSSProperties
  beforeSubmit?: (value: any, update: any) => {}
  size?: 'small' | 'lg' | 'sm' | 'lg'
}

export type FieldContextProps = {
  error?: string
  meta?: any
  /** Followings are set required so there is no extra validation  */
  onChange: (data: any, form: any) => void
  onFocus: () => void
  onBlur: () => void
} & FieldProps

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 4px;
`

export const Field = styled.div<{ focused?: boolean }>`
  width: 100%;

  > * {
    transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  input {
    border: none;
    height: 32px;
    outline: none;
    padding: 0 8px;
    background: none;
  }

  ${FieldContainer} {
    &::after {
      display: ${({ focused }) => (!focused ? 'inline-block' : 'none')};
    }
  }
`
