import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

type ClassNames = {
  /**
   * Base class for text field
   */
  base: string
  /**
   * Themed class for text field
   */
  themed: string

  /**
   * Proped class for text field
   */
  proped: {
    focused: string
  }

  option: string
  placeholder: string
}

type ClassProps = {
  baseline: ComponentBaselineType
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline } = props
  const base = style({
    // background: theme.getBaseline('background').color.medium,
    border: '1px solid rgb(230,230,230)',
    // borderRadius: 4,
    cursor: 'pointer',
    padding: 0,
    width: '100%',
  })

  const themed = style({
    background: theme.getBaseline('surface').color.medium,
    // color: theme.getBaseline(baseline).contrast.medium,
  })

  const proped = {
    focused: style({
      // background: theme.getBaseline(baseline).color.dark,
      $nest: {
        '*': {
          background: theme.getBaseline('surface').color.dark,
        },
      },
    }),
  }

  const option = style({
    $nest: {
      '&:hover': {
        background: theme.getBaseline(baseline).color.light,
      },
    },
  })

  const placeholder = style({
    color: 'rgb(120,120,120)',
    whiteSpace: 'nowrap',
  })

  return {
    base,
    themed,
    proped,
    option,
    placeholder,
  }
}

/* Export ======================================================================================= */
export const selectClass = new StyleClass<ClassNames, ClassProps>(getNames)
