import { generateSet } from './set.init'

export const margin: Margin = {
  ...generateSet({
    key: { m: 'margin' },
    getValue: (i: any) => `${8 * i}px`,
  }),
  ...generateSet({
    key: { ml: 'marginLeft' },
    getValue: (i: any) => `${8 * i}px`,
  }),
  ...generateSet({
    key: { mb: 'marginBottom' },
    getValue: (i: any) => `${8 * i}px`,
  }),
  ...generateSet({
    key: { mr: 'marginRight' },
    getValue: (i: any) => `${8 * i}px`,
  }),
  ...generateSet({
    key: { mt: 'marginTop' },
    getValue: (i: any) => `${8 * i}px`,
  }),
}

/** Below type is generated by `generateTypes` in set.ts */
type Margin = {
  m0: {}
  m0i: {}
  m0sm: {}
  m0smi: {}
  m0md: {}
  m0mdi: {}
  m0lg: {}
  m0lgi: {}
  m0xl: {}
  m0xli: {}
  m8: {}
  m8i: {}
  m8sm: {}
  m8smi: {}
  m8md: {}
  m8mdi: {}
  m8lg: {}
  m8lgi: {}
  m8xl: {}
  m8xli: {}
  m16: {}
  m16i: {}
  m16sm: {}
  m16smi: {}
  m16md: {}
  m16mdi: {}
  m16lg: {}
  m16lgi: {}
  m16xl: {}
  m16xli: {}
  m24: {}
  m24i: {}
  m24sm: {}
  m24smi: {}
  m24md: {}
  m24mdi: {}
  m24lg: {}
  m24lgi: {}
  m24xl: {}
  m24xli: {}
  m32: {}
  m32i: {}
  m32sm: {}
  m32smi: {}
  m32md: {}
  m32mdi: {}
  m32lg: {}
  m32lgi: {}
  m32xl: {}
  m32xli: {}
  m40: {}
  m40i: {}
  m40sm: {}
  m40smi: {}
  m40md: {}
  m40mdi: {}
  m40lg: {}
  m40lgi: {}
  m40xl: {}
  m40xli: {}
  m48: {}
  m48i: {}
  m48sm: {}
  m48smi: {}
  m48md: {}
  m48mdi: {}
  m48lg: {}
  m48lgi: {}
  m48xl: {}
  m48xli: {}
  m56: {}
  m56i: {}
  m56sm: {}
  m56smi: {}
  m56md: {}
  m56mdi: {}
  m56lg: {}
  m56lgi: {}
  m56xl: {}
  m56xli: {}
  m64: {}
  m64i: {}
  m64sm: {}
  m64smi: {}
  m64md: {}
  m64mdi: {}
  m64lg: {}
  m64lgi: {}
  m64xl: {}
  m64xli: {}
  m72: {}
  m72i: {}
  m72sm: {}
  m72smi: {}
  m72md: {}
  m72mdi: {}
  m72lg: {}
  m72lgi: {}
  m72xl: {}
  m72xli: {}
  m80: {}
  m80i: {}
  m80sm: {}
  m80smi: {}
  m80md: {}
  m80mdi: {}
  m80lg: {}
  m80lgi: {}
  m80xl: {}
  m80xli: {}
  m88: {}
  m88i: {}
  m88sm: {}
  m88smi: {}
  m88md: {}
  m88mdi: {}
  m88lg: {}
  m88lgi: {}
  m88xl: {}
  m88xli: {}
  m96: {}
  m96i: {}
  m96sm: {}
  m96smi: {}
  m96md: {}
  m96mdi: {}
  m96lg: {}
  m96lgi: {}
  m96xl: {}
  m96xli: {}
  m104: {}
  m104i: {}
  m104sm: {}
  m104smi: {}
  m104md: {}
  m104mdi: {}
  m104lg: {}
  m104lgi: {}
  m104xl: {}
  m104xli: {}
  m112: {}
  m112i: {}
  m112sm: {}
  m112smi: {}
  m112md: {}
  m112mdi: {}
  m112lg: {}
  m112lgi: {}
  m112xl: {}
  m112xli: {}
  m120: {}
  m120i: {}
  m120sm: {}
  m120smi: {}
  m120md: {}
  m120mdi: {}
  m120lg: {}
  m120lgi: {}
  m120xl: {}
  m120xli: {}
  m128: {}
  m128i: {}
  m128sm: {}
  m128smi: {}
  m128md: {}
  m128mdi: {}
  m128lg: {}
  m128lgi: {}
  m128xl: {}
  m128xli: {}
  m136: {}
  m136i: {}
  m136sm: {}
  m136smi: {}
  m136md: {}
  m136mdi: {}
  m136lg: {}
  m136lgi: {}
  m136xl: {}
  m136xli: {}
  m144: {}
  m144i: {}
  m144sm: {}
  m144smi: {}
  m144md: {}
  m144mdi: {}
  m144lg: {}
  m144lgi: {}
  m144xl: {}
  m144xli: {}
  m152: {}
  m152i: {}
  m152sm: {}
  m152smi: {}
  m152md: {}
  m152mdi: {}
  m152lg: {}
  m152lgi: {}
  m152xl: {}
  m152xli: {}
  m160: {}
  m160i: {}
  m160sm: {}
  m160smi: {}
  m160md: {}
  m160mdi: {}
  m160lg: {}
  m160lgi: {}
  m160xl: {}
  m160xli: {}
  mt0: {}
  mt0i: {}
  mt0sm: {}
  mt0smi: {}
  mt0md: {}
  mt0mdi: {}
  mt0lg: {}
  mt0lgi: {}
  mt0xl: {}
  mt0xli: {}
  mt8: {}
  mt8i: {}
  mt8sm: {}
  mt8smi: {}
  mt8md: {}
  mt8mdi: {}
  mt8lg: {}
  mt8lgi: {}
  mt8xl: {}
  mt8xli: {}
  mt16: {}
  mt16i: {}
  mt16sm: {}
  mt16smi: {}
  mt16md: {}
  mt16mdi: {}
  mt16lg: {}
  mt16lgi: {}
  mt16xl: {}
  mt16xli: {}
  mt24: {}
  mt24i: {}
  mt24sm: {}
  mt24smi: {}
  mt24md: {}
  mt24mdi: {}
  mt24lg: {}
  mt24lgi: {}
  mt24xl: {}
  mt24xli: {}
  mt32: {}
  mt32i: {}
  mt32sm: {}
  mt32smi: {}
  mt32md: {}
  mt32mdi: {}
  mt32lg: {}
  mt32lgi: {}
  mt32xl: {}
  mt32xli: {}
  mt40: {}
  mt40i: {}
  mt40sm: {}
  mt40smi: {}
  mt40md: {}
  mt40mdi: {}
  mt40lg: {}
  mt40lgi: {}
  mt40xl: {}
  mt40xli: {}
  mt48: {}
  mt48i: {}
  mt48sm: {}
  mt48smi: {}
  mt48md: {}
  mt48mdi: {}
  mt48lg: {}
  mt48lgi: {}
  mt48xl: {}
  mt48xli: {}
  mt56: {}
  mt56i: {}
  mt56sm: {}
  mt56smi: {}
  mt56md: {}
  mt56mdi: {}
  mt56lg: {}
  mt56lgi: {}
  mt56xl: {}
  mt56xli: {}
  mt64: {}
  mt64i: {}
  mt64sm: {}
  mt64smi: {}
  mt64md: {}
  mt64mdi: {}
  mt64lg: {}
  mt64lgi: {}
  mt64xl: {}
  mt64xli: {}
  mt72: {}
  mt72i: {}
  mt72sm: {}
  mt72smi: {}
  mt72md: {}
  mt72mdi: {}
  mt72lg: {}
  mt72lgi: {}
  mt72xl: {}
  mt72xli: {}
  mt80: {}
  mt80i: {}
  mt80sm: {}
  mt80smi: {}
  mt80md: {}
  mt80mdi: {}
  mt80lg: {}
  mt80lgi: {}
  mt80xl: {}
  mt80xli: {}
  mt88: {}
  mt88i: {}
  mt88sm: {}
  mt88smi: {}
  mt88md: {}
  mt88mdi: {}
  mt88lg: {}
  mt88lgi: {}
  mt88xl: {}
  mt88xli: {}
  mt96: {}
  mt96i: {}
  mt96sm: {}
  mt96smi: {}
  mt96md: {}
  mt96mdi: {}
  mt96lg: {}
  mt96lgi: {}
  mt96xl: {}
  mt96xli: {}
  mt104: {}
  mt104i: {}
  mt104sm: {}
  mt104smi: {}
  mt104md: {}
  mt104mdi: {}
  mt104lg: {}
  mt104lgi: {}
  mt104xl: {}
  mt104xli: {}
  mt112: {}
  mt112i: {}
  mt112sm: {}
  mt112smi: {}
  mt112md: {}
  mt112mdi: {}
  mt112lg: {}
  mt112lgi: {}
  mt112xl: {}
  mt112xli: {}
  mt120: {}
  mt120i: {}
  mt120sm: {}
  mt120smi: {}
  mt120md: {}
  mt120mdi: {}
  mt120lg: {}
  mt120lgi: {}
  mt120xl: {}
  mt120xli: {}
  mt128: {}
  mt128i: {}
  mt128sm: {}
  mt128smi: {}
  mt128md: {}
  mt128mdi: {}
  mt128lg: {}
  mt128lgi: {}
  mt128xl: {}
  mt128xli: {}
  mt136: {}
  mt136i: {}
  mt136sm: {}
  mt136smi: {}
  mt136md: {}
  mt136mdi: {}
  mt136lg: {}
  mt136lgi: {}
  mt136xl: {}
  mt136xli: {}
  mt144: {}
  mt144i: {}
  mt144sm: {}
  mt144smi: {}
  mt144md: {}
  mt144mdi: {}
  mt144lg: {}
  mt144lgi: {}
  mt144xl: {}
  mt144xli: {}
  mt152: {}
  mt152i: {}
  mt152sm: {}
  mt152smi: {}
  mt152md: {}
  mt152mdi: {}
  mt152lg: {}
  mt152lgi: {}
  mt152xl: {}
  mt152xli: {}
  mt160: {}
  mt160i: {}
  mt160sm: {}
  mt160smi: {}
  mt160md: {}
  mt160mdi: {}
  mt160lg: {}
  mt160lgi: {}
  mt160xl: {}
  mt160xli: {}
  mr0: {}
  mr0i: {}
  mr0sm: {}
  mr0smi: {}
  mr0md: {}
  mr0mdi: {}
  mr0lg: {}
  mr0lgi: {}
  mr0xl: {}
  mr0xli: {}
  mr8: {}
  mr8i: {}
  mr8sm: {}
  mr8smi: {}
  mr8md: {}
  mr8mdi: {}
  mr8lg: {}
  mr8lgi: {}
  mr8xl: {}
  mr8xli: {}
  mr16: {}
  mr16i: {}
  mr16sm: {}
  mr16smi: {}
  mr16md: {}
  mr16mdi: {}
  mr16lg: {}
  mr16lgi: {}
  mr16xl: {}
  mr16xli: {}
  mr24: {}
  mr24i: {}
  mr24sm: {}
  mr24smi: {}
  mr24md: {}
  mr24mdi: {}
  mr24lg: {}
  mr24lgi: {}
  mr24xl: {}
  mr24xli: {}
  mr32: {}
  mr32i: {}
  mr32sm: {}
  mr32smi: {}
  mr32md: {}
  mr32mdi: {}
  mr32lg: {}
  mr32lgi: {}
  mr32xl: {}
  mr32xli: {}
  mr40: {}
  mr40i: {}
  mr40sm: {}
  mr40smi: {}
  mr40md: {}
  mr40mdi: {}
  mr40lg: {}
  mr40lgi: {}
  mr40xl: {}
  mr40xli: {}
  mr48: {}
  mr48i: {}
  mr48sm: {}
  mr48smi: {}
  mr48md: {}
  mr48mdi: {}
  mr48lg: {}
  mr48lgi: {}
  mr48xl: {}
  mr48xli: {}
  mr56: {}
  mr56i: {}
  mr56sm: {}
  mr56smi: {}
  mr56md: {}
  mr56mdi: {}
  mr56lg: {}
  mr56lgi: {}
  mr56xl: {}
  mr56xli: {}
  mr64: {}
  mr64i: {}
  mr64sm: {}
  mr64smi: {}
  mr64md: {}
  mr64mdi: {}
  mr64lg: {}
  mr64lgi: {}
  mr64xl: {}
  mr64xli: {}
  mr72: {}
  mr72i: {}
  mr72sm: {}
  mr72smi: {}
  mr72md: {}
  mr72mdi: {}
  mr72lg: {}
  mr72lgi: {}
  mr72xl: {}
  mr72xli: {}
  mr80: {}
  mr80i: {}
  mr80sm: {}
  mr80smi: {}
  mr80md: {}
  mr80mdi: {}
  mr80lg: {}
  mr80lgi: {}
  mr80xl: {}
  mr80xli: {}
  mr88: {}
  mr88i: {}
  mr88sm: {}
  mr88smi: {}
  mr88md: {}
  mr88mdi: {}
  mr88lg: {}
  mr88lgi: {}
  mr88xl: {}
  mr88xli: {}
  mr96: {}
  mr96i: {}
  mr96sm: {}
  mr96smi: {}
  mr96md: {}
  mr96mdi: {}
  mr96lg: {}
  mr96lgi: {}
  mr96xl: {}
  mr96xli: {}
  mr104: {}
  mr104i: {}
  mr104sm: {}
  mr104smi: {}
  mr104md: {}
  mr104mdi: {}
  mr104lg: {}
  mr104lgi: {}
  mr104xl: {}
  mr104xli: {}
  mr112: {}
  mr112i: {}
  mr112sm: {}
  mr112smi: {}
  mr112md: {}
  mr112mdi: {}
  mr112lg: {}
  mr112lgi: {}
  mr112xl: {}
  mr112xli: {}
  mr120: {}
  mr120i: {}
  mr120sm: {}
  mr120smi: {}
  mr120md: {}
  mr120mdi: {}
  mr120lg: {}
  mr120lgi: {}
  mr120xl: {}
  mr120xli: {}
  mr128: {}
  mr128i: {}
  mr128sm: {}
  mr128smi: {}
  mr128md: {}
  mr128mdi: {}
  mr128lg: {}
  mr128lgi: {}
  mr128xl: {}
  mr128xli: {}
  mr136: {}
  mr136i: {}
  mr136sm: {}
  mr136smi: {}
  mr136md: {}
  mr136mdi: {}
  mr136lg: {}
  mr136lgi: {}
  mr136xl: {}
  mr136xli: {}
  mr144: {}
  mr144i: {}
  mr144sm: {}
  mr144smi: {}
  mr144md: {}
  mr144mdi: {}
  mr144lg: {}
  mr144lgi: {}
  mr144xl: {}
  mr144xli: {}
  mr152: {}
  mr152i: {}
  mr152sm: {}
  mr152smi: {}
  mr152md: {}
  mr152mdi: {}
  mr152lg: {}
  mr152lgi: {}
  mr152xl: {}
  mr152xli: {}
  mr160: {}
  mr160i: {}
  mr160sm: {}
  mr160smi: {}
  mr160md: {}
  mr160mdi: {}
  mr160lg: {}
  mr160lgi: {}
  mr160xl: {}
  mr160xli: {}
  mb0: {}
  mb0i: {}
  mb0sm: {}
  mb0smi: {}
  mb0md: {}
  mb0mdi: {}
  mb0lg: {}
  mb0lgi: {}
  mb0xl: {}
  mb0xli: {}
  mb8: {}
  mb8i: {}
  mb8sm: {}
  mb8smi: {}
  mb8md: {}
  mb8mdi: {}
  mb8lg: {}
  mb8lgi: {}
  mb8xl: {}
  mb8xli: {}
  mb16: {}
  mb16i: {}
  mb16sm: {}
  mb16smi: {}
  mb16md: {}
  mb16mdi: {}
  mb16lg: {}
  mb16lgi: {}
  mb16xl: {}
  mb16xli: {}
  mb24: {}
  mb24i: {}
  mb24sm: {}
  mb24smi: {}
  mb24md: {}
  mb24mdi: {}
  mb24lg: {}
  mb24lgi: {}
  mb24xl: {}
  mb24xli: {}
  mb32: {}
  mb32i: {}
  mb32sm: {}
  mb32smi: {}
  mb32md: {}
  mb32mdi: {}
  mb32lg: {}
  mb32lgi: {}
  mb32xl: {}
  mb32xli: {}
  mb40: {}
  mb40i: {}
  mb40sm: {}
  mb40smi: {}
  mb40md: {}
  mb40mdi: {}
  mb40lg: {}
  mb40lgi: {}
  mb40xl: {}
  mb40xli: {}
  mb48: {}
  mb48i: {}
  mb48sm: {}
  mb48smi: {}
  mb48md: {}
  mb48mdi: {}
  mb48lg: {}
  mb48lgi: {}
  mb48xl: {}
  mb48xli: {}
  mb56: {}
  mb56i: {}
  mb56sm: {}
  mb56smi: {}
  mb56md: {}
  mb56mdi: {}
  mb56lg: {}
  mb56lgi: {}
  mb56xl: {}
  mb56xli: {}
  mb64: {}
  mb64i: {}
  mb64sm: {}
  mb64smi: {}
  mb64md: {}
  mb64mdi: {}
  mb64lg: {}
  mb64lgi: {}
  mb64xl: {}
  mb64xli: {}
  mb72: {}
  mb72i: {}
  mb72sm: {}
  mb72smi: {}
  mb72md: {}
  mb72mdi: {}
  mb72lg: {}
  mb72lgi: {}
  mb72xl: {}
  mb72xli: {}
  mb80: {}
  mb80i: {}
  mb80sm: {}
  mb80smi: {}
  mb80md: {}
  mb80mdi: {}
  mb80lg: {}
  mb80lgi: {}
  mb80xl: {}
  mb80xli: {}
  mb88: {}
  mb88i: {}
  mb88sm: {}
  mb88smi: {}
  mb88md: {}
  mb88mdi: {}
  mb88lg: {}
  mb88lgi: {}
  mb88xl: {}
  mb88xli: {}
  mb96: {}
  mb96i: {}
  mb96sm: {}
  mb96smi: {}
  mb96md: {}
  mb96mdi: {}
  mb96lg: {}
  mb96lgi: {}
  mb96xl: {}
  mb96xli: {}
  mb104: {}
  mb104i: {}
  mb104sm: {}
  mb104smi: {}
  mb104md: {}
  mb104mdi: {}
  mb104lg: {}
  mb104lgi: {}
  mb104xl: {}
  mb104xli: {}
  mb112: {}
  mb112i: {}
  mb112sm: {}
  mb112smi: {}
  mb112md: {}
  mb112mdi: {}
  mb112lg: {}
  mb112lgi: {}
  mb112xl: {}
  mb112xli: {}
  mb120: {}
  mb120i: {}
  mb120sm: {}
  mb120smi: {}
  mb120md: {}
  mb120mdi: {}
  mb120lg: {}
  mb120lgi: {}
  mb120xl: {}
  mb120xli: {}
  mb128: {}
  mb128i: {}
  mb128sm: {}
  mb128smi: {}
  mb128md: {}
  mb128mdi: {}
  mb128lg: {}
  mb128lgi: {}
  mb128xl: {}
  mb128xli: {}
  mb136: {}
  mb136i: {}
  mb136sm: {}
  mb136smi: {}
  mb136md: {}
  mb136mdi: {}
  mb136lg: {}
  mb136lgi: {}
  mb136xl: {}
  mb136xli: {}
  mb144: {}
  mb144i: {}
  mb144sm: {}
  mb144smi: {}
  mb144md: {}
  mb144mdi: {}
  mb144lg: {}
  mb144lgi: {}
  mb144xl: {}
  mb144xli: {}
  mb152: {}
  mb152i: {}
  mb152sm: {}
  mb152smi: {}
  mb152md: {}
  mb152mdi: {}
  mb152lg: {}
  mb152lgi: {}
  mb152xl: {}
  mb152xli: {}
  mb160: {}
  mb160i: {}
  mb160sm: {}
  mb160smi: {}
  mb160md: {}
  mb160mdi: {}
  mb160lg: {}
  mb160lgi: {}
  mb160xl: {}
  mb160xli: {}
  ml0: {}
  ml0i: {}
  ml0sm: {}
  ml0smi: {}
  ml0md: {}
  ml0mdi: {}
  ml0lg: {}
  ml0lgi: {}
  ml0xl: {}
  ml0xli: {}
  ml8: {}
  ml8i: {}
  ml8sm: {}
  ml8smi: {}
  ml8md: {}
  ml8mdi: {}
  ml8lg: {}
  ml8lgi: {}
  ml8xl: {}
  ml8xli: {}
  ml16: {}
  ml16i: {}
  ml16sm: {}
  ml16smi: {}
  ml16md: {}
  ml16mdi: {}
  ml16lg: {}
  ml16lgi: {}
  ml16xl: {}
  ml16xli: {}
  ml24: {}
  ml24i: {}
  ml24sm: {}
  ml24smi: {}
  ml24md: {}
  ml24mdi: {}
  ml24lg: {}
  ml24lgi: {}
  ml24xl: {}
  ml24xli: {}
  ml32: {}
  ml32i: {}
  ml32sm: {}
  ml32smi: {}
  ml32md: {}
  ml32mdi: {}
  ml32lg: {}
  ml32lgi: {}
  ml32xl: {}
  ml32xli: {}
  ml40: {}
  ml40i: {}
  ml40sm: {}
  ml40smi: {}
  ml40md: {}
  ml40mdi: {}
  ml40lg: {}
  ml40lgi: {}
  ml40xl: {}
  ml40xli: {}
  ml48: {}
  ml48i: {}
  ml48sm: {}
  ml48smi: {}
  ml48md: {}
  ml48mdi: {}
  ml48lg: {}
  ml48lgi: {}
  ml48xl: {}
  ml48xli: {}
  ml56: {}
  ml56i: {}
  ml56sm: {}
  ml56smi: {}
  ml56md: {}
  ml56mdi: {}
  ml56lg: {}
  ml56lgi: {}
  ml56xl: {}
  ml56xli: {}
  ml64: {}
  ml64i: {}
  ml64sm: {}
  ml64smi: {}
  ml64md: {}
  ml64mdi: {}
  ml64lg: {}
  ml64lgi: {}
  ml64xl: {}
  ml64xli: {}
  ml72: {}
  ml72i: {}
  ml72sm: {}
  ml72smi: {}
  ml72md: {}
  ml72mdi: {}
  ml72lg: {}
  ml72lgi: {}
  ml72xl: {}
  ml72xli: {}
  ml80: {}
  ml80i: {}
  ml80sm: {}
  ml80smi: {}
  ml80md: {}
  ml80mdi: {}
  ml80lg: {}
  ml80lgi: {}
  ml80xl: {}
  ml80xli: {}
  ml88: {}
  ml88i: {}
  ml88sm: {}
  ml88smi: {}
  ml88md: {}
  ml88mdi: {}
  ml88lg: {}
  ml88lgi: {}
  ml88xl: {}
  ml88xli: {}
  ml96: {}
  ml96i: {}
  ml96sm: {}
  ml96smi: {}
  ml96md: {}
  ml96mdi: {}
  ml96lg: {}
  ml96lgi: {}
  ml96xl: {}
  ml96xli: {}
  ml104: {}
  ml104i: {}
  ml104sm: {}
  ml104smi: {}
  ml104md: {}
  ml104mdi: {}
  ml104lg: {}
  ml104lgi: {}
  ml104xl: {}
  ml104xli: {}
  ml112: {}
  ml112i: {}
  ml112sm: {}
  ml112smi: {}
  ml112md: {}
  ml112mdi: {}
  ml112lg: {}
  ml112lgi: {}
  ml112xl: {}
  ml112xli: {}
  ml120: {}
  ml120i: {}
  ml120sm: {}
  ml120smi: {}
  ml120md: {}
  ml120mdi: {}
  ml120lg: {}
  ml120lgi: {}
  ml120xl: {}
  ml120xli: {}
  ml128: {}
  ml128i: {}
  ml128sm: {}
  ml128smi: {}
  ml128md: {}
  ml128mdi: {}
  ml128lg: {}
  ml128lgi: {}
  ml128xl: {}
  ml128xli: {}
  ml136: {}
  ml136i: {}
  ml136sm: {}
  ml136smi: {}
  ml136md: {}
  ml136mdi: {}
  ml136lg: {}
  ml136lgi: {}
  ml136xl: {}
  ml136xli: {}
  ml144: {}
  ml144i: {}
  ml144sm: {}
  ml144smi: {}
  ml144md: {}
  ml144mdi: {}
  ml144lg: {}
  ml144lgi: {}
  ml144xl: {}
  ml144xli: {}
  ml152: {}
  ml152i: {}
  ml152sm: {}
  ml152smi: {}
  ml152md: {}
  ml152mdi: {}
  ml152lg: {}
  ml152lgi: {}
  ml152xl: {}
  ml152xli: {}
  ml160: {}
  ml160i: {}
  ml160sm: {}
  ml160smi: {}
  ml160md: {}
  ml160mdi: {}
  ml160lg: {}
  ml160lgi: {}
  ml160xl: {}
  ml160xli: {}
}
