import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../../exports'

/* Styles ======================================================================================== */
import { SchemaDetailedStyled } from './schema-detailed.styled'

const SchemaDetailed: FC<IconProps> = props => {
  const { size = 'xxl', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <SchemaDetailedStyled
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M9 8C9 7.44772 9.44771 7 10 7H33.1429C33.6951 7 34.1429 7.44772 34.1429 8V38C34.1429 38.5523 33.6951 39 33.1429 39H10C9.44771 39 9 38.5523 9 38V8Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M42 5L43 5"
        className="stroke-bg stroke-bg-1 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 9L45 9"
        className="stroke-bg stroke-bg-2 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 34H3"
        className="stroke-bg stroke-bg-3 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 42H9"
        className="stroke-bg stroke-bg-4 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M23.2273 27H18.6818V35M32.3182 35V27H27.7728M25.5 24.7143V19M30.0455 37.2857H25.5V29.2857M23.2273 14.4286H27.7727V19H23.2273V14.4286ZM23.2273 24.7143H27.7728V29.2857H23.2273V24.7143ZM30.0455 35H34.5909V39.5714H30.0455V35ZM16.4091 35H20.9545V39.5714H16.4091V35Z"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2692 11H13V31.5714M31.2692 11L38 18.4667M31.2692 11V18.4667H38M38 18.4667V21.2857M13 38.4286V43H38V28.1429"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SchemaDetailedStyled>
  )
}

export default SchemaDetailed
