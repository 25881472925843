import { style } from 'typestyle'

/* Constants ==================================================================================== */
import { ZINDEX_8, ZINDEX_4 } from '../__constants/z-index'
import { NAVBAR_WIDTH, SIDEBAR_WIDTH } from './app-layout.constants'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for sidebar
   */
  base: string
  /**
   * Proped class for sidebar
   */
  proped: {
    active: string
    offsetted: string
  }
}

type ClassProps = {
  topOffset?: number
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { topOffset } = props

  const base = style({
    width: 0,
    right: 0,
    overflow: 'hidden',
    position: 'fixed',
    transition: theme.transition.medium,
    transitionProperty: 'top, left, width',
    zIndex: ZINDEX_4,
  })

  const proped = {
    active: style({
      width: SIDEBAR_WIDTH,
      overflow: 'visible',
    }),
    offsetted: style({
      top: topOffset,
      height: `calc(100% - ${topOffset}px)`,
    }),
  }

  return {
    base,
    proped,
  }
}

/* Export ======================================================================================= */
export const sidebarClass = new StyleClass<ClassNames, ClassProps>(getNames)
