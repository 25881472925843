import styled from 'styled-components'
import { drawStroke, fade, slideLeftRight, slideRightLeft } from '../../animate'

export const UsersDetailedStyled = styled.svg`
  .stroke {
    stroke: ${props => props.theme};
  }

  .fill {
    fill: #383649;
  }

  .primary-fill-color {
    fill: #7dbade;
  }

  .secondary-stroke-color {
    stroke: #a1ded7;
  }

  &.is-animated {
    transition: 0.2s ease-in-out;
    &:hover {
      .stroke-bg-1 {
        animation: ${slideLeftRight} 2s linear forwards;
      }
      .stroke-bg-2 {
        animation: ${slideLeftRight} 4s linear forwards;
      }
      .stroke-bg-3 {
        animation: ${slideRightLeft} 6s linear forwards;
      }
      .stroke-bg-4 {
        animation: ${slideLeftRight} 8s linear forwards;
      }
      .fill-bg {
        animation: ${fade} 3s linear forwards;
      }
      .draw-stroke-path {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: ${drawStroke} 4s linear forwards;
      }
    }
  }
`
