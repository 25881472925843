import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { HelpStyled } from './help.styled'

const Help: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <HelpStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M28.1223 9.00117C31.9877 15.6961 29.6938 24.257 22.9988 28.1223C16.3039 31.9877 7.74302 29.6938 3.87767 22.9988C0.0123209 16.3039 2.30619 7.74302 9.00117 3.87767C13.9858 0.999804 20.0047 1.53614 24.3499 4.76108"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22 12C22 8.68629 19.3137 6 16 6C13.4202 6 11.2206 7.62819 10.3729 9.91304L14 12L14 11.9961C14.0021 10.8933 14.8967 10 16 10C17.1046 10 18 10.8954 18 12C18 14 14 15 14 19H18C18 17 22 15.8106 22 11.9961M18 24C18 25.1046 17.1046 26 16 26C14.8954 26 14 25.1046 14 24C14 22.8954 14.8954 22 16 22C17.1046 22 18 22.8954 18 24Z"
        className="stroke stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </HelpStyled>
  )
}

export default Help
