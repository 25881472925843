import styled, { keyframes } from 'styled-components'
import { drawStroke, bounce } from '../animate'

export const AlertStyled = styled.svg`
  .stroke {
    stroke: ${props => props.theme};
  }

  &.is-animated {
    &:hover {
      .stroke-path-1,
      .stroke-path-2 {
        animation: ${bounce} 0.5s linear forwards;
      }
      .stroke-path-3 {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: ${drawStroke} 1s linear forwards;
      }
    }
  }
`
