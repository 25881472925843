/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { blue, blueGrey } from '@material-ui/core/colors'

/* Styled Components ============================================================================ */
export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      wwwMenuList: {
        '& .MuiListItemText-primary': {
          fontWeight: 500,
        },
      },
      wwwMenuListSubHeader: {
        color: theme.palette.text.disabled,
        lineHeight: '1.5',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      wwwMenuListItem: {
        '&.Mui-selected, &.Mui-selected:hover': {
          color: blue[100],
          backgroundColor: theme.palette.secondary.main,
        },
      },
      wwwMenuItemIcon: {
        minWidth: `${theme.spacing(4)}px`,
        color: 'inherit',
        '& svg': {
          width: '18px',
          height: '18px',
        },
      },
      wwwFirstIndent: {
        paddingLeft: theme.spacing(4),
      },
      wwwSecondIndent: {
        paddingLeft: theme.spacing(6),
      },
      wwwThirdIndent: {
        paddingLeft: theme.spacing(8),
      },
      wwwFourthIndent: {
        paddingLeft: theme.spacing(10),
      },
      wwwSelectedItem: {
        color: blue[100],
        backgroundColor: theme.palette.secondary.main,
      },
    }),
  { index: 1 }, //Use index if needed for production, sometimes the css may not be prioritized properly when it is bundled
)
