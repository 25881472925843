import styled from 'styled-components'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { blue, blueGrey } from '@material-ui/core/colors'

// Reusable exports needed for icons

/*
 * Types
 */
export type IconProps = {
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  index?: number
  onClick?: (e: any) => void
  isAnimated?: boolean
  color?: string
}

/*
 * Constants
 */
export const SIZES: any = {
  xs: {
    width: 10,
    height: 10,
  },
  sm: {
    width: 14,
    height: 14,
  },
  md: {
    width: 16,
    height: 16,
  },
  lg: {
    width: 18,
    height: 18,
  },
  xl: {
    width: 20,
    height: 20,
  },
}

/*
 * Styled Components
 */
export const SVG = styled.svg`
  .stroke {
    stroke: #383649;
  }

  .fill {
    fill: #383649;
  }

  .primary-fill-color {
    fill: #7dbade;
  }

  .secondary-stroke-color {
    stroke: #a1ded7;
  }
`
