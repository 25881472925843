import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { MenuStyled } from './menu.styled'

const Menu: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <MenuStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M2 7H30"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16H30"
        className="stroke draw-stroke-path stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 25H30"
        className="stroke draw-stroke-path stroke-path-3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </MenuStyled>
  )
}

export default Menu
