import React, { FC, useEffect } from 'react'
// import SET from '../styles/set'

/* Components ======================================================================================== */
// import { Box } from '../modules/box/box.component'
import { TextField } from '../modules/text-field'
// import { Tab } from '../modules/tab'
import { Form } from '../modules/form'
import { RepeaterField } from '../modules/repeater-field'
import { Table } from '../modules/table'
import { Pagination } from '../components/table/template'

/* Hooks ======================================================================================== */
import useFetch from '../hooks/use-fetch'
import useValue from '../hooks/use-value'

/* Material UI ======================================================================================== */
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '@material-ui/lab/TabPanel'

export const UserManagement: FC<{}> = (props: any) => {
  const { orgId } = props
  const [users, $users] = useFetch(
    {
      data: [],
    },
    {
      url: `${process.env.REACT_APP_API}/${orgId}/users`,
      search: {
        page: 1,
        limit: 8,
      },
    },
  )
  const [tabValue, $tabValue] = useValue(0)

  const handleTabChange = (event: any, newValue: any) => {
    $tabValue.set(newValue)
  }

  useEffect(() => {
    if (users.mounted) {
      $users.get()
    }
  }, [users.mounted])

  return (
    <Box
      style={{
        background: 'white',
        minWidth: 540,
        maxHeight: 360,
      }}
    >
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Invite User" />
        <Tab label="Team" />
      </Tabs>
      {/* <Tabs allowIndex={false}> */}
      <TabPanel value={tabValue}>
        <Box p={2}>
          <Form>
            <RepeaterField
              style={{
                maxHeight: 360,
                overflowY: 'auto',
              }}
              name={'users'}
              itemLabel={'User'}
              render={(data: any, index: any, name: any, bind: any) => {
                return (
                  <>
                    <TextField label="Email" />
                  </>
                )
              }}
            />
          </Form>
        </Box>
      </TabPanel>
      <TabPanel value={tabValue}>
        {/* <Box className={SET.p16} maxHeight={360} minHeight={360} style={{ overflowY: 'auto' }}> */}
        <Box p={16}>
          <Table
            size={'lg'}
            topOffset={700}
            data={users.payload.data}
            headers={[
              {
                label: 'Email',
                name: 'userId.email',
                width: 1,
              },
            ]}
          ></Table>
        </Box>
        <Box p={1}>
          <Pagination data={users} $data={$users} size="xs" />
        </Box>
      </TabPanel>
      {/* </Tabs> */}
    </Box>
  )
}
