import React, { FC, useEffect, useContext } from 'react'
import { classes } from 'typestyle'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'

/* Config ======================================================================================= */

/* Hooks ======================================================================================== */
import useAxios, { parseStates } from '../../../hooks/use-axios'

/* Components =================================================================================== */
import AppLayout from '../layout'
import { TableTemplate } from '../../../components/summary-table-template'
import { TableContext } from '../../../components/table/context'
import { Icon } from '../../../modules/icons'
import { APIWrapperContext } from '../../../api-wrapper'
import useFetch from '../../../hooks/use-fetch'
import { Box } from '../../../modules/box/box.component'
import SET from '../../../styles/set'
import { Text } from '../../../modules/text/text.component'
import { Button } from '../../../modules/button'
import { TutorialsModal } from '../../../components/tutorials/modal'

/* <AppTable /> ================================================================================= */
const AppTable: FC<RouteComponentProps> = ({ match, history }) => {
  const { orgId, schemaId, parentEntryId, summarySlug } = match.params as any
  const { layout, schema } = useContext(APIWrapperContext)

  const { schemaData } = parseStates(
    {
      schemaData: schema,
    },
    {
      summaries: [],
    },
  )

  let name =
    Object.keys(schemaData.payload.summaries)
      .map((a: any) => schemaData.payload.summaries[a])
      .filter((a: any) => a.slug === summarySlug)[0] || {}

  name = {
    plural: name.fieldName,
    singular: name.fieldName,
  }

  const url = {
    conditional: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries`,
    summary: parentEntryId
      ? `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/parent-entry/${parentEntryId}/summary/${summarySlug}`
      : `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/summary/${summarySlug}`,
    api: parentEntryId
      ? `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/parent-entry/${parentEntryId}/entries`
      : `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries`,
    view: `/${orgId}/table/${schemaId}/entries`,
    new: parentEntryId
      ? `/${orgId}/table/${schemaId}/parent-entry/${parentEntryId}/new`
      : `/${orgId}/table/${schemaId}/new`,
  }

  return (
    <>
      <TableTemplate
        value={{
          layoutComponent: AppLayout,
          layout,
          name,
          url,
          headers: schema.payload ? schema.payload.headers : [],
          actions: (
            <Box centerX row fullWidth maxWidth={1200} baseline="none">
              <Box baseline="none" className={classes(SET.mt16)} flex spaceBetween>
                <Button
                  // label={`Run summary on ${
                  //   selected.length ? `${selected.length} entry(s)` : 'all entry(s)'
                  // }`}
                  color="secondary"
                  size="medium"
                  onClick={() => {
                    // console.log(selected)
                    // if (selected.length) {
                    //   $viewData.get({
                    //     url: `${url.summary}/json`,
                    //     search: {
                    //       _id: {
                    //         include: selected,
                    //       },
                    //     },
                    //   })
                    // } else {
                    //   $viewData.get({
                    //     url: `${url.summary}/json`,
                    //   })
                    // }
                    // $loadSummary.set(true)
                  }}
                />
                <Button
                  label="Reset selection(s)"
                  color="secondary"
                  size="md"
                  onClick={() => {
                    // $viewData.reset()
                    // $loadSummary.reset()
                    // $selected.reset()
                  }}
                />
              </Box>
            </Box>
          ),
          search: {
            /**
             * Default query will not affect url search parameters
             */
            default: {
              limit: 10,
              page: 1,
            },
            presets: [],
          },
          history,
          schemaUrl: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}`,
          loaded: schema.loading,
          isWaitingForSchema: true,
        }}
      />
      <TutorialsModal
        match={match}
        name={schema.payload ? schema.payload.name : { plural: '', singular: '' }}
      />
    </>
  )
}

export default withRouter(AppTable)
