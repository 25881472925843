import styled from 'styled-components'
import { drawStroke } from '../animate'

export const PencilStyled = styled.svg`
  .stroke {
    stroke: ${props => props.theme};
  }
  .fill {
    fill: ${props => props.theme};
  }
  &.is-animated {
    &:hover {
      .draw-stroke-path {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: ${drawStroke} 1s linear forwards;
        &.stroke-path-3 {
          animation: ${drawStroke} 1s 0.8s linear forwards;
        }
      }
    }
  }
`
