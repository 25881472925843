import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { ViewStyled } from './view.styled'

const View: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <ViewStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M24.1667 30H4V11.3333M20.0769 2H4V5.73333M20.0769 2L26 8.53333M20.0769 2V8.53333H26M26 8.53333V12M27.2857 27.2857L29 29M26.7143 19.8571C26.7143 23.6442 23.6442 26.7143 19.8571 26.7143C16.07 26.7143 13 23.6442 13 19.8571C13 16.07 16.07 13 19.8571 13C23.6442 13 26.7143 16.07 26.7143 19.8571Z"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ViewStyled>
  )
}

export default View
