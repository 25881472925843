import React, { useRef, useEffect } from 'react'
import { classes as typeClasses } from 'typestyle'

/* Styles ======================================================================================= */
import { checkboxClass } from './checkbox.class'
import SET from '../../styles/set'

/* Context ====================================================================================== */
import { withField, FieldContextProps } from '../field/field.component'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { Icon } from '../../modules/icons'
// import { Text } from '../text/text.component'

/* Types ======================================================================================== */
// import { ComponentBaselineType } from '../__core/component.types'
// import { buttonClass } from '../button/button.class'
import { FormActions } from '../form'

/* Material UI ======================================================================================== */
import { Checkbox as MaterialCheckbox } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from '@material-ui/core/Box'

type CheckboxProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  // baseline?: ComponentBaselineType
  // rounded?: boolean
} & FieldContextProps

const defaultProps: {} = {}

/* <Checkbox /> ================================================================================= */
const CheckboxComponent: React.FC<CheckboxProps> = props => {
  const {
    baseline,
    className,
    dispatch,
    handleValidate,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    // rounded,
    // style,
    field = {
      focused: undefined,
      validate: [],
    },
    helperText,
  } = props

  const inputRef = useRef(null)
  const [value, $value] = useValue(props.value || false)
  const [focused, $focused] = useValue(props.focused || false)
  const checked = value || false

  // Material UI Styled Classes
  // const classes = useStyles()

  useEffect(() => {
    dispatch({ type: FormActions.FIELD, param: { name, value: { validate: props.validate } } })
  }, [])

  useEffect(() => {
    $value.set(props.formValue || '')
  }, [props.formValue])

  useEffect(() => {
    if (props.value !== undefined) {
      handle('change')(props.value || '')
      handle('blur')()
    }
  }, [props.value])

  useEffect(() => {
    $focused.set(field.focused)
  }, [field.focused])

  /* ClassNames --------------------------------------------------------------------------------- */
  // const { base, checkBase, checkThemed, checkProped } = checkboxClass.setProps({
  //   baseline: baseline || 'primary',
  // })

  /* Event Handlers ----------------------------------------------------------------------------- */
  const handle: (type: string) => any = type => {
    switch (type) {
      case 'change':
        return () => {
          ;(inputRef as any).current.focus()

          if (onChange) {
            onChange(handleValidate(!value, props.validate))
          }

          dispatch({
            type: FormActions.SET,
            param: { name, value: handleValidate(!value, props.validate) },
          })

          $value.set(!value)
        }
      case 'focus':
        return () => {
          if (onFocus) {
            onFocus()
          }

          if (dispatch) {
            dispatch({ type: FormActions.FOCUS, param: { name } })
          } else {
            $focused.set(true)
          }
        }

      case 'blur':
        return () => {
          if (onBlur) {
            onBlur()
          }

          if (dispatch) {
            dispatch({ type: FormActions.BLUR, param: { name } })
          } else {
            $focused.set(false)
          }
        }
      case 'keyDown':
        return (e: any) => {
          if (e.keyCode === 13) {
            e.preventDefault()
            handle('change')()
          }
        }
      default:
        throw new Error('Unexpected event handler')
    }
  }

  // Template condition with/without checkbox label
  let CheckboxControl

  const CheckboxElement = (
    <MaterialCheckbox
      color="secondary"
      // className={classes.root}
      // type="checkbox"
      checked={checked}
      inputRef={inputRef}
      onChange={handle('change')}
      inputProps={{
        onBlur: handle('blur'),
        onClick: handle('change'),
        onFocus: handle('focus'),
        onKeyDown: handle('keyDown'),
      }}
    />
  )

  const HelperTextElement = <FormHelperText variant="filled">{helperText}</FormHelperText>

  if (label) {
    CheckboxControl = (
      <>
        <FormControlLabel control={CheckboxElement} label={label} />
        {helperText ? HelperTextElement : HelperTextElement}
      </>
    )
  } else {
    CheckboxControl = (
      <>
        {CheckboxElement}
        {helperText ? HelperTextElement : HelperTextElement}
      </>
    )
  }

  return CheckboxControl
  // (
  //   <div
  //     className={classes('www-checkbox', base, className)}
  //     style={style}
  //     // onClick={handle('change')}
  //   >
  //     {CheckboxControl}
  //     {/* <FormControlLabel control={CheckboxElement} label={label} /> */}
  //     {/* <div
  //       className={classes(
  //         checkBase,
  //         checkThemed,
  //         rounded && checkProped.rounded,
  //         value && checkProped.checked,
  //         value && focused && checkProped.focused,
  //         focused && checkProped.focusedWithoutSet,
  //         buttonClass.names.baseAnimated,
  //       )}
  //     />
  //     <Text size={'sm'} semiBold tag={<label />} className={SET.pl8}>
  //       {props.label}
  //     </Text>
  //     <input
  //       type="checkbox"
  //       checked={checked}
  //       ref={inputRef}
  //       onBlur={handle('blur')}
  //       onChange={handle('change')}
  //       onClick={handle('change')}
  //       onFocus={handle('focus')}
  //       onKeyDown={handle('keyDown')}
  //     /> */}
  //   </div>
  // )
}

CheckboxComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
// export const Checkbox = withField(CheckboxComponent)
export const Checkbox = withField(CheckboxComponent)
