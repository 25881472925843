import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Core base for component
   */
  base: string
  defaultBase: string
  tooltipBase: string
}

type ClassProps = {
  tooltip?: boolean
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  return {
    base: style({
      position: 'relative',
    }),
    defaultBase: style({
      position: 'relative',
      width: 'fit-content',
      height: 'fit-content',
    }),
    tooltipBase: style({
      background: theme.getBaseline('background').color.md,
      borderRadius: 4,
      boxShadow: `1px 1px 10px 5px ${theme.boxes.shadow.md}`,
      color: theme.getBaseline('background').contrast.md,
      whiteSpace: 'nowrap',
      padding: '4px 8px',
    }),
  }
}

export const componentClass = new StyleClass<ClassNames, ClassProps>(getNames)
