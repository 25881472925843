import { generateSet } from './set.init'

export const fullWidth: Hide = {
  ...generateSet({
    key: { fw: 'width' },
    getValue: () => '100%',
    incremental: false,
  }),
}

/** Below type is generated by `generateTypes` in set.ts */
type Hide = {
  fw: {}
  fwi: {}
  fwsm: {}
  fwsmi: {}
  fwmd: {}
  fwmdi: {}
  fwlg: {}
  fwlgi: {}
  fwxl: {}
  fwxli: {}
}
