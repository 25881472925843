/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { lightGreen, green } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwTutorialButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      backgroundColor: green[500],
      color: '#ffffff',
      zIndex: 5,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    wwwDialogTitle: {
      backgroundColor: theme.palette.secondary.main,
      color: '#ffffff',
    },
    wwwDialogClose: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(1),
      color: '#ffffff',
    },
    wwwSectionHeader: {
      color: theme.palette.grey[700],
    },
    wwwTitleLink: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        flexGrow: 1,
      },
    },
    wwwTutorialContentBox: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      '&:last-child': {
        borderBottom: '0',
      },
    },
    wwwDialogActions: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
  }),{index: 1}
)
