import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    height: 100%;
    background: rgb(244,244,244);
  }

  body {
    font-family: 'Roboto', sans-serif;
    height: fit-content;
  }

  html,body {
  }

  #root {
    height: fit-content;
  }

  * {
    box-sizing: border-box;
  }

  // *::-webkit-scrollbar {
  //   display: none;  /* Safari and Chrome */
  // }

  button {
    border: none;
    outline: none;
    cursor :pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  select {
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0;
  }

  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(220,220,220);
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

export default GlobalStyle
