import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../../exports'

/* Styles ======================================================================================== */
import { OrganizationsDetailedStyled } from './organizations-detailed.styled'

const OrganizationsDetailed: FC<IconProps> = props => {
  const { size = 'xxl', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <OrganizationsDetailedStyled
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M42 10L43 10"
        className="stroke-bg stroke-bg-1 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 14L45 14"
        className="stroke-bg stroke-bg-2 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.10526 34H3.10526"
        className="stroke-bg stroke-bg-3 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 41H8"
        className="stroke-bg stroke-bg-4 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 6H17.5C18.0523 6 18.5 6.44772 18.5 7V9.38326H21C21.5523 9.38326 22 9.83098 22 10.3833V15.304H25.5146V12.9529C25.5146 12.4006 25.9623 11.9529 26.5146 11.9529H31.5C32.0523 11.9529 32.5 12.4006 32.5 12.9529V15.304H35C35.5523 15.304 36 15.7517 36 16.304V37C36 37.5523 35.5523 38 35 38H22H9C8.44772 38 8 37.5523 8 37V10.3833C8 9.83097 8.44772 9.38326 9 9.38326H11.5V7C11.5 6.44772 11.9477 6 12.5 6Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M42 43C42.5523 43 43 42.5523 43 42C43 41.4477 42.5523 41 42 41V43ZM10 41C9.44772 41 9 41.4477 9 42C9 42.5523 9.44772 43 10 43V41ZM42 41H10V43H42V41Z"
        className="fill stroke-path"
      />
      <path
        d="M28.9933 31.4118V30.4118C28.441 30.4118 27.9933 30.8595 27.9933 31.4118H28.9933ZM28.9933 36.6747H27.9933C27.9933 37.2269 28.441 37.6747 28.9933 37.6747V36.6747ZM37.0067 31.4118H38.0067C38.0067 30.8595 37.5589 30.4118 37.0067 30.4118V31.4118ZM37.0067 36.6747V37.6747C37.5589 37.6747 38.0067 37.2269 38.0067 36.6747H37.0067ZM29.9933 36.6747V31.4118H27.9933V36.6747H29.9933ZM28.9933 32.4118H37.0067V30.4118H28.9933V32.4118ZM36.0067 31.4118V36.6747H38.0067V31.4118H36.0067ZM37.0067 35.6747H28.9933V37.6747H37.0067V35.6747Z"
        className="fill stroke-path"
      />
      <path
        d="M28.9933 23.0561V22.0561C28.441 22.0561 27.9933 22.5038 27.9933 23.0561H28.9933ZM37.0067 23.0561H38.0067C38.0067 22.5038 37.5589 22.0561 37.0067 22.0561V23.0561ZM37.0067 28.3189V29.3189C37.5589 29.3189 38.0067 28.8712 38.0067 28.3189H37.0067ZM28.9933 28.3189H27.9933C27.9933 28.8712 28.441 29.3189 28.9933 29.3189V28.3189ZM28.9933 24.0561H37.0067V22.0561H28.9933V24.0561ZM36.0067 23.0561V28.3189H38.0067V23.0561H36.0067ZM37.0067 27.3189H28.9933V29.3189H37.0067V27.3189ZM29.9933 28.3189V23.0561H27.9933V28.3189H29.9933Z"
        className="fill stroke-path"
      />
      <path d="M33 23.5V28.3189" className="stroke stroke-path" strokeWidth="2" />
      <path d="M33 32V36" className="stroke stroke-path" strokeWidth="2" />
      <path
        d="M15.0489 16.7383V15.7383C14.4966 15.7383 14.0489 16.186 14.0489 16.7383H15.0489ZM23.0133 16.7383H24.0133C24.0133 16.186 23.5656 15.7383 23.0133 15.7383V16.7383ZM23.0133 22.0012V23.0012C23.5656 23.0012 24.0133 22.5535 24.0133 22.0012H23.0133ZM15.0489 22.0012H14.0489C14.0489 22.5535 14.4966 23.0012 15.0489 23.0012V22.0012ZM15.0489 17.7383H23.0133V15.7383H15.0489V17.7383ZM22.0133 16.7383V22.0012H24.0133V16.7383H22.0133ZM23.0133 21.0012H15.0489V23.0012H23.0133V21.0012ZM16.0489 22.0012V16.7383H14.0489V22.0012H16.0489Z"
        className="fill stroke-path"
      />
      <path
        d="M15.0489 25.1589V24.1589C14.4966 24.1589 14.0489 24.6066 14.0489 25.1589H15.0489ZM15.0489 30.4217H14.0489C14.0489 30.974 14.4966 31.4217 15.0489 31.4217V30.4217ZM23.0133 25.1589H24.0133C24.0133 24.6066 23.5656 24.1589 23.0133 24.1589V25.1589ZM23.0133 30.4217V31.4217C23.5656 31.4217 24.0133 30.974 24.0133 30.4217H23.0133ZM16.0489 30.4217V25.1589H14.0489V30.4217H16.0489ZM15.0489 26.1589H23.0133V24.1589H15.0489V26.1589ZM22.0133 25.1589V30.4217H24.0133V25.1589H22.0133ZM23.0133 29.4217H15.0489V31.4217H23.0133V29.4217Z"
        className="fill stroke-path"
      />
      <path
        d="M16 35.2335V34.2335C15.4477 34.2335 15 34.6812 15 35.2335H16ZM22 35.2335H23C23 34.6812 22.5523 34.2335 22 34.2335V35.2335ZM21 42C21 42.5523 21.4477 43 22 43C22.5523 43 23 42.5523 23 42H21ZM15 42C15 42.5523 15.4477 43 16 43C16.5523 43 17 42.5523 17 42H15ZM16 36.2335H22V34.2335H16V36.2335ZM21 35.2335V42H23V35.2335H21ZM17 42V35.2335H15V42H17Z"
        className="fill stroke-path"
      />
      <path
        d="M40 21.5V19.375H36.5H33H29.5H26V33.3057V37.2576V42H40V28"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5 19.375V16H29.5V19.375"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 13.375H26V42H12V36M22.5 13.375H15.5M22.5 13.375V10H15.5V13.375M15.5 13.375H12V31"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19 16.5V22" className="stroke stroke-path" strokeWidth="2" />
      <path d="M19 25V30.5" className="stroke stroke-path" strokeWidth="2" />
    </OrganizationsDetailedStyled>
  )
}

export default OrganizationsDetailed
