import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'
import { circleSizes } from './circle.sizes'
import { StyleSized } from '../style-class/style-class.types'
import { CircleProps } from '.'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for circle
   */
  base: string
  themed: string
  sized: StyleSized
}

type ClassProps = CircleProps

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline } = props
  const base = style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
    cursor: 'pointer',
  })

  const themed = style({
    background: theme.getBaseline(baseline).color.medium,
  })

  const sized = {
    xs: style(circleSizes.xm),
    sm: style(circleSizes.sm),
    md: style(circleSizes.md),
    lg: style(circleSizes.lg),
    xl: style(circleSizes.xl),
  }

  return {
    base,
    themed,
    sized,
  }
}

/* Export ======================================================================================= */
export const circleClass = new StyleClass<ClassNames, ClassProps>(getNames)
