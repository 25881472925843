/* Constants ==================================================================================== */
import * as colors from './colors'

export type Color = string[]
export type Pallette = { color: Color; contrast: Color }
export type Baseline = {
  primary?: Pallette
  secondary?: Pallette
  background?: Pallette
  surface?: Pallette
  error?: Pallette
  disabled?: Pallette

  navMain?: Pallette
  navSub?: Pallette
  header?: Pallette
  headerbar?: Pallette
  sidebarLeft?: Pallette
  sidebarRight?: Pallette
}

export const getDefaultBaseline: () => Baseline = () => ({
  primary: {
    color: colors.BLUE,
    contrast: colors.WHITE,
  },
  secondary: {
    color: colors.BLUE_GREY,
    contrast: colors.WHITE,
  },
  background: {
    color: colors.GREY,
    contrast: colors.BLACK,
  },
  surface: {
    color: colors.WHITE,
    contrast: colors.BLACK,
  },
  error: {
    color: colors.WHITE,
    contrast: colors.RED,
  },
  disabled: {
    color: colors.GREY,
    contrast: colors.GREY,
  },
  none: {
    color: '',
    contrast: '',
  },
})
