import LoginForm from './components/login.form'
import RegisterForm from './components/register.form'
import ForgetPasswordForm from './components/forget-password.form'
import ChangePasswordForm from './components/change-password.form'

export default [
  {
    Component: LoginForm,
    title: 'Login',
    path: '/login',
    visibility: 'public',
  },
  {
    Component: RegisterForm,
    title: 'Register',
    path: '/register',
    visibility: 'public',
  },
  {
    Component: ForgetPasswordForm,
    title: 'Forget Password',
    path: '/forget-password',
    visibility: 'public',
  },
  {
    Component: ChangePasswordForm,
    title: 'Change Password',
    path: '/change-password/:token',
    visibility: 'public',
  },
]
