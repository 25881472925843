import React, { createContext, useContext, useEffect, useReducer } from 'react'
import styled from 'styled-components'

/* Hooks ======================================================================================== */
import useObject from '../../hooks/use-object'
import useBoolean from '../../hooks/use-boolean'
import { objEqual, flat, structureObj } from '../../share/obj-equal'
import { fieldClass } from '../field/field.class'
import { Prompt } from 'react-router-dom'

/* Types ======================================================================================== */
export type Form = {
  form?: any
  $form?: any
  fields?: any
  $fields?: any
  temp?: any
  $temp?: any
  meta?: any
  $meta?: any
  hasError?: any
  $hasError?: any
  validate: (name?: string) => boolean
  set: any
}

export type FieldValue = string | { value: any; error: string }

/* Components =================================================================================== */
const FormWrapper = styled.div`
  align-items: inherit;
  display: inherit;
  height: inherit;
  justify-content: inherit;
  width: 100%;
  flex-direction: inherit;

  form {
    align-items: inherit;
    display: inherit;
    height: inherit;
    justify-content: inherit;
    width: inherit;
    flex-direction: inherit;

    .${fieldClass.names.base} {
      margin-bottom: 8px;
    }
  }
`

/* Context ====================================================================================== */
const initialState = {
  form: {},
  raw: {},
  fields: {},
}

export const FormActions = {
  DELETE: 'delete',
  SET: 'set',
  GET: 'get',
  FOCUS: 'focus',
  BLUR: 'blur',
  FIELD: 'field',
  FIELDS: 'fields',
  RAW: 'raw',
  RAW_FIND: 'raw_find',
  RAW_PATCH: 'raw_patch',
}

const reducer = (state = initialState, action: { type: string; param: any }) => {
  switch (action.type) {
    case FormActions.DELETE: {
      const { name } = action.param
      const regex = new RegExp(`${name}`)
      const form: any = state.form

      // Loop through keys in state.form and match with regex
      // Delete key if true
      for (const key in form) {
        if (key.match(regex)) {
          delete form[key]
        }
      }
      return {
        ...state,
        form: form,
      }
    }

    case FormActions.SET: {
      const { name, value } = action.param
      return {
        ...state,
        form: name
          ? {
              ...state.form,
              [name]: value,
            }
          : value,
      }
    }
    case FormActions.FOCUS: {
      const { name } = action.param
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...(state.fields as any)[name],
            focused: true,
          },
        },
      }
    }

    case FormActions.BLUR: {
      const { name } = action.param
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...(state.fields as any)[name],
            focused: false,
          },
        },
      }
    }

    case FormActions.FIELD: {
      const { name, value } = action.param

      if (!name) {
        return state
      }
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            ...(state.fields ? (state.fields as any)[name] : {}),
            ...value,
          },
        },
      }
    }

    case FormActions.FIELDS: {
      const { name, value } = action.param

      return {
        ...state,
        fields: value,
      }
    }

    case FormActions.RAW: {
      const { name, value } = action.param

      return {
        ...state,
        raw: name
          ? {
              ...state.raw,
              [name]: value,
            }
          : value,
      }
    }

    case FormActions.RAW_FIND: {
      const { name } = action.param
    }

    case FormActions.RAW_PATCH: {
    }

    default:
      throw new Error('Unexpected action')
  }
}

export const FormContext = createContext({
  value: initialState,
  dispatch: null,
})

/* Form ========================================================================================= */
export const Form: React.FC<{
  className?: string
  name?: string
  onChange?: (data: any, fields: any) => void
  onSubmit?: (data: any) => void
  onMetaChange?: (data: any) => void
  values?: {}
  defaultValues?: {}
  excludeStructure?: any[]
  withoutForm?: boolean
}> = ({
  children,
  defaultValues = {},
  values,
  onSubmit,
  onChange,
  onMetaChange,
  className,
  withoutForm,
}) => {
  const [value, dispatch] = useReducer(reducer, initialState)
  const { form, fields } = value
  const [loaded, $loaded] = useBoolean(false)
  const [isSubmitted, $isSubmitted] = useBoolean(false)

  useEffect(() => {
    if (values !== undefined && !loaded && Object.keys(values as any).length > 1) {
      dispatch({ type: FormActions.SET, param: { value: flat(values) } })
      dispatch({ type: FormActions.RAW, param: { value: values } })
      $loaded.set(true)
    }
  }, [values, loaded])

  useEffect(() => {
    if (onChange) {
      onChange(form, fields)
    }
  }, [form])

  const contextValue = {
    value,
    dispatch,
  }

  const validate = () => {
    let hasError = false

    for (const key in fields) {
      const value: FieldValue = form[key]
      let error

      if (!(fields as any)[key]) {
        continue
      }

      let validators = (fields as any).validate
      if (typeof validators === 'function') {
        validators = validators(form)
      }

      if (validators) {
        for (let i = 0; i < validators.length; i++) {
          if (typeof value === 'object' && value.error) {
            error = validators[i](value.value)
          } else {
            error = validators[i](value)
          }
          if (error) {
            hasError = true
            if (typeof value === 'object' && value.error) {
              dispatch({ type: FormActions.SET, param: { name: key, value } })
            } else {
              dispatch({ type: FormActions.SET, param: { name: key, value: { value, error } } })
            }
            break
          } else {
            if (typeof value === 'object' && value.error) {
              dispatch({ type: FormActions.SET, param: { name: key, value: value.value } })
            }
          }
        }
      }
    }
    return hasError
  }

  const beforeSubmit = (formCopy: any, fieldsCopy: any) => {
    const temp: any = { ...formCopy }
    const temp2 = { ...fieldsCopy }

    for (const key in temp) {
      const value: FieldValue = temp[key]
      const field = (temp2 as any)[key]

      if (!field) {
        if (value && typeof value === 'object' && Object.keys(value).length === 0) {
          delete temp[key]
        }
        continue
      }

      // if (field.isArray) {
      //   const gathered = {}
      //   for (const key2 in temp2 ) {
      //     if (key2.match(`^${key}`)) {
      //       console.log(key2)
      //     }
      //   }
      //   console.log(key, field, temp2)
      // } else {
      // }

      const beforeSubmit = (temp2 as any)[key].beforeSubmit

      if (beforeSubmit && beforeSubmit(value) !== undefined) {
        temp[key] = beforeSubmit(value)
      }

      if (temp[key] === undefined || temp[key] === null) {
        delete temp[key]
      }

      if (temp[key] === '') {
        delete temp[key]
      }

      if (temp[key] && typeof temp[key] === 'object' && Object.keys(temp[key]).length === 0) {
        delete temp[key]
      }
    }

    // console.log('hello', temp)

    return structureObj(temp)
  }

  const _onSubmit = (e: any) => {
    e.preventDefault()

    const hasError = validate()

    if (onSubmit && !hasError) {
      const temp = beforeSubmit(form, fields)

      $isSubmitted.set(true)

      onSubmit(temp)
    }

    return false
  }

  return (
    <FormContext.Provider value={contextValue as any}>
      <FormWrapper>
        <form className={className} onSubmit={_onSubmit}>
          {children}
        </form>
      </FormWrapper>
      <Prompt when={!isSubmitted} message="Are you sure you want to leave?" />
    </FormContext.Provider>
  )
}

export const withForm: (Component: any) => React.FC<any> = Component => {
  const ComposedComponent: React.FC<any> = props => {
    const formContext = useContext(FormContext)

    return (
      <>
        <Component {...props} {...formContext} />
      </>
    )
  }

  return ComposedComponent
}
