import React, { FC, useContext, useState, useEffect } from 'react'
// import styled from 'styled-components'

/* Styles ======================================================================================= */
// import SET from '../styles/set'

/* Components =================================================================================== */
// import { Button } from '../modules/button'
// import { Circle } from '../modules/circle'
// import { Dropdown } from '../modules/dropdown'
// import { GLOBAL_COMPONENTS } from '../global-components'
// import { NAVBAR_WIDTH } from '../modules/app-layout/app-layout.constants'
// import { Search } from '../modules/search/search.component'
// import { Text } from '../modules/text/text.component'
// import { Border } from '../modules/border/border.component'
// import { Box } from '../modules/box/box.component'
// import { Selector } from '../modules/selector/selector.component'
import { Icon } from '../../modules/icons'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { APIWrapperContext } from '../../api-wrapper'
import { Select } from '../../modules/select/select.component'
import { useStyles } from './header.styled'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Material UI  ======================================================================================== */
import { Theme, makeStyles } from '@material-ui/core/styles'
import { blueGrey } from '@material-ui/core/colors'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

/* <Header /> =================================================================================== */
type HeaderProps = {
  context: any
  orgId?: any
}

export const Header: FC<HeaderProps> = props => {
  const { context, orgId } = props
  const history = useHistory()
  const { org, app, layout } = useContext(APIWrapperContext)
  const [anchorEl, $setAnchorEl] = useState<null | HTMLElement>(null)
  const [selected, $selected] = useValue()
  const styleClasses = useStyles()

  useEffect(() => {
    if (app.payload && app.payload.data) {
      // console.log(layout)
      // console.log(org.payload)
      $selected.set(app.payload.data.find((a: any) => a._id === orgId))
    }
  }, [app.payload])

  const handleUserMenu = (event: any) => {
    $setAnchorEl(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    $setAnchorEl(null)
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs sm>
        <Box display="inline-flex" alignItems="center">
          <Button
            component={RouterLink}
            to={`/${orgId}`}
            color="secondary"
            variant="contained"
            disableElevation
          >
            Dashboard
          </Button>
          {app.payload && app.payload.data && (
            <Box ml={2}>
              <Select
                className={styleClasses.wwwSelect}
                value={selected}
                options={app.payload.data}
                renderValue={(option: any) => {
                  if (typeof option == 'string') {
                    return option
                  }
                  return option.name
                }}
                renderOption={(option: any) => {
                  if (typeof option == 'string') {
                    return option
                  }
                  return option.name
                }}
                onChange={org => {
                  // console.log(org)
                  // console.log(selected)
                  if (org) {
                    if (selected && selected._id !== org._id) {
                      $selected.set(org._id)
                      setTimeout(() => {
                        history.push(`/${org._id}`)
                      }, 300)
                    }
                  }
                }}
                // style={{ maxWidth: '150px' }}
              />
            </Box>
          )}
        </Box>
        {/* <Box className={SET.pl8} flex fullHeight style={{ width: NAVBAR_WIDTH }} alignCenterY> */}
        {/* {GLOBAL_COMPONENTS.logo.md}
            <div className={SET.mr8} />
            {GLOBAL_COMPONENTS.appName.md} */}
        {/* </Box> */}
        {/* <Box flex fullHeight className={SET.classes(SET.mr8, SET.pl8)}> */}
        {/* <Selector spacing={24} className={SET.mr8}>
          <div
            data-highlight={history.location.pathname.split('/').length === 2}
            style={{ height: '100%' }}
          ></div> */}
        {/* <Dropdown className={SET.fh}>
                <NavButton>
                  <Text size="lg" semiBold>
                    Group
                  </Text>
                  <Icon.ArrowDown size="xs" />
                </NavButton>
                <Box shadow className={SET.p8}>
                  Content
                </Box>
              </Dropdown> */}
        {/* <Dropdown className={SET.fh}>
                <NavButton>
                  <Text size="lg" semiBold>
                    Projects
                  </Text>
                  <Icon.ArrowDown size="xs" />
                </NavButton>
                <Box shadow className={SET.p8}>
                  Content
                </Box>
              </Dropdown> */}
        {/* </Selector> */}
        {/* <Dropdown className={SET.classes(SET.ml8, SET.fh)}>
              <Button label="Compose" size="md" baseline="surface" float square />
              <Box shadow className={SET.p8}>
                Content
              </Box>
            </Dropdown> */}
        {/* </Box> */}
      </Grid>
      <Grid item xs sm>
        <Box display="flex" justifyContent="flex-end">
          {/* <Search className={SET.classes(SET.hidemd, SET.hidesm)} /> */}
          {/* <Dropdown
            floatProps={{
              anchorPosition: 'bottom right',
              dialogPosition: 'top right',
            }}
            className={SET.classes(SET.mr8, SET.ml24)}
          >
            <Button icon={<Icon.Support size="lg" />} size={'md'} baseline="surface" alt float />
            <Box shadow className={SET.p8}>
              Content
            </Box>
          </Dropdown> */}
          {/* <Dropdown
            floatProps={{
              anchorPosition: 'bottom right',
              dialogPosition: 'top right',
            }}
            className={SET.classes(SET.mr8, SET.ml24)}
          >
            <Button icon={<Icon.Notification size="lg" />} size={'md'} baseline="surface" float />
            <Box shadow className={SET.p8}>
              Content
            </Box>
          </Dropdown> */}

          {org.payload && org.payload.user && (
            <>
              <Button
                className={styleClasses.wwwUserButton}
                endIcon={<Icon.ArrowDown size="sm" />}
                onClick={handleUserMenu}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <Box
                  bgcolor={blueGrey[200]}
                  color={blueGrey[500]}
                  p={1}
                  mr={1}
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  lineHeight="1"
                  width="32px"
                  height="32px"
                >
                  {org.payload.user.initials}
                </Box>
                {org.payload.user.firstName + ' ' + org.payload.user.lastName}
              </Button>
              <Menu
                className={styleClasses.wwwUserMenu}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseUserMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuItem
                  onClick={() => {
                    document.cookie = 'token= ;'

                    history.push('/login')
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
