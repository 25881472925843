import React, { FC, useEffect } from 'react'

import SET from '../../styles/set'

import useAxios from '../../hooks/use-axios'
import useValue from '../../hooks/use-value'

import { Box } from '../../modules/box/box.component'
import { Button } from '../../modules/button'
import { Text } from '../../modules/text/text.component'
import { TextField } from '../../modules/text-field'
import { Tab, Tabs } from '../../modules/tab'
import { Form } from '../../modules/form'
import { RepeaterField } from '../../modules/repeater-field'
import { Table } from '../../modules/table'
import useFetch from '../../hooks/use-fetch'
import { Pagination } from './template'

export const TableModal: FC<{}> = (props: any) => {
  const { organizationId, schemaId, url } = props
  const [schema, $schema] = useFetch<{ _id?: string; headers?: [] }>(
    {},
    {
      url: `${process.env.REACT_APP_API}/${organizationId}/schema/${schemaId}`,
    },
  )
  const [entries, $entries] = useFetch({ data: [] })
  // const [users, $users] = useFetch(
  //   {
  //     data: [],
  //   },
  //   {
  //     url: `${process.env.REACT_APP_API}/${organizationId}/users`,
  //     search: {
  //       page: 1,
  //       limit: 8,
  //     },
  //   },
  // )

  useEffect(() => {
    if (schema.mounted) {
      $schema.get()
    }
  }, [schema.mounted])

  useEffect(() => {
    if (schema.payload._id) {
      $entries.get({
        url: url.api,
        search: {
          page: 1,
          limit: 10,
        },
      })
    }
  }, [schema.mounted, schema.payload])

  return (
    <Box
      style={{
        background: 'white',
        minWidth: 540,
        maxHeight: 360,
      }}
    >
      <Box className={SET.p16} maxHeight={360} minHeight={360} style={{ overflowY: 'auto' }}>
        <Table
          size={'lg'}
          topOffset={700}
          data={entries.payload.data}
          headers={schema.payload && schema.payload.headers ? schema.payload.headers : []}
        ></Table>
      </Box>
      <Box className={SET.p8}>
        <Pagination data={entries} $data={$entries} size="sm" />
      </Box>
    </Box>
  )
}
