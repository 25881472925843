import React, { createContext, useContext, FC, useEffect } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import useObject from '../../hooks/use-object'
import useValue from '../../hooks/use-value'

import { getDefaultBaseline, Baseline } from './baseline'
import { getDefaultBox, Box } from './box'
import { ComponentBaselineType } from '../__core/component.types'
import { DefaultTheme } from './theme.default'

const DEFAULT_THEME: {
  baselines: Baseline
  box: any
  index: number
  $index: any
} = {
  baselines: getDefaultBaseline(),
  index: 0,
  box: getDefaultBox(),
  $index: {},
}

export const ThemeContext = createContext(DefaultTheme)
export const useTheme = () => {
  const theme = useContext(ThemeContext)

  return theme
}

export const ThemeProvider: FC<{
  children?: any
  baselines?: Baseline[]
  boxes?: Box[]
  theme?: any
}> = ({
  children,
  baselines = [getDefaultBaseline()],
  boxes = [getDefaultBox()],
  theme = DefaultTheme,
}) => {
  const [index, $index] = useValue(0)
  const [_theme] = useObject({
    ...theme,
    getBaseline: (name: ComponentBaselineType, alt?: boolean) => {
      if (!name) {
        name = 'surface'
      }

      if (alt) {
        if (theme.baselines[`${name}Alt`]) {
          return theme.baselines[`${name}Alt`]
        }
      }

      return theme.baselines[name]
    },
  })

  return (
    <ThemeContext.Provider value={_theme}>
      <StyledThemeProvider theme={_theme}>{_theme && children}</StyledThemeProvider>
    </ThemeContext.Provider>
  )
}
