import React, { FC, useContext, useEffect, useState } from 'react'
// import { classes } from 'typestyle'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

/* Hooks ======================================================================================== */
import useFetch, { parseSearch } from '../../hooks/use-fetch'
import useBoolean from '../../hooks/use-boolean'
import useValue from '../../hooks/use-value'

/* Styles ======================================================================================= */
// import SET from '../../styles/set'

/* Components =================================================================================== */
import { APIWrapperContext } from '../../api-wrapper'
// import { Border } from '../../modules/border/border.component'
// import { Box } from '../../modules/box/box.component'
import { Button } from '../../modules/button'
import { DialogWrapper } from '../../modules/dialog-wrapper'
import { FloatMenu } from '../../modules/menu'
// import { Heading } from '../../modules/text/heading.component'
import { Icon } from '../../modules/icons'
import { Select } from '../../modules/select/select.component'
import { Table } from '../../modules/table'
// import { Text } from '../../modules/text/text.component'
import { TextField } from '../../modules/text-field'
import { flat } from '../../share/obj-equal'

// import { ComponentSizeType } from '../../modules/__core/component.types'
import { Filter } from '../render-filters'
import { formatPrice } from '../../common/format'
import { formatDate } from '../date-field'

/* Material UI ======================================================================================== */
// Core
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'

// Lab
import Alert from '@material-ui/lab/Alert'
import { find } from '../../common/find'

export const Pagination: FC<{ data: any; $data: any; size?: string }> = ({
  data,
  $data,
  size = 'small',
}) => {
  const classes = paginationStyles()

  return (
    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
      <Box mr={1}>
        <Button
          className={classes.wwwPaginationButton}
          icon={<Icon.ArrowLeft />}
          size={size}
          onClick={() => {
            if (data.search.page - 1 > 0) {
              $data.get({
                id: 'paginate',
                search: {
                  page: data.search.page - 1,
                },
                updateGetParams: true,
              })
            }
          }}
        />
      </Box>
      {(() => {
        if (data.payload.count < 1 || !data.payload.count) {
          return (
            <Box ml={1} mr={1}>
              <Button
                className={classes.wwwPaginationButton}
                label="1"
                size={size}
                variant="contained"
                color="secondary"
              />
            </Box>
          )
        }

        const pageButtons = []

        const minPage = Math.max(0, Math.floor(data.search.page / 10) * 10 - 1)
        const maxPage = Math.min(data.payload.totalPage, minPage + 10)

        for (let i = minPage; i < maxPage; i++) {
          pageButtons.push(
            <Box ml={1} mr={1} key={i}>
              <Button
                className={classes.wwwPaginationButton}
                label={`${i + 1}`}
                size={size}
                color={data.search.page === i + 1 ? 'secondary' : 'default'}
                variant="contained"
                onClick={() => {
                  $data.get({
                    id: 'pagination 2',
                    search: {
                      page: i + 1,
                    },
                    updateGetParams: true,
                  })
                }}
                key={i}
              />
            </Box>,
          )
        }

        return pageButtons
      })()}
      <Box ml={1}>
        <Button
          className={classes.wwwPaginationButton}
          icon={<Icon.ArrowRight />}
          size={size}
          onClick={() => {
            if (data.search.page < data.payload.totalPage) {
              $data.get({
                id: 'page',
                search: {
                  page: data.search.page + 1,
                },
                updateGetParams: true,
              })
            }
          }}
        />
      </Box>
    </Box>
  )
}

const paginationStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwPaginationButton: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      padding: theme.spacing(2),
    },
  }),
)

const debounceId: any = null
/* <TableTemplate /> ============================================================================ */
export const TableTemplate: FC<{ value?: any }> = ({ value }) => {
  const {
    search = { default: {} },
    layout,
    layoutComponent,
    name = {
      singular: '',
      plural: '',
    },
    headers = [],
    url = { api: '', post: '' },
    history,
    templates,
    isWaitingForSchema,
  } = value

  const { schema, org } = useContext(APIWrapperContext)
  const [showingAllStages, $showingAllStages] = useBoolean(false)

  const [entries, $entries] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>(
    {
      count: 0,
      data: [],
      page: 1,
      totalPage: 1,
    },
    {
      url: url.api,
      search: search.default,
      location: history.location,
      updateGetParams: false,
      onLocationChange: (props: any) => {
        const { url, encodedParam } = props
        history.push({ path: url, search: encodedParam })
      },
    },
  )

  const [recentEntries, $recentEntries] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>(
    {
      count: 0,
      data: [],
      page: 1,
      totalPage: 1,
    },
    {
      url: url.api,
      updateGetParams: false,
    },
  )

  const [trigger, $trigger] = useValue()
  const [postApi, $postApi] = useFetch({}, {})
  const [selected, $selected] = useValue([])
  const [internalHeaders, $internalHeaders] = useValue([...headers])
  const [defaultSearch, $defaultSearch] = useValue()
  const [floatMenuOpen, $floatMenuOpen] = useValue(false)
  const [anchorEl, $setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorPosition, $setAnchorPosition] = useValue({ left: 0, top: 0 })
  const [anchorReference, $setAnchorReference] = useValue()
  // const [openModal, $openModal] = useBoolean(false)
  // const classes = useStyles()

  useEffect(() => {
    if (entries.loaded && !isWaitingForSchema) {
      $entries.get({ id: 'initial' })
    }
  }, [entries.loaded])

  useEffect(() => {
    // console.log('hello 1')
    if (schema.payload && schema.payload.populateItems && entries.mounted && isWaitingForSchema) {
      let defaultStagesForSearch: any
      let defaultSortsForSearch: any
      if (!history.location.search.includes('stage.name.equal=All') && schema.payload.stages) {
        const defaultStage =
          schema.payload.stages && schema.payload.stages.filter((a: any) => a.isDefault)[0]

        defaultStagesForSearch = {
          ...(() => {
            if (defaultStage) {
              return {
                stage: {
                  name: {
                    equal: defaultStage.name,
                  },
                },
              }
            }
          })(),
        }
      }

      const sort = headers.find((a: any) => a.sort !== undefined)

      defaultSortsForSearch = {
        ...(() => {
          if (sort) {
            return {
              sort: {
                [sort.name]: sort.sort,
              },
            }
          }
        })(),
      }

      const defaultPopulate = {
        ...(() => {
          if (schema.payload.populateItems) {
            const populate = schema.payload.populateItems
              .filter((a: any) => a.allowFromTable)
              .map((item: any) => {
                return {
                  key: item.key,
                  from: item.from,
                }
              })

            if (populate.length > 0) {
              return { populate }
            }
          }
          return {}
        })(),
      }

      const defaultSearch = {
        ...search.default,
        ...(() => {
          if (!history.location.search.includes('stage.name')) {
            return defaultStagesForSearch
          }
        })(),
        ...defaultSortsForSearch,
        ...defaultPopulate,
      }

      if (schema.payload.isChildPerEntry) {
        $entries.get({
          id: 'hello 1 1',
          location: history.location,
          search: defaultSearch,
          deleteSearch:
            history.location.search.includes('stage.name.equal=All') && 'stage.name.equal',
        })
      } else {
        $entries.get({
          id: 'hello 1 2',
          location: history.location,
          search: defaultSearch,
          deleteSearch:
            history.location.search.includes('stage.name.equal=All') && 'stage.name.equal',
        })
      }

      $defaultSearch.set({
        ...defaultStagesForSearch,
        ...defaultSortsForSearch,
      })
    }
  }, [schema.payload, entries.mounted, trigger])

  useEffect(() => {
    if (recentEntries.mounted) {
      $recentEntries.get({
        id: 'recent',
        search: {
          limit: 5,
          page: 1,
          sort: {
            updatedAt: -1,
          },
          populate: entries.search.populate || [],
        },
        updateGetParams: false,
      })
    }
  }, [recentEntries.mounted, trigger, defaultSearch, entries.search])

  useEffect(() => {
    // console.log('hello 2')
    let unlisten: any
    if (defaultSearch) {
      unlisten = history.listen(() => {
        if (history.action === 'POP') {
          $entries.get({
            search: parseSearch(history.location, {}),
            updateGetParams: true,
            onLocationChange: (props: any) => {},
          })
        }
      })
    }

    return () => {
      if (unlisten) unlisten()
    }
  }, [defaultSearch, entries.loaded])

  useEffect(() => {
    if (headers.length > 0) {
      const model = { ...schema.payload.sections, ...schema.payload.fields }
      let newHeaders = [...headers]
      newHeaders.shift()

      newHeaders = [
        {
          ...headers[0],
          ...(() => {
            if (headers[0].sort !== undefined) {
              return {
                sort: parseInt(headers[0].sort) === 1 ? 1 : -1,
              }
            }
          })(),
          renderHeader: (header: any) => {
            return header.label
          },
        },
        ...newHeaders,
      ]

      newHeaders = newHeaders.map((header: any, key) => {
        const headerIndex = key
        const format = (header: any) => {
          switch (header.format) {
            case 'money':
              return (column: any) => formatPrice(column)
            case 'date':
              return (column: any) => formatDate(new Date(column))
            default:
              return (column: any) => column
          }
        }

        // Element for vertical ellipsis action button
        const actionButton = (
          <Box display="flex" justifyContent="flex-end">
            <Button
              icon={<Icon.VerticalEllipsis />}
              color="secondary"
              size="small"
              onClick={(event: any) => {
                $floatMenuOpen.set(true)
                $setAnchorEl(event.currentTarget)
                $setAnchorReference.set('anchorEl')
              }}
            />
          </Box>
        )

        if (key === 0) {
          header.renderColumn = (column: any, row: any, key: number) => {
            const createdBy = row['createdBy.fullName'] || find('createdBy.fullName', row)
            const updatedBy = row['updatedBy.fullName'] || find('updatedBy.fullName', row)

            return (
              <Grid container alignItems="center">
                <Grid item sm={7}>
                  <Typography>
                    <Link component={RouterLink} to={`${history.location.pathname}/${row._id}`}>
                      {column ? format(header)(column) : '-'}
                    </Link>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {updatedBy !== '-'
                      ? `Updated at ${formatDate(new Date(row.updatedAt))} by ${updatedBy}`
                      : `Created at ${formatDate(new Date(row.createdAt))} by ${createdBy}`}
                  </Typography>
                </Grid>
                {headerIndex === newHeaders.length - 1 ? (
                  <Grid item sm={5}>
                    {actionButton}
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            )
          }
        } else {
          header.renderColumn = (column: any, row: any, key: number) => {
            return (
              <Grid container alignItems="center">
                <Grid item sm={7}>
                  <Typography>{column ? format(header)(column) : '-'}</Typography>
                </Grid>
                {headerIndex === newHeaders.length - 1 ? (
                  <Grid item sm={5}>
                    {actionButton}
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            )
          }
        }

        if (!header.function) {
          return header
        } else {
          const func = eval(header.function)

          header.renderColumn = (column: any, row: any, key: number) => {
            return <Typography>{format(header)(func(row))}</Typography>
          }
        }

        return header
      })

      const flattenedSearch = flat(entries.search)
      const sortValue =
        parseInt(
          flattenedSearch[Object.keys(flattenedSearch).filter(a => a.includes('sort.'))[0]],
        ) === 1
          ? 1
          : -1
      let sortName = Object.keys(flattenedSearch).filter(a => a.includes('sort.'))[0]

      if (sortName) {
        sortName = sortName.replace('sort.', '')
        newHeaders = newHeaders.map((header: any) => {
          let sort = header.sort
          if (header.name === sortName) {
            sort = sortValue
          }

          return {
            ...header,
            sort,
          }
        })
      }

      $internalHeaders.set(newHeaders)
    }
  }, [headers, entries.search])

  return (
    <layoutComponent.render
      {...layout}
      content={
        <Box>
          <Box bgcolor="#ffffff" pt={3} pb={3}>
            <Container maxWidth="xl">
              <Grid container alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4">
                    <Box fontWeight="fontWeightMedium">{name.plural}</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography component="span">
                    <Box textAlign="right">
                      Page {`${entries.search.page}`} of {`${entries.payload.totalPage || 1}`}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box bgcolor="#ffffff" pb={4}>
            <Container maxWidth="xl">
              <Typography variant="subtitle1">
                <Box fontWeight="fontWeightMedium" pb={1} mr={1}>
                  Create a {name.singular} from Template
                </Box>
              </Typography>
              <Box display="flex" alignItems="center">
                <Button
                  component={RouterLink}
                  to={url.new}
                  label={`Blank ${name.singular}`}
                  startIcon={<Icon.AddCircle color="#ffffff" />}
                  variant="contained"
                  color="secondary"
                />
                {templates &&
                  templates.payload.data.map((template: any, key: any) => {
                    return (
                      <Box ml={1} key={key}>
                        <Button
                          component={RouterLink}
                          to={`${url.new}/${template._id}`}
                          label={template.name}
                          icon={<Icon.AddCircle />}
                          variant="contained"
                          color="secondary"
                        />
                      </Box>
                    )
                  })}
              </Box>
            </Container>
            {entries.search.page === 1 &&
              recentEntries.payload &&
              recentEntries.payload.data.length > 0 && (
                <Container maxWidth="xl">
                  <Box mt={3}>
                    <Typography variant="subtitle1">
                      <Box fontWeight="fontWeightMedium" pb={1}>
                        Recent {name.plural}
                      </Box>
                    </Typography>
                    <Table
                      headers={(() => {
                        if (schema.payload.headers) {
                          const header = { ...schema.payload.headers[0] }
                          const format = (header: any) => {
                            switch (header.format) {
                              case 'money':
                                return (column: any) => formatPrice(column)
                              case 'date':
                                return (column: any) => formatDate(new Date(column))
                              default:
                                return (column: any) => column
                            }
                          }

                          header.width = 0.22
                          delete header.sort
                          header.renderColumn = (column: any, row: any, key: number) => {
                            return (
                              <Typography key={key}>
                                <Link
                                  component={RouterLink}
                                  to={`${history.location.pathname}/${row._id}`}
                                >
                                  {column ? format(header)(column) : '-'}
                                </Link>
                              </Typography>
                            )
                          }

                          return [
                            header,
                            {
                              label: 'Stage',
                              name: 'stage.name',
                              width: 0.78,
                            },
                          ]
                        }

                        return []
                      })()}
                      topOffset={110}
                      data={recentEntries.payload.data}
                    />
                  </Box>
                </Container>
              )}
          </Box>
          <Box pt={4} pb={4}>
            <Container maxWidth="xl">
              <Box mb={2}>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                  {schema.payload.allowSearch && (
                    <Grid item xs={12} sm={2}>
                      <TextField
                        name={'search'}
                        label={'Search'}
                        onChange={(value: string) => {
                          const defaultPopulate = {
                            ...(() => {
                              if (schema.payload.populateItems) {
                                const populate = schema.payload.populateItems
                                  .filter((a: any) => a.allowFromTable)
                                  .map((item: any) => {
                                    return {
                                      key: item.key,
                                      from: item.from,
                                    }
                                  })

                                if (populate.length > 0) {
                                  return { populate }
                                }
                              }
                              return {}
                            })(),
                          }

                          const search: any = {
                            search: {
                              page: 1,
                              search: {
                                [schema.payload.primaryField]: value,
                                ...(() => {
                                  const searchKeys: any = {}
                                  const additionalSearchKeys =
                                    schema.payload.additionalSearchKeys || []

                                  additionalSearchKeys.map((key: any) => {
                                    searchKeys[key] = value
                                  })

                                  return searchKeys
                                })(),
                              },
                              ...defaultPopulate,
                            },
                            updateGetParams: true,
                          }

                          if (debounceId) {
                            $entries.cancel()
                          }

                          if (!value) {
                            search['deleteSearch'] = `search.${schema.payload.primaryField}${
                              schema.payload.additionalSearchKeys &&
                              schema.payload.additionalSearchKeys.length
                                ? `,${schema.payload.additionalSearchKeys.join()}`
                                : ''
                            }`
                          }

                          $entries.get({ ...search, id: 'search' })
                        }}
                        placeholder="Search..."
                      />
                    </Grid>
                  )}

                  {schema.payload.stages && schema.payload.stages.length > 0 && (
                    <Grid item xs={12} sm={2}>
                      <Select
                        label="Stage"
                        options={[{ name: 'All' }, ...schema.payload.stages]}
                        value={{
                          name:
                            entries.search.stage && entries.search.stage.name
                              ? entries.search.stage.name.equal
                              : '',
                        }}
                        renderValue={(option: any) => {
                          if (typeof option == 'string') {
                            return option
                          }
                          if (showingAllStages) {
                            return 'All'
                          }

                          return entries.search.stage && entries.search.stage.name
                            ? entries.search.stage.name.equal
                            : schema.payload.stages.filter((a: any) => a.isDefault)[0] &&
                                schema.payload.stages.filter((a: any) => a.isDefault)[0].name
                        }}
                        renderOption={(option: any) => {
                          if (typeof option == 'string') {
                            return option
                          }
                          return option.name
                        }}
                        onChange={(data: any) => {
                          if (data) {
                            if (data.name === 'All') {
                              $entries.get({
                                id: 'select 1',
                                url: url.api,
                                deleteSearch: 'stage.name.equal',
                                updateGetParams: true,
                              })
                              $showingAllStages.set(true)
                            } else {
                              $entries.get({
                                id: 'select 2',
                                url: url.api,
                                search: {
                                  stage: {
                                    name: {
                                      equal: data.name,
                                    },
                                  },
                                },
                                updateGetParams: true,
                              })
                              $showingAllStages.set(false)
                            }
                          }
                        }}
                        // style={{
                        //   width: 140,
                        //   marginRight: 16,
                        // }}
                        size="sm"
                      />
                    </Grid>
                  )}
                  {schema.payload.filters &&
                    schema.payload.filters.length > 0 &&
                    schema.payload.filters.map((filter: any, key: any) => {
                      return <Filter filter={filter} fetch={entries} $fetch={$entries} key={key} />
                    })}
                </Grid>
              </Box>

              {!entries.meta.isLoading ? (
                <Box mb={2}>
                  {entries.payload.data.length > 0 ? (
                    <Table
                      headers={[...internalHeaders]}
                      topOffset={110}
                      data={entries.payload.data}
                      onSort={(header: any) => {
                        $entries.get({
                          id: 'table sort',
                          search: {
                            sort: { [header.name]: header.sort === 0 ? 1 : -1 },
                          },
                          updateGetParams: true,
                          allowDeepMerge: false,
                        })
                      }}
                      selectKey="_id"
                      selected={selected}
                      onSelect={data => {
                        $selected.set([data['_id']])
                      }}
                      onContextMenu={(event: any) => {
                        $setAnchorEl(event.currentTarget)
                        $floatMenuOpen.set(true)
                      }}
                    />
                  ) : (
                    <Alert severity="info">No {name.plural} found</Alert>
                  )}
                </Box>
              ) : (
                <Container maxWidth="xl">
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Box>
                </Container>
              )}

              {entries.payload.data.length > 0 && entries.payload.totalPage > 1 && (
                <Pagination data={entries} $data={$entries} />
              )}
              <FloatMenu
                open={floatMenuOpen}
                handleOpen={() => $floatMenuOpen.set(true)}
                handleClose={() => {
                  $floatMenuOpen.set(false)
                  $setAnchorEl(null)
                }}
                anchorElem={anchorEl}
                anchorPosition={anchorPosition}
                anchorRef={anchorReference}
                options={[
                  {
                    type: 'subtitle',
                    label: 'Entry',
                  },
                  {
                    label: 'Edit',
                    to: selected && `${history.location.pathname}/${selected[0]}/edit`,
                  },
                  {
                    label: 'Delete',
                    onClick: async () => {
                      DialogWrapper.push({
                        componentProps: {
                          label: 'Are you sure to delete?',
                          confirm: async () => {
                            await $postApi.delete({
                              url: `${url.api}/${selected[0]}`,
                            })
                            $trigger.set(Date.now())

                            $selected.reset()
                            DialogWrapper.pop()
                          },
                        },
                      })
                    },
                  },
                  ...(() => {
                    if (schema.payload.stages && schema.payload.stages.length > 0) {
                      return [
                        {
                          type: 'break',
                        },
                        {
                          type: 'subtitle',
                          label: 'Change Status',
                        },
                        ...schema.payload.stages.map((stage: any) => {
                          return {
                            label: stage.name,
                            onClick: async () => {
                              $postApi
                                .put({
                                  url: `${url.api}/${selected}`,
                                  body: {
                                    stage: {
                                      ...stage,
                                      updatedBy: `${org.payload.user.firstName} ${org.payload.user.lastName}`,
                                    },
                                  },
                                })
                                .then((res: any) => {
                                  $trigger.set(Date.now())
                                  $selected.reset()
                                })
                                .catch((e: any) => {
                                  DialogWrapper.push({
                                    type: 'Prompt',
                                    componentProps: {
                                      label: e.title,
                                    },
                                  })
                                })
                            },
                          }
                        }),
                      ]
                    }
                    return []
                  })(),
                ]}
              />
            </Container>
          </Box>
        </Box>
      }
    />
  )
}
