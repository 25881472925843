import React from 'react'

/* Routes ======================================================================================= */
// import { routes } from './routes'

/* Constants ==================================================================================== */
import { HEADER, NAVBAR, TOOLBAR } from '../../modules/app-layout/app-layout.constants'

/* Components =================================================================================== */
import { AppLayoutClass } from '../../modules/app-layout'
import { Header } from '../../components/header'
import { Menubar } from '../../components/menubar'
import { Navbar } from '../../components/navbar'

/* App Layout Init ============================================================================== */
const AppLayout = new AppLayoutClass()

AppLayout.setConfig({
  menubar: {
    active: true,
  },
})

/* App Layout Components ======================================================================== */
AppLayout.setComponent(HEADER, Header)
AppLayout.setComponent(NAVBAR, Navbar)
AppLayout.setComponent(TOOLBAR, Menubar)

export default AppLayout
