/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

/* Styled Components ============================================================================ */
export const useStyles = makeStyles({
  wwwSelect: {
    '& .MuiInputBase-root': {
      height: '33px',
    },
  },
  wwwUserButton: {
    '&:hover': {
      background: 'transparent',
    },
  },
  wwwUserMenu: {
    '& .MuiMenu-list': {
      padding: '0',
    },
  },
})
