import { DOMElement } from 'react'

export const parseWidth = (width?: string | number) => {
  if (!width) {
    return undefined
  }

  let parsedWidth = `${width}`

  if (typeof parseInt(width as any) === 'number') {
    parsedWidth += 'px'
  }

  return parsedWidth
}

export const calcPrimaryHeadersWidth = (
  tableParentRef: any,
  left: any,
  primaryHeaders: any,
) => () => {
  let widthCombined = 0
  let minWidthCombined = 0

  for (let i = 0; i < primaryHeaders.length; i++) {
    const width = primaryHeaders[i].width
    const minWidth = primaryHeaders[i].minWidth

    widthCombined += parseFloat(width) || 0
    minWidthCombined += parseInt(minWidth) || 0
  }

  if (tableParentRef.current) {
    const currentWidth = tableParentRef.current.getBoundingClientRect().width * widthCombined

    if (currentWidth > minWidthCombined) {
      return `calc(${widthCombined * 100}% + ${left}px)`
    }
  }

  return `calc(${minWidthCombined}px + ${left}px)`
}
