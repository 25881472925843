import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { InfoStyled } from './info.styled'

const Info: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <InfoStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M28.1223 9.00117C31.9877 15.6961 29.6938 24.257 22.9988 28.1223C16.3039 31.9877 7.74302 29.6938 3.87767 22.9988C0.0123209 16.3039 2.30619 7.74302 9.00117 3.87767C13.9858 0.999804 20.0047 1.53614 24.3499 4.76108"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18 8C18 9.10457 17.1046 10 16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8Z"
        className="stroke stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 15C14 13.8954 14.8954 13 16 13C17.1046 13 18 13.8954 18 15V24C18 25.1046 17.1046 26 16 26C14.8954 26 14 25.1046 14 24V15Z"
        className="stroke stroke-path-3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </InfoStyled>
  )
}

export default Info
