import React, { FC, useContext, useEffect } from 'react'
import { classes } from 'typestyle'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Constants ==================================================================================== */
import { TOPBAR_HEIGHT, SIDEBAR, HEADER_HEIGHT, TOOLBAR_HEIGHT } from './app-layout.constants'

/* Styles ======================================================================================= */
import { sidebarClass } from './sidebar.class'

/* Components =================================================================================== */
import { AppContext } from './app-layout.controller'

/* Types ======================================================================================== */
type SidebarProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  context: React.Context<AppContext>
}

const defaultProps: {} = {}

/* <Sidebar /> ================================================================================= */
const SidebarComponent: React.FC<SidebarProps> = props => {
  const { className, children, style, context } = props
  const appLayoutContext = useContext(context)
  const { components, $components } = appLayoutContext
  const { header, topbar, toolbar } = components
  const [active, $active] = useBoolean(false)
  const show = () => $active.set(true)
  const hide = () => $active.set(false)
  const toggle = () => $active.toggle()

  useEffect(() => {
    $components.patch(SIDEBAR, {
      active,
      $active,
      show,
      hide,
      toggle,
    })
  }, [])

  useEffect(() => {
    $components.patch(SIDEBAR, {
      active,
      $active,
      show,
      hide,
      toggle,
    })

    localStorage.setItem(`${SIDEBAR}`, `${active}`)
  }, [active])

  const getTopOffset = () => {
    let top = 0

    if (topbar && topbar.active) {
      top += TOPBAR_HEIGHT
    }

    if (header && header.active) {
      top += HEADER_HEIGHT
    }

    if (toolbar && toolbar.active) {
      top += TOOLBAR_HEIGHT
    }

    return top
  }

  const { base, proped } = sidebarClass.setProps({ topOffset: getTopOffset() })

  return (
    <div
      className={classes('www-sidebar', base, active && proped.active, proped.offsetted, className)}
      style={style}
    >
      {children}
    </div>
  )
}

SidebarComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Sidebar = SidebarComponent
