import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { UploadStyled } from './upload.styled'

const Upload: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <UploadStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M7 21H2V29H6M25 21H30V29C22.8172 29 18.0528 29 12 29"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 12.6806L16.0241 3L8 12.6806H12.9191V24H19.0809V12.6806H24Z"
        className="stroke"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </UploadStyled>
  )
}

export default Upload
