import React, { SyntheticEvent, useRef, useEffect, CSSProperties } from 'react'
import { classes } from 'typestyle'

import useBoolean from '../../hooks/use-boolean'

import { componentClass } from './component.class'
import { ComponentProps } from './component.types'

import { Float } from '../float'
import { Components } from './components'
import { combineClassNames } from '../__utils/combine-class-names'

export const withComponent = <P extends {}>(
  Component: React.ComponentType<P>,
  componentBase?: string,
) => (props: P & ComponentProps) => {
  const ref = useRef(null)
  const [note, $note] = useBoolean(false)
  const [tooltip, $tooltip] = useBoolean(false)
  const { base, defaultBase, tooltipBase } = componentClass.setProps({})

  useEffect(() => {
    if (props.componentProps && props.componentProps.id) {
      Components.$elements.push({
        id: props.componentProps.id,
        element: ref.current,
        render,
        $note,
      })
    }
  }, [])

  const handle = (name: string) => {
    switch (name) {
      case 'mouseOver':
      case 'mouseEnter':
      case 'click':
        return (e: any) => {
          $tooltip.set(true)
        }
      case 'mouseOut':
        return (e: any) => {
          $tooltip.set(false)
        }
    }
  }

  const render = () => (
    <div
      className={classes(
        base,
        componentBase || defaultBase,
        combineClassNames(props.classNames) || props.className,
      )}
      ref={ref}
      style={props.style}
      onMouseOver={handle('mouseOver')}
      onMouseEnter={handle('mouseEnter')}
      onMouseLeave={handle('mouseOut')}
    >
      {props.tooltip && (
        <Float
          anchorPosition={'bottom right'}
          close={() => true}
          dialogPosition={'top right'}
          offsetY={8}
          open={tooltip}
          ref={ref}
        >
          <div className={tooltipBase}>{props.tooltip}</div>
        </Float>
      )}
      {props.componentProps && (
        <Float
          anchorPosition={'bottom left'}
          close={() => $note.set(false)}
          dialogPosition={'bottom right'}
          offsetY={8}
          open={note}
          ref={ref}
        >
          {props.componentProps.note}
        </Float>
      )}
      <Component {...(props as P)} />
    </div>
  )

  return render()
}
