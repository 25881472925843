/**
 * reponsive 무시. I wantet to disable responsive but it was before
 * I changed font size to rem
 */

import styled from 'styled-components'

const h1 = styled.h1`
  font-size: 4rem;
  line-height: 4rem;
`
/** Heading 2 */
const h2 = styled.h2`
  font-size: 3.75rem;
  line-height: 3.5rem;
`
/** Heading 3 */
const h3 = styled.h3`
  font-size: 3rem;
  line-height: 3rem;
`
/** Heading 4 */
const h4 = styled.h4`
  font-size: 2.125rem;
  line-height: 2.5rem;
`
/** Heading 5 */
const h5 = styled.h5`
  font-size: 1.5rem;
  line-height: 2rem;
`
/** Heading 6 */
const h6 = styled.h6`
  font-size: 1.25rem;
  line-height: 2rem;
`
/** Paragraph 1 */
const p1 = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
`
/** Paragraph 2 */
const p2 = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
`
/** Paragraph 3 */
const p3 = styled.div`
  font-size: 0.625rem;
  line-height: 1rem;
`

/** Description 1 */
const d1 = styled.p`
  font-size: 2.125rem;
  line-height: 2.5rem;
`

/** Label 1 */
const l1 = styled.label`
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-bottom: 0px;
  padding-bottom: 4px;
  // text-transform: uppercase;
`

/** Label 2 */
const l2 = styled.label`
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.8px;
`

const l3 = styled.label`
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
  margin-bottom: 4px;
  padding-bottom: 4px;
  // text-transform: uppercase;
`

/** Error 1 */
const e1 = styled.label<{ children: any }>`
  display: block;
  font-size: 11px;
  margin-top: 4px;
  margin-bottom: ${({ children }) => (children ? '8px' : '0')};
`

/** Anchor 1 */
const a1 = styled.label<{ highlight?: boolean }>`
  transition: color 0.18s cubic-bezier(0.19, 1, 0.22, 1);
`

const a2 = styled.label<{ highlight?: boolean }>`
  width: 100%;
  position: relative;
  padding: ${({ highlight }) => (highlight ? '8px' : '8px 0px')};

  onbackground: ${({ highlight }) => highlight && 'rgb(250,250,250)'};
  transition: color 0.18s cubic-bezier(0.19, 1, 0.22, 1);

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    onbackground: white;
    display: ${({ highlight }) => (highlight ? 'inline-block' : 'none')};
    position: absolute;
    box-sizing: border-box;
    right: -10px;
    border-left: 1px solid rgb(230, 230, 230);
    border-bottom: 1px solid rgb(230, 230, 230);
    top: 50%;
    // top: 0;
    transform-origin: center;
    transform: translateY(-50%) rotate(45deg);
  }
`
export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p1,
  p2,
  p3,
  a1,
  a2,
  d1,
  e1,
  l1,
  l2,
  l3,
}
