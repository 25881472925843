import { generateSet } from './set.init'

export const show: Show = {
  ...generateSet({
    key: { show: 'display' },
    getValue: () => 'inherit',
    incremental: false,
  }),
}

/** Below type is generated by `generateTypes` in set.ts */
type Show = {
  show: {}
  showi: {}
  showsm: {}
  showsmi: {}
  showmd: {}
  showmdi: {}
  showlg: {}
  showlgi: {}
  showxl: {}
  showxli: {}
}
