import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { SchemaStyled } from './schema.styled'

const Schema: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <SchemaStyled
      viewBox={'0 0 32 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M5 20V17.1429C5.875 15.7333 8.5 13.1429 12 12M27 20V17.1429C26.125 15.7333 23.5 13.1429 20 12M16 13V20M2 24H8V30H2V24ZM24 24H30V30H24V24ZM13 24H19V30H13V24ZM13 2H19V8H13V2Z"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SchemaStyled>
  )
}

export default Schema
