import React, { useEffect, FC, useRef } from 'react'
import styled from 'styled-components'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
import { Float } from '../float'
import OutsideClickHandler from 'react-outside-click-handler'
import { Button } from '../button'
import { Option, OptionContainer } from '../select'
import useValue from '../../hooks/use-value'
import { CSSTransition } from 'react-transition-group'
import { Link } from 'react-router-dom'
import { Box } from '../box/box.component'
import { Line } from '../line'
import SET from '../../styles/set'
import { Text } from '../text/text.component'

/* Material UI ======================================================================================== */
import { Menu as MaterialMenu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'

const StyledOption = styled(Option)`
  font-size: 14px;
  min-width: 144px;
  width: 100%;
  padding: 8px 16px;
  float: left;
  white-space: nowrap;
`

const FloatMenuContainer = styled.div`
  z-index: 20;
  &.popin-enter {
    opacity: 0;
    transform: translateY(-8px);
  }

  &.popin-enter-active {
    opacity: 1;
    transform: translateY(0px);
  }

  &.popin-exit {
    opacity: 1;
    transform: translateY(0px);
  }

  &.popin-exit-active {
    opacity: 0;
    transform: translateY(-8px);
  }
`

/* <Menu /> ===================================================================================== */
export const Menu: React.FC<{
  options: { onClick?: any; type?: string; label: any; to?: string }[]
  dataKey?: string
  button?: any
}> = ({ options, button }) => {
  const [focus, $focus] = useBoolean(false)
  const parentRef = useRef(null)
  const [open, $open] = useBoolean(false)

  const handleClick = () => {
    $focus.toggle()
    $open.set(true)
  }

  const handleClose = () => {
    $focus.set(false)
    $open.set(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div ref={parentRef}>
        <Button {...button} onClick={handleClick} />
      </div>
      <Float
        ref={parentRef}
        anchorPosition={'top right'}
        dialogPosition={'top right'}
        close={$open.reset}
        open={open}
      >
        <Box shadow>
          <OptionContainer>
            {options.map((option: any, key) => {
              switch (option.type) {
                case 'subtitle':
                  return (
                    <div
                      className={SET.classes(SET.pl16, SET.mb8)}
                      key={key}
                      style={{ marginTop: 4 }}
                    >
                      <Text size="sm">{option.label}</Text>
                    </div>
                  )
                case 'break':
                  return <Line className={SET.mb8} key={key} />
                default:
                  if (option.to) {
                    return (
                      <Link to={option.to} key={key}>
                        <StyledOption
                          onClick={() => {
                            if (option.onClick) option.onClick()

                            handleClose()
                          }}
                        >
                          {option.label}
                        </StyledOption>
                      </Link>
                    )
                  }
                  return (
                    <StyledOption
                      onClick={() => {
                        if (option.onClick) option.onClick()

                        handleClose()
                      }}
                      key={key}
                    >
                      {option.label}
                    </StyledOption>
                  )
              }
            })}
          </OptionContainer>
        </Box>
      </Float>
    </OutsideClickHandler>
  )
}

export const FloatMenu: FC<{
  open: boolean
  options: { onClick?: any; label: any; to?: string }[]
  handleOpen: () => void
  handleClose: () => void
  anchorElem: any
  anchorPosition?: any
  anchorRef?: string
}> = ({
  open = false,
  options,
  handleClose,
  handleOpen,
  anchorElem,
  anchorPosition,
  anchorRef,
}) => {
  const [floatMenuCoordinate, $floatMenuCoordinate] = useValue(anchorPosition)
  const [anchorReference, $setAnchorReference] = useValue(anchorRef)
  const [internalOpen, $internalOpen] = useValue(false)
  const parentRef = useRef(null)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        $internalOpen.set(true)
      }, 50)
    } else {
      $internalOpen.set(false)
    }
  }, [open])

  useEffect(() => {
    const handleMouseDown = (event: any) => {
      if (event.which === 3) {
        $floatMenuCoordinate.set({ left: event.clientX, top: event.clientY })
        $setAnchorReference.set('anchorPosition')
      }
    }

    window.addEventListener('mousedown', handleMouseDown)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  return (
    <>
      <List
        component={MaterialMenu}
        ref={parentRef}
        open={internalOpen}
        onClose={() => {
          handleClose()
          $internalOpen.set(false)
        }}
        dense
        anchorEl={anchorElem}
        anchorReference={anchorReference}
        anchorPosition={floatMenuCoordinate}
      >
        {options.map((option: any, key) => {
          switch (option.type) {
            case 'subtitle':
              return <ListSubheader key={key}>{option.label}</ListSubheader>
            case 'break':
              return <Divider key={key} />
            default:
              if (option.to) {
                return (
                  <ListItem
                    button
                    component={Link}
                    to={option.to}
                    key={key}
                    onClick={() => {
                      if (option.onClick) option.onClick()

                      handleClose()
                    }}
                  >
                    {option.label}
                  </ListItem>
                )
              }
              return (
                <ListItem
                  button
                  onClick={() => {
                    if (option.onClick) option.onClick()

                    handleClose()
                  }}
                  key={key}
                >
                  {option.label}
                </ListItem>
              )
          }
        })}
      </List>
    </>
  )
}
