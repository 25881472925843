import { NAVBAR_WIDTH } from '../../modules/app-layout/app-layout.constants'

/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

/* Styled Components ============================================================================ */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwBottomAppBar: {
      // width: `calc(100% - ${navbar.active ? NAVBAR_WIDTH : 0}px)`,
      width: `calc(100% - ${NAVBAR_WIDTH}px)`,
      top: 'auto',
      bottom: 0,
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
  }),
)
