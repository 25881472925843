/**
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * WILL BE DELETED
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * */
import React, { FC, useEffect } from 'react'
import { withRouter, useHistory } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useAxios from '../../../hooks/use-axios'

/* Components =================================================================================== */
import AuthenticationLayout, { StyledForm } from './authentication.layout'
import SET from '../../../styles/set'
import { DialogWrapper } from '../../../modules/dialog-wrapper'
import { Link } from 'react-router-dom'
import { Submit } from '../../../modules/submit'
import { Text } from '../../../modules/text'
import { TextField } from '../../../modules/text-field'
import useValue from '../../../hooks/use-value'

/* Material UI ======================================================================================== */
import Typography from '@material-ui/core/Typography'

/* <ForgetPasswordForm /> ================================================================================ */
const ChangePasswordForm: FC<{ match: any }> = ({ match }) => {
  const { token } = match.params
  const [, $users] = useAxios(`${process.env.REACT_APP_API}/users/change-password`)
  const [error, $error] = useValue()
  const history = useHistory()
  let timeoutId: number

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId)
    }
  })

  const passwordLength = (value: any) => {
    return value.length < 8 || value === undefined
      ? 'Password must be longer than 8 characters'
      : undefined
  }

  const empty = (value: any) => {
    return value === '' || value === undefined ? 'can not be empty' : undefined
  }

  const validationTools = [empty, passwordLength]

  const handleSubmit = async (data: any) => {
    let dialogData: any = {}
    if (data.password !== data.passwordConfirm) {
      dialogData = {
        render: <div>Passwords do not match</div>,
        label: 'Error',
        status: 'danger',
      }
      DialogWrapper.push(dialogData)
      return false
    }

    data.token = token

    //attach token to payload
    const response = await $users.put(data)
    if (response) {
      dialogData = {
        render: <div>You have succesfully changed your password</div>,
        label: 'Success',
      }
      DialogWrapper.push(dialogData)
    }
  }

  return (
    <AuthenticationLayout title="Type and confirm your new password">
      <StyledForm name="users" onSubmit={handleSubmit}>
        <TextField
          name="password"
          type="password"
          label="New Password"
          validate={validationTools}
        />
        <TextField
          name="passwordConfirm"
          type="password"
          label="Confirm New Password"
          validate={validationTools}
        />
        <Submit label="Submit" className={SET.classes(SET.mb8, SET.mt24, SET.fw)} />
        <Typography>
          Already have an account? <Link to={'/login'}>Click here</Link>
        </Typography>
      </StyledForm>
      {/* <DialogWrapper.render /> */}
    </AuthenticationLayout>
  )
}

export default withRouter(ChangePasswordForm)
