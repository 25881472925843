import React from 'react'

/* Material UI  ======================================================================================== */
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export const Collapsible: React.FC<{ name: string; fields: any; renderForm: any }> = ({
  name,
  fields,
  renderForm,
}) => {
  return (
    <Box>
      <Box>
        <Box p={2}>
          {/* <Typography size={'sm'} semiBold tag={<label />}> */}
          <Typography variant="subtitle2">{name.split('.')[name.split('.').length - 1]}</Typography>
        </Box>

        <Box p={2}>
          {fields.map((field: any, key: number) => {
            return renderForm({ ...field, name: `${name}.${field.name}` }, key)
          })}
        </Box>
      </Box>
    </Box>
  )
}
