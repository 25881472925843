import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useAxios from '../../../hooks/use-axios'
import useValue from '../../../hooks/use-value'

/* Components =================================================================================== */
import AuthenticationLayout, { StyledForm } from './authentication.layout'
import SET from '../../../styles/set'
import { DialogWrapper } from '../../../modules/dialog-wrapper'
import { Link } from 'react-router-dom'
import { Submit } from '../../../modules/submit'
import { Text } from '../../../modules/text'
import { TextField } from '../../../modules/text-field'

/* Constants ==================================================================================== */
import { APP_CONFIG } from '../../../app-config'

/* Material UI  ======================================================================================== */
import Typography from '@material-ui/core/Typography'

/* <RegisterForm /> ============================================================================= */
const RegisterForm = () => {
  const [, $users] = useAxios(`${process.env.REACT_APP_API}/users/register`)
  const [error, $error] = useValue()
  const history = useHistory()
  let timeoutId: number

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId)
    }
  })

  const empty = (value: any) => {
    if (value === '' || value === undefined) {
      return 'Cannot be empty'
    }
    return undefined
  }

  const confirmPassword = (form: any) => {
    return [
      empty,
      (value: any) => {
        if (form.find('password') !== value) {
          return 'Password does not match'
        }
      },
    ]
  }

  const handleSubmit = async (data: any) => {
    const { error } = await $users.post(data)

    if (error) {
      $error.set('Some error occured!')
    } else {
      DialogWrapper.push({
        type: 'Info',
        componentProps: {
          label: 'Congratulations',
          body: (
            <div>
              You have successfully registered!
              <br />
              <br />
              You will be redirected to login page.
            </div>
          ),
        },
      })

      timeoutId = setTimeout(() => {
        DialogWrapper.pop()
        history.push('/login')
      }, APP_CONFIG.redirectWaitTime)
    }
  }

  return (
    <AuthenticationLayout title="Sign up" subtitle="by filling out the form below">
      <StyledForm name="users" onSubmit={handleSubmit} onChange={(data: any) => {}}>
        <TextField name="firstName" label="First Name" validate={[empty]} />
        <TextField name="lastName" label="Last Name" validate={[empty]} />
        <TextField name="email" label="Email" validate={[empty]} />
        <TextField name="password" type="password" label="Password" validate={[empty]} />
        <TextField
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          className={SET.mb24}
          validate={confirmPassword}
        />
        {error && (
          <>
            <hr />
            <Typography>{error}</Typography>
            <hr />
          </>
        )}
        <Submit label="Register" className={SET.classes(SET.mb8, SET.mt24, SET.fw)} />
        <Typography>
          Already have an acccount?{' '}
          <Link to={'login'} className={SET.underline}>
            Click here
          </Link>
        </Typography>
      </StyledForm>
      {/* <DialogWrapper.render /> */}
    </AuthenticationLayout>
  )
}

export default RegisterForm
