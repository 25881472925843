import React from 'react'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
import { borderClass } from './border.class'
import { ComponentPositionType } from '../__core/component.types'

/* Types ======================================================================================== */
type BorderProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  contrast?: 'light' | 'medium' | 'dark'
  position?: ComponentPositionType
}

const defaultProps: {} = {}

/* <Border /> =================================================================================== */
const BorderComponent: React.FC<BorderProps> = props => {
  const { className, children, style, contrast, position } = props

  const { base, proped } = borderClass.setProps({ contrast })

  return (
    <div
      className={classes(
        'www-grid-item',
        base,
        className,
        position === 'bottom' && proped.bottom,
        position === 'left' && proped.left,
        position === 'right' && proped.right,
        position === 'top' && proped.top,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

BorderComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Border = BorderComponent
