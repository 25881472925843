/* BEING REPLACED WITH MATERIAL UI BUTTON */
import React, { ElementType, FC } from 'react'
import styled from 'styled-components'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
// import { buttonClass } from './button.class'

/* Components =================================================================================== */
import {
  // ComponentBaselineType,
  // ComponentSizeType,
  // ComponentLeftRightType,
  ComponentProps,
  ComponentContextProps,
} from '../__core/component.types'

/* Components =================================================================================== */
// import { withComponent } from '../__core/component'
// import { textClass } from '../text/text.class'

/* Material UI ======================================================================================== */
import { Button as MaterialButton } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

/* Types ======================================================================================== */
type ButtonProps = {
  bind?: {}
  float?: boolean
  icon?: any
  iconPosition?: 'left' | 'right'
  square?: boolean
  style?: React.CSSProperties
  type?: 'button' | 'submit'
  center?: boolean
} & ComponentProps &
  muiButtonProps

type muiButtonProps = {
  color?: string
  variant?: string
  startIcon?: any
  endIcon?: any
  size?: string
  disabled?: boolean
  href?: string
  component?: ElementType
  to?: string
  fullWidth?: boolean
  edge?: any
  classes?: object
}

// const defaultProps: {
//   baseline?: ComponentBaselineType
//   iconPosition?: ComponentLeftRightType
//   // size?: ComponentSizeType
// } = {
//   baseline: 'surface',
//   iconPosition: 'left',
//   // size: 'lg',
// }

/* <Button /> =================================================================================== */
const ButtonComponent: React.FC<ButtonProps & ComponentContextProps> = props => {
  const {
    // alt,
    // baseline,
    bind,
    // float,
    icon,
    // iconPosition,
    label,
    onClick,
    onMouseLeave,
    onMouseOver,
    size,
    // square,
    // style,
    type = 'button',
    className,
    color = 'default',
    variant,
    disabled,
    startIcon,
    endIcon,
    href,
    component,
    to,
    fullWidth = false,
    edge,
    classes,
  } = props

  // const { base, proped, baseAnimated, baseThemed, iconBase } = buttonClass.setProps({
  //   alt,
  //   baseline,
  //   float,
  //   icon,
  //   iconPosition,
  //   label,
  //   size,
  //   square,
  // })

  const handleClick = (e: any) => {
    if (onClick && !disabled) {
      onClick(e)
    }
  }
  const buttonProps: any = {
    onClick: handleClick,
    onMouseOver: onMouseOver,
    onMouseLeave: onMouseLeave,
    type: type,
    color: color,
    size: size,
    href: href,
    className: className,
    component: component,
    to: to,
    ...(fullWidth && {fullWidth: fullWidth}),
    edge: edge,
    classes: classes,
  }

  return typeof icon !== 'undefined' && !label ? (
    <IconButton {...buttonProps}>
      {icon}
      {/* {React.cloneElement(icon, { props: { ...icon.props, style: { padding: '5px' } } })} */}
    </IconButton>
  ) : (
    <MaterialButton
      disableElevation
      //   textClass.names.baseSized[size || 'md'],
      //   base,
      //   float && proped.float,
      //   baseAnimated,
      //   baseThemed,
      //   className,
      // )}
      // style={style}
      // onClick={handleClick}
      // onMouseOver={onMouseOver}
      // onMouseLeave={onMouseLeave}
      // type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      {...bind}
      {...buttonProps}
    >
      {/* <span className={classes('www-button-icon', iconBase)}>
        {icon &&
          React.cloneElement(icon, { props: { baseline, ...icon.props, style: { color: 'red' } } })}
      </span> */}
      {label}
    </MaterialButton>
  )
}

// ButtonComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Button: FC<ButtonProps> = ButtonComponent
// export const Button: FC<ButtonProps> = withComponent(ButtonComponent)

export const ButtonCore = styled.button<{
  float?: boolean
  children?: any
  state?: boolean
  label?: string
  icon?: boolean
  iconPosition?: 'left' | 'right'
  success?: boolean
  baseline?: string
  // size?: ComponentSizeType
}>``
