import React, { ComponentType, createContext, useContext, useReducer } from 'react'

/* Reducer ====================================================================================== */
type State = { scrollDirection: boolean }
type Action = { type: 'set_scroll_direction'; scrollDirection: boolean }

const INITIAL_STATE: State = {
  scrollDirection: true,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set_scroll_direction':
      return {
        ...state,
        scrollDirection: action.scrollDirection,
      }
    default:
      return state
  }
}

/* Context ====================================================================================== */
const DispatchContext = createContext((() => 0) as React.Dispatch<Action>)
const StateContext = createContext(INITIAL_STATE)

export const GlobalProvider: ComponentType = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export const useGlobalDispatch = () => {
  return useContext(DispatchContext)
}

export const useGlobalState: () => State = () => {
  const state = useContext(StateContext)
  return state
}
