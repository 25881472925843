import { keyframes } from 'styled-components'

/**
 * STROKE ANIMATIONS
 */
export const drawStroke = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
`

/**
 * ROTATE ANIMATIONS
 */
export const rotate180 = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg);
    transform-origin: center;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: rotate(180deg);
    transform-origin: center;
  }
`

/**
 * BOUNCE ANIMATIONS
 */
export const bounce = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0px, -1px, 0px);
  }
  20% {
    transform: translate3d(0px, 1px, 0px);
  }
  40% {
    opacity: 1;
    transform: translate3d(0px, -1px, 0px);
  }
  60% {
    transform: translate3d(0px, 1px, 0px);
  }
  80% {
    transform: translate3d(0px, -1px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
`

/**
 * FADE ANIMATIONS
 */
export const fade = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
`

/**
 * SLIDE ANIMATIONS
 */
export const slideLeftRight = keyframes`
  0% {
    transform: translate3d(0, 0px, 0px);
  }
  50% {
    transform: translate3d(-5px, 0px, 0px);
  }
  100% {
    transform: translate3d(-2px, 0px, 0px);
  }
`

export const slideRightLeft = keyframes`
  0% {
    transform: translate3d(0, 0px, 0px);
  }
  50% {
    transform: translate3d(5px, 0px, 0px);
  }
  100% {
    transform: translate3d(2px, 0px, 0px);
  }
`

/**
 * SHAKE ANIMATIONS
 */
export const seesawShake = keyframes`
  0% {
    transform: rotate(3deg);
    transform-origin: center;
  }
  20%{
    transform: rotate(-3deg);
    transform-origin: center;
  }
  40%{
    transform: rotate(-3deg);
    transform-origin: center;
  }
  60% {
    transform: rotate(3deg);
    transform-origin: center;
  }
  80% {
    transform: rotate(-3deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: center;
  }
`
