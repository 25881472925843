/**
 * TextFieldComponent
 *
 * Write CSS with following as reference
 *  <Field> // from withField()
 *    <FieldContainer className='www-tfc'>
 *      <input className='www-tfi'/>
 *    </FieldContainer>
 *  </Field>
 */
import React, { useRef, useEffect, FC, useCallback } from 'react'
import { classes } from 'typestyle'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'
import { FormActions } from '../form/index'

/* Styles ======================================================================================= */
// import { textFieldClass } from './text-field.class'
// import { fieldClass } from '../field/field.class'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'
// import { Box } from '../box/box.component'

/* Material UI ======================================================================================== */
import { TextField as MaterialTextField } from '@material-ui/core'

type TextFieldProps = {
  type?: 'text' | 'password' | 'hidden'
  baseline?: ComponentBaselineType
  InputProps?: object
  // inputLabel?: string
} & FieldProps

/* <TextFieldComponent /> ======================================================================= */
/**  See bottom for wrapped component */
const TextFieldComponent: React.FC<FieldContextProps & {
  type: 'text' | 'password'
}> = props => {
  const {
    // baseline = 'surface',
    dispatch,
    name,
    label,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    // size = 'md',
    type = 'text',
    field = {
      focused: undefined,
      validate: [],
    },
    handleValidate,
    handleValue,
    InputProps,
    helperText,
  } = props
  const inputRef = useRef(null)
  const [value, $value] = useValue(props.value || '')
  const [focused, $focused] = useValue(props.focused || false)

  useEffect(() => {
    // if (dispatch) {
    // dispatch({
    //   type: FormActions.FIELD,
    //   param: { name, value: { validate: props.validate, beforeSubmit: props.beforeSubmit } },
    // })
    // }
  }, [dispatch])

  useEffect(() => {
    let _value = props.formValue || ''

    if (_value && props.beforeValue) {
      _value = props.beforeValue(_value)
    }
    $value.set(_value)
  }, [props.formValue])

  useEffect(() => {
    if (props.value !== undefined) {
      handle('change')(props.value || '').then(() => {
        handle('blur')(props.value || '')
      })
    }
  }, [props.value])

  useEffect(() => {
    $focused.set(field.focused)
  }, [field.focused])

  useEffect(() => {
    if (field.focused || (inputRef as any).current === document.activeElement) {
      handle('focus')()
      ;(inputRef as any).current.focus()
    }

    if (field.focused === false) {
      handle('blur')()
    }
  }, [field.focused])

  useEffect(() => {
    $focused.set(props.focused)
  }, [props.focused])

  /* Field ClassNames --------------------------------------------------------------------------- */
  // const { fieldSized } = fieldClass.setProps({
  //   size: props.size,
  // })

  /* ClassNames --------------------------------------------------------------------------------- */
  // const { base, themed, proped } = textFieldClass.setProps({ baseline })

  /* Event Handlers ----------------------------------------------------------------------------- */
  const handle: (type: string) => any = useCallback(
    type => {
      switch (type) {
        case 'change':
          return async (value: any) => {
            if (onChange) {
              onChange(handleValidate(value, props.validate))
            }

            $value.set(value)
          }
        case 'focus':
          return async () => {
            if (onFocus) {
              onFocus()
            }

            if (dispatch) {
              dispatch({ type: FormActions.FOCUS, param: { name } })
            } else {
              $focused.set(true)
            }
          }

        case 'blur':
          return async (value: any) => {
            if (onBlur) {
              onBlur()
            }

            if (dispatch && props.name && value !== undefined) {
              dispatch({
                type: FormActions.SET,
                param: { name, value: handleValidate(value, props.validate) },
              })
              dispatch({ type: FormActions.BLUR, param: { name } })
            } else {
              $focused.set(false)
            }
          }
        default:
          throw new Error('Unexpected event handler')
      }
    },
    [dispatch, value, props.validate],
  )

  const inputProps = {
    autoComplete: 'new-password',
    onBlur: () => handle('blur')(value),
    onChange: (e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
      handle('change')(e.target.value)
    },
    onFocus: handle('focus'),
    placeholder: placeholder,
    type: type,
    onKeyPress: (e: any) => {
      e.key === 'Enter' && e.preventDefault()
    },
  }

  if (InputProps) {
    Object.assign(inputProps, InputProps)
  }

  return (
    <MaterialTextField
      InputProps={inputProps}
      variant="outlined"
      label={label}
      fullWidth
      inputRef={inputRef}
      value={handleValue(value)}
      size="small"
      helperText={helperText}
    />
  )
}

export const TextField: FC<TextFieldProps> = withField(TextFieldComponent)
