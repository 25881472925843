import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { EditStyled } from './edit.styled'

const Edit: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <EditStyled
      viewBox={'0 0 33 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M16 7H2V20M24 20C24 23.0275 24 25.131 24 29H2V26"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1905 10.619L30 6.80952L26.1905 3L22.381 6.80953M26.1905 10.619L14.4898 22.3197L10 23L10.6803 18.5102L12.1358 17.0546M26.1905 10.619L22.381 6.80953M22.381 6.80953L15.1663 14.0242"
        className="stroke draw-stroke-path stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </EditStyled>
  )
}

export default Edit
