import React, { useEffect, FC, useRef, useContext } from 'react'

/* Hooks =================================================================================== */
import useValue from '../../hooks/use-value'
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
import { Checkbox } from '../../modules/checkbox/checkbox.component'
import { RepeaterField } from '../../modules/repeater-field'
import { Select } from '../../modules/select/select.component'
import { TextField } from '../../modules/text-field'
import { TableSelect } from '../table-select'
import { DateField } from '../date-field'
import { Text } from '../../modules/text/text.component'
import SET from '../../styles/set'
import { Textarea } from '../../modules/textarea'
import { Upload } from '../../modules/upload'
import Axios from 'axios'
// import { Box } from '../../modules/box/box.component'
import { Icon } from '../../modules/icons'
// import { Button } from '../../modules/button'

import { fieldClass } from '../../modules/field/field.class'
import { flat } from '../../share/obj-equal'
import { useStyles } from './render-filters.styled'

/* Material UI  ======================================================================================== */
// Core
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

// Lab
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { withRouter } from 'react-router-dom'
import useFetch from '../../hooks/use-fetch'

/* Constants ==================================================================================== */
const FIELD_TYPES = {
  Month: 'Month',
  Select: 'Select',
  TableSelect: 'TableSelect',
}

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const TableSelectFilter = withRouter((props: any) => {
  const { fetch, $fetch, filter, match } = props
  const { entryId, orgId, schemaId } = match.params as any
  const [items, $items] = useValue([])

  const [entries, $entries] = useFetch<{
    data: any[]
  }>(
    {
      data: [],
    },
    {
      updateGetParams: false,
      url: `${process.env.REACT_APP_API}/${filter.apiUrl
        .replace('{{orgId}}', (match as any).params.orgId)
        .replace('{{parent-entry}}', (match as any).params.parentEntryId)}`,
    },
  )

  useEffect(() => {
    if (entries.mounted) {
      $entries.get().then((payload: any) => {
        const temp: any = []
        payload.data.map((item: any) => {
          const flatten = flat(item)

          temp.push({
            value: flatten[filter.labelKey],
            _id: flatten[filter.valueKey],
          })
        })

        $items.set(temp)
      })
    }
  }, [entries.mounted])

  return (
    <Select
      label={filter.label}
      options={[{ value: 'All' }, ...(items || [])]}
      value={(() => {
        if (fetch.search.search) {
          const flattendSearch = flat(fetch.search.search)

          return { value: decodeURIComponent(flattendSearch[filter.key] || 'All') }
        }

        return { value: 'All' }
      })()}
      onChange={data => {
        if (data.value === 'All') {
          $fetch.get({
            deleteSearch: `search.${filter.key}`,
            updateGetParams: true,
          })
        } else {
          $fetch.get({
            search: {
              search: {
                [filter.key]: data.value,
              },
            },
            updateGetParams: true,
          })
        }
      }}
      renderValue={(option: any) => {
        if (typeof option == 'string') {
          return option
        }
        return option.value
      }}
      renderOption={(option: any) => {
        if (typeof option == 'string') {
          return option
        }
        return option.value
      }}
    />
  )
})

export const Filter = (props: { filter: any; key: any; fetch: any; $fetch: any }) => {
  const { filter, fetch, $fetch } = props
  const [date, $date] = useValue()
  const styleClasses = useStyles()

  useEffect(() => {
    if (fetch.search.date) {
      $date.set(new Date(decodeURIComponent(fetch.search.date.from.value)))
    }
  }, [fetch.search.date])

  return (
    <React.Fragment>
      {(() => {
        switch (filter.type) {
          case FIELD_TYPES.TableSelect: {
            return (
              <Grid item xs={12} sm={2}>
                <TableSelectFilter filter={filter} fetch={fetch} $fetch={$fetch} />
              </Grid>
            )
          }
          case FIELD_TYPES.Select: {
            return (
              <Grid item xs={12} sm={2}>
                <Select
                  label={filter.label}
                  options={[{ value: 'All' }, ...filter.items]}
                  value={(() => {
                    if (fetch.search.search) {
                      const flattendSearch = flat(fetch.search.search)

                      return { value: decodeURIComponent(flattendSearch[filter.key] || 'All') }
                    }

                    return { value: 'All' }
                  })()}
                  onChange={data => {
                    if (data.value === 'All') {
                      $fetch.get({
                        deleteSearch: `search.${filter.key}`,
                        updateGetParams: true,
                      })
                    } else {
                      $fetch.get({
                        search: {
                          search: {
                            [filter.key]: data.value,
                          },
                        },
                        updateGetParams: true,
                      })
                    }
                  }}
                  renderValue={(option: any) => {
                    if (typeof option == 'string') {
                      return option
                    }
                    return option.value
                  }}
                  renderOption={(option: any) => {
                    if (typeof option == 'string') {
                      return option
                    }
                    return option.value
                  }}
                />
              </Grid>
            )
          }
          case FIELD_TYPES.Month: {
            return (
              <Grid item xs={12} sm style={{ order: 10 }}>
                <Box display="flex" alignItems="center">
                  {/* <Typography variant="subtitle2">
                    <Box mr={1}>{filter.type}</Box>
                  </Typography> */}
                  <ButtonGroup color="default" className={styleClasses.wwwDateButtonGroup}>
                    <Button
                      value="all"
                      onClick={() => {
                        $date.set(null)
                        $fetch.get({
                          updateGetParams: true,
                          search: {
                            date: null,
                          },
                        })
                      }}
                    >
                      All
                    </Button>
                    <Button
                      value="prev"
                      onClick={() => {
                        const newDate = date ? new Date(date) : new Date()
                        newDate.setMonth(newDate.getMonth() - 1)

                        const y = newDate.getFullYear(),
                          m = newDate.getMonth()
                        const firstDay = new Date(y, m, 1)
                        const lastDay = new Date(y, m + 1, 0)

                        $fetch.get({
                          search: {
                            date: {
                              from: {
                                key: filter.key,
                                value: firstDay.toISOString(),
                              },
                              to: {
                                key: filter.key,
                                value: lastDay.toISOString(),
                              },
                            },
                          },
                          updateGetParams: true,
                        })

                        $date.set(newDate)
                      }}
                    >
                      Prev Month
                    </Button>
                    <Button
                      value="next"
                      onClick={() => {
                        const newDate = date ? new Date(date) : new Date()
                        newDate.setMonth(newDate.getMonth() + 1)

                        const y = newDate.getFullYear(),
                          m = newDate.getMonth()
                        const firstDay = new Date(y, m, 1)
                        const lastDay = new Date(y, m + 1, 0)

                        $fetch.get({
                          search: {
                            date: {
                              from: {
                                key: filter.key,
                                value: firstDay.toISOString(),
                              },
                              to: {
                                key: filter.key,
                                value: lastDay.toISOString(),
                              },
                            },
                          },
                          updateGetParams: true,
                        })

                        $date.set(newDate)
                      }}
                    >
                      Next Month
                    </Button>
                  </ButtonGroup>
                  {date && typeof date !== 'undefined' && (
                    <Box textAlign="center" ml={1}>
                      <Typography variant="caption">
                        <Box fontWeight="fontWeightMedium" lineHeight="1.5">
                          Filtered By
                        </Box>
                      </Typography>
                      <Typography variant="subtitle2">
                        <Box lineHeight="1">{`${
                          MONTH_NAMES[date.getMonth()]
                        } ${date.getFullYear()}`}</Box>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            )
          }
        }
      })()}
    </React.Fragment>
  )
}
