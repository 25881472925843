import 'typeface-roboto'
import React from 'react'
import ReactDOM from 'react-dom'
import { forceRenderStyles } from 'typestyle'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import App from './app'
import * as serviceWorker from './service-worker'

Bugsnag.start({
  apiKey: '4104c18c993076703eb423adb1943359',
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = (Bugsnag as any).getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <App />{' '}
  </ErrorBoundary>,
  document.getElementById('root'),
)
forceRenderStyles()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
