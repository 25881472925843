import React, { FC, useEffect, useContext } from 'react'
/* Components =================================================================================== */
// import SET from '../styles/set'

/* Components =================================================================================== */
import { Form } from '../../modules/form'
// import { Box } from '../modules/box/box.component'
// import { Heading } from '../modules/text/heading.component'
// import { classes } from 'typestyle'
import { Menu } from '../../modules/menu'
import { Icon } from '../../modules/icons'
import { DialogWrapper } from '../../modules/dialog-wrapper'
import useValue from '../../hooks/use-value'
import { useHistory, withRouter } from 'react-router-dom'
import { APIWrapperContext } from '../../api-wrapper'
import useFetch from '../../hooks/use-fetch'
import { structureObj } from '../../share/obj-equal'
import { useStyles } from './tab-template.styled'
import { formatDate } from '../date-field'

/* Material UI ======================================================================================== */
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'

/* <FormTemplate /> ============================================================================= */
const FormTemplateComponent: FC<any> = props => {
  const history = useHistory()
  const {
    formProps,
    AppLayout,
    layoutProps,
    children,
    config,
    loading,
    match,
    renderAction,
  } = props

  const { orgId, schemaId, templateId, parentEntryId } = match.params as any
  const [tempData, $tempData] = useValue()
  const [template, $template] = useFetch<{
    name: string
  }>()
  const { schema } = useContext(APIWrapperContext)

  const styleClasses = useStyles()

  useEffect(() => {
    if (templateId && template.mounted) {
      $template.get({
        url: `${process.env.REACT_APP_API}/templates/${templateId}`,
      })
    }
  }, [template.mounted])

  return (
    <AppLayout.render
      {...layoutProps}
      content={
        <AppLayout.context.Consumer>
          {(props: any) => {
            const { navbar } = props.components
            return (
              <Form
                {...formProps}
                excludeStructure={(() => {
                  const keys: any = new Set()

                  if (schema.payload.populateItems) {
                    schema.payload.populateItems.map((item: any) => {
                      let regex = item.key
                      regex = regex.replaceAll('.', '[.]')
                      regex = regex.replaceAll('$$', '[.][0-9]+')
                      regex = regex.replaceAll('$', '[.][0-9]+')

                      return keys.add(new RegExp(`^${regex}`))
                    })
                  }

                  return Array.from(keys)
                })()}
                onChange={(form: any, fields: any) => {
                  // console.log(form)
                  $tempData.set({ form, fields })
                }}
              >
                <Box pb={6}>
                  <Box bgcolor="#ffffff" pt={3} pb={3}>
                    <Container maxWidth="xl">
                      <Grid container>
                        <Grid item xs={12} sm={8}>
                          <Typography variant="h4">
                            <Box fontWeight="fontWeightMedium">
                              {(() => {
                                const form = tempData && tempData.form && tempData?.form
                                const primaryField = config.primaryField && config.primaryField
                                if (form && primaryField) {
                                  // Check if primaryField is a number & date
                                  const fieldLabel =
                                    isNaN(form[primaryField]) &&
                                    !isNaN(Date.parse(form[primaryField]))
                                      ? formatDate(new Date(form[primaryField]))
                                      : form[primaryField]
                                  if (history.location.pathname.includes('edit')) {
                                    if (config.name.singular) {
                                      return `Edit ${config.name.singular} - ${fieldLabel}`
                                    }
                                  }
                                  if (config.name.singular) {
                                    return `Create a ${config.name.singular}`
                                  }
                                }
                                return 'Loading...'
                              })()}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Box display="flex" justifyContent="flex-end">
                            <Menu
                              button={{
                                icon: <Icon.HorizontalEllipsis size="lg" />,
                                baseline: 'background',
                                alt: true,
                              }}
                              options={
                                templateId
                                  ? [
                                      {
                                        type: 'subtitle',
                                        label: 'Entry',
                                      },
                                      {
                                        label: 'Update template',
                                        onClick: () => {
                                          DialogWrapper.push({
                                            type: 'TemplateForm',
                                            componentProps: {
                                              label: 'Update Template',
                                              data: tempData,
                                              name: template.payload.name,
                                              organizationId: orgId,
                                              parentEntryId: parentEntryId,
                                              schemaId: schemaId,
                                              templateId,
                                            },
                                          })
                                        },
                                      },
                                      {
                                        label: 'Save as new template',
                                        onClick: () => {
                                          DialogWrapper.push({
                                            type: 'TemplateForm',
                                            componentProps: {
                                              label: 'Save as New Template',
                                              data: tempData,
                                              name: template.payload.name,
                                              organizationId: orgId,
                                              parentEntryId: parentEntryId,
                                              schemaId: schemaId,
                                            },
                                          })
                                        },
                                      },
                                      {
                                        label: 'Delete this template',
                                        onClick: () => {
                                          DialogWrapper.push({
                                            componentProps: {
                                              label: 'Are you sure to delete this template?',
                                              confirm: async () => {
                                                await $template.delete({
                                                  url: `${process.env.REACT_APP_API}/templates/${templateId}`,
                                                })
                                                DialogWrapper.pop()
                                                DialogWrapper.push({
                                                  componentProps: {
                                                    label: 'Template is deleted!',
                                                  },
                                                })
                                              },
                                            },
                                          })
                                        },
                                      },
                                    ]
                                  : [
                                      {
                                        type: 'subtitle',
                                        label: 'Template',
                                      },
                                      {
                                        label: 'Save as template',
                                        onClick: () => {
                                          DialogWrapper.push({
                                            type: 'TemplateForm',
                                            componentProps: {
                                              label: 'Save as new Template',
                                              data: tempData,
                                              name: template.payload.name,
                                              organizationId: orgId,
                                              parentEntryId: parentEntryId,
                                              schemaId: schemaId,
                                            },
                                          })
                                        },
                                      },
                                    ]
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                  <Box pt={4} pb={4}>
                    <Container maxWidth="xl">{children}</Container>
                  </Box>
                </Box>
                <AppBar className={styleClasses.wwwBottomAppBar}>
                  <Container maxWidth="xl">
                    <Box pb={1} pt={1} display="flex" justifyContent="flex-end">
                      {renderAction}
                    </Box>
                  </Container>
                </AppBar>
              </Form>
            )
          }}
        </AppLayout.context.Consumer>
      }
    />
  )
}

export const FormTemplate = withRouter(FormTemplateComponent)
