import { style, types } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Component===================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

/* Types ======================================================================================== */
export type ClassNames = {
  component: string
  /**
   * Base class for the most parent text element
   */
  base: string

  /**
   * Sized extension class for text element
   */
  baseSized: {
    xs: string
    small?: string
    sm: string
    md: string
    lg: string
    xl: string
  }

  /**
   * Themed extension class for text element
   */
  baseThemed: string

  /**
   * Themed extension class for semi-bolded text
   */
  baseSemiBold: string

  /**
   * Themed extension class for bolded text
   */
  baseBold: string

  /**
   * Themed extension class for italic text
   */
  baseItalic: string

  /**
   * Themed extension class for italic text
   */
  baseLink: string

  /**
   * Themed extension class for upper-cased text
   */
  baseUppercase: string
}

export type ClassProps = {
  alt?: boolean
  baseline?: ComponentBaselineType
}

const base = style({})

export const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline, alt } = props

  const component = style({
    width: 'fit-content',
    display: 'inline-block',
  })

  const baseSized = {
    xl: style({
      fontSize: 14,
      letterSpacing: '-0.003em',
      lineHeight: '16px',
    }),
    lg: style({
      fontSize: 13,
      letterSpacing: 0,
      lineHeight: '20px',
    }),
    md: style({
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: '16px',
    }),
    sm: style({
      fontSize: 11,
      letterSpacing: '0',
      lineHeight: '14px',
    }),
    xs: style({
      fontSize: 10,
      letterSpacing: '0',
      lineHeight: '12px',
    }),
  }

  const baseThemed = style({
    color: theme.getBaseline(baseline, alt).contrast.medium,
  })

  const baseSemiBold = style({
    fontWeight: 500,
  })

  const baseBold = style({
    fontWeight: 600,
  })

  const baseItalic = style({
    fontStyle: 'italic',
  })

  const baseLink = style({
    cursor: 'pointer',
    fontWeight: 500,
    color: theme.getBaseline('primary').color.dark,
  })

  const baseUppercase = style({
    textTransform: 'uppercase',
  })

  return {
    component,
    base,
    baseSized,
    baseThemed,
    baseSemiBold,
    baseBold,
    baseItalic,
    baseLink,
    baseUppercase,
  }
}

export const textClass = new StyleClass<ClassNames, ClassProps>(getNames)
