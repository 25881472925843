import React, { FC, useEffect, useContext, useState } from 'react'
import download from 'downloadjs'

/* Components =================================================================================== */
import Axios from 'axios'
import SET from '../../styles/set'
import useAxios, { parseStates } from '../../hooks/use-axios'
import useValue from '../../hooks/use-value'
// import { Border } from '../modules/border/border.component'
// import { Box } from '../modules/box/box.component'
import { Button } from '../../modules/button'
// import { Form } from '../modules/form'
// import { Heading } from '../modules/text/heading.component'
// import { Icon } from '../modules/icons'
// import { Line } from '../modules/line'
// import { Menu } from '../modules/menu'
import { Select } from '../../modules/select/select.component'
import { Table } from '../../modules/table'
// import { Tabs, Tab } from '../modules/tab'
// import { Text } from '../modules/text/text.component'
// import { classes } from 'typestyle'
import { flat, structureObj } from '../../share/obj-equal'
// import { DialogWrapper } from '../modules/dialog'
import { APIWrapperContext } from '../../api-wrapper'
// import { url } from 'inspector'
import useFetch from '../../hooks/use-fetch'
import { Link as RouterLink } from 'react-router-dom'
import { formatPrice } from '../../common/format'
import { formatDate } from '../date-field'
import Cookies from 'js-cookie'
import { FileAxios } from '../../modules/upload'
import { useStyles } from './tab-template.styled'

/* Material UI  ======================================================================================== */
// Core
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'

// Lab
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { url } from 'inspector'
import { DialogWrapper } from '../../modules/dialog-wrapper'
import { FloatMenu } from '../../modules/menu'

/* <ViewTemplate /> ============================================================================= */
export const ViewTemplate: FC<{
  AppLayout: any
  layoutProps?: any
  children?: any
  history: any
  heading?: string
  description?: string
  apiUrl: string
  match: any
  parseHeading?: any
  parseDescription?: any
  model?: any
  search?: any
  schemaId?: any
  entry: any
  refresh?: any
}> = props => {
  const {
    AppLayout,
    layoutProps,
    children,
    history,
    match,
    apiUrl,
    parseHeading,
    parseDescription,
    model,
    search,
    entry,
    refresh,
  } = props

  const { schema, org } = useContext(APIWrapperContext)

  const [trigger, $trigger] = useValue()
  const [postApi, $postApi] = useFetch({}, {})
  const [selected, $selected] = useValue([])
  const [floatMenuOpen, $floatMenuOpen] = useValue(false)
  const [anchorEl, $setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorPosition, $setAnchorPosition] = useValue({ left: 0, top: 0 })
  const [anchorReference, $setAnchorReference] = useValue()

  const { entryId, schemaId, orgId, parentEntryId } = match.params as any

  const [viewData, $viewData] = useFetch({ response: [] }, {})
  const [viewType, $viewType] = useValue({ label: 'Default', url: '' })
  const [tabNumber, $setTabNumber] = useValue('0')

  const styleClasses = useStyles()

  useEffect(() => {
    if (viewType && viewType.url) {
      $viewData.get({
        url: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}/view/${viewType.url}/json`,
      })
    }
  }, [viewType])

  // const { schemaData } = parseStates(
  //   {
  //     schemaData: schema,
  //   },
  //   {
  //     stages: [],
  //   },
  // )
  const handleTabChange = (event: any, newValue: number) => {
    $setTabNumber.set(newValue)
  }

  const renderItem = (fields: any, parentName: any, flatten: any, value: any) => {
    return fields.map((field: any, key: any) => {
      switch (field.type) {
        case 'Collapsible': {
          const fieldValue =
            parentName !== null && parentName !== undefined && value[parentName]
              ? value[parentName][field.name]
              : value[field.name] || {}

          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle1">
                <Box mb={1} fontWeight="fontWeightMedium">
                  {field.name}
                </Box>
              </Typography>
              <Box p={2} pb={1}>
                <Box mb={2}>
                  <Typography>
                    {renderItem(field.fields, undefined, flat(fieldValue), fieldValue)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        }
        case 'RepeaterField':
          const fieldValue =
            parentName !== null && parentName !== undefined && value[parentName]
              ? value[parentName][field.name]
              : value[field.name]
          let priceKey: any
          let totalPriceValue: any = null

          if (fieldValue) {
            field.renderItems.map((renderItem: any) => {
              if (renderItem.type === 'MoneyField') {
                priceKey = renderItem.name
              }
            })

            return (
              <Box
                className="www-box-item"
                key={key}
                mb={1}
                pb={1}
                borderColor="grey.300"
                border={1}
                borderTop={0}
                borderLeft={0}
                borderRight={0}
              >
                <Typography variant="subtitle1">
                  <Box mb={1} fontWeight="fontWeightMedium">
                    {field.label}
                  </Box>
                </Typography>
                <Box border={1} borderColor="grey.300" borderRadius="4px">
                  {fieldValue.map((item: any, key: any) => {
                    if (item && item[priceKey]) {
                      if (item.quantity) {
                        totalPriceValue += priceKey ? parseInt(item[priceKey]) * item.quantity : 0
                      } else {
                        totalPriceValue += parseInt(item[priceKey])
                      }
                    }

                    return (
                      <Box className={styleClasses.wwwRepeaterBoxItem} key={key} bgcolor="grey.200">
                        <Typography variant="subtitle2">
                          <Box pl={2} pr={2} pb={1} pt={1} key={key} fontWeight="fontWeightMedium">
                            {`${field.label} ${key + 1}`}
                          </Box>
                        </Typography>
                        <Box
                          className="www-repeater-box-item"
                          p={2}
                          mb={2}
                          key={key}
                          bgcolor="grey.50"
                        >
                          {renderItem(field.renderItems, key, flat(fieldValue), fieldValue)}
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
                {totalPriceValue && (
                  <Typography>
                    <Box pt={2} textAlign="right" fontSize={16} fontWeight="fontWeightMedium">
                      Total: {formatPrice(totalPriceValue)}
                    </Box>
                  </Typography>
                )}
              </Box>
            )
          }

          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>{'-'}</Typography>
            </Box>
          )

        // break
        case 'TableSelect':
          const label =
            flatten[
              `${parentName !== null && parentName !== undefined ? `${parentName}.` : ''}${
                field.name
              }.${field.labelKey}`
            ]

          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>{label ? label : '-'}</Typography>
            </Box>
          )
        case 'Checkbox': {
          const fieldValue =
            flatten[
              `${parentName !== null && parentName !== undefined ? `${parentName}.` : ''}${
                field.name
              }`
            ]

          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>{renderCheckboxValue(fieldValue)}</Typography>
            </Box>
          )
        }
        case 'MultiCheckbox': {
          const fieldValue =
            flatten[
              `${parentName !== null && parentName !== undefined ? `${parentName}.` : ''}${
                field.name
              }`
            ]

          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>
                {field.items.find((a: any) => a.value === fieldValue)
                  ? field.items.find((a: any) => a.value === fieldValue).label
                  : field.items[0].label}
              </Typography>
            </Box>
          )
        }
        case 'MoneyField':
          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>
                {flatten[
                  `${parentName !== null && parentName !== undefined ? `${parentName}.` : ''}${
                    field.name
                  }`
                ]
                  ? formatPrice(
                      flatten[
                        `${
                          parentName !== null && parentName !== undefined ? `${parentName}.` : ''
                        }${field.name}`
                      ],
                    )
                  : '-'}
              </Typography>
            </Box>
          )
        case 'Date':
          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>
                {flatten[
                  `${parentName !== null && parentName !== undefined ? `${parentName}.` : ''}${
                    field.name
                  }`
                ]
                  ? formatDate(
                      new Date(
                        flatten[
                          `${
                            parentName !== null && parentName !== undefined ? `${parentName}.` : ''
                          }${field.name}`
                        ],
                      ),
                    )
                  : '-'}
              </Typography>
            </Box>
          )
        case 'Break': {
          return (
            <Box className="www-box-item" key={key} mb={4} mt={2}>
              {' '}
              <Divider />
            </Box>
          )
        }

        case 'Upload': {
          const fieldValue =
            parentName !== null && parentName !== undefined && (value || {})[parentName]
              ? (value || {})[parentName][field.name]
              : (value || {})[field.name]

          if (fieldValue) {
            return (
              <Box
                className="www-box-item"
                key={key}
                mb={2}
                pb={2}
                borderColor="grey.300"
                border={1}
                borderTop={0}
                borderLeft={0}
                borderRight={0}
              >
                <Typography variant="subtitle2">
                  <Box color="primary.light">{field.label}</Box>
                </Typography>
                <Box
                  p={2}
                  display="flex"
                  bgcolor="grey.100"
                  borderRadius="4px"
                  border={1}
                  borderColor="grey.300"
                  mt={1}
                >
                  <Grid container spacing={2}>
                    {(() => {
                      return fieldValue.map((value: any, key2: any) => {
                        return (
                          <Grid item key={key2}>
                            {/* <Box> */}
                            <FileAxios
                              url={value?.url}
                              baseUrl={process.env.REACT_APP_API || ''}
                              displayFileName
                            />
                            {/* </Box> */}
                          </Grid>
                        )
                      })
                    })()}
                  </Grid>
                </Box>
              </Box>
            )
          }

          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>
                {flatten[
                  `${parentName !== null && parentName !== undefined ? `${parentName}.` : ''}${
                    field.name
                  }`
                ] || '-'}
              </Typography>
            </Box>
          )
        }
        default:
          const fieldItem =
            flatten[
              `${parentName !== null && parentName !== undefined ? `${parentName}.` : ''}${
                field.name
              }`
            ]
          return (
            <Box
              className="www-box-item"
              key={key}
              mb={1}
              pb={1}
              borderColor="grey.300"
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
            >
              <Typography variant="subtitle2">
                <Box color="primary.light">{field.label}</Box>
              </Typography>
              <Typography>{fieldItem ? fieldItem : '-'}</Typography>
            </Box>
          )
      }
    })
  }

  const renderCheckboxValue = (item: any) => {
    return item ? 'Yes' : 'No'
  }

  return (
    <AppLayout.render
      {...layoutProps}
      content={
        <Box>
          <Box bgcolor="#ffffff" pt={3} pb={3}>
            <Container maxWidth="xl">
              {entry.payload && entry.payload.stage && (
                <Box mb={1}>
                  <Chip size="small" label={entry.payload.stage.name} />
                </Box>
              )}
              <Box mb={2} display="flex" alignItems="center">
                <Typography variant="h4">
                  <Box fontWeight="fontWeightMedium" mr={1}>
                    {parseHeading(schema.payload, entry.payload) || '-'}
                  </Box>
                </Typography>
                <Button
                  label="Edit"
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(`${history.location.pathname}/edit`)}
                />
                <Box ml={1}>
                  <Button
                    label="Change Status"
                    variant="contained"
                    color="secondary"
                    onClick={(event: any) => {
                      $setAnchorEl(event.currentTarget)
                      $floatMenuOpen.set(true)
                    }}
                  />
                </Box>
                <FloatMenu
                  open={floatMenuOpen}
                  handleOpen={() => $floatMenuOpen.set(true)}
                  handleClose={() => {
                    $floatMenuOpen.set(false)
                    $setAnchorEl(null)
                  }}
                  anchorElem={anchorEl}
                  anchorPosition={anchorPosition}
                  anchorRef={anchorReference}
                  options={[
                    ...(() => {
                      if (schema.payload.stages && schema.payload.stages.length > 0) {
                        return [
                          {
                            type: 'subtitle',
                            label: 'Change Status',
                          },
                          ...schema.payload.stages.map((stage: any) => {
                            return {
                              label: stage.name,
                              onClick: async () => {
                                $postApi
                                  .put({
                                    url: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/parent-entry/${parentEntryId}/entries/${entryId}`,
                                    body: {
                                      stage: {
                                        ...stage,
                                        updatedBy: `${org.payload.user.firstName} ${org.payload.user.lastName}`,
                                      },
                                    },
                                  })
                                  .then((res: any) => {
                                    $trigger.set(Date.now())
                                    if (refresh) {
                                      refresh()
                                    }
                                  })
                                  .catch((e: any) => {
                                    DialogWrapper.push({
                                      type: 'Prompt',
                                      componentProps: {
                                        label: e.title,
                                      },
                                    })
                                  })
                              },
                            }
                          }),
                        ]
                      }
                      return []
                    })(),
                  ]}
                />
              </Box>
              <Typography>
                <Box component="span" fontStyle="italic" color="grey.500">
                  {parseDescription(entry.payload) || '-'}
                </Box>
              </Typography>

              {/* <Box baseline="none" alignCenterY>
                   <Menu
                    button={{
                      icon: <Icon.HorizontalEllipsis size="lg" />,
                      baseline: 'background',
                      alt: true,
                    }}
                    options={[
                      {
                        type: 'subtitle',
                        label: 'Entry',
                      },
                      {
                        label: 'Delete',
                        onClick: async () => {
                          DialogWrapper.push({
                            label: 'Are you sure to delete?',
                            confirm: async () => {
                              await $entry.delete()
                              history.goBack()
                            },
                          })
                        },
                      },
                      ...(() => {
                        if (schemaData.payload.stages && schemaData.payload.stages.length > 0) {
                          return [
                            {
                              type: 'break',
                            },
                            {
                              type: 'subtitle',
                              label: 'Change Status',
                            },
                            ...schemaData.payload.stages.map((stage: any) => {
                              return {
                                label: stage.name,
                                onClick: async () => {
                                  // console.log(`${apiUrl}/${schemaId}`)
                                  // await $apiData.patch({ stage }, { url: `${apiUrl}/${schemaId}` })
                                  await $entry.get()
                                },
                              }
                            }),
                          ]
                        }
                        return []
                      })(),
                    ]}
                  />
                </Box> */}
            </Container>
          </Box>
          <Box pt={4} pb={4}>
            <Container maxWidth="xl">
              <Box mb={2}>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                  <Grid item xs={12} sm={2}>
                    {model && model.views && (
                      <Select
                        label="View"
                        options={[
                          {
                            url: '',
                            label: 'Default',
                          },
                          ...(() => {
                            if (model && model.views) {
                              return model.views.map((view: any) => ({
                                label: view.fieldName,
                                url: view.slug,
                              }))
                            }
                            return []
                          })(),
                        ]}
                        className={SET.mr8}
                        renderValue={(option: any) => {
                          if (typeof option == 'string') {
                            return option
                          }
                          return option.label
                        }}
                        renderOption={(option: any) => {
                          if (typeof option == 'string') {
                            return option
                          }
                          return option.label
                        }}
                        value={viewType}
                        onChange={value => {
                          if (value) {
                            $viewType.set(value)
                          }
                        }}
                      />
                    )}
                  </Grid>
                  {viewType.url && (
                    <Grid item sm>
                      <Box display="flex" justifyContent="flex-end">
                        <Box>
                          <Button
                            label="Download PDF"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              const cookie = Cookies.get('token')
                              Axios.get(
                                `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}/view/${viewType.url}/pdf`,
                                {
                                  responseType: 'arraybuffer',
                                  headers: {
                                    authorization: cookie,
                                  },
                                },
                              ).then(res => {
                                download(res.data, 'report.pdf', res.headers['content-type'])
                              })
                            }}
                          />
                        </Box>
                        <Box ml={1}>
                          <Button
                            label="Download JSON"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              const cookie = Cookies.get('token')
                              Axios.get(
                                `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}/view/${viewType.url}/json`,
                                {
                                  responseType: 'arraybuffer',
                                  headers: {
                                    authorization: cookie,
                                  },
                                },
                              ).then(res => {
                                download(
                                  res.data.response,
                                  'report.json',
                                  res.headers['content-type'],
                                )
                              })
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>

              <Box>
                {(() => {
                  if (!viewType.url) {
                    const value: any = flat(entry.payload)
                    const value2: any = entry.payload

                    if (model.fields && model.fields.length > 0) {
                      return <Box p={3}>{renderItem(model.fields, null, value, value2)}</Box>
                    } else if (model.sections && model.sections.length > 0) {
                      return (
                        <Box bgcolor="#ffffff" border={1} borderColor="grey.300">
                          <TabContext value={tabNumber}>
                            <TabList
                              className={styleClasses.wwwTabList}
                              onChange={handleTabChange}
                              indicatorColor="primary"
                              textColor="primary"
                              scrollButtons="auto"
                              variant="scrollable"
                            >
                              {model &&
                                model.sections &&
                                model.sections.map((item: any, key: any) => {
                                  const parentName = item.name

                                  return (
                                    <Tab
                                      key={key}
                                      value={key.toString()}
                                      label={`${key + 1} - ${item.name}`}
                                    />
                                  )
                                })}
                            </TabList>
                            <Divider className={styleClasses.wwwDivider} />
                            {model &&
                              model.sections &&
                              model.sections.map((item: any, key: any) => {
                                return (
                                  <TabPanel
                                    className={styleClasses.wwwTabPanel}
                                    key={key}
                                    value={key.toString()}
                                  >
                                    {item.fields &&
                                      renderItem(item.fields, item.name, value, value2)}
                                  </TabPanel>
                                )
                              })}
                          </TabContext>
                        </Box>
                      )
                    }
                  } else {
                    if (viewData.payload && viewData.payload.response && !viewData.meta.isLoading) {
                      return (
                        <>
                          {viewData.payload.response.map((item: any, key: any) => {
                            let renderColumn = (
                              column: any,
                              row: any,
                              key: number,
                              name: string,
                            ) => {
                              if (row && row[`${name}.to`]) {
                                return (
                                  <Typography>
                                    <Link component={RouterLink} to={row[`${name}.to`]} target="_">
                                      {row[`${name}.label`] || '-'}
                                    </Link>
                                  </Typography>
                                )
                              } else if (typeof column !== 'object') {
                                return <Typography>{column || '-'}</Typography>
                              }

                              return <Typography>{'-'}</Typography>
                            }

                            if (item.isAttachments) {
                              renderColumn = (column: any, row: any, key: number, name: string) => {
                                const structured: any = structureObj(row)
                                const structuredColumn = structured[name]

                                if (typeof structuredColumn === 'string') {
                                  return <Typography>{structuredColumn}</Typography>
                                }

                                if (structuredColumn) {
                                  return (
                                    <Box
                                      className="www-box-item"
                                      key={key}
                                      mb={2}
                                      pb={2}
                                      borderColor="grey.300"
                                      border={1}
                                      borderTop={0}
                                      borderLeft={0}
                                      borderRight={0}
                                    >
                                      <Box
                                        p={2}
                                        display="flex"
                                        bgcolor="grey.100"
                                        borderRadius="4px"
                                        border={1}
                                        borderColor="grey.300"
                                        mt={1}
                                      >
                                        <Grid container spacing={2}>
                                          {(() => {
                                            return (Object.keys(structuredColumn).length > 0
                                              ? structuredColumn
                                              : []
                                            ).map((value: any, key2: any) => {
                                              return (
                                                <Grid item key={key2}>
                                                  {/* <Box> */}
                                                  <FileAxios
                                                    url={value.url}
                                                    baseUrl={process.env.REACT_APP_API || ''}
                                                    displayFileName
                                                  />
                                                  {/* </Box> */}
                                                </Grid>
                                              )
                                            })
                                          })()}
                                        </Grid>
                                      </Box>
                                    </Box>
                                  )
                                } else {
                                  return <Typography>{'-'}</Typography>
                                }
                              }
                            }

                            if (item.rows && item.rows.length > 0) {
                              return (
                                <Box key={key} mb={3} mt={3}>
                                  {item.title && (
                                    <Typography variant="subtitle1">
                                      <Box mb={1} fontWeight="fontWeightMedium">
                                        {item.title}
                                      </Box>
                                    </Typography>
                                  )}
                                  <Table
                                    topOffset={108}
                                    footer={item.footer}
                                    noLimit
                                    headers={item.headers.map((header: any) => {
                                      return {
                                        ...header,
                                        renderColumn,
                                      }
                                    })}
                                    data={item.rows || []}
                                  />
                                </Box>
                              )
                            }
                          })}
                        </>
                      )
                    } else {
                      return (
                        <Container maxWidth="xl">
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                          </Box>
                        </Container>
                      )
                    }
                  }
                })()}
              </Box>
            </Container>
          </Box>
        </Box>
      }
    />
  )
}
