import React, { FC, createContext } from 'react'

import useArray from '../../hooks/use-array'
import useValue from '../../hooks/use-value'
import { dialogBackgroundStyle } from '../__styles/dialog-background'
import SET from '../../styles/set'

const COMPOENT_CONTEXT: {
  elements: any
  $elements: any
  highlight: any
  $highlight: any
  clsPrefix: string
} = {
  elements: [],
  $elements: {},
  highlight: null,
  $highlight: {},
  clsPrefix: 'www',
}
export const ComponentContext = createContext(COMPOENT_CONTEXT)

class ComponentsClass {
  $elements: any
  elements: any
  $highlight: any
  highlight: any

  render: FC<{ children: any }> = ({ children }) => {
    const [elements, $elements] = useArray([])
    const [highlight, $highlight] = useValue(null)
    this.$elements = $elements
    this.elements = elements
    this.$highlight = $highlight
    this.highlight = highlight

    const renderHighlighted = () => {
      const highlighted: any = elements.find((item: any) => item.id === highlight)

      if (highlighted) {
        window.scrollTo(0, highlighted.element.offsetTop - 160)
        document.body.style.overflow = 'hidden'

        const box = highlighted.element.getBoundingClientRect()
        return (
          <div className={dialogBackgroundStyle} onClick={$highlight.reset}>
            <div
              className={SET.absolute}
              style={{
                left: box.x,
                top: box.y,
              }}
            >
              {highlighted && highlighted.render()}
            </div>
          </div>
        )
      } else {
        document.body.style.overflow = ''
      }
    }

    return (
      <ComponentContext.Provider
        value={{
          elements,
          $elements,
          highlight,
          $highlight,
          clsPrefix: 'www',
        }}
      >
        {children}
        {renderHighlighted()}
      </ComponentContext.Provider>
    )
  }
}

export const Components = new ComponentsClass()
