import React, { useRef, useEffect, FC } from 'react'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'

/* Hooks ======================================================================================== */
import combineHooks from '../../hooks/combine-hooks'
import useArray from '../../hooks/use-array'
import useBoolean from '../../hooks/use-boolean'
import useCounter from '../../hooks/use-counter'
import useValue from '../../hooks/use-value'

/* Constants ==================================================================================== */

/* Components =================================================================================== */
import { Icon } from '../icons'
import { Button, ButtonCore } from '../button'
import { Float } from '../float'
import { flat } from '../../share/obj-equal'
import { buttonSizes } from '../button/button.constants'

const SelectValue = styled.div`
  margin-right: 8px;
  user-select: none;
`

export const OptionContainer = styled.div<{ fullwidth?: boolean }>`
  width: 100%;
  max-height: 400px;
  overflow: auto;
`

export const Option = styled.div<{ highlight?: boolean; selected?: boolean }>`
  padding: 4px 8px;
  min-width: 36px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background: rgb(240, 240, 240);
  }
`

const SelectInputParent = styled.div<{ compact?: boolean; focus?: boolean; fullwidth?: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  // min-height: 32px;
  height: 100%;
  padding: 0 8px;
  // padding: 4px 8px 4px 8px;
  position: relative;

  &:active:hover {
    svg {
      transform: scale(0.8);
      transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  > input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    flex: 1;
  }

  > svg {
    margin-left: 8px;
    transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  > ${ButtonCore} {
    margin-right: 4px;
  }

  > ${SelectValue} {
    margin-right: 8px;
  }

  > ${Option} {
    font-size: 12px;
  }
`

const SelectContainer = styled.div<{ size?: 'sm' | 'lg' | 'small' | 'lg' }>`
  position: relative;

  ${({ size }) => {
    switch (size) {
      case 'lg':
      case 'lg':
        return `
          font-size: ${buttonSizes.lg.fontSize}px;
          height: ${buttonSizes.lg.dimension}px;
          min-width: ${buttonSizes.lg.dimension}px;

          svg {
            height: ${buttonSizes.lg.iconSize}px;
          }
        `
      case 'small':
      case 'sm':
        return `
          font-size: ${buttonSizes.default.fontSize}px;
          height: ${buttonSizes.default.dimension}px;
          min-width: ${buttonSizes.default.dimension}px;

          svg {
            height: ${buttonSizes.default.iconSize}px;
          }
        `
      default:
        return `
          font-size: ${buttonSizes.lg.fontSize}px;
          height: ${buttonSizes.lg.dimension}px;
          min-width: ${buttonSizes.lg.dimension}px;

          svg {
            height: ${buttonSizes.lg.iconSize}px;
          }
        `
    }
  }}
`

type Option = any[]
type SelectFieldProps = {
  options: Option
  labelKey?: string
  valueKey?: string
  allowEmpty?: boolean
  multiple?: boolean
}

const prev: any = []

let staticIndex = 0
const SelectComponent: React.FC<FieldContextProps & SelectFieldProps> = ({
  allowEmpty,
  focused,
  onChange,
  value = '',
  labelKey,
  valueKey,
  options,
  placeholder,
  onFocus,
  onBlur,
  multiple,
  size,
}) => {
  const [cursor, $cursor] = useCounter(0, { min: 0, max: options.length - 1 })
  const [, $focus] = useBoolean(false)
  const [isSearching, $isSearching] = useBoolean(false)
  const [parsedOptions, $parsedOptions] = useArray<{ key: number; data: any }>()
  const [parsedSearchedOptions, $parsedSearchedOptions] = useArray<{ key: number; data: any }>()
  const [search, $search] = useValue('')
  const [thisIndex] = useValue(staticIndex++)

  const inputRef = useRef(null)
  const parentRef = useRef(null)

  const combinedHooks = combineHooks([$isSearching, $search, $cursor])

  useEffect(() => {
    prev[thisIndex] = value
  }, [value])

  useEffect(() => {
    $cursor.set(0)
  }, [search])

  useEffect(() => {
    if (focused) {
      ;(inputRef as any).current.focus()
    }
  }, [focused])

  useEffect(() => {
    $parsedOptions.reset()
    options.map((option, key) => {
      $parsedOptions.push({
        key,
        data: typeof option === 'object' ? flat(option) : option,
      })

      return null
    })
  }, [options])

  useEffect(() => {
    let temp: any = parsedOptions
    if (search) {
      temp = temp.filter((a: any) => {
        let needle: string
        if (labelKey) {
          needle = a.data[labelKey]
        } else {
          needle = a.data
        }

        return new RegExp(search, 'i').test(needle)
      })
    }

    $parsedSearchedOptions.set(temp)
    // $cursor.reset()
    $cursor.setMax(temp.length - 1)
  }, [search, parsedOptions, isSearching, value])

  useEffect(() => {
    if (isSearching) {
      $search.set(search[search.length - 1])
    }
  }, [isSearching])

  const handleChange = (option: any, allowSplice?: boolean) => () => {
    if (multiple) {
      let newValue = [...(value || [])]
      let index = -1
      const exists = newValue.some((item: any, key: number) => {
        if (labelKey ? item[labelKey] === option.data[labelKey] : item === option.data) {
          index = key
          return true
        }
      })

      if (allowSplice && exists) {
        newValue.splice(index, 1)
      } else {
        newValue = [...newValue, option.data]
      }

      onChange(newValue)
    } else {
      if (value && labelKey ? value[labelKey] === option.data[labelKey] : value === option.data) {
        if (allowSplice) {
          alert(allowSplice)
          onChange(null)
        }
      } else {
        if (valueKey) {
          onChange(option.data[valueKey])
        } else {
          onChange(option.data)
        }
      }
    }

    if (!multiple) {
      onBlur()
    }
  }

  const handleOutsideClick = () => {
    combinedHooks.reset()
    if (focused) {
      onBlur()
    }
  }

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    $search.set(event.target.value)
    $isSearching.set(true)
  }

  const handleClick = () => {
    if ((inputRef as any).current) {
      ;(inputRef as any).current.focus()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    $focus.set(true)

    switch (event.keyCode) {
      /* Key backspace */
      case 8: {
        if (!search) {
          if (onChange) {
            onChange(null)
            $search.set('')
          }
          $cursor.reset()
        }
        break
      }
      /* Key enter */
      case 13: {
        event.preventDefault()
        event.stopPropagation()

        if (cursor > 0) {
          handleChange(parsedSearchedOptions[cursor - 1])()
        } else if (allowEmpty) {
          if (search) {
            if (labelKey) {
              handleChange({ data: { [labelKey]: search } })()
            } else {
              handleChange({ data: search })()
            }
          }
        }

        $search.set('')

        break
      }
      /* Key arrow up */
      case 40: {
        event.preventDefault()
        event.stopPropagation()
        onFocus()
        if (parsedSearchedOptions.length > 0) {
          $cursor.increment()
        }
        break
      }
      /* Key arrow down */
      case 38: {
        event.preventDefault()
        event.stopPropagation()
        onFocus()
        if (parsedSearchedOptions.length > 0) {
          $cursor.decrement()
        }
        break
      }
      /* Key tab */
      case 9: {
        handleOutsideClick()
        break
      }
      default: {
      }
    }
  }

  const removeItem = (index: number) => () => {
    prev[thisIndex].splice(index, 1)
    onChange(prev[thisIndex])
  }

  const renderSearchText = () => {
    if (search) {
      return search
    }
  }

  const renderLabel = () => {
    if (search) {
      return search
    }

    if (multiple) {
      return search
    }

    if (!isSearching || search === '') {
      if (valueKey) {
        const test = parsedSearchedOptions.find(option => {
          return option.data[valueKey as any] === value
        })

        if (value && test) {
          return test.data[labelKey as any]
        }

        return ''
      }

      return value
    }

    return ''
  }

  const renderInput = () => {
    return (
      <input
        ref={inputRef}
        placeholder={!value ? placeholder : ''}
        value={renderLabel() || ''}
        onFocus={onFocus}
        onChange={handleTextInputChange}
        onKeyDown={handleKeyDown}
      />
    )
  }

  const renderOptions = () => {
    if (search && parsedSearchedOptions.length === 0) {
      return (
        <Option className="www-so">
          <i>No matching options. Press enter to insert</i>
        </Option>
      )
    }

    return parsedSearchedOptions.map((option: any, key: number) => {
      const optionLabel = labelKey ? option.data[labelKey] : option.data

      if (multiple) {
        const selected = (value || []).some((item: any) =>
          labelKey ? item[labelKey] === optionLabel : item === optionLabel,
        )

        return (
          <Option
            className="www-so"
            onClick={() => {
              handleChange(option, allowEmpty)()
              ;(inputRef.current as any).focus()
            }}
            key={option.key}
            selected={selected}
            highlight={cursor - 1 === key}
          >
            {optionLabel}
          </Option>
        )
      }

      return (
        <Option
          onClick={() => {
            handleChange(option, false)()
          }}
          key={option.key}
          selected={renderLabel() === optionLabel}
          highlight={cursor - 1 === key}
          className="www-so"
        >
          {optionLabel}
        </Option>
      )
    })
  }

  const renderMultiple = () => (
    <>
      {value &&
        value.map((item: any, key: any) => (
          <Button
            icon={<i className="material-icons">close</i>}
            key={key}
            label={labelKey ? item[labelKey] : item}
            variant="contained"
            color="secondary"
            onClick={removeItem(key)}
          />
        ))}
      {renderInput()}
    </>
  )
  const renderSingle = () => <>{renderInput()}</>

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <SelectContainer className="www-sc" size={size as any}>
        <SelectInputParent ref={parentRef} onClick={handleClick} className="www-sip">
          {multiple ? renderMultiple() : renderSingle()}
          {/* <Icon.Sort size="md" index={focused ? 2 : 1} /> */}
          <Icon.ArrowDown size="md" index={focused ? 2 : 1} />
        </SelectInputParent>
        <Float
          ref={parentRef}
          anchorPosition={'bottom left'}
          dialogPosition={'top left'}
          open={focused}
          close={onBlur}
          fullWidth={true}
        >
          <OptionContainer className="www-soc">{renderOptions()}</OptionContainer>
        </Float>
      </SelectContainer>
    </OutsideClickHandler>
  )
}

export const Select: FC<FieldProps & SelectFieldProps> = withField(SelectComponent)
