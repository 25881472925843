import { generateSet } from './set.init'

export const hide: Hide = {
  ...generateSet({
    key: { hide: 'display' },
    getValue: () => 'none',
    incremental: false,
  }),
}

/** Below type is generated by `generateTypes` in set.ts */
type Hide = {
  hide: {}
  hidei: {}
  hidesm: {}
  hidesmi: {}
  hidemd: {}
  hidemdi: {}
  hidelg: {}
  hidelgi: {}
  hidexl: {}
  hidexli: {}
}
