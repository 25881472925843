import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { ArrowLeftStyled } from './arrow-left.styled'

const ArrowLeft: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }

  return (
    <ArrowLeftStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M25 30L22 27.6667M25 2L7 16C11.3899 19.4144 14.3345 21.7046 18 24.5556"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ArrowLeftStyled>
  )
}

export default ArrowLeft
