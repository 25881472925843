import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { PDFStyled } from './pdf.styled'

const PDF: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <PDFStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M21.0769 2H5V20M21.0769 2L27 8.53333M21.0769 2V8.53333H27M27 8.53333V11M5 26V30H27V17"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5368 14.1966C12.6822 14.4804 12.7549 14.7963 12.7549 15.1443C12.7549 15.4923 12.6822 15.8082 12.5368 16.0919C12.3915 16.3703 12.2042 16.5925 11.975 16.7585C11.5109 17.1012 11.0301 17.2725 10.5326 17.2725H9.48433V18.18C9.48433 18.3031 9.47873 18.3968 9.46755 18.4611C9.46196 18.52 9.4368 18.5896 9.39208 18.6699C9.31381 18.8144 9.09577 18.8867 8.73797 18.8867C8.34662 18.8867 8.1174 18.7877 8.05032 18.5896C8.01677 18.4985 8 18.3593 8 18.1719V13.7148C8 13.5916 8.0028 13.5006 8.00839 13.4417C8.01957 13.3775 8.04752 13.3052 8.09225 13.2249C8.17052 13.0803 8.38855 13.008 8.74636 13.008H10.541C11.0329 13.008 11.5109 13.1794 11.975 13.522C12.2042 13.688 12.3915 13.9129 12.5368 14.1966ZM10.541 15.851C10.7087 15.851 10.8736 15.7921 11.0357 15.6743C11.1979 15.5565 11.2789 15.3798 11.2789 15.1443C11.2789 14.9087 11.1979 14.732 11.0357 14.6142C10.8736 14.4911 10.7059 14.4295 10.5326 14.4295H9.48433V15.851H10.541Z"
        className="fill"
      />
      <path
        d="M14.0449 13L15.5964 13.008C16.407 13.008 17.1198 13.2891 17.7348 13.8513C18.3498 14.4081 18.6573 15.0961 18.6573 15.9152C18.6573 16.7291 18.3554 17.4277 17.7516 18.0113C17.1534 18.5949 16.4266 18.8867 15.5712 18.8867H14.0366C13.6955 18.8867 13.4831 18.8198 13.3992 18.6859C13.3321 18.5735 13.2986 18.4022 13.2986 18.1719V13.7067C13.2986 13.5782 13.3014 13.4845 13.307 13.4256C13.3182 13.3667 13.3461 13.2971 13.3908 13.2168C13.4691 13.0723 13.6871 13 14.0449 13ZM15.5964 17.4652C15.9989 17.4652 16.3623 17.3233 16.6865 17.0396C17.0108 16.7505 17.1729 16.3891 17.1729 15.9554C17.1729 15.5217 17.0136 15.1603 16.6949 14.8712C16.3818 14.5767 16.0129 14.4295 15.588 14.4295H14.7829V17.4652H15.5964Z"
        className="fill"
      />
      <path
        d="M23.2704 13.008C23.399 13.008 23.494 13.0134 23.5555 13.0241C23.6226 13.0294 23.6953 13.0535 23.7736 13.0964C23.8574 13.1392 23.9161 13.2115 23.9497 13.3132C23.9832 13.4149 24 13.5515 24 13.7228C24 13.8941 23.9832 14.0306 23.9497 14.1324C23.9161 14.2341 23.8574 14.3064 23.7736 14.3492C23.6897 14.3867 23.6142 14.4108 23.5472 14.4215C23.4857 14.4268 23.3878 14.4295 23.2536 14.4295H20.6875V15.2406H22.3396C22.4737 15.2406 22.5716 15.246 22.6331 15.2567C22.7002 15.2621 22.7756 15.2862 22.8595 15.329C23.0049 15.4093 23.0775 15.6208 23.0775 15.9634C23.0775 16.3382 22.9713 16.5604 22.7589 16.63C22.6694 16.6568 22.5269 16.6702 22.3312 16.6702H20.6875V18.188C20.6875 18.3165 20.6819 18.4102 20.6707 18.4691C20.6652 18.528 20.64 18.5976 20.5953 18.6779C20.517 18.8225 20.299 18.8947 19.9412 18.8947C19.5498 18.8947 19.3206 18.793 19.2535 18.5896C19.22 18.5039 19.2032 18.3674 19.2032 18.18V13.7148C19.2032 13.4417 19.2563 13.257 19.3625 13.1606C19.4688 13.0589 19.67 13.008 19.9663 13.008H23.2704Z"
        className="fill"
      />
    </PDFStyled>
  )
}

export default PDF
