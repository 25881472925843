// export const PAGE_MAX_WIDTH = '1120px'
export const HEADER_HEIGHT = 80
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`
export const PAGE_MAX_WIDTH_PX = '1440px'
export const PAGE_MAX_WIDTH = 1440
export const GOOGLE_MAP_MIN_HEIGHT = 80
export const PAGE_MIN_HEIGHT = 575
export const LAYOUT_SM = 576
export const LAYOUT_MD = 768
export const LAYOUT_LG = 992
export const LAYOUT_XL = 1200
