/**
 * <FieldArray>
 *   // Array of Fields
 *   ...
 *     <FieldArrayItem>
 *       <ItemHeader />
 *       <ItemContainer>
 *          <ItemContent>
 *            <itemRender />
 *          </ItemContent>
 *          <ItemActions />
 *       </ItemContainer>
 *     </FieldArrayItem>
 *   ...
 * </FieldArray>
 */

import React, { FC, useContext, useEffect } from 'react'
import styled from 'styled-components'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { FormContext, FormActions } from '../form'
import { structureObj } from '../../share/obj-equal'

/* Material UI ======================================================================================== */
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

export const FieldArrayItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const EmptyPad = styled.div<{ height: number }>`
  display: inline-block;
  height: ${({ height }) => height}px;
  width: 100%;
`

const DropPad = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  width: 100%;
`

const divider = '.'
const split = (string: string) => {
  return [
    string.substring(0, string.indexOf(divider)),
    string.substring(string.indexOf(divider) + 1),
  ]
}

export class FieldArray {
  $fields: any
  $focused: any
  $temp: any
  temp: any
  $form: any
  form: any
  name = ''

  context: any

  find = (): any => {
    const find = (key: string, obj: any = {}): any => {
      // ['1', '2.3.4'] when input is '1.2.3.4'
      // ['', '1'] when input is '1'
      const parsed = split(key)

      if (parsed[0] && !obj[parsed[0]]) {
        return undefined
      }

      if (parsed[1].includes('.')) {
        return find(parsed[1], obj[parsed[0]])
      } else {
        if (parsed[0]) {
          return obj[parsed[0]][parsed[1]]
        } else {
          return obj[parsed[1]]
        }
      }
    }

    if (this.context && this.context.value) {
      return find(this.name, this.context.value.raw)
    }
  }

  patch = (value: any) => {
    const patch = (key: string, obj: any = {}, value: any) => {
      const parsed = split(key)

      if (parsed[0] && !obj[parsed[0]]) {
        obj[parsed[0]] = {}
      }

      if (parsed[1].includes('.')) {
        patch(parsed[1], obj[parsed[0]], value)
      } else {
        if (parsed[0]) {
          obj[parsed[0]] = {
            ...obj[parsed[0]],
            [parsed[1]]: value,
          }
        } else {
          obj[parsed[1]] = value
        }
      }
      return obj
    }

    return { ...patch(this.name, this.context.value.raw, value) }
  }

  push = async (_value: any) => {
    const found = this.find()
    let value = found

    if (found && found.error) {
      value = found.value
    }

    if (_value && _value.constructor.name === 'Array') {
      if (!found) {
        this.context.dispatch({
          type: FormActions.RAW,
          param: { value: this.patch(_value) },
        })
      } else {
        this.context.dispatch({
          type: FormActions.RAW,
          param: { value: this.patch([...value, ..._value]) },
        })
      }
    } else {
      if (!found) {
        this.context.dispatch({
          type: FormActions.RAW,
          param: { value: this.patch([_value ? _value : {}]) },
        })
      } else {
        this.context.dispatch({
          type: FormActions.RAW,
          param: { value: this.patch([...value, _value ? _value : {}]) },
        })
      }
    }
  }

  destructure = () => {
    const tempForm: any = {}
    const match = new RegExp(`${this.name.replaceAll('.', '[.]')}[.][0-9]+[.]`)
    for (const key in this.context.value.form) {
      const matched = key.match(match)
      if (matched) {
        // console.log(matched[0], key.replace(`${matched[0]}.`, `${index}.`))
        const index2 = matched[0].slice(0, -1).split('.')[
          matched[0].slice(0, -1).split('.').length - 1
        ]
        tempForm[index2] = {
          ...tempForm[index2],
          [key.replace(`${matched[0]}`, '')]: this.context.value.form[key],
        }
      }
    }

    const tempFormArr = Object.keys(tempForm).map((key: any) => tempForm[key])

    const tempFields: any = {}
    for (const key in this.context.value.fields) {
      const matched = key.match(match)
      if (matched) {
        // console.log(matched[0], key.replace(`${matched[0]}.`, `${index}.`))
        const index2 = matched[0].slice(0, -1).split('.')[
          matched[0].slice(0, -1).split('.').length - 1
        ]
        tempFields[index2] = {
          ...tempFields[index2],
          [key.replace(`${matched[0]}`, '')]: this.context.value.fields[key],
        }
      }
    }

    const tempFieldArr = Object.keys(tempForm).map((key: any) => tempFields[key])

    return [tempFormArr, tempFieldArr]
  }

  construct = (data: any, fieldData: any, toDelete?: any) => {
    const form = { ...this.context.value.form }
    const fields = { ...this.context.value.fields }

    if (toDelete) {
      toDelete.map((deleteIndex: any, key: any) => {
        if (key === toDelete.length - 1) {
          data.map((item: any, key: any) => {
            for (const key2 in item) {
              form[`${this.name}.${key}.${key2}`] = item[key2]
            }
          })
        }
        const match = new RegExp(`^${this.name}.${deleteIndex}`)

        for (const key in form) {
          if (key.match(match)) {
            delete form[key]
          }
        }

        for (const key in fields) {
          if (key.match(match)) {
            delete fields[key]
          }
        }
      })
    }

    data.map((item: any, key: any) => {
      for (const key2 in item) {
        form[`${this.name}.${key}.${key2}`] = item[key2]
      }
    })

    fieldData.map((item: any, key: any) => {
      for (const key2 in item) {
        fields[`${this.name}.${key}.${key2}`] = item[key2]
      }
    })

    this.context.dispatch({ type: FormActions.SET, param: { value: form } })
    this.context.dispatch({ type: FormActions.FIELDS, param: { value: fields } })
  }

  pop = (index: number) => {
    const found = this.find()
    let value = found

    const destructuredArr = this.destructure()[0]
    const destructuredFields = this.destructure()[1]

    if (found && found.error) {
      value = found.value
    }

    value.splice(index, 1)

    const toDelete: any = []

    toDelete.push(index)
    // toDelete.push(index + 1)
    toDelete.push(destructuredArr.length - 1)

    destructuredArr.splice(index, 1)
    destructuredFields.splice(index, 1)

    this.construct(destructuredArr, destructuredFields, toDelete)
  }

  set = (value: any) => {
    this.$temp.patch(this.name, value)

    return this.getValue()
  }

  setValue = (index: any, newValue: any) => {
    const found = this.find()
    let value = found

    const destructuredArr = this.destructure()[0]
    const destructuredFieldsArr = this.destructure()[1]

    if (found && found.error) {
      value = found.value
    }

    value[index] = { ...value[index], ...newValue }
    destructuredArr[index] = { ...value[index] }

    this.construct(destructuredArr, destructuredFieldsArr)
  }

  move = (from: number, to: number) => {
    const found = this.name && this.$temp.find(this.name as any)
    let value = found

    if (found && found.error) {
      value = found.value
    }

    const destructuredArr = this.destructure()

    value.splice(to, 0, value.splice(from, 1)[0])
    destructuredArr.splice(to, 0, destructuredArr.splice(from, 1)[0])

    this.$focused.set(to)

    // this.construct(destructuredArr)
    this.$temp.patch(this.name, value)
  }

  size = () => {
    if (!this.context) {
      return 0
    }

    const found = this.find()
    let value = found

    if (found && found.error) {
      value = found.value
    }

    if (value) {
      return value.length
    }

    return 0
  }

  getValue = () => {
    const found = this.find()

    // let error: React.ReactNode
    let value: any = found || []

    if (found && found.error) {
      value = found.value
      // error = found.error
    }
    // console.log(found)

    return Object.keys(value).map(key => value[key])
  }

  render: FC<{
    children?: any
    itemActions?: (index: number) => React.ReactNode
    itemContainer?: React.ReactElement
    itemContent?: React.ReactElement
    itemDividerHeight?: number
    itemHeader?: (index: number) => React.ReactNode
    itemRender: (key: number, bind: any) => any
    /**
     * Render elements after the array
     */
    itemBottom?: (value?: any) => React.ReactNode
    name: string
    value?: any[]
    onUpdate?: any
  }> = ({
    itemActions,
    itemContainer = <div />,
    itemContent = <div />,
    itemDividerHeight = 8,
    itemHeader,
    itemRender,
    itemBottom,
    onUpdate,
    name,
  }) => {
    const context = useContext(FormContext)

    const [dragIndex, $dragIndex] = useValue(-1)
    const [dragItemSize, $dragItemSize] = useValue(0)
    const [dropIndex, $dropIndex] = useValue(-1)
    const [dropTop, $dropTop] = useBoolean(false)
    const [focused, $focused] = useValue(-1)

    this.context = context
    this.name = name

    useEffect(() => {
      if (onUpdate) {
        onUpdate(this)
      }
    }, [this.getValue()])

    return (
      <>
        {this.size() > 0 && (
          <DropPad
            height={dragIndex > -1 && dropTop ? dragItemSize.height : itemDividerHeight}
            onDragEnter={() => {
              if (dragIndex !== 0) {
                $dropTop.set(true)
              }
            }}
            onDragExit={() => {
              $dropTop.set(false)
            }}
          />
        )}
        {(() => {
          return this.getValue().map((item: any, key: number) => {
            // console.log(item)
            // console.log(name)
            const parentName = `${name}.${key}`
            const bind = {
              onFocus: () => {
                $focused.set(key)
              },
              onBlur: () => {
                $focused.reset()
              },
            }
            const children = itemRender(key, bind)

            return (
              <React.Fragment key={key}>
                {/* <div
                  style={{
                    height: focused === key ? 0 : 0,
                    width: '100%',
                    display: 'inline-block',
                  }}
                /> */}
                {React.cloneElement(itemContainer, {
                  ...itemContainer.props,
                  focused: focused === key,
                  style: {
                    marginBottom: 16,
                    ...itemContainer.props.style,
                  },
                  children: (
                    <Box
                      display="flex"
                      key={key}
                      // draggable={this.size() > 1}
                      onClick={() => {
                        $focused.set(key)
                      }}
                      // onDragStart={(e: any) => {
                      //   $dragItemSize.set(e.target.getBoundingClientRect())
                      //   $dragIndex.set(key)
                      // }}
                      // onDragOver={() => {
                      //   $dropIndex.set(key)
                      // }}
                      // onDragEnd={() => {
                      //   if (dragIndex > -1 && dropIndex > -1) {
                      //     if (dropTop) {
                      //       this.move(dragIndex, 0)
                      //     } else if (dropIndex === 0) {
                      //       this.move(dragIndex, dropIndex + 1)
                      //     } else {
                      //       this.move(dragIndex, dropIndex)
                      //     }
                      //   }

                      //   $dragIndex.reset()
                      //   $dropIndex.reset()
                      //   $dropTop.reset()
                      // }}
                    >
                      {itemHeader && itemHeader(key)}
                      {React.cloneElement(itemContent, {
                        ...itemContent.props,
                        children: React.Children.map(
                          children.props.children ? children.props.children : children,
                          (child, key2) => {
                            return React.cloneElement(child, {
                              ...child.props,
                              ...bind,
                              key2,
                              name: child.props.name,
                            })
                          },
                        ),
                      })}
                      {itemActions && itemActions(key)}
                    </Box>
                  ),
                })}
                {this.size() > 0 && (
                  <DropPad
                    height={
                      dragIndex > -1 && dropIndex === key && dragIndex !== key && !dropTop
                        ? dragItemSize.height
                        : itemDividerHeight
                    }
                    onDragEnter={() => {
                      $dropIndex.set(key)
                    }}
                  />
                )}
              </React.Fragment>
            )
          })
        })()}
        {itemBottom && itemBottom()}
      </>
    )
  }
}

export const createFieldArray = () => {
  return new FieldArray()
}
