import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { CommentsStyled } from './comments.styled'

const Comments: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <CommentsStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M27.938 12.5C29.1677 13.1824 30 14.494 30 16V22.2857C30 24.1793 28.465 25.7143 26.5714 25.7143L24.5714 25.7143V30C24.5714 30 21.437 27.388 19.4286 25.7143L15.1429 25.7143C14.2947 25.7143 13.5082 25.4503 12.861 25"
        className="stroke draw-stroke-path path-1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16C2 18.9739 4.88777 19.7819 5.756 19.9576C5.87669 19.982 6.08993 19.9936 6.19177 19.9979C6.23001 19.9995 6.26817 20 6.30644 20H8V25L14 20H20C22.2091 20 24 18.2091 24 16V8C24 5.79086 22.2091 4 20 4H6C3.79086 4 2 5.79086 2 8V10"
        className="stroke draw-stroke-path path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CommentsStyled>
  )
}

export default Comments
