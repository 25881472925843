/**
 * TextFieldComponent
 *
 * Write CSS with following as reference
 *  <Field> // from withField()
 *    <FieldContainer className='www-tfc'>
 *      <input className='www-tfi'/>
 *    </FieldContainer>
 *  </Field>
 */
import React, { useRef, useEffect, FC } from 'react'
import { classes } from 'typestyle'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'

/* Styles ======================================================================================= */
// import { textFieldClass } from './textarea.class'

/* Types ======================================================================================== */
// import { ComponentBaselineType } from '../__core/component.types'
// import SET from '../../styles/set'
// import { Text } from '../text/text.component'
import useValue from '../../hooks/use-value'
import { FormActions } from '../form'

/* Material UI ======================================================================================== */
import { TextField } from '@material-ui/core'

type TextareaProps = {} & FieldProps

/* <Textarea /> ======================================================================= */
/**  See bottom for wrapped component */
const TextareaComponent: React.FC<FieldContextProps & {}> = props => {
  const {
    // baseline = 'surface',
    dispatch,
    name,
    label,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    field = {
      focused: undefined,
      validate: [],
    },
    handleValidate,
    handleValue,
    helperText,
  } = props

  const inputRef = useRef(null)
  const [value, $value] = useValue(props.value || '')
  const [focused, $focused] = useValue(props.focused || false)

  // const { base, themed } = textFieldClass.setProps({ baseline })

  useEffect(() => {
    if (dispatch) {
      dispatch({ type: FormActions.FIELD, param: { name, value: { validate: props.validate } } })
    }
  }, [])

  useEffect(() => {
    $value.set(props.formValue || '')
  }, [props.formValue])

  useEffect(() => {
    if (props.value !== undefined) {
      handle('change')(props.value || '')
      handle('blur')()
    }
  }, [props.value])

  useEffect(() => {
    $focused.set(field.focused)
  }, [field.focused])

  useEffect(() => {
    if (field.focused || (inputRef as any).current === document.activeElement) {
      handle('focus')()
      ;(inputRef as any).current.focus()
    }

    if (field.focused === false) {
      handle('blur')()
    }
  }, [field.focused])

  useEffect(() => {
    if (focused || (inputRef as any).current === document.activeElement) {
      if (onFocus) {
        onFocus()
      }
      ;(inputRef as any).current.focus()
    }
    if (!focused) {
      if (onBlur) {
        onBlur()
      }
    }
  }, [focused])

  useEffect(() => {
    $focused.set(props.focused)
  }, [props.focused])

  /* Event Handlers ----------------------------------------------------------------------------- */
  const handle: (type: string) => any = type => {
    switch (type) {
      case 'change':
        return (value: any) => {
          if (onChange) {
            onChange(handleValidate(value, props.validate))
          }

          $value.set(value)
        }
      case 'focus':
        return () => {
          if (onFocus) {
            onFocus()
          }

          if (dispatch) {
            dispatch({ type: FormActions.FOCUS, param: { name } })
          } else {
            $focused.set(true)
          }
        }

      case 'blur':
        return () => {
          if (onBlur) {
            onBlur()
          }

          if (dispatch && props.name) {
            dispatch({
              type: FormActions.SET,
              param: { name, value: handleValidate(value, props.validate) },
            })
            dispatch({ type: FormActions.BLUR, param: { name } })
          } else {
            $focused.set(false)
          }
        }
      default:
        throw new Error('Unexpected event handler')
    }
  }

  return (
    <TextField
      className={classes('www-tfi')}
      label={label}
      multiline
      rows={10}
      variant="outlined"
      placeholder={placeholder}
      inputRef={inputRef}
      value={handleValue(value)}
      fullWidth
      onChange={(e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) =>
        handle('change')(e.target.value)
      }
      InputProps={{
        onBlur: handle('blur'),
        onFocus: onFocus,
      }}
      size="small"
      helperText={helperText}
    ></TextField>
  )
}

{
  /* <textarea
        className={classes('www-tfi', base, themed)}
        onBlur={handle('blur')}
        onFocus={onFocus}
        onChange={(e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) =>
          handle('change')(e.target.value)
        }
        placeholder={placeholder}
        ref={inputRef}
        value={handleValue(value)}
        style={{
          resize: 'none',
        }}
      /> */
}

export const Textarea: FC<TextareaProps> = withField(TextareaComponent)
