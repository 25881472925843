import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { UserStyled } from './user.styled'

const User: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <UserStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M23.5767 24.5139L24.2838 25.221C24.4714 25.0335 24.5767 24.7791 24.5767 24.5139H23.5767ZM8.42328 24.5139H7.42328C7.42328 24.7791 7.52864 25.0335 7.71617 25.221L8.42328 24.5139ZM9.52596 26.5423C10.0123 26.8041 10.6187 26.6221 10.8805 26.1358C11.1423 25.6495 10.9603 25.0431 10.474 24.7813L9.52596 26.5423ZM15.9981 26C15.4459 25.9999 14.9981 26.4476 14.998 26.9999C14.9979 27.5522 15.4456 27.9999 15.9979 28L15.9981 26ZM19.7354 9.36045C19.7354 11.4235 18.063 13.0959 16 13.0959V15.0959C19.1676 15.0959 21.7354 12.528 21.7354 9.36045H19.7354ZM16 13.0959C13.937 13.0959 12.2646 11.4235 12.2646 9.36045H10.2646C10.2646 12.528 12.8324 15.0959 16 15.0959V13.0959ZM12.2646 9.36045C12.2646 7.29741 13.937 5.625 16 5.625V3.625C12.8324 3.625 10.2646 6.19284 10.2646 9.36045H12.2646ZM16 5.625C18.063 5.625 19.7354 7.29741 19.7354 9.36045H21.7354C21.7354 6.19284 19.1676 3.625 16 3.625V5.625ZM22.5767 22.6197V24.5139H24.5767V22.6197H22.5767ZM9.42328 24.5139V22.6197H7.42328V24.5139H9.42328ZM16 16.043C19.6322 16.043 22.5767 18.9875 22.5767 22.6197H24.5767C24.5767 17.8829 20.7368 14.043 16 14.043V16.043ZM16 14.043C11.2632 14.043 7.42328 17.8829 7.42328 22.6197H9.42328C9.42328 18.9875 12.3678 16.043 16 16.043V14.043ZM7.71617 25.221C8.23756 25.7424 8.85379 26.1804 9.52596 26.5423L10.474 24.7813C9.94314 24.4954 9.49182 24.1682 9.13039 23.8068L7.71617 25.221ZM15.9979 28C19.0833 28.0004 22.3584 27.1464 24.2838 25.221L22.8696 23.8068C21.4795 25.1969 18.8334 26.0004 15.9981 26L15.9979 28Z"
        className="fill"
      />
    </UserStyled>
  )
}

export default User
