import React from 'react'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
import { circleClass } from './circle.class'

/* Components ======================================================================================= */
import { useStyles } from './circle.styled'

/* Types ======================================================================================== */
import { ComponentSizeType, ComponentBaselineType } from '../__core/component.types'
export type CircleProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  size?: ComponentSizeType
  onClick?: any
  baseline?: ComponentBaselineType
}

/* <Circle /> =================================================================================== */
export const Circle: React.FC<CircleProps> = props => {
  const { size = 'md', children, className, style, onClick } = props
  const { base, themed, sized } = circleClass.setProps(props)
  const styleClasses = useStyles()
  return (
    <div
      className={classes(
        base,
        themed,
        sized[size],
        className,
        typeof onClick == 'undefined' ? styleClasses.root : null,
      )}
      style={style}
      onClick={
        typeof onClick !== 'undefined'
          ? onClick
          : (e: any) => {
              e.stopPropagation()
            }
      }
    >
      {children}
    </div>
  )
}
