import React from 'react'
// import { classes } from 'typestyle'

/* Styles ======================================================================================= */
// import { tableHeaderClass } from './table-header.class'
import { Header } from '.'
// import { Text } from '../text/text.component'
import { ComponentSizeType } from '../__core/component.types'
import { Icon } from '../icons'
import { parseWidth } from './table.utils'
// import { Box } from '../box/box.component'
// import { Border } from '../border/border.component'

/* Material UI ======================================================================================== */
import { TableHead as MaterialTableHead } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

/* Styles ======================================================================================== */
import { useStyles } from './table-header.styled'

/* Types ======================================================================================== */
type TableHeaderProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  headers: Header[]
  size?: ComponentSizeType
  float?: boolean
}

const defaultProps: {} = {}

/* <TableHeader /> ================================================================================= */
const TableHeaderComponent: React.FC<TableHeaderProps> = props => {
  const { className, children, style, headers, size = 'md', float } = props
  const styleClasses = useStyles()
  // const renderSortIcon = (sort?: number) => {
  //   let index = 0

  //   if (sort) {
  //     if (sort === 1) {
  //       index = 1
  //     } else {
  //       index = 2
  //     }
  //   }

  //   return <Icon.ArrowDown size="xs" index={index} />
  //   // return <Icon.Sort size="xs" index={index} />
  // }

  // const { base, proped, themed, column, columnThemed, content } = tableHeaderClass.setProps({
  //   size,
  // })

  return (
    <MaterialTableHead>
      <TableRow>
        {headers.map((header, key) => {
          const {
            name,
            label,
            width,
            minWidth,
            handleClick,
            sort,
            renderHeader,
            align,
            fontWeight,
          } = header
          // console.log(header)
          return (
            <TableCell
              className={styleClasses.wwwTh}
              key={`${name}-${key}`}
              align={align}
              style={{
                width: `${width * 100}%`,
                minWidth: parseWidth(minWidth),
                fontWeight: fontWeight,
                borderBottom: `${
                  label === '' || name === 'empty' ? '0' : '1px solid rgba(224, 224, 224, 1)'
                }`,
              }}
              padding={label ? 'default' : 'none'}
            >
              {label && (
                <TableSortLabel
                  onClick={handleClick}
                  active
                  direction={sort === 1 ? 'asc' : 'desc'}
                  IconComponent={() => (
                    <Icon.ArrowDown
                      size="xs"
                      className={`MuiTableSortLabel-icon ${
                        sort === 1
                          ? 'MuiTableSortLabel-iconDirectionAsc'
                          : 'MuiTableSortLabel-iconDirectionDesc'
                      }`}
                    />
                  )}
                >
                  {renderHeader ? renderHeader(header, key) : label}
                </TableSortLabel>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </MaterialTableHead>
  )
}

TableHeaderComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const TableHeader = TableHeaderComponent
