import React, { useRef, useEffect } from 'react'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
import { floatDialogClass } from './float-dialog.class'
import useValue from '../../hooks/use-value'

/* Types ======================================================================================== */
import { ComponentCornerPositionType } from '../__core/component.types'

type GridItemProps = {
  style?: React.CSSProperties
  children?: any
  anchorPosition: ComponentCornerPositionType
  dialogPosition: ComponentCornerPositionType
  fullWidth?: boolean
  offsetY?: number
  onClick?: any
  open?: boolean
  parentRect: any
  parentRef: any
}

const defaultProps: {} = {}

/* <FloatDialog /> ============================================================================== */
const FloatDialogComponent: React.FC<GridItemProps> = props => {
  const {
    children,
    anchorPosition,
    dialogPosition,
    fullWidth,
    offsetY = 0,
    onClick,
    open,
    parentRef,
    // parentRect,
  } = props

  const dialogRef = useRef(null)
  const contentRef = useRef(null)
  const [time, $time] = useValue(Date.now)
  const [internalChildren, $internalChildren] = useValue(null)
  const [internalOffsetX, $internalOffsetX] = useValue(null)
  const [internalOffsetY, $internalOffsetY] = useValue(null)

  useEffect(() => {
    const updateByTime = () => {
      $time.set(Date.now)
    }

    updateByTime()

    window.addEventListener('resize', updateByTime)

    $internalChildren.set(children)

    return () => {
      window.removeEventListener('resize', updateByTime)
    }
  }, [])

  useEffect(() => {
    if (parentRef.current) {
      const contentRect = (contentRef.current as any).getBoundingClientRect()
      const parentRect = (parentRef.current as any).getBoundingClientRect()
      const parsedAnchorPosition = anchorPosition.split(' ')
      const parsedDialogPosition = dialogPosition.split(' ')
      /**
       * pad number when calculating for overflown
       */
      const pad = 8
      const clientHeight = document.documentElement.clientHeight
      const clientWidth = document.documentElement.clientWidth
      /**
       * default pad when floating element is close to the sides/top/bottom
       */
      const offset = 8

      let x = 0
      let y = 0

      /* Compute anchor positions starts */
      switch (parsedAnchorPosition[0]) {
        case 'top':
          y = 0
          break
        case 'bottom':
          y = parentRect.height
          break
      }

      switch (parsedAnchorPosition[1]) {
        case 'left':
          x = 0
          break
        case 'center':
          x = parentRect.width / 2
          break
        case 'right':
          x = parentRect.width
          break
      }
      /* Compute anchor positions ends */

      /* Compute dialog positions starts */
      switch (parsedDialogPosition[0]) {
        case 'top':
          if (contentRect.y + contentRect.height > clientHeight - 200) {
            y = y - parentRect.height - contentRect.height - 8
          } else {
            // y += offset
          }
          // y -= 200
          break
        case 'bottom':
          if (parentRect.y + contentRect.height < clientHeight - pad) {
            y = parentRect.height + offset
          } else {
            y -= contentRect.height + offset
          }
          break
      }

      switch (parsedDialogPosition[1]) {
        case 'left':
          if (parsedAnchorPosition[1] === 'right') {
            if (parentRect.x + contentRect.width + x >= clientWidth - pad) {
              x -= contentRect.width + x + parentRect.x - clientWidth + offset
            }
          } else {
            if (parentRect.x + contentRect.width >= clientWidth - pad) {
              x -= contentRect.width + parentRect.x - clientWidth + offset
            }

            if (parentRect.x < pad) {
              x += offset
            }
          }

          break
        case 'center':
          x -= contentRect.width / 2
          // console.log(x, dialogRect.width, contentRect.width)
          // x += 60
          // x = x + dialogRect.width / 2
          // if (contentRect.width + dialogRect.x + 16 > clientHeight) {
          //   x -= contentRect.width + dialogRect.x - clientHeight + 8
          // }
          break
        case 'right':
          x = x - contentRect.width
          if (parsedAnchorPosition[1] === 'right') {
            if (x + parentRect.x > clientWidth - pad) {
              x -= parentRect.width + contentRect.width + parentRect.x - clientWidth
            }

            if (parentRect.x + parentRect.width - contentRect.width - pad <= 0) {
              x -= parentRect.x + parentRect.width - contentRect.width - pad
            }
            x -= offset
          } else {
            if (parentRect.x + x <= 0) {
              x -= parentRect.x + x - offset
            }
          }

          break
      }

      $internalOffsetX.set(x)
      $internalOffsetY.set(y)
    }
  }, [time, internalChildren, parentRef.current, open, contentRef.current])

  const { base, proped, contentBase, contentProped } = floatDialogClass.setProps({ open })
  const parentRect = (parentRef.current as any).getBoundingClientRect()

  return (
    <div
      className={classes(
        'www-float-dialog',
        base,
        open && proped.open,
        fullWidth && proped.fullWidth,
      )}
      onClick={onClick}
      ref={dialogRef}
      style={{
        overflow: !open ? 'hidden' : '',
        background: 'white',
        width: parentRect.width,
        top: parentRect.top,
      }}
    >
      {/* <div
        style={{
          width: 8,
          height: 8,
          background: 'rgb(60,60,60)',
          position: 'absolute',
          transform: 'rotate(45deg) translateX(-50%)',
          left: '50%',
          top: parentRect.height + offsetY,
          zIndex: -1,
        }}
      /> */}
      <div
        className={classes(contentBase, fullWidth && contentProped.fullWidth)}
        ref={contentRef}
        style={{
          top: internalOffsetY,
          left: internalOffsetX,
        }}
      >
        {children}
      </div>
    </div>
  )
}

FloatDialogComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const FloatDialog = FloatDialogComponent
