import React, { useEffect, FC } from 'react'

/* Context ====================================================================================== */
import { withField, FieldContextProps, FieldProps } from '../field/field.component'
import { FormActions } from '../form'

/* <StaticFielcComponent /> ======================================================================= */
/**  See bottom for wrapped component */
const StaticFielcComponent: React.FC<FieldContextProps> = ({
  value = '',
  dispatch,
  name,
  beforeSubmit,
}) => {
  useEffect(() => {
    dispatch({ type: FormActions.FIELD, param: { name, value: { beforeSubmit } } })
  }, [])

  useEffect(() => {
    if (value) {
      dispatch({
        type: FormActions.SET,
        param: { name, value },
      })
    }
  }, [value])

  return (
    <input
      style={{
        display: 'none',
      }}
      onChange={() => {}}
      value={value}
    />
  )
}

export const StaticField: FC<FieldProps> = withField(StaticFielcComponent)
