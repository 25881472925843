import React from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { Button, ButtonCore } from '../button'
import { Text } from '../text/text.component'
import { Box } from '../box/box.component'
import SET from '../../styles/set'

/* Material UI  ======================================================================================== */
// Core
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

/* Types ======================================================================================== */
type Dialog = {
  render?: any
  confirmRender?: any
  confirm?: () => void
  cancelRender?: any
  cancel?: () => void
  label?: string
  disableAction?: boolean
  status?: string
  props?: any
  type?: string
  componentProps?: any
}

/* DialogStack ================================================================================== */
class DialogStack {
  count = 0
  self: any = null
  storage: Dialog[] = []
  components: any

  setComponents = (components: any) => {
    this.components = components
  }

  push = (dialog: Dialog) => {
    this.storage[this.count + 1] = dialog
    this.count++

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  pop = () => {
    delete this.storage[this.count]

    this.count--

    if (this.count < 0) {
      this.count = 0
    }

    this.self.set((prev: any) => ({ ...prev, ...this }))
  }

  update = (props?: any) => {
    this.storage[this.count].props = {
      ...this.storage[this.count].props,
      ...props,
    }

    this.self.set(this)
  }

  size = () => this.count

  render = () => {
    const [self, $self] = useValue(this)
    // const [currentDialog, $currentDialog] = useValue(this)
    this.self = $self

    const handleContainerClick = () => {
      self.pop()
    }

    const handleContentClick = (event: any) => {
      event.stopPropagation()
    }

    return (
      <>
        {self.storage.map((dialog: Dialog, key: number) => {
          const { componentProps, type = 'Prompt' } = dialog
          switch (type) {
            case 'Prompt': {
              return (
                <Dialog open={dialog.componentProps} key={key} onClose={handleContainerClick}>
                  {/* <DialogContainer onClick={handleContainerClick}> */}
                  {/* <DialogWrapperContainer onClick={handleContentClick}> */}
                  <DialogTitle>{dialog.componentProps.label}</DialogTitle>
                  <DialogActions>
                    <Button label="Cancel" onClick={handleContainerClick} />
                    <Button
                      label="Confirm"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        if (dialog.componentProps.confirm) {
                          dialog.componentProps.confirm()
                        } else {
                          this.pop()
                        }
                      }}
                    />
                  </DialogActions>
                  {/* </DialogWrapperContainer> */}
                  {/* </DialogContainer> */}
                </Dialog>
              )
            }

            case 'Info': {
              return (
                <Dialog open={dialog.componentProps} key={key} onClose={handleContainerClick}>
                  {/* <DialogContainer onClick={handleContainerClick} key={key}> */}
                  {/* <DialogWrapperContainer onClick={handleContentClick}> */}
                  <DialogTitle>{dialog.componentProps.label}</DialogTitle>
                  <DialogActions>
                    <Button
                      label="Confirm"
                      variant="contained"
                      color="secondary"
                      onClick={handleContainerClick}
                    />
                  </DialogActions>
                  {/* </DialogWrapperContainer> */}
                  {/* </DialogContainer> */}
                </Dialog>
              )
            }

            default: {
              const Component = this.components[type as any]
              return (
                <Dialog
                  open={componentProps}
                  key={key}
                  onClose={handleContainerClick}
                  onClick={handleContentClick}
                >
                  {/* <DialogContainer onClick={handleContainerClick} key={key}> */}
                  {/* <div onClick={handleContentClick}> */}
                  <Component key={key} {...componentProps} pop={self.pop} />
                  {/* </div> */}
                  {/* </DialogContainer> */}
                </Dialog>
              )
            }
          }
        })}
      </>
    )
  }
}

/* Styled Components ============================================================================ */
const DialogWrapperContainer = styled.div`
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  min-width: 296px;
  padding: 16px;
  background: white;
`

// const DialogWrapper = styled.div``

export const DialogContainer = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;
  z-index: 4;

  &.popin-enter {
    opacity: 0;
    transform: translateY(-8px);
  }

  &.popin-enter-active {
    opacity: 1;
    transform: translateY(0px);
  }

  &.popin-exit {
    opacity: 1;
    transform: translateY(0px);
  }

  &.popin-exit-active {
    opacity: 0;
    transform: translateY(-8px);
  }
`

export const DialogActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;

  ${ButtonCore}:last-child {
    margin-left: 8px;
  }
`

export const DialogWrapper = new DialogStack()
