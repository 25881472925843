/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwBreadcrumbContainer: {
      alignItems: 'center',
      display: 'flex',
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
  }),
)
