import { generateSet } from './set.init'

export const padding: Padding = {
  ...generateSet({
    key: { p: 'padding' },
    getValue: (i: any) => `${8 * i}px`,
    size: 20,
  }),
  ...generateSet({
    key: { pl: 'paddingLeft' },
    getValue: (i: any) => `${8 * i}px`,
    size: 20,
  }),
  ...generateSet({
    key: { pb: 'paddingBottom' },
    getValue: (i: any) => `${8 * i}px`,
    size: 20,
  }),
  ...generateSet({
    key: { pr: 'paddingRight' },
    getValue: (i: any) => `${8 * i}px`,
    size: 20,
  }),
  ...generateSet({
    key: { pt: 'paddingTop' },
    getValue: (i: any) => `${8 * i}px`,
    size: 20,
  }),
}

/** Below type is generated by `generateTypes` in set.ts */
type Padding = {
  p0: {}
  p0i: {}
  p0sm: {}
  p0smi: {}
  p0md: {}
  p0mdi: {}
  p0lg: {}
  p0lgi: {}
  p0xl: {}
  p0xli: {}
  p8: {}
  p8i: {}
  p8sm: {}
  p8smi: {}
  p8md: {}
  p8mdi: {}
  p8lg: {}
  p8lgi: {}
  p8xl: {}
  p8xli: {}
  p16: {}
  p16i: {}
  p16sm: {}
  p16smi: {}
  p16md: {}
  p16mdi: {}
  p16lg: {}
  p16lgi: {}
  p16xl: {}
  p16xli: {}
  p24: {}
  p24i: {}
  p24sm: {}
  p24smi: {}
  p24md: {}
  p24mdi: {}
  p24lg: {}
  p24lgi: {}
  p24xl: {}
  p24xli: {}
  p32: {}
  p32i: {}
  p32sm: {}
  p32smi: {}
  p32md: {}
  p32mdi: {}
  p32lg: {}
  p32lgi: {}
  p32xl: {}
  p32xli: {}
  p40: {}
  p40i: {}
  p40sm: {}
  p40smi: {}
  p40md: {}
  p40mdi: {}
  p40lg: {}
  p40lgi: {}
  p40xl: {}
  p40xli: {}
  p48: {}
  p48i: {}
  p48sm: {}
  p48smi: {}
  p48md: {}
  p48mdi: {}
  p48lg: {}
  p48lgi: {}
  p48xl: {}
  p48xli: {}
  p56: {}
  p56i: {}
  p56sm: {}
  p56smi: {}
  p56md: {}
  p56mdi: {}
  p56lg: {}
  p56lgi: {}
  p56xl: {}
  p56xli: {}
  p64: {}
  p64i: {}
  p64sm: {}
  p64smi: {}
  p64md: {}
  p64mdi: {}
  p64lg: {}
  p64lgi: {}
  p64xl: {}
  p64xli: {}
  p72: {}
  p72i: {}
  p72sm: {}
  p72smi: {}
  p72md: {}
  p72mdi: {}
  p72lg: {}
  p72lgi: {}
  p72xl: {}
  p72xli: {}
  p80: {}
  p80i: {}
  p80sm: {}
  p80smi: {}
  p80md: {}
  p80mdi: {}
  p80lg: {}
  p80lgi: {}
  p80xl: {}
  p80xli: {}
  p88: {}
  p88i: {}
  p88sm: {}
  p88smi: {}
  p88md: {}
  p88mdi: {}
  p88lg: {}
  p88lgi: {}
  p88xl: {}
  p88xli: {}
  p96: {}
  p96i: {}
  p96sm: {}
  p96smi: {}
  p96md: {}
  p96mdi: {}
  p96lg: {}
  p96lgi: {}
  p96xl: {}
  p96xli: {}
  p104: {}
  p104i: {}
  p104sm: {}
  p104smi: {}
  p104md: {}
  p104mdi: {}
  p104lg: {}
  p104lgi: {}
  p104xl: {}
  p104xli: {}
  p112: {}
  p112i: {}
  p112sm: {}
  p112smi: {}
  p112md: {}
  p112mdi: {}
  p112lg: {}
  p112lgi: {}
  p112xl: {}
  p112xli: {}
  p120: {}
  p120i: {}
  p120sm: {}
  p120smi: {}
  p120md: {}
  p120mdi: {}
  p120lg: {}
  p120lgi: {}
  p120xl: {}
  p120xli: {}
  p128: {}
  p128i: {}
  p128sm: {}
  p128smi: {}
  p128md: {}
  p128mdi: {}
  p128lg: {}
  p128lgi: {}
  p128xl: {}
  p128xli: {}
  p136: {}
  p136i: {}
  p136sm: {}
  p136smi: {}
  p136md: {}
  p136mdi: {}
  p136lg: {}
  p136lgi: {}
  p136xl: {}
  p136xli: {}
  p144: {}
  p144i: {}
  p144sm: {}
  p144smi: {}
  p144md: {}
  p144mdi: {}
  p144lg: {}
  p144lgi: {}
  p144xl: {}
  p144xli: {}
  p152: {}
  p152i: {}
  p152sm: {}
  p152smi: {}
  p152md: {}
  p152mdi: {}
  p152lg: {}
  p152lgi: {}
  p152xl: {}
  p152xli: {}
  p160: {}
  p160i: {}
  p160sm: {}
  p160smi: {}
  p160md: {}
  p160mdi: {}
  p160lg: {}
  p160lgi: {}
  p160xl: {}
  p160xli: {}
  pt0: {}
  pt0i: {}
  pt0sm: {}
  pt0smi: {}
  pt0md: {}
  pt0mdi: {}
  pt0lg: {}
  pt0lgi: {}
  pt0xl: {}
  pt0xli: {}
  pt8: {}
  pt8i: {}
  pt8sm: {}
  pt8smi: {}
  pt8md: {}
  pt8mdi: {}
  pt8lg: {}
  pt8lgi: {}
  pt8xl: {}
  pt8xli: {}
  pt16: {}
  pt16i: {}
  pt16sm: {}
  pt16smi: {}
  pt16md: {}
  pt16mdi: {}
  pt16lg: {}
  pt16lgi: {}
  pt16xl: {}
  pt16xli: {}
  pt24: {}
  pt24i: {}
  pt24sm: {}
  pt24smi: {}
  pt24md: {}
  pt24mdi: {}
  pt24lg: {}
  pt24lgi: {}
  pt24xl: {}
  pt24xli: {}
  pt32: {}
  pt32i: {}
  pt32sm: {}
  pt32smi: {}
  pt32md: {}
  pt32mdi: {}
  pt32lg: {}
  pt32lgi: {}
  pt32xl: {}
  pt32xli: {}
  pt40: {}
  pt40i: {}
  pt40sm: {}
  pt40smi: {}
  pt40md: {}
  pt40mdi: {}
  pt40lg: {}
  pt40lgi: {}
  pt40xl: {}
  pt40xli: {}
  pt48: {}
  pt48i: {}
  pt48sm: {}
  pt48smi: {}
  pt48md: {}
  pt48mdi: {}
  pt48lg: {}
  pt48lgi: {}
  pt48xl: {}
  pt48xli: {}
  pt56: {}
  pt56i: {}
  pt56sm: {}
  pt56smi: {}
  pt56md: {}
  pt56mdi: {}
  pt56lg: {}
  pt56lgi: {}
  pt56xl: {}
  pt56xli: {}
  pt64: {}
  pt64i: {}
  pt64sm: {}
  pt64smi: {}
  pt64md: {}
  pt64mdi: {}
  pt64lg: {}
  pt64lgi: {}
  pt64xl: {}
  pt64xli: {}
  pt72: {}
  pt72i: {}
  pt72sm: {}
  pt72smi: {}
  pt72md: {}
  pt72mdi: {}
  pt72lg: {}
  pt72lgi: {}
  pt72xl: {}
  pt72xli: {}
  pt80: {}
  pt80i: {}
  pt80sm: {}
  pt80smi: {}
  pt80md: {}
  pt80mdi: {}
  pt80lg: {}
  pt80lgi: {}
  pt80xl: {}
  pt80xli: {}
  pt88: {}
  pt88i: {}
  pt88sm: {}
  pt88smi: {}
  pt88md: {}
  pt88mdi: {}
  pt88lg: {}
  pt88lgi: {}
  pt88xl: {}
  pt88xli: {}
  pt96: {}
  pt96i: {}
  pt96sm: {}
  pt96smi: {}
  pt96md: {}
  pt96mdi: {}
  pt96lg: {}
  pt96lgi: {}
  pt96xl: {}
  pt96xli: {}
  pt104: {}
  pt104i: {}
  pt104sm: {}
  pt104smi: {}
  pt104md: {}
  pt104mdi: {}
  pt104lg: {}
  pt104lgi: {}
  pt104xl: {}
  pt104xli: {}
  pt112: {}
  pt112i: {}
  pt112sm: {}
  pt112smi: {}
  pt112md: {}
  pt112mdi: {}
  pt112lg: {}
  pt112lgi: {}
  pt112xl: {}
  pt112xli: {}
  pt120: {}
  pt120i: {}
  pt120sm: {}
  pt120smi: {}
  pt120md: {}
  pt120mdi: {}
  pt120lg: {}
  pt120lgi: {}
  pt120xl: {}
  pt120xli: {}
  pt128: {}
  pt128i: {}
  pt128sm: {}
  pt128smi: {}
  pt128md: {}
  pt128mdi: {}
  pt128lg: {}
  pt128lgi: {}
  pt128xl: {}
  pt128xli: {}
  pt136: {}
  pt136i: {}
  pt136sm: {}
  pt136smi: {}
  pt136md: {}
  pt136mdi: {}
  pt136lg: {}
  pt136lgi: {}
  pt136xl: {}
  pt136xli: {}
  pt144: {}
  pt144i: {}
  pt144sm: {}
  pt144smi: {}
  pt144md: {}
  pt144mdi: {}
  pt144lg: {}
  pt144lgi: {}
  pt144xl: {}
  pt144xli: {}
  pt152: {}
  pt152i: {}
  pt152sm: {}
  pt152smi: {}
  pt152md: {}
  pt152mdi: {}
  pt152lg: {}
  pt152lgi: {}
  pt152xl: {}
  pt152xli: {}
  pt160: {}
  pt160i: {}
  pt160sm: {}
  pt160smi: {}
  pt160md: {}
  pt160mdi: {}
  pt160lg: {}
  pt160lgi: {}
  pt160xl: {}
  pt160xli: {}
  pr0: {}
  pr0i: {}
  pr0sm: {}
  pr0smi: {}
  pr0md: {}
  pr0mdi: {}
  pr0lg: {}
  pr0lgi: {}
  pr0xl: {}
  pr0xli: {}
  pr8: {}
  pr8i: {}
  pr8sm: {}
  pr8smi: {}
  pr8md: {}
  pr8mdi: {}
  pr8lg: {}
  pr8lgi: {}
  pr8xl: {}
  pr8xli: {}
  pr16: {}
  pr16i: {}
  pr16sm: {}
  pr16smi: {}
  pr16md: {}
  pr16mdi: {}
  pr16lg: {}
  pr16lgi: {}
  pr16xl: {}
  pr16xli: {}
  pr24: {}
  pr24i: {}
  pr24sm: {}
  pr24smi: {}
  pr24md: {}
  pr24mdi: {}
  pr24lg: {}
  pr24lgi: {}
  pr24xl: {}
  pr24xli: {}
  pr32: {}
  pr32i: {}
  pr32sm: {}
  pr32smi: {}
  pr32md: {}
  pr32mdi: {}
  pr32lg: {}
  pr32lgi: {}
  pr32xl: {}
  pr32xli: {}
  pr40: {}
  pr40i: {}
  pr40sm: {}
  pr40smi: {}
  pr40md: {}
  pr40mdi: {}
  pr40lg: {}
  pr40lgi: {}
  pr40xl: {}
  pr40xli: {}
  pr48: {}
  pr48i: {}
  pr48sm: {}
  pr48smi: {}
  pr48md: {}
  pr48mdi: {}
  pr48lg: {}
  pr48lgi: {}
  pr48xl: {}
  pr48xli: {}
  pr56: {}
  pr56i: {}
  pr56sm: {}
  pr56smi: {}
  pr56md: {}
  pr56mdi: {}
  pr56lg: {}
  pr56lgi: {}
  pr56xl: {}
  pr56xli: {}
  pr64: {}
  pr64i: {}
  pr64sm: {}
  pr64smi: {}
  pr64md: {}
  pr64mdi: {}
  pr64lg: {}
  pr64lgi: {}
  pr64xl: {}
  pr64xli: {}
  pr72: {}
  pr72i: {}
  pr72sm: {}
  pr72smi: {}
  pr72md: {}
  pr72mdi: {}
  pr72lg: {}
  pr72lgi: {}
  pr72xl: {}
  pr72xli: {}
  pr80: {}
  pr80i: {}
  pr80sm: {}
  pr80smi: {}
  pr80md: {}
  pr80mdi: {}
  pr80lg: {}
  pr80lgi: {}
  pr80xl: {}
  pr80xli: {}
  pr88: {}
  pr88i: {}
  pr88sm: {}
  pr88smi: {}
  pr88md: {}
  pr88mdi: {}
  pr88lg: {}
  pr88lgi: {}
  pr88xl: {}
  pr88xli: {}
  pr96: {}
  pr96i: {}
  pr96sm: {}
  pr96smi: {}
  pr96md: {}
  pr96mdi: {}
  pr96lg: {}
  pr96lgi: {}
  pr96xl: {}
  pr96xli: {}
  pr104: {}
  pr104i: {}
  pr104sm: {}
  pr104smi: {}
  pr104md: {}
  pr104mdi: {}
  pr104lg: {}
  pr104lgi: {}
  pr104xl: {}
  pr104xli: {}
  pr112: {}
  pr112i: {}
  pr112sm: {}
  pr112smi: {}
  pr112md: {}
  pr112mdi: {}
  pr112lg: {}
  pr112lgi: {}
  pr112xl: {}
  pr112xli: {}
  pr120: {}
  pr120i: {}
  pr120sm: {}
  pr120smi: {}
  pr120md: {}
  pr120mdi: {}
  pr120lg: {}
  pr120lgi: {}
  pr120xl: {}
  pr120xli: {}
  pr128: {}
  pr128i: {}
  pr128sm: {}
  pr128smi: {}
  pr128md: {}
  pr128mdi: {}
  pr128lg: {}
  pr128lgi: {}
  pr128xl: {}
  pr128xli: {}
  pr136: {}
  pr136i: {}
  pr136sm: {}
  pr136smi: {}
  pr136md: {}
  pr136mdi: {}
  pr136lg: {}
  pr136lgi: {}
  pr136xl: {}
  pr136xli: {}
  pr144: {}
  pr144i: {}
  pr144sm: {}
  pr144smi: {}
  pr144md: {}
  pr144mdi: {}
  pr144lg: {}
  pr144lgi: {}
  pr144xl: {}
  pr144xli: {}
  pr152: {}
  pr152i: {}
  pr152sm: {}
  pr152smi: {}
  pr152md: {}
  pr152mdi: {}
  pr152lg: {}
  pr152lgi: {}
  pr152xl: {}
  pr152xli: {}
  pr160: {}
  pr160i: {}
  pr160sm: {}
  pr160smi: {}
  pr160md: {}
  pr160mdi: {}
  pr160lg: {}
  pr160lgi: {}
  pr160xl: {}
  pr160xli: {}
  pb0: {}
  pb0i: {}
  pb0sm: {}
  pb0smi: {}
  pb0md: {}
  pb0mdi: {}
  pb0lg: {}
  pb0lgi: {}
  pb0xl: {}
  pb0xli: {}
  pb8: {}
  pb8i: {}
  pb8sm: {}
  pb8smi: {}
  pb8md: {}
  pb8mdi: {}
  pb8lg: {}
  pb8lgi: {}
  pb8xl: {}
  pb8xli: {}
  pb16: {}
  pb16i: {}
  pb16sm: {}
  pb16smi: {}
  pb16md: {}
  pb16mdi: {}
  pb16lg: {}
  pb16lgi: {}
  pb16xl: {}
  pb16xli: {}
  pb24: {}
  pb24i: {}
  pb24sm: {}
  pb24smi: {}
  pb24md: {}
  pb24mdi: {}
  pb24lg: {}
  pb24lgi: {}
  pb24xl: {}
  pb24xli: {}
  pb32: {}
  pb32i: {}
  pb32sm: {}
  pb32smi: {}
  pb32md: {}
  pb32mdi: {}
  pb32lg: {}
  pb32lgi: {}
  pb32xl: {}
  pb32xli: {}
  pb40: {}
  pb40i: {}
  pb40sm: {}
  pb40smi: {}
  pb40md: {}
  pb40mdi: {}
  pb40lg: {}
  pb40lgi: {}
  pb40xl: {}
  pb40xli: {}
  pb48: {}
  pb48i: {}
  pb48sm: {}
  pb48smi: {}
  pb48md: {}
  pb48mdi: {}
  pb48lg: {}
  pb48lgi: {}
  pb48xl: {}
  pb48xli: {}
  pb56: {}
  pb56i: {}
  pb56sm: {}
  pb56smi: {}
  pb56md: {}
  pb56mdi: {}
  pb56lg: {}
  pb56lgi: {}
  pb56xl: {}
  pb56xli: {}
  pb64: {}
  pb64i: {}
  pb64sm: {}
  pb64smi: {}
  pb64md: {}
  pb64mdi: {}
  pb64lg: {}
  pb64lgi: {}
  pb64xl: {}
  pb64xli: {}
  pb72: {}
  pb72i: {}
  pb72sm: {}
  pb72smi: {}
  pb72md: {}
  pb72mdi: {}
  pb72lg: {}
  pb72lgi: {}
  pb72xl: {}
  pb72xli: {}
  pb80: {}
  pb80i: {}
  pb80sm: {}
  pb80smi: {}
  pb80md: {}
  pb80mdi: {}
  pb80lg: {}
  pb80lgi: {}
  pb80xl: {}
  pb80xli: {}
  pb88: {}
  pb88i: {}
  pb88sm: {}
  pb88smi: {}
  pb88md: {}
  pb88mdi: {}
  pb88lg: {}
  pb88lgi: {}
  pb88xl: {}
  pb88xli: {}
  pb96: {}
  pb96i: {}
  pb96sm: {}
  pb96smi: {}
  pb96md: {}
  pb96mdi: {}
  pb96lg: {}
  pb96lgi: {}
  pb96xl: {}
  pb96xli: {}
  pb104: {}
  pb104i: {}
  pb104sm: {}
  pb104smi: {}
  pb104md: {}
  pb104mdi: {}
  pb104lg: {}
  pb104lgi: {}
  pb104xl: {}
  pb104xli: {}
  pb112: {}
  pb112i: {}
  pb112sm: {}
  pb112smi: {}
  pb112md: {}
  pb112mdi: {}
  pb112lg: {}
  pb112lgi: {}
  pb112xl: {}
  pb112xli: {}
  pb120: {}
  pb120i: {}
  pb120sm: {}
  pb120smi: {}
  pb120md: {}
  pb120mdi: {}
  pb120lg: {}
  pb120lgi: {}
  pb120xl: {}
  pb120xli: {}
  pb128: {}
  pb128i: {}
  pb128sm: {}
  pb128smi: {}
  pb128md: {}
  pb128mdi: {}
  pb128lg: {}
  pb128lgi: {}
  pb128xl: {}
  pb128xli: {}
  pb136: {}
  pb136i: {}
  pb136sm: {}
  pb136smi: {}
  pb136md: {}
  pb136mdi: {}
  pb136lg: {}
  pb136lgi: {}
  pb136xl: {}
  pb136xli: {}
  pb144: {}
  pb144i: {}
  pb144sm: {}
  pb144smi: {}
  pb144md: {}
  pb144mdi: {}
  pb144lg: {}
  pb144lgi: {}
  pb144xl: {}
  pb144xli: {}
  pb152: {}
  pb152i: {}
  pb152sm: {}
  pb152smi: {}
  pb152md: {}
  pb152mdi: {}
  pb152lg: {}
  pb152lgi: {}
  pb152xl: {}
  pb152xli: {}
  pb160: {}
  pb160i: {}
  pb160sm: {}
  pb160smi: {}
  pb160md: {}
  pb160mdi: {}
  pb160lg: {}
  pb160lgi: {}
  pb160xl: {}
  pb160xli: {}
  pl0: {}
  pl0i: {}
  pl0sm: {}
  pl0smi: {}
  pl0md: {}
  pl0mdi: {}
  pl0lg: {}
  pl0lgi: {}
  pl0xl: {}
  pl0xli: {}
  pl8: {}
  pl8i: {}
  pl8sm: {}
  pl8smi: {}
  pl8md: {}
  pl8mdi: {}
  pl8lg: {}
  pl8lgi: {}
  pl8xl: {}
  pl8xli: {}
  pl16: {}
  pl16i: {}
  pl16sm: {}
  pl16smi: {}
  pl16md: {}
  pl16mdi: {}
  pl16lg: {}
  pl16lgi: {}
  pl16xl: {}
  pl16xli: {}
  pl24: {}
  pl24i: {}
  pl24sm: {}
  pl24smi: {}
  pl24md: {}
  pl24mdi: {}
  pl24lg: {}
  pl24lgi: {}
  pl24xl: {}
  pl24xli: {}
  pl32: {}
  pl32i: {}
  pl32sm: {}
  pl32smi: {}
  pl32md: {}
  pl32mdi: {}
  pl32lg: {}
  pl32lgi: {}
  pl32xl: {}
  pl32xli: {}
  pl40: {}
  pl40i: {}
  pl40sm: {}
  pl40smi: {}
  pl40md: {}
  pl40mdi: {}
  pl40lg: {}
  pl40lgi: {}
  pl40xl: {}
  pl40xli: {}
  pl48: {}
  pl48i: {}
  pl48sm: {}
  pl48smi: {}
  pl48md: {}
  pl48mdi: {}
  pl48lg: {}
  pl48lgi: {}
  pl48xl: {}
  pl48xli: {}
  pl56: {}
  pl56i: {}
  pl56sm: {}
  pl56smi: {}
  pl56md: {}
  pl56mdi: {}
  pl56lg: {}
  pl56lgi: {}
  pl56xl: {}
  pl56xli: {}
  pl64: {}
  pl64i: {}
  pl64sm: {}
  pl64smi: {}
  pl64md: {}
  pl64mdi: {}
  pl64lg: {}
  pl64lgi: {}
  pl64xl: {}
  pl64xli: {}
  pl72: {}
  pl72i: {}
  pl72sm: {}
  pl72smi: {}
  pl72md: {}
  pl72mdi: {}
  pl72lg: {}
  pl72lgi: {}
  pl72xl: {}
  pl72xli: {}
  pl80: {}
  pl80i: {}
  pl80sm: {}
  pl80smi: {}
  pl80md: {}
  pl80mdi: {}
  pl80lg: {}
  pl80lgi: {}
  pl80xl: {}
  pl80xli: {}
  pl88: {}
  pl88i: {}
  pl88sm: {}
  pl88smi: {}
  pl88md: {}
  pl88mdi: {}
  pl88lg: {}
  pl88lgi: {}
  pl88xl: {}
  pl88xli: {}
  pl96: {}
  pl96i: {}
  pl96sm: {}
  pl96smi: {}
  pl96md: {}
  pl96mdi: {}
  pl96lg: {}
  pl96lgi: {}
  pl96xl: {}
  pl96xli: {}
  pl104: {}
  pl104i: {}
  pl104sm: {}
  pl104smi: {}
  pl104md: {}
  pl104mdi: {}
  pl104lg: {}
  pl104lgi: {}
  pl104xl: {}
  pl104xli: {}
  pl112: {}
  pl112i: {}
  pl112sm: {}
  pl112smi: {}
  pl112md: {}
  pl112mdi: {}
  pl112lg: {}
  pl112lgi: {}
  pl112xl: {}
  pl112xli: {}
  pl120: {}
  pl120i: {}
  pl120sm: {}
  pl120smi: {}
  pl120md: {}
  pl120mdi: {}
  pl120lg: {}
  pl120lgi: {}
  pl120xl: {}
  pl120xli: {}
  pl128: {}
  pl128i: {}
  pl128sm: {}
  pl128smi: {}
  pl128md: {}
  pl128mdi: {}
  pl128lg: {}
  pl128lgi: {}
  pl128xl: {}
  pl128xli: {}
  pl136: {}
  pl136i: {}
  pl136sm: {}
  pl136smi: {}
  pl136md: {}
  pl136mdi: {}
  pl136lg: {}
  pl136lgi: {}
  pl136xl: {}
  pl136xli: {}
  pl144: {}
  pl144i: {}
  pl144sm: {}
  pl144smi: {}
  pl144md: {}
  pl144mdi: {}
  pl144lg: {}
  pl144lgi: {}
  pl144xl: {}
  pl144xli: {}
  pl152: {}
  pl152i: {}
  pl152sm: {}
  pl152smi: {}
  pl152md: {}
  pl152mdi: {}
  pl152lg: {}
  pl152lgi: {}
  pl152xl: {}
  pl152xli: {}
  pl160: {}
  pl160i: {}
  pl160sm: {}
  pl160smi: {}
  pl160md: {}
  pl160mdi: {}
  pl160lg: {}
  pl160lgi: {}
  pl160xl: {}
  pl160xli: {}
}
