import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { DailyStyled } from './daily.styled'

const Daily: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <DailyStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M16 12V16L20.3301 18.5"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7848 13.5527C30.4875 17.5235 29.4779 21.7659 26.6595 25.0761C21.647 30.9631 12.811 31.6721 6.92395 26.6595C1.03686 21.647 0.327919 12.811 5.34048 6.92395C10.353 1.03686 19.189 0.327919 25.0761 5.34048C25.7379 5.90402 26.3343 6.51588 26.8645 7.16644"
        className="stroke draw-stroke-path stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8125 6.875L26.9375 7.23009L26.5976 3.125"
        className="stroke draw-stroke-path stroke-path-3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 15.9714L27 15.9775M5.00002 16.0225L2.00003 16.0286M28.1387 22.9752L25.5375 21.4805M6.4888 10.5346L3.86136 9.02482M23.0248 28.11L21.5042 25.4888M10.4805 6.485L8.97522 3.88999M16.0286 30L16.0225 27M15.9775 5.00003L15.9714 2.00004M9.02481 28.1387L10.5346 25.5112M3.88998 23.0248L6.51124 21.5043"
        className="stroke draw-stroke-path stroke-path-4"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </DailyStyled>
  )
}

export default Daily
