/* Material UI  ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwPreview: {
      cursor: 'pointer',
      '& .www-preview-overlay': {
        transition: 'all 0.25s ease-in-out',
        opacity: 0,
      },
      '&:hover': {
        '& .www-preview-overlay': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    wwwPreviewImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    wwwUploadedItem: {
      width: '100%',
    },
    wwwDeleteButton: {
      color: theme.palette.error.main,
    },
    wwwProgressBar: {
      '&.MuiLinearProgress-root': {
        borderRadius: '50px',
        height: '10px',
        '& .MuiLinearProgress-bar': {
          borderRadius: '50px',
        },
      },
    },
  }),
)
