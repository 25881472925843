import styled from 'styled-components'

/* Constants ==================================================================================== */
import { HEADER_HEIGHT_PX, PAGE_MAX_WIDTH_PX } from '../../styles/constants'

/* Wrapper ====================================================================================== */
export const Wrapper = {
  /**
   * Full size `div`
   */
  FullSize: styled.div`
    height: 100%;
    position: relative;
    width: 100%;
  `,
  /**
   * Flex `space-bettwen`
   */
  SpaceBetween: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    *:last-child {
      margin-right: 0;
    }
  `,
  /**
   * Fixed hero container
   */
  Hero: styled.div<{ toggled: boolean }>`
    display: flex;
    justify-content: center;
    position: fixed;
    top: ${({ toggled }) => (toggled ? HEADER_HEIGHT_PX : 0)};
    transition: top 0.2s ease-in-out;
    width: 100%;
    z-index: 5;
  `,
  /**
   * Floating div
   */
  Float: styled.div`
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    max-width: ${PAGE_MAX_WIDTH_PX};
    padding: 16px 16px;
    position: absolute;
    width: 100%;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  `,
  BackgroundToggle: styled.div<{ active: boolean }>`
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    left: 0;
    opacity: ${({ active }) => (active ? 1 : 0)};
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease-in-out;
    visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
    width: 100%;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${PAGE_MAX_WIDTH_PX};
    margin: 0 auto;
    display: flex;
    padding: 24px 16px 0 16px;
  `,
  MaxWidth: styled.div`
    width: 100%;
    max-width: ${PAGE_MAX_WIDTH_PX};
    margin: 0 auto;
    display: flex;
    padding: 0 16px 0 16px;
  `,
  FloatRight: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  `,
  Sticky: styled.div`
    position: sticky;
    top: 96px;
  `,
  Flex: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}
