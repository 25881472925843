import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    '& .icon-wrap': {
      display: 'flex',
      position: 'absolute',
      right: 0,
      left: 'auto',
      pointerEvents: 'none',
    },
  },
  animateToArrowUp: {
    transform: 'rotate(180deg)',
  },
})

export default useStyles
