import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { PencilStyled } from './pencil.styled'

const Pencil: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <PencilStyled
      viewBox={'0 0 33 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M24.6667 1.79899L25.3738 1.09188C25.1862 0.904345 24.9319 0.798988 24.6667 0.798988C24.4015 0.798988 24.1471 0.904345 23.9596 1.09188L24.6667 1.79899ZM30 7.13232L30.7071 7.83943C31.0976 7.4489 31.0976 6.81574 30.7071 6.42522L30 7.13232ZM8.28572 28.8466L8.43552 29.8353C8.64659 29.8033 8.84188 29.7047 8.99282 29.5537L8.28572 28.8466ZM2.95238 23.5133L2.24528 22.8062C2.09433 22.9571 1.99565 23.1524 1.96367 23.3635L2.95238 23.5133ZM2 29.799L1.01128 29.6492C0.963685 29.9633 1.06822 30.2814 1.29289 30.5061C1.51757 30.7308 1.83565 30.8353 2.14981 30.7877L2 29.799ZM20.0404 6.42522C19.6499 6.03469 19.0167 6.03469 18.6262 6.42522C18.2357 6.81574 18.2357 7.44891 18.6262 7.83943L20.0404 6.42522ZM23.9596 13.1728C24.3501 13.5633 24.9832 13.5633 25.3738 13.1728C25.7643 12.7822 25.7643 12.1491 25.3738 11.7586L23.9596 13.1728ZM5.69729 22.1826C6.08782 21.792 6.08782 21.1589 5.69729 20.7684C5.30677 20.3778 4.6736 20.3778 4.28308 20.7684L5.69729 22.1826ZM8.52572 16.5257C8.1352 16.9162 8.1352 17.5494 8.52572 17.9399C8.91625 18.3305 9.54941 18.3305 9.93994 17.9399L8.52572 16.5257ZM23.9596 2.5061L29.2929 7.83943L30.7071 6.42522L25.3738 1.09188L23.9596 2.5061ZM29.2929 6.42522L7.57861 28.1395L8.99282 29.5537L30.7071 7.83943L29.2929 6.42522ZM1.96367 23.3635L1.01128 29.6492L2.98872 29.9488L3.9411 23.6631L1.96367 23.3635ZM2.14981 30.7877L8.43552 29.8353L8.13591 27.8579L1.85019 28.8103L2.14981 30.7877ZM18.6262 7.83943L23.9596 13.1728L25.3738 11.7586L20.0404 6.42522L18.6262 7.83943ZM3.65949 24.2204L5.69729 22.1826L4.28308 20.7684L2.24528 22.8062L3.65949 24.2204ZM9.93994 17.9399L25.3738 2.5061L23.9596 1.09188L8.52572 16.5257L9.93994 17.9399Z"
        className="fill"
      />
    </PencilStyled>
  )
}

export default Pencil
