import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { CloseStyled } from './close.styled'

const Close: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <CloseStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M6.10052 25.8995L25.8995 6.10052M6.10051 6.10051L16 16M25.8995 25.8995L19.9598 19.9598"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </CloseStyled>
  )
}

export default Close
