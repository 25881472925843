import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { LumberStyled } from './lumber.styled'

const Lumber: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }

  return (
    <LumberStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M3.77147 19.7399L11.7822 11.7313L19.7449 3.77161C20.8177 2.7665 22.3658 2.04896 23.9519 2.04896C27.2656 2.04896 29.9519 4.73525 29.9519 8.04896C29.9519 9.66126 29.316 11.125 28.2812 12.2031L12.2596 28.228M14 24C14 27.3137 11.3137 30 8 30C4.68629 30 2 27.3137 2 24C2 20.6863 4.68629 18 8 18C11.3137 18 14 20.6863 14 24Z"
        className="stroke-path stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4642 7.61594L13.5015 15.5756M19.7449 14.7634L15.3616 19.145M26.2994 8.51931L22.318 12.4991"
        className="stroke-path stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 24C11.5 25.933 9.93301 27.5 8.00001 27.5C6.06702 27.5 4.50002 25.933 4.50002 24C4.50002 22.067 6.06702 20.5 8.00001 20.5C9.93301 20.5 11.5 22.067 11.5 24Z"
        className="stroke-path stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30592 24C9.30592 24.7212 8.72125 25.3059 8.00002 25.3059C7.27879 25.3059 6.69412 24.7212 6.69412 24C6.69412 23.2788 7.27879 22.6941 8.00002 22.6941C8.72125 22.6941 9.30592 23.2788 9.30592 24Z"
        className="stroke-path stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </LumberStyled>
  )
}

export default Lumber
