import { style, types } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Component===================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

/* Types ======================================================================================== */
export type ClassNames = {
  /**
   * Base class for the most parent text element
   */
  base: string

  /**
   * Sized extension class for text element
   */
  baseSized: {
    xs: string
    small?: string
    sm: string
    md: string
    lg: string
    xl: string
  }

  /**
   * Themed extension class for text element
   */
  baseThemed: string

  /**
   * Themed extension class for semi-bolded text
   */
  baseSemiBold: string

  /**
   * Themed extension class for bolded text
   */
  baseBold: string

  /**
   * Themed extension class for italic text
   */
  baseItalic: string

  /**
   * Themed extension class for italic text
   */
  baseLink: string

  /**
   * Themed extension class for upper-cased text
   */
  baseUppercase: string
}

export type ClassProps = {
  alt?: boolean
  baseline?: ComponentBaselineType
}

const base = style({})

export const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline, alt } = props

  const baseSized = {
    xl: style({
      fontSize: 35,
      letterSpacing: '-0.01em',
      lineHeight: '40px',
    }),
    lg: style({
      fontSize: 29,
      letterSpacing: '-0.01em',
      lineHeight: '32px',
    }),
    md: style({
      fontSize: 24,
      letterSpacing: '-0.01em',
      lineHeight: '28px',
    }),
    sm: style({
      fontSize: 20,
      letterSpacing: '-0.008em',
      lineHeight: '24px',
    }),
    xs: style({
      fontSize: 16,
      letterSpacing: '-0.006em',
      lineHeight: '24px',
    }),
  }

  const baseThemed = style({
    color: theme.getBaseline(baseline, alt).contrast.medium,
  })

  const baseSemiBold = style({
    fontWeight: 500,
  })

  const baseBold = style({
    fontWeight: 600,
  })

  const baseItalic = style({
    fontStyle: 'italic',
  })

  const baseLink = style({})

  const baseUppercase = style({
    textTransform: 'uppercase',
  })

  return {
    base,
    baseSized,
    baseThemed,
    baseSemiBold,
    baseBold,
    baseItalic,
    baseLink,
    baseUppercase,
  }
}

export const headingClass = new StyleClass<ClassNames, ClassProps>(getNames)
