import React, { FC, useContext } from 'react'
import styled from 'styled-components'

/* Styles ======================================================================================= */
import SET from '../styles/set'

/* Components =================================================================================== */
import { Border } from '../modules/border/border.component'
import { Box } from '../modules/box/box.component'
import { Breadcrumb } from './breadcrumb'
import { Button } from '../modules/button'
import { Icon } from '../modules/icons'
import { Wrapper } from '../modules/wrapper'

/* <Menubar /> ================================================================================== */
export const Menubar: FC = (props: any) => {
  const { context, routes, breadcrumb } = props
  const appLayoutContext: any = useContext(context)
  const { active, toggle } = appLayoutContext.components.navbar || {}

  return (
    <Box baseline="surface" fullHeight fullWidth>
      <SpaceBetween className={SET.classes(SET.pl8, SET.pr8, SET.pr8smi, SET.pl8smi)}>
        <Box flex fullHeight alignCenterY>
          <Button startIcon={<Icon.Menu size="sm" />} onClick={toggle} />
          <Box fullHeight flex>
            <Breadcrumb routes={routes} {...breadcrumb} />
          </Box>
        </Box>
      </SpaceBetween>
      <Border contrast="light" position="bottom" />
    </Box>
  )
}

/* Styled Components ============================================================================ */
const SpaceBetween = styled(Wrapper.SpaceBetween)`
  height: 100%;
`
