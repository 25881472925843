import useValue, { UseValue, UseValueCallbacks } from '../use-value'

export type UseCounterReturnType = UseValueCallbacks<number> & {
  /** Adds offet or new offset to the value and updates state */
  readonly increment: (newOffset?: number) => number
  /** Subtracts offet or new offset to the value and updates state */
  readonly decrement: (newOffset?: number) => number
  /** Check if value is at minimum */
  readonly isMin: () => boolean
  /** Check if value is at maximum */
  readonly isMax: () => boolean
  /** Set default value to maximum */
  readonly max: () => number
  /** Set default value to minimum */
  readonly min: () => number
  /** Change default minimum value */
  readonly setMin: (min: number) => number
  /** Change default maximum value */
  readonly setMax: (max: number) => number
}

export type UseCounterOptionType = { min?: number; max?: number; offset?: number }

/**
 * useCounter
 *
 * @param initialValue Initial value
 * @param min Minumum value of counter
 * @param max Maximum value of counter
 * @param offset Offset
 */
const useCounter: UseValue<number, UseCounterReturnType, UseCounterOptionType> = (
  initialValue = 0,
  options = {},
) => {
  const { min = -Infinity, max = Infinity, offset = 1 } = options
  const [value, $value] = useValue(initialValue)
  const [_min, $min] = useValue(min)
  const [_max, $max] = useValue(max)

  const increment = (newOffset = offset) =>
    $value.set((prev: any) => {
      prev = prev + newOffset
      return Math.max(Math.min(prev, _max), _min)
    })

  const decrement = (newOffset = offset) =>
    $value.set((prev: any) => {
      prev = prev - newOffset
      return Math.max(Math.min(prev, _max), _min)
    })

  const set = (newValue: number) =>
    $value.set((prev: any) => {
      prev = newValue
      return Math.max(Math.min(prev, _max), _min)
    })

  const isMin = () => {
    return value === _min
  }

  const isMax = () => value === _max

  const setMax = (max: number) => $max.set(max)

  const setMin = (min: number) => {
    return $min.set(min)
  }

  return [
    value,
    {
      ...$value,
      increment,
      decrement,
      set,
      isMin,
      isMax,
      max: () => $value.set(_max),
      min: () => $value.set(_min),
      setMax,
      setMin,
    },
  ]
}

export default useCounter
