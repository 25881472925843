import React, { useEffect, FC, useContext } from 'react'
import styled from 'styled-components'
import { withRouter, useHistory } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useValue from '../../../hooks/use-value'
import { Select } from '../../../modules/select/select.component'
import { APIWrapperContext } from '../../../api-wrapper'

/* Material UI  ================================================================================= */
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

const FormContainer = styled.div``

/* <App /> ====================================================================================== */
const App: FC<{ match: any }> = ({ match }) => {
  const [selected, $selected] = useValue()
  const history = useHistory()

  const { app } = useContext(APIWrapperContext)
  const styleClasses = useStyles()

  useEffect(() => {
    if (app.payload && app.payload.data) {
      if (app.payload.data.length === 1) {
        history.push(`/${app.payload.data[0]._id}`)
      }
    }
  }, [app])

  return (
    <Container style={{ height: '100%', width: '100%' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ height: '100%', width: '100%' }}
      >
        <Box display="inline-block" className={styleClasses.wwwFormContainer}>
          {(() => {
            if (app.payload.data && app.payload.data.length > 0) {
              return (
                <>
                  {/* <Text className={SET.mb8}>Choose an organization</Text> */}
                  <Select
                    label="Choose an organization *"
                    options={app.payload.data}
                    renderValue={(option: any) => {
                      if (typeof option == 'string') {
                        return option
                      }
                      return option.name
                    }}
                    renderOption={(option: any) => {
                      if (typeof option == 'string') {
                        return option
                      }
                      return option.name
                    }}
                    onChange={org => {
                      if (org) {
                        $selected.set(org._id)
                        setTimeout(() => {
                          history.push(`/${org._id}`)
                        }, 500)
                      }
                    }}
                  />
                </>
              )
            }
          })()}
        </Box>
      </Box>
    </Container>
  )
}

export default withRouter(App)

/* Styled Components ============================================================================ */
export const useStyles = makeStyles({
  wwwFormContainer: {
    minWidth: '400px',
  },
})
