import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { MapLineStyled } from './map-line.styled'

const MapLine: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <MapLineStyled
      viewBox={'0 0 33 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M2 30C8.27732 11.0614 24.8472 22.1169 30 2"
        className="stroke draw-stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
    </MapLineStyled>
  )
}

export default MapLine
