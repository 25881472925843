/**
 * <RepeaterField
 *   label="Cups"
 *   name="cup"
 *   itemLabel="Cup"
 *   render={() => (
 *     <>
 *        // Fields come here
 *     </>
 *   )}
 * />
 */

import React, { useEffect, FC } from 'react'
import styled from 'styled-components'
import { color } from 'csx'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Components =================================================================================== */
import { Button } from '../button'
import { DialogWrapper } from '../dialog-wrapper'
import { withField, FieldContextProps } from '../field/field.component'
import { FieldArray, FieldArrayItem } from '../field-array'
// import { Menu } from '../menu'
// import { Text } from '../text'
// import { Wrapper } from '../wrapper'
// import { Box } from '../box/box.component'
import { Icon } from '../icons'
// import { Dropdown } from '../dropdown'
// import SET from '../../styles/set'
import { formatPrice } from '../../common/format'

/* Components =================================================================================== */
import { Typography } from '@material-ui/core'
import { useStyles } from './repeater-field.styled'

/* Material UI ======================================================================================== */
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'

/* <RepeaterFieldComponent /> =================================================================== */
export const RepeaterFieldComponent: FC<FieldContextProps & {
  itemLabel: string
  renderItems: any[]
  label: string
  name: string
  render: (data: any, index?: number, field?: any, bind?: any) => any
}> = ({ itemLabel, renderItems, label, name, render }) => {
  const [FieldArrayInstance, $FieldArrayInstance] = useValue(null)
  const classes = useStyles()
  const [totalPriceValue, $totalPriceValue] = useValue()
  let priceKey: any
  let priceValue: any = 0

  useEffect(() => {
    $FieldArrayInstance.set(new FieldArray())
  }, [])

  return (
    FieldArrayInstance && (
      <>
        <Typography variant="h6">{label}</Typography>
        <FieldArrayInstance.render
          name={name}
          itemContainer={<Card variant="outlined"></Card>}
          itemContent={<Box flexGrow={1} p={2} bgcolor="grey.50"></Box>}
          itemHeader={(index: number) => (
            <Box order={3} p={1} borderLeft={1} bgcolor="grey.50" borderColor="grey.300">
              <Button
                icon={<Icon.Delete />}
                onClick={() => {
                  FieldArrayInstance.pop(index)
                  DialogWrapper.pop()
                }}
                size="small"
                variant="contained"
                classes={{ root: classes.root }}
              />
            </Box>
          )}
          itemRender={(index: number, bind: any) => {
            // console.log(index)
            return <Box>{render(FieldArrayInstance.getValue(), index, name, bind)}</Box>
          }}
          itemBottom={() => {
            return (
              <>
                <Box>
                  {totalPriceValue && (
                    <Typography>
                      <Box textAlign="right" fontSize={16} fontWeight="fontWeightMedium">
                        Total: {formatPrice(totalPriceValue)}
                      </Box>
                    </Typography>
                  )}
                </Box>
              </>
            )
          }}
          onUpdate={(e: any) => {
            const value = e.getValue()
            const form = e.context.value.form
            const name = e.name

            renderItems.map((item: any) => {
              if (item.type === 'MoneyField') {
                priceKey = item.name
              }
            })

            value &&
              value.length > 0 &&
              value.map((item: any, index: number) => {
                const fieldQuantity = form[`${name}.${index}.quantity`]
                const fieldPrice = form[`${name}.${index}.${priceKey}`]

                // Calculate price when form is updated on blur
                if (typeof item[priceKey] !== 'undefined') {
                  if (item && item[priceKey]) {
                    if (fieldQuantity) {
                      priceValue += parseInt(fieldPrice) * fieldQuantity
                      $totalPriceValue.set(priceValue)
                    } else {
                      priceValue += parseInt(fieldPrice)
                      $totalPriceValue.set(priceValue)
                    }
                  } else {
                    if (fieldPrice && fieldQuantity) {
                      priceValue += parseInt(fieldPrice) * fieldQuantity
                      $totalPriceValue.set(priceValue)
                    }
                  }
                }
              })
          }}
        />
        <Button
          startIcon={<Icon.AddCircle />}
          onClick={() => {
            FieldArrayInstance.push()
          }}
          variant="contained"
          color="secondary"
          label={`Add ${itemLabel}`}
          size="small"
        />
      </>
    )
  )
}

export const RepeaterField = withField(RepeaterFieldComponent, null, true)
