import { style } from 'typestyle'
import { ZINDEX_9 } from '../__constants/z-index'

export const dialogBackgroundStyle = style({
  width: '100%',
  height: '100%',
  display: 'inline-block',
  background: 'rgba(0,0,0,0.7)',
  position: 'fixed',
  zIndex: ZINDEX_9,
  left: 0,
  top: 0,
})
