import axios from 'axios'

/* Common --------------------------------------------------------------------------------------- */
import { flat } from '../../share/obj-equal'

/* Types ---------------------------------------------------------------------------------------- */
export type UseAxiosState = {
  error?: any
  loading: boolean
  loadingInitial: boolean
  payload?: { [key: string]: any }
  search?: any
  url?: string
  timestamp?: Date
}

/* AxiosHelper ---------------------------------------------------------------------------------- */
export default class AxiosHelper {
  $state: any
  axios: any
  history: any
  url: string
  getIndex: number
  timestamp?: Date

  constructor(props: {
    $state: any
    axiosInstance?: any
    history: any
    url: string
    search?: any
  }) {
    const { $state, axiosInstance, history, url, search } = props

    this.$state = $state
    this.axios = axiosInstance ? axiosInstance : axios
    this.history = history
    this.url = url
    this.getIndex = 0

    this.setParams({ url, params: search, update: false })
  }

  get = async (options: any = {}) => {
    this.$state.update({ loading: true })

    // I put it here to debug but good thing to have, lets talk about it later
    this.timestamp = new Date()

    // Initial load flag, no need to worry about this yet
    if (this.getIndex === 0) {
      this.$state.update({ loadingInitial: true })
    }

    let url = options.url || this.url
    if (options.search) {
      url = this.setParams({ url, params: options.search, update: options.update })
    }

    try {
      const response = await this.axios.get(url)

      this.$state.set({
        payload: response.data,
        loading: false,
        loadingInitial: false,
      })
    } catch (error) {
      this.$state.set({
        loading: false,
        loadingInitial: false,
        error,
      })
    }

    this.getIndex++
  }

  delete = async (options: any = {}) => {
    this.$state.update({ loading: true })
    try {
      const response = await this.axios.delete(options.url || this.url, options)

      this.$state.update({
        payload: response.data,
        loading: false,
      })
    } catch (error) {
      this.$state.update({
        loading: false,
        error,
      })
    }
  }

  head = async (options = {}) => {
    this.$state.update({ loading: true })
    try {
      const response = await this.axios.head(this.url, options)

      this.$state.update({
        payload: response.data,
        loading: false,
      })
    } catch (error) {
      this.$state.update({
        loading: false,
        error,
      })
    }
  }

  post = async (data = {}, options: any = {}) => {
    this.$state.update({ loading: true })
    try {
      const response = await this.axios.post(options.url || this.url.split('?')[0], data, options)

      this.$state.update({
        loading: false,
      })

      return {
        payload: response.data,
        loading: false,
      }
    } catch (error) {
      this.$state.update({
        loading: false,
        error,
      })

      return {
        loading: false,
        error,
      }
    }
  }

  put = async (data = {}, options = {}) => {
    this.$state.update({ loading: true })
    try {
      const response = await this.axios.put(this.url, data, options)

      this.$state.update({
        // payload: response.data,
        loading: false,
      })

      return response.data
    } catch (error) {
      this.$state.update({
        loading: false,
        error,
      })
    }
  }

  patch = async (data = {}, options = {}) => {
    this.$state.update({ loading: true })
    try {
      const response = await this.axios.patch(this.url, data, options)

      this.$state.update({
        // payload: response.data,
        loading: false,
      })

      return response.data
    } catch (error) {
      this.$state.update({
        loading: false,
        error,
      })
    }
  }

  setParams: (props: { url: string; params: any; update: boolean }) => string = ({
    url,
    params,
    update = true,
  }) => {
    const flattenParam = flat(params)
    let encodedParam = ''

    for (const key in flattenParam) {
      if (encodedParam !== '') {
        encodedParam += '&'
      }
      encodedParam += key + '=' + encodeURIComponent(flattenParam[key])
    }

    this.url = `${url}?${encodedParam}`

    if (update && this.history) {
      this.history.push({ path: url, search: encodedParam })
    }

    return `${url}?${encodedParam}`
  }

  resetParams: (props: { url: string }) => string = ({ url }) => {
    this.url = `${url}`

    if (this.history) {
      this.history.push({ path: url })
    }

    return `${url}`
  }
}
