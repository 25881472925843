import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../../exports'

/* Styles ======================================================================================== */
import { ConcretePourDetailedStyled } from './concrete-pour-detailed.styled'

const ConcretePourReportDetailed: FC<IconProps> = props => {
  const { size = 'xxl', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <ConcretePourDetailedStyled
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M11.9615 32.8462C11.9615 34.6305 10.5151 36.0769 8.73077 36.0769C6.94646 36.0769 5.5 34.6305 5.5 32.8462C5.5 31.0619 6.94646 29.6154 8.73077 29.6154C10.5151 29.6154 11.9615 31.0619 11.9615 32.8462Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M21.9615 32.8462C21.9615 34.6305 20.5151 36.0769 18.7308 36.0769C16.9465 36.0769 15.5 34.6305 15.5 32.8462C15.5 31.0619 16.9465 29.6154 18.7308 29.6154C20.5151 29.6154 21.9615 31.0619 21.9615 32.8462Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M38.9615 32.8462C38.9615 34.6305 37.5151 36.0769 35.7308 36.0769C33.9465 36.0769 32.5 34.6305 32.5 32.8462C32.5 31.0619 33.9465 29.6154 35.7308 29.6154C37.5151 29.6154 38.9615 31.0619 38.9615 32.8462Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M22.0285 22.9106L18.0882 24.0769C16.8869 23.7575 12.9491 22.7104 11.8868 22.4279L3 17.297L5.23104 9.03371L15.5122 9C16.5746 9.2825 20.5124 10.3296 21.7136 10.6491L24.5385 13.6144L22.0285 22.9106Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M43.9231 22.7722V30.5385H39.6154C39.6154 28.7542 38.1689 27.3077 36.3846 27.3077H31V14.3846H39.0333L43.9231 22.7722Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M43 10L44 10"
        className="stroke-bg stroke-bg-1 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 14H45"
        className="stroke-bg stroke-bg-2 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 28H7"
        className="stroke-bg stroke-bg-3 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 41H11"
        className="stroke-bg stroke-bg-4 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1651 28.3846L23.1055 27.2183L25.6154 17.9221L22.7906 14.9568C21.5893 14.6373 17.6515 13.5902 16.5892 13.3077M19.1651 28.3846C17.9639 28.0652 14.0261 27.018 12.9637 26.7355L4.07693 21.6047L6.30797 13.3414L16.5892 13.3077M19.1651 28.3846L21.8087 18.5935M16.5892 13.3077L13.568 24.4976M37.4615 31.6154H4.07693V34.8462H7.3077M37.4615 31.6154C35.6772 31.6154 34.2308 33.0618 34.2308 34.8462C34.2308 36.6305 35.6772 38.0769 37.4615 38.0769C39.2458 38.0769 40.6923 36.6305 40.6923 34.8462M37.4615 31.6154C39.2458 31.6154 40.6923 33.0618 40.6923 34.8462M37.4615 31.6154L32.0769 31.6154V18.6923H40.1102L45 27.0799V27.3077M26.4115 34.8462H23.4615M23.4615 34.8462C23.4615 36.6305 22.0151 38.0769 20.2308 38.0769C18.4465 38.0769 17 36.6305 17 34.8462M23.4615 34.8462C23.4615 33.0618 22.0151 31.6154 20.2308 31.6154C18.4465 31.6154 17 33.0618 17 34.8462M17 34.8462H13.7692M13.7692 34.8462C13.7692 36.6305 12.3228 38.0769 10.5385 38.0769C8.75416 38.0769 7.3077 36.6305 7.3077 34.8462M13.7692 34.8462C13.7692 33.0618 12.3228 31.6154 10.5385 31.6154C8.75416 31.6154 7.3077 33.0618 7.3077 34.8462M40.6923 34.8462L45 34.8461V27.3077M33.351 34.8462H31M45 27.3077L36.3846 25.5885V21.9231H41.7692M28.8462 31.6154V15.4615M26.6923 33.7692H31V37H26.6923V33.7692Z"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ConcretePourDetailedStyled>
  )
}

export default ConcretePourReportDetailed
