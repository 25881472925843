const divider = '.'
const split = (string: string) => {
  return [
    string.substring(0, string.indexOf(divider)),
    string.substring(string.indexOf(divider) + 1),
  ]
}

export const find = (
  key: string,
  value: any,
  type?: 'number' | 'string' | 'boolean' | 'array',
): any => {
  try {
    const find = (key: string, obj: any = {}): any => {
      // ['1', '2.3.4'] when input is '1.2.3.4'
      // ['', '1'] when input is '1'
      const parsed = split(key)

      if (parsed[0] && !obj[parsed[0]]) {
        return undefined
      }

      if (parsed[1].includes('.')) {
        return find(parsed[1], obj[parsed[0]])
      } else {
        if (parsed[0]) {
          return obj[parsed[0]][parsed[1]]
        } else {
          return obj[parsed[1]]
        }
      }
    }

    const found = find(key, value)
    if (found !== undefined && found != null && found !== 'null') {
      // console.log(key, found, found !== undefined, found != null, found !== 'null')
      switch (type) {
        case 'number':
          return Number(found)
        case 'boolean':
          return found === 'true' || found === true
        default:
          return found
      }
    } else {
      switch (type) {
        case 'number':
          return 0
        case 'boolean':
          return false
        case 'array':
          return []
        default:
          return '-'
      }
    }
  } catch (e) {
    switch (type) {
      case 'number':
        return 0
      case 'boolean':
        return false
      case 'array':
        return []
      default:
        return '-'
    }
  }
}
