import React, { FC, useContext } from 'react'

/* Styles ======================================================================================= */
import SET from '../../styles/set'
import { classes, style } from 'typestyle'

/* Components =================================================================================== */
// import { Box } from '../modules/box/box.component'
// import { Button } from '../modules/button'
import { TextField } from '../../modules/text-field'
import { FormContext } from '../../modules/form'
import useValue from '../../hooks/use-value'
import { useStyles } from './date-field.styled'

/* Material UI =================================================================================== */
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Box from '@material-ui/core/Box'

export const formatDate = (date: any) => {
  const month = date.getMonth() + 1
  const monthString = month < 10 ? `0${month}` : `${month}`
  const dateNumber = date.getDate()
  const dateString = dateNumber < 10 ? `0${dateNumber}` : `${dateNumber}`
  const year = date.getFullYear()

  const time = date.toLocaleString('en', { hour: 'numeric', minute: 'numeric', hour12: true })

  return `${year}/${monthString}/${dateString} ${time}`
}

export const DateField: FC<{
  label: string
  name: string
  helperText?: string
  allowDefault?: boolean
}> = props => {
  const { label, name, helperText, allowDefault = true } = props
  const formContext = useContext(FormContext)
  const { value } = formContext
  const today = new Date()
  const form: any = value.form
  const [date, $date] = useValue(form[name] ? formatDate(new Date(form[name])) : undefined)

  const styleClasses = useStyles()
  // console.log(allowDefault)
  return (
    <Box mb={1}>
      <TextField
        name={name}
        label={label}
        value={date}
        onChange={value => {
          if (!value) {
            $date.set(null)
          }
        }}
        // placeholder="Ex. yyyy/mm/dd"
        beforeSubmit={(data: any) => {
          try {
            return new Date(data).toISOString()
          } catch (e) {
            if (allowDefault) {
              return new Date().toISOString()
            }
          }

          return ''
        }}
        beforeValue={(value: any) => {
          return value ? formatDate(new Date(value)) : ''
        }}
        helperText={helperText}
      />
      <ButtonGroup
        className={styleClasses.wwwDateButtonGroup}
        variant="contained"
        color="secondary"
        disableElevation
        size="small"
      >
        <Button
          onClick={() => {
            $date.set(formatDate(new Date(today)))
          }}
        >
          Today
        </Button>
        <Button
          onClick={() => {
            const yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1)
            $date.set(formatDate(yesterday))
          }}
        >
          Yesterday
        </Button>
        <Button
          onClick={() => {
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            $date.set(formatDate(tomorrow))
          }}
        >
          Tomorrow
        </Button>
      </ButtonGroup>
    </Box>
  )
}
