import React, { FC, useContext, useEffect } from 'react'
// import { classes } from 'typestyle'
import { Link as RouterLink } from 'react-router-dom'
import Cookies from 'js-cookie'
import download from 'downloadjs'
import Axios from 'axios'

/* Hooks ======================================================================================== */
import { parseStates } from '../../hooks/use-axios'
import useValue from '../../hooks/use-value'
/* Styles ======================================================================================= */
// import SET from '../styles/set'

/* Components =================================================================================== */
// import { Border } from '../modules/border/border.component'
// import { Box } from '../modules/box/box.component'
import { Button } from '../../modules/button'
// import { Heading } from '../modules/text/heading.component'
import { Icon } from '../../modules/icons'
import { Select } from '../../modules/select/select.component'
import { Table } from '../../modules/table'
// import { Text } from '../modules/text/text.component'
import { APIWrapperContext } from '../../api-wrapper'
import useFetch, { parseSearch, parseUrlParms } from '../../hooks/use-fetch'
import { flat } from '../../share/obj-equal'
import useBoolean from '../../hooks/use-boolean'
import { Filter } from '../render-filters'
import { formatPrice } from '../../common/format'
import { useStyles } from './summary-table-template.styled'
import { formatDate } from '../date-field'

/* Material UI ======================================================================================== */
// Core
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

// Lab
import Alert from '@material-ui/lab/Alert'
/* <TableTemplate /> ============================================================================ */
export const TableTemplate: FC<{ value?: any }> = ({ value }) => {
  const {
    search = { default: {} },
    layout,
    layoutComponent,
    name = {
      singular: '',
      plural: '',
    },
    headers = [],
    url = { api: '', post: '' },
    history,
    templates,
    actions,
    isWaitingForSchema,
  } = value

  const { schema } = useContext(APIWrapperContext)

  const [entries, $entries] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>(
    {
      count: 0,
      data: [],
      page: 1,
      totalPage: 1,
    },
    {
      url: url.api,
      search: search.default,
      location: history.location,
      updateGetParams: false,
      onLocationChange: (props: any) => {
        const { url, encodedParam } = props
        history.push({ path: url, search: encodedParam })
      },
    },
  )

  const [viewData, $viewData] = useFetch<{
    response?: any[]
  }>(
    {},
    {
      updateGetParams: false,
    },
  )

  const [floatMenuOpen, $floatMenuOpen] = useValue(false)
  const [internalHeaders, $internalHeaders] = useValue([...headers])
  const [selected, $selected] = useValue([])
  const [viewType, $viewType] = useValue({ label: 'Show Table', url: '' })
  const [defaultSearch, $defaultSearch] = useValue()
  const [isShowingSummary, $isShowingSummary] = useBoolean()
  const [isShowingSelection, $isShowingSelection] = useBoolean(true)
  const [summaryTooLarge, $summaryTooLarge] = useBoolean(false)

  const styleClasses = useStyles()

  useEffect(() => {
    if (entries.loaded && !isWaitingForSchema) {
      $entries.get()
    }
  }, [entries.loaded])

  useEffect(() => {
    if (
      schema.payload &&
      // schema.payload.stages &&
      schema.payload.populateItems &&
      entries.mounted &&
      isWaitingForSchema
    ) {
      let defaultStagesForSearch: any
      let defaultSortsForSearch: any

      if (schema.payload.stages) {
        const defaultStage =
          schema.payload.stages && schema.payload.stages.filter((a: any) => a.isDefault)[0]

        defaultStagesForSearch = {
          ...(() => {
            if (defaultStage) {
              return {
                stage: {
                  name: {
                    equal: defaultStage.name,
                  },
                },
              }
            }
          })(),
        }
      }

      const sort = headers.find((a: any) => a.sort !== undefined)

      defaultSortsForSearch = {
        ...(() => {
          if (sort) {
            return {
              sort: {
                [sort.name]: sort.sort,
              },
            }
          }
        })(),
      }

      const defaultPopulate = {
        ...(() => {
          if (schema.payload.populateItems) {
            const populate = schema.payload.populateItems
              .filter((a: any) => a.allowFromTable)
              .map((item: any) => {
                return {
                  key: item.key,
                  from: item.from,
                }
              })

            if (populate.length > 0) {
              return { populate }
            }
          }
          return {}
        })(),
      }

      const defaultSearch = {
        ...search.default,
        // ...(() => {
        //   if (!history.location.search.includes('stage.name')) {
        //     return defaultStagesForSearch
        //   }
        // })(),
        ...defaultSortsForSearch,
        ...defaultPopulate,
      }

      if (schema.payload.isChildPerEntry) {
        $entries.get({
          search: defaultSearch,
          location: history.location,
        })
      } else {
        $entries.get({
          location: history.location,
          search: defaultSearch,
        })
      }

      $defaultSearch.set({
        ...defaultStagesForSearch,
        ...defaultSortsForSearch,
      })
    }
  }, [schema.payload, entries.mounted])

  useEffect(() => {
    const getEntries = (event: any) => {
      $entries.get({
        search: parseSearch(history.location, defaultSearch),
        updateGetParams: true,
        onLocationChange: (props: any) => {},
      })
    }

    if (defaultSearch) {
      window.addEventListener('popstate', getEntries)
    }

    return () => {
      window.removeEventListener('popstate', getEntries)
    }
  }, [defaultSearch, entries.loaded])

  useEffect(() => {
    if (headers.length > 0) {
      const model = { ...schema.payload.sections, ...schema.payload.fields }
      let newHeaders = [...headers]
      newHeaders.shift()

      newHeaders = [
        {
          ...headers[0],
          ...(() => {
            if (headers[0].sort !== undefined) {
              return {
                sort: parseInt(headers[0].sort) === 1 ? 1 : -1,
              }
            }
          })(),
          renderHeader: (header: any, renderSortIcon: any) => {
            return header.label
          },
        },
        ...newHeaders,
      ]

      newHeaders = newHeaders.map((header: any, key) => {
        const format = (header: any) => {
          switch (header.format) {
            case 'money':
              return (column: any) => formatPrice(column)
            case 'date':
              return (column: any) => formatDate(new Date(column))
            default:
              return (column: any) => column
          }
        }

        if (key === 0) {
          header.renderColumn = (column: any, row: any, key: number) => {
            return (
              <Box>
                <Typography>
                  <Link
                    component={RouterLink}
                    to={`${history.location.pathname}`.replace(/\/summary\/(.*?)\//gm, '/')}
                    target="_blank"
                  >
                    {column ? format(header)(column) : '-'}
                  </Link>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {row.updatedAt
                    ? `Updated at ${formatDate(new Date(row.updatedAt))} by ${row[
                        'updatedBy.fullName'
                      ] || 'JAMS'}`
                    : `Created at ${formatDate(new Date(row.createdAt))} by ${row[
                        'createdBy.fullName'
                      ] || 'JAMS'}`}
                </Typography>
              </Box>
            )
          }
        } else {
          header.renderColumn = (column: any, row: any, key: number) => {
            return <Typography>{column ? format(header)(column) : '-'}</Typography>
          }
        }

        if (!header.function) {
          return header
        } else {
          const func = eval(header.function)

          header.renderColumn = (column: any, row: any, key: number) => {
            return <Typography>{format(header)(func(row))}</Typography>
          }
        }

        return header
      })

      const flattenedSearch = flat(entries.search)
      const sortValue =
        parseInt(
          flattenedSearch[Object.keys(flattenedSearch).filter(a => a.includes('sort.'))[0]],
        ) === 1
          ? 1
          : -1
      let sortName = Object.keys(flattenedSearch).filter(a => a.includes('sort.'))[0]

      if (sortName) {
        sortName = sortName.replace('sort.', '')
        newHeaders = newHeaders.map((header: any) => {
          let sort = header.sort
          if (header.name === sortName) {
            sort = sortValue
          }

          return {
            ...header,
            sort,
          }
        })
      }

      $internalHeaders.set(newHeaders)
    }
  }, [headers, entries.search])

  useEffect(() => {
    $summaryTooLarge.reset()
    if (isShowingSummary) {
      const search = { ...entries.search }
      delete search.page
      delete search.limit

      if (selected.length) {
        $viewData.get({
          url: `${url.summary}/json`,
          copySearchProp: true,
          search: {
            ...entries.search,
            _id: {
              include: selected,
            },
          },
        })
      } else {
        $viewData.get({
          url: `${url.summary}/json`,
          deleteSearch: '_id',
          copySearchProp: true,
          search: {
            ...entries.search,
          },
        })
      }
    }
  }, [viewType, isShowingSummary, entries.search, selected, summaryTooLarge])

  useEffect(() => {}, [viewData.payload])

  const { schemaData } = parseStates(
    {
      schemaData: schema,
    },
    {
      stages: [],
    },
  )

  useEffect(() => {
    if (isShowingSelection && !isShowingSummary) {
      document.getElementById('root')?.classList.add(styleClasses.wwwNoScroll)
    } else {
      document.getElementById('root')?.classList.remove(styleClasses.wwwNoScroll)
    }
  }, [])

  return (
    <layoutComponent.render
      {...layout}
      content={
        <>
          <Box bgcolor="#ffffff" pt={3} pb={3}>
            <Container maxWidth="xl">
              <Grid container alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4">
                    <Box fontWeight="fontWeightMedium">{name.plural}</Box>
                  </Typography>
                </Grid>
                {(() => {
                  if (!isShowingSummary && !isShowingSelection) {
                    return (
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            variant="contained"
                            color="secondary"
                            label={`Run Summary (${
                              selected.length === 0 ? 'all' : selected.length
                            })`}
                            onClick={() => {
                              $isShowingSummary.set(false)
                              $isShowingSummary.set(true)
                            }}
                          />
                        </Box>
                      </Grid>
                    )
                  }

                  if (isShowingSummary && !isShowingSelection) {
                    return (
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box ml={1}>
                            <Button
                              label="Reset Summary"
                              float
                              onClick={() => {
                                $selected.reset()
                                $isShowingSummary.reset()
                              }}
                            />
                          </Box>
                          <Box ml={1}>
                            <Button
                              label="Download PDF"
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                let search = { ...entries.search }
                                delete search.page
                                delete search.limit

                                if (selected.length) {
                                  search = {
                                    ...search,
                                    _id: {
                                      include: selected,
                                    },
                                  }
                                }

                                const params = parseUrlParms('', search).encodedParam

                                const cookie = Cookies.get('token')
                                Axios.get(`${url.summary}/pdf?${params}`, {
                                  responseType: 'arraybuffer',
                                  headers: {
                                    authorization: cookie,
                                  },
                                }).then(res => {
                                  download(res.data, 'report.pdf', res.headers['content-type'])
                                })
                              }}
                            />
                          </Box>
                          <Box ml={1}>
                            <Button
                              label="Download JSON"
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                let search = { ...entries.search }
                                delete search.page
                                delete search.limit

                                if (selected.length) {
                                  search = {
                                    ...search,
                                    _id: {
                                      include: selected,
                                    },
                                  }
                                }

                                const params = parseUrlParms('', search).encodedParam

                                const cookie = Cookies.get('token')
                                Axios.get(`${url.summary}/json?${params}`, {
                                  responseType: 'arraybuffer',
                                  headers: {
                                    authorization: cookie,
                                  },
                                }).then(res => {
                                  download(res.data, 'report.json', res.headers['content-type'])
                                })
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    )
                  }
                })()}
              </Grid>
            </Container>
          </Box>
          {isShowingSummary ? (
            <Box pt={4} pb={4}>
              <Container maxWidth="xl">
                <Box mb={2}>
                  <Grid container alignItems="center" spacing={2}>
                    {schemaData.payload.stages && schemaData.payload.stages.length > 0 && (
                      <Grid item xs={12} sm={2}>
                        <Select
                          label="Stage"
                          options={[
                            {
                              name: '',
                            },
                            ...schemaData.payload.stages,
                          ]}
                          value={(() => {
                            if (entries.search.stage) {
                              return {
                                name: entries.search.stage.name.equal,
                              }
                            } else {
                              return {
                                name: '',
                              }
                            }
                          })()}
                          renderValue={(option: any) => {
                            if (typeof option == 'string') {
                              return option
                            }
                            return entries.search.stage && entries.search.stage.name.equal
                              ? entries.search.stage.name.equal
                              : 'All'
                          }}
                          renderOption={(option: any) => {
                            if (typeof option == 'string') {
                              return option
                            }
                            return option.name ? option.name : 'All'
                          }}
                          onChange={(data: any) => {
                            if (data && data.name) {
                              $entries.get({
                                url: url.api,
                                search: {
                                  stage: {
                                    name: {
                                      equal: data.name === '' ? '' : data.name,
                                    },
                                  },
                                  page: 1,
                                },
                                location: history.location,
                                updateGetParams: true,
                                onLocationChange: (props: any) => {
                                  const { url, encodedParam } = props
                                  history.push({ path: url, search: encodedParam })
                                },
                              })
                            } else {
                              $entries.get({
                                url: url.api,
                                search: {
                                  page: 1,
                                },
                                deleteSearch: 'stage.name.equal',
                                location: history.location,
                                updateGetParams: true,
                                onLocationChange: (props: any) => {
                                  const { url, encodedParam } = props
                                  history.push({ path: url, search: encodedParam })
                                },
                              })
                            }

                            $isShowingSummary.set(true)
                          }}
                        />
                      </Grid>
                    )}
                    {schema.payload.filters &&
                      schema.payload.filters.length > 0 &&
                      schema.payload.filters.map((filter: any, key: any) => {
                        return (
                          <Filter filter={filter} fetch={entries} $fetch={$entries} key={key} />
                        )
                      })}
                  </Grid>
                </Box>
                {summaryTooLarge && (
                  <Alert severity="error">
                    This summary is too large to render in the web browser. Please download the PDF
                    version.
                  </Alert>
                )}
                {(() => {
                  if (viewData.payload.response && !viewData.meta.isLoading) {
                    return (
                      <Box>
                        {viewData.payload.response.map((item: any, key: any) => {
                          if (item.rows.length > 100 && !summaryTooLarge) {
                          }

                          item.header = item.headers.map((header: any) => {
                            header.renderColumn = (column: any, row: any, key: number) => {
                              if (header.link && row['linkSearch']) {
                                return (
                                  <Typography>
                                    <Link
                                      target="_blank"
                                      href={`${header.link}?${row['linkSearch']}`}
                                    >
                                      {column}
                                    </Link>
                                  </Typography>
                                )
                              }

                              return (
                                <Typography
                                  color={`${column}`.match(/[-][0-9]+/) ? 'error' : 'initial'}
                                >
                                  {column}
                                </Typography>
                              )
                            }
                          })
                          return (
                            <Box key={key} mb={2}>
                              <Typography variant="subtitle1">
                                <Box mb={1} fontWeight="fontWeightMedium">
                                  {item.title}
                                </Box>
                              </Typography>
                              {viewData.payload ? (
                                <Table
                                  headers={item.headers}
                                  key={key}
                                  topOffset={108}
                                  data={
                                    item.rows.length
                                      ? [
                                          ...(item.rows.length > 200
                                            ? item.rows.splice(0, 200)
                                            : item.rows),
                                          ...item.footer,
                                        ]
                                      : [{}]
                                  }
                                />
                              ) : (
                                <Alert severity="info">No {name.plural} found</Alert>
                              )}
                            </Box>
                          )
                        })}
                      </Box>
                    )
                  } else {
                    return (
                      <Container maxWidth="xl">
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <CircularProgress />
                        </Box>
                      </Container>
                    )
                  }
                })()}
              </Container>
            </Box>
          ) : (
            <Box pt={4} pb={4}>
              {!entries.meta.isLoading ? (
                <>
                  {/* {entries.payload.data.length > 0 ? ( */}
                  <Container maxWidth="xl">
                    <Box mb={2}>
                      <Grid container alignItems="center" spacing={2}>
                        {schemaData.payload.stages && schemaData.payload.stages.length > 0 && (
                          <Grid item xs={12} sm={2}>
                            <Select
                              label="Stage"
                              options={[
                                {
                                  name: '',
                                },
                                ...schemaData.payload.stages,
                              ]}
                              value={(() => {
                                if (entries.search.stage) {
                                  return {
                                    name: entries.search.stage.name.equal,
                                  }
                                } else {
                                  return {
                                    name: '',
                                  }
                                }
                              })()}
                              renderValue={(option: any) => {
                                if (typeof option == 'string') {
                                  return option
                                }
                                return entries.search.stage && entries.search.stage.name.equal
                                  ? entries.search.stage.name.equal
                                  : 'All'
                              }}
                              renderOption={(option: any) => {
                                if (typeof option == 'string') {
                                  return option
                                }
                                return option.name ? option.name : 'All'
                              }}
                              onChange={(data: any) => {
                                if (data) {
                                  if (data.name) {
                                    $entries.get({
                                      url: url.api,
                                      search: {
                                        stage: {
                                          name: {
                                            equal: data.name === '' ? '' : data.name,
                                          },
                                        },
                                        page: 1,
                                      },
                                      location: history.location,
                                      updateGetParams: true,
                                      onLocationChange: (props: any) => {
                                        const { url, encodedParam } = props
                                        history.push({ path: url, search: encodedParam })
                                      },
                                    })
                                  } else {
                                    $entries.get({
                                      url: url.api,
                                      search: {
                                        page: 1,
                                      },
                                      deleteSearch: 'stage.name.equal',
                                      location: history.location,
                                      updateGetParams: true,
                                      onLocationChange: (props: any) => {
                                        const { url, encodedParam } = props
                                        history.push({ path: url, search: encodedParam })
                                      },
                                    })
                                  }
                                }
                              }}
                            />
                          </Grid>
                        )}
                        {schema.payload.filters &&
                          schema.payload.filters.length > 0 &&
                          schema.payload.filters.map((filter: any, key: any) => {
                            return (
                              <Filter filter={filter} fetch={entries} $fetch={$entries} key={key} />
                            )
                          })}
                      </Grid>
                    </Box>
                    {entries.payload.data.length > 0 ? (
                      <Table
                        headers={[...internalHeaders]}
                        topOffset={108}
                        data={entries.payload.data}
                        onSort={(header: any) => {
                          $entries.get({
                            search: {
                              sort: { [header.name]: header.sort === 0 ? 1 : -1 },
                            },
                            updateGetParams: true,
                            allowDeepMerge: false,
                          })
                        }}
                        selectKey="_id"
                        selected={selected}
                        onSelect={data => {
                          if (selected.includes(data['_id'])) {
                            $selected.set((prev: any) => {
                              const temp = [...prev]
                              temp.splice(
                                temp.findIndex((a: any) => a === data['_id']),
                                1,
                              )
                              $selected.set(temp)
                            })
                          } else {
                            $selected.set((prev: any) => [...prev, data['_id']])
                          }
                        }}
                        onContextMenu={() => {
                          $floatMenuOpen.set(true)
                        }}
                      />
                    ) : (
                      <Alert severity="info">No {name.plural} found</Alert>
                    )}
                  </Container>
                  {/* // ) : (
                  //   <Container>
                  //     <Alert severity="info">No {name.plural} found</Alert>
                  //   </Container>
                  // )} */}
                  {entries.payload.data.length > 0 && entries.payload.totalPage > 1 && (
                    <Box mt={2} mb={2} display="flex" justifyContent="center" alignItems="center">
                      <Box mr={1}>
                        <Button
                          className={styleClasses.wwwPagination}
                          icon={<Icon.ArrowLeft />}
                          onClick={() => {
                            if (entries.search.page - 1 > 0) {
                              $entries.get({
                                search: {
                                  page: entries.search.page - 1,
                                },
                                updateGetParams: true,
                              })
                            }
                          }}
                        />
                      </Box>
                      {(() => {
                        if (entries.payload.count < 1 || !entries.payload.count) {
                          return (
                            <Box ml={1} mr={1}>
                              <Button
                                className={styleClasses.wwwPagination}
                                label="1"
                                variant="contained"
                                color="secondary"
                              />
                            </Box>
                          )
                        }

                        const pageButtons = []

                        const minPage = Math.max(0, Math.floor(entries.search.page / 10) * 10 - 1)
                        const maxPage = Math.min(entries.payload.totalPage, minPage + 10)

                        for (let i = minPage; i < maxPage; i++) {
                          pageButtons.push(
                            <Box ml={1} mr={1} key={i}>
                              <Button
                                className={styleClasses.wwwPagination}
                                label={`${i + 1}`}
                                variant="contained"
                                color={entries.search.page === i + 1 ? 'secondary' : 'default'}
                                onClick={() => {
                                  $entries.get({
                                    search: {
                                      page: i + 1,
                                    },
                                    updateGetParams: true,
                                  })
                                }}
                              />
                            </Box>,
                          )
                        }

                        return pageButtons
                      })()}
                      <Box ml={1}>
                        <Button
                          className={styleClasses.wwwPagination}
                          icon={<Icon.ArrowRight />}
                          onClick={() => {
                            if (entries.search.page < entries.payload.totalPage) {
                              $entries.get({
                                search: {
                                  page: entries.search.page + 1,
                                },
                                updateGetParams: true,
                              })
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Container>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Box>
                </Container>
              )}
            </Box>
          )}

          {isShowingSelection && (
            <Backdrop open={isShowingSelection} className={styleClasses.wwwBackdrop}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  label="Run summary on every row"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    $isShowingSelection.set(false)
                    $isShowingSummary.set(true)
                    document.getElementById('root')?.classList.remove(styleClasses.wwwNoScroll)
                  }}
                />
                <Typography component="span">
                  <Box fontWeight="fontWeightMedium" ml={1} mr={1} color="#ffffff">
                    or
                  </Box>
                </Typography>
                <Button
                  label="Make a selection(s)"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    $isShowingSelection.set(false)
                    document.getElementById('root')?.classList.remove(styleClasses.wwwNoScroll)
                  }}
                />
              </Box>
            </Backdrop>
          )}
        </>
      }
    />
  )
}
