import { stylesheet, classes } from 'typestyle'
import { border } from './set.border'
import { hide } from './set.hide'
import { show } from './set.show'
import { padding } from './set.padding'
import { margin } from './set.margin'
import { fullWidth } from './set.full-width'

/**
 * Set of class names for styling responsive layout
 *
 * Examples
 *
 * Center Horizontal
 *  `ch`
 * Center Vorizontal
 *  `cv`
 * Border Left
 *  `bl`
 * Border Left for md
 *  `blmd`
 * Border None
 *  `bn`
 * Padding / Margin Left for 8px
 *  `pl8` / `ml8`
 * Padding / Margin Left for 0px in sm device with !important
 *  `pl0i` / `ml0i`
 * Hide / Display: hidden on lg
 *  `hidelg`
 * Show / Display: inherit on md
 *  `showmd`
 * Full width on sm
 *  `fwsm`
 */
const SET = {
  classes,
  ...stylesheet({
    ch: {
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)',
    },
    cv: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    c: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      left: '50%',
    },
    fh: {
      height: '100%',
    },
    underline: {
      textDecoration: 'underline',
    },
    absolute: {
      position: 'absolute',
    },
    ...border,
    ...fullWidth,
    ...hide,
    ...margin,
    ...padding,
    ...show,
  }),
}

export default SET
