/**
 *
 * Axios package, React Router package is dependencie
 *
 * Get rid of dependencies
 *  - Look into fetch vs axios
 *
 * Get rid of auto fetch
 * Get rid of setUrl
 *
 * Future
 * - organize hook by pure hooks (can be used in both web/native), dependencies
 *
 *
 * const [api, $api] = useAxios('url to that duummy api')
 *
 * act(async () => { await $api.get(); console.log(api) })
 *
 *
 *
 * test idea
 * - get('aergnaergkaergnlaekrgnaelrkg')
 */

import { useEffect, useCallback } from 'react'
import { History } from 'history'

/* Helpers -------------------------------------------------------------------------------------- */
import AxiosHelper, { UseAxiosState } from './axios-helper'

/* Hooks ---------------------------------------------------------------------------------------- */
import useObject from '../use-object'
import useValue from '../use-value'

/* Common --------------------------------------------------------------------------------------- */
import { structureObj } from '../../share/obj-equal'

/* Types ---------------------------------------------------------------------------------------- */
type UseAxiosCallbacks = {
  /** Axios.get */
  get: (options?: {}) => Promise<any>
  /** Axios.delete */
  delete: (options?: {}) => Promise<any>
  /** Axios.head */
  head: (options?: {}) => Promise<any>
  /** Axios.post */
  post: (data?: {}, options?: {}) => Promise<any>
  /** Axios.put */
  put: (data?: {}, options?: {}) => Promise<any>
  /** Axios.patch */
  patch: (data?: {}, options?: {}) => Promise<any>

  /** Updates url parameters */
  search: (params: {}) => void
  /** Resets url parameters to initial search data */
  reset: () => void
  /** Increment search.page */
  increment: () => void
  /** Decrement search.page */
  decrement: () => void
  /** Change URL */
  setUrl: (url: string) => void
}

/* Constants ------------------------------------------------------------------------------------ */
const INITIAL_STATE = {
  loading: false,
  loadingInitial: false,
  error: undefined,
  payload: undefined,
}

/**
 * create a file called axios.utils.ts
 * and move this function to there
 */

/**
 * Setting default value
 * @param objects
 * @param defaultPayload
 */
export const parseStates: (
  objects: any,
  defaultPayload: any,
) => {
  [key: string]: {
    payload: any
    error: any
    loading: boolean
  }
} = (objects, defaultPayload) => {
  const returnObjects: any = {}

  for (const key in objects) {
    returnObjects[key] = {
      payload: objects[key].payload || defaultPayload || {},
      loading: objects[key].loading || false,
      error: objects[key].error,
    }
  }

  return returnObjects
}

/**
 * create a file called axios.utils.ts
 * and move this function to there
 *
 * http://localhost:3000/admin/schema?limit=10&page=1
 * to
 * {
 *  limit: 10,
 *   page: 1
 * }
 */
const parseSearch = (location: any, defaultSearch: any) => {
  const searchQuery: string = location.search.substring(1, location.search.length)
  const parsedSearchQuery = searchQuery.split('&')
  const parsedSearchQueryObject: { [key: string]: any } = {}

  parsedSearchQuery.map(item => {
    const itemParsed = item.split('=')
    const key = itemParsed[0]
    const value = itemParsed[1]

    if (key) {
      parsedSearchQueryObject[key] = value
    }
  })

  return {
    ...defaultSearch,
    ...structureObj(parsedSearchQueryObject),
  }
}

/**
 * useAxios
 *
 * @param url initial and only url for endpoint
 */
const useAxios: (
  initialUrl?: string,
  options?: {
    history?: History
    search?: { [key: string]: any }
    autoFetch?: boolean
    parseData?: (payload: any) => any
  },
) => [UseAxiosState, UseAxiosCallbacks] = (initialUrl = '', options = {}) => {
  const [url, $url] = useValue(initialUrl)
  const [state, $state] = useObject<UseAxiosState>(INITIAL_STATE)
  const [search, $search] = useValue(options.search)
  const [loaded, $loaded] = useValue(false)

  const [axiosHelper, $axiosHelper] = useValue(
    new AxiosHelper({
      // look into line 26 in AxiosHelper
      $state,
      axiosInstance: null,
      history: options.history,
      search: options.search,
      url,
    }),
  )

  useEffect(() => {
    if (options.history) {
      const newSearch = parseSearch(options.history.location, options.search)
      $axiosHelper.set((prev: any) => {
        prev.setParams({
          params: newSearch,
          update: false,
          url,
        })

        $search.set(newSearch)

        return prev
      })

      const unlisten = options.history.listen((location: any) => {
        const newSearch = location.search
          ? parseSearch(options.history ? options.history.location : {}, options.search)
          : options.search

        $axiosHelper.set((prev: any) => {
          prev.setParams({
            params: newSearch,
            update: false,
            url,
          })
          $search.set(newSearch)

          return prev
        })
      })

      return () => {
        unlisten()
      }
    } else {
      $axiosHelper.set((prev: any) => {
        prev.setParams({
          params: options.search,
          update: false,
          url,
        })

        $search.set(options.search)

        return prev
      })
    }

    $loaded.set(true)
  }, [])

  useEffect(() => {
    if (options.autoFetch && loaded) {
      axiosHelper.get()
    }
  }, [search, url, loaded])

  const searchCallback: (params: any) => void = async params => {
    $axiosHelper.set((prev: any) => {
      prev.setParams({ url, params })
      $search.set(params)

      return prev
    })
  }

  const increment = async () => {
    $axiosHelper.set((prev: any) => {
      $search.set((params: any) => {
        prev.setParams({
          url,
          params: {
            ...params,
            page: params.page + 1,
          },
        })

        return {
          ...params,
          page: params.page + 1,
        }
      })

      return prev
    })
  }

  const decrement = async () => {
    $axiosHelper.set((prev: any) => {
      $search.set((params: any) => {
        prev.setParams({
          url,
          params: {
            ...params,
            page: params.page - 1,
          },
        })

        return {
          ...params,
          page: params.page - 1,
        }
      })

      return prev
    })
  }

  const setUrl = (url: string) => {
    $axiosHelper.set((prev: any) => {
      const newHelper = new AxiosHelper({
        $state,
        axiosInstance: null,
        history: options.history,
        search: options.search,
        url,
      })

      $url.set(url)

      return newHelper
    })
  }

  const reset = () => {
    $axiosHelper.set((prev: any) => {
      prev.resetParams({ url })
      $search.set(options.search)

      return prev
    })
  }

  const getLoading = useCallback(() => state.loading, [state])

  return [
    {
      ...state,
      search,
      url,
      timestamp: axiosHelper.timestamp,
    },
    {
      ...axiosHelper,
      search: searchCallback,
      increment,
      decrement,
      setUrl,
      reset,
      getLoading,
    },
  ]
}

export default useAxios
