import styled from 'styled-components'
import { drawStroke } from '../animate'

export const MenuStyled = styled.svg`
  .stroke {
    stroke: ${props => props.theme};
  }
  .fill {
    fill: ${props => props.theme};
  }
  &.is-animated {
    &:hover {
      .stroke-path-1 {
        transform: translate3d(0px, 18px, 0px);
      }
      .stroke-path-3 {
        transform: translate3d(0px, -18px, 0px);
      }
    }
  }
`
