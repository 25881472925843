import useValue, { UseValueCallbacks } from '../use-value'

export type UseArrayCallbacks<TYPE> = UseValueCallbacks<TYPE> & {
  /** Adds new item and updates current state */
  readonly push: (item: TYPE) => void
  /** Returns the size of array */
  readonly length: () => number
  /** Move an item to a specific index */
  readonly move: (from: number, to: number) => void
  /** Update specific value at index */
  readonly patch: (index: number, value: TYPE) => void
  /** Removes last item and updates current state */
  readonly pop: (index?: number) => TYPE | undefined
  /** Sort array by default order (ascending) */
  readonly sort: (sortCallback?: () => number) => void
  /** Removes item at index and updates current state */
  readonly splice: (index: number) => void
}

export type UseArray = <TYPE>(
  initialValue?: TYPE[],
  options?: {},
) => [TYPE[], UseArrayCallbacks<TYPE>]

/**
 * useArray
 *
 * Returns a stateful array, and functions to interact with it
 */
export const useArray: UseArray = (initialValue = []) => {
  const [value, $value] = useValue(initialValue)

  const length = () => value.length

  const move = (from: number, to: number) => {
    $value.set((prev: any) => {
      const items = [...prev]
      items.splice(to, 0, items.splice(from, 1)[0])
      return items
    })
  }

  const patch = (index: number, item: any) => {
    const items = [...value]
    items[index] = item
    $value.set(items)
  }

  const pop = (index: any) => {
    if (index !== undefined) {
      $value.set((prev: any) => {
        const items = [...prev]
        items.splice(index, 1)
        return items
      })
    } else {
      let removedItem

      $value.set((prev: any) => {
        const items = [...prev]
        removedItem = items.pop()
        return items
      })

      return removedItem
    }
  }

  const push = (item: any) => {
    $value.set((prev: any) => {
      const items = [...prev, item]
      return items
    })
  }

  const sort = (sortCallback?: () => number) =>
    $value.set((prev: any) => {
      if (sortCallback) {
        // Sort values as string by ascending order
        return prev.sort()
      } else {
        // Compare passed callback argument and sort by ascending order
        return prev.sort(sortCallback)
      }
    })

  const splice = (index: number) => {
    $value.set((prev: any) => {
      const items = [...prev]
      items.splice(index, 1)
      return items
    })
  }

  return [
    value,
    {
      ...$value,
      length,
      move,
      patch,
      pop,
      push,
      sort,
      splice,
    },
  ]
}

export default useArray
