export const RED = ['#ffebee', '#ef9a9a', '#ef5350', '#e53935', '#c62828']
export const PINK = ['#fce4ec', '#f48fb1', '#ec407a', '#d81b60', '#ad1457']
export const PURPLE = ['#f3e5f5', '#ce93d8', '#ab47bc', '#8e24aa', '#6a1b9a']
export const DEEP_PURPLE = ['#ede7f6', '#b39ddb', '#7e57c2', '#5e35b1', '#4527a0']
export const INDIGO = ['#e8eaf6', '#9fa8da', '#5c6bc0', '#3949ab', '#283593']
export const BLUE = ['#e3f2fd', '#90caf9', '#42a5f5', '#1e88e5', '#1565c0']
export const LIGHT_BLUE = ['#e1f5fe', '#81d4fa', '#29b6f6', '#039be5', '#0277bd']
export const CYAN = ['#e0f7fa', '#80deea', '#26c6da', '#00acc1', '#00838f']
export const TEAL = ['#e0f2f1', '#80cbc4', '#26a69a', '#00897b', '#00695c']
export const GREEN = ['#e8f5e9', '#a5d6a7', '#66bb6a', '#43a047', '#2e7d32']
export const LIGHT_GREEN = ['#f1f8e9', '#c5e1a5', '#9ccc65', '#7cb342', '#558b2f']
export const LIME = ['#f9fbe7', '#e6ee9c', '#d4e157', '#c0ca33', '#9e9d24']
export const YELLOW = ['#fffde7', '#fff59d', '#ffee58', '#fdd835', '#f9a825']
export const AMBER = ['#fff8e1', '#ffe082', '#ffca28', '#ffb300', '#ff8f00']
export const ORANGE = ['#fff3e0', '#ffcc80', '#ffa726', '#fb8c00', '#ef6c00']
export const DEEP_ORANGE = ['#fbe9e7', '#ffab91', '#ff7043', '#f4511e', '#d84315']
export const BROWN = ['#efebe9', '#bcaaa4', '#8d6e63', '#6d4c41', '#4e342e']
export const GREY = ['#fafafa', '#f7f7f7', '#eeeeee', '#e0e0e0', '#bdbdbd']
export const BLUE_GREY = ['#eceff1', '#cfd8dc', '#90a4ae', '#607d8b', '#263238']
export const WHITE = ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']
export const BLACK = ['#000', '#000', '#000', '#000', '#000']
