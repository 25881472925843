import React, { FC } from 'react'

/* Routes ======================================================================================= */
// import { routes } from './routes'

/* Constants ==================================================================================== */
import { HEADER, NAVBAR, TOOLBAR } from '../../modules/app-layout/app-layout.constants'

/* Components =================================================================================== */
import { AppLayoutClass } from '../../modules/app-layout'
import { Header } from '../header'
import { Menubar } from '../menubar'
import { Navbar } from '../navbar'
import { withRouter } from 'react-router-dom'
import { Box } from '../../modules/box/box.component'
import SET from '../../styles/set'
import { Heading } from '../../modules/text/heading.component'
import { Text } from '../../modules/text/text.component'

/* App Layout Init ============================================================================== */
const AppLayout = new AppLayoutClass()

AppLayout.setConfig({
  menubar: {
    active: false,
  },
  navbar: {
    active: false,
  },
})

/* App Layout Components ======================================================================== */
AppLayout.setComponent(HEADER, Header)

export default AppLayout

const NoAuthComponent: FC<{ data: any }> = ({ data }) => {
  return (
    <AppLayout.render
      content={
        <Box
          alignCenterY
          alignCenterX
          baseline="none"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Box shadow className={SET.p16} maxWidth={400}>
            <Heading size="sm" className={SET.mb16}>
              {data.title}
            </Heading>
            <Text>{data.message}</Text>
          </Box>
        </Box>
      }
    />
  )
}

export const NoAuth = withRouter(NoAuthComponent as any) as any
