import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { ProjectsStyled } from './projects.styled'

const Projects: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <ProjectsStyled
      viewBox={'0 0 33 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M27 6V4H20M27 12V30H6V26.5M6 21V4H13M20 4L20 2H13V6H20L20 4ZM17 11H23M17 25H23M17 18H23M10 9H14V13H10V9ZM10 16H14V20H10V16ZM10 23H14V27H10V23Z"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ProjectsStyled>
  )
}

export default Projects
