import React, { FC, useContext, useEffect } from 'react'
// import styled from 'styled-components'
import { Link as RouterLink, useHistory } from 'react-router-dom'

/* Styles ======================================================================================= */
import SET from '../../styles/set'
import { classes, style } from 'typestyle'

/* Hooks ======================================================================================== */
import { parseStates } from '../../hooks/use-axios'
import useValue from '../../hooks/use-value'

/* Common ======================================================================================= */
import { convertToArray } from '../../share/obj-equal'

/* Components =================================================================================== */
// import { Border } from '../modules/border/border.component'
import { Circle } from '../../modules/circle'
// import { TOOLBAR_HEIGHT } from '../modules/app-layout/app-layout.constants'
// import { Text } from '../modules/text/text.component'
// import { Selector } from '../modules/selector/selector.component'
// import { circleClass } from '../modules/circle/circle.class'
// import { Box } from '../modules/box/box.component'
// import { Line } from '../modules/line'
// import { Select } from '../modules/select/select.component'
import { useStyles } from './navbar.styled'

/* Material UI  ======================================================================================== */
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { BottomNavigation } from '@material-ui/core'

/* <Navbar /> =================================================================================== */

type NavbarProps = {
  app?: any
  domain?: string
  items: any
  context?: any
  orgId?: any
}

export const Navbar: FC<NavbarProps> = props => {
  const { domain, items = [], context, app = {}, orgId } = props
  const appLayoutContext: any = useContext(context)
  const history = useHistory()
  const [selected, $selected] = useValue()
  const styleClasses = useStyles()

  useEffect(() => {
    if (app.payload && app.payload.data) {
      $selected.set(app.payload.data.find((a: any) => a._id === orgId))
    }
  }, [app.payload])

  /**
   * @param navItem
   * @returns Template per nav item
   */
  const navItemTemplate = (navItem: any, key: number, navItemArray?: any, navItemData?: any) => {
    if (navItem && typeof navItem !== 'undefined' && navItem.label) {
      const buttonCondition = navItem.onClick || navItem.to ? true : false

      // Add subheader if type is summary otherwise display default nav item component
      return (
        <React.Fragment key={navItem._id}>
          {navItem.type == 'summary' ? (
            <ListSubheader
              className={classes(
                styleClasses.wwwMenuListSubHeader,
                navItem.isSelected && styleClasses.wwwSelectedItem,
                navItem.hasParent && styleClasses.wwwSecondIndent,
              )}
              disableSticky
            >
              {navItem.label}
            </ListSubheader>
          ) : (
            <ListItem
              className={classes(
                styleClasses.wwwMenuListItem,
                navItem.hasParent && styleClasses.wwwFirstIndent,
                navItemArray && navItem.hasParent && styleClasses.wwwSecondIndent,
                navItem.parentItem && styleClasses.wwwSecondIndent,
                navItem.type === 'entry' &&
                  navItemData &&
                  navItemData.children &&
                  styleClasses.wwwThirdIndent,
              )}
              onClick={navItem.onClick ? navItem.onClick : undefined}
              button={buttonCondition as true}
              selected={navItem.isSelected}
              component={navItem.to && RouterLink}
              to={navItem.to ? navItem.to : ''}
            >
              {navItem.icon && (
                <ListItemIcon className={styleClasses.wwwMenuItemIcon}>{navItem.icon}</ListItemIcon>
              )}
              <ListItemText primary={navItem.label} />
            </ListItem>
          )}
        </React.Fragment>
      )
    }
  }

  /*
   * Render children schema items
   */
  const renderNestedNavItems = (items: any) => {
    return (
      typeof items !== 'undefined' &&
      items &&
      items.length > 0 &&
      items.map((item: any, index: number) => {
        return (
          <React.Fragment key={item._id}>
            {item.parentItem && navItemTemplate(item.parentItem, index)}
            {navItemTemplate(item, index)}
            {item.children &&
              item.children.map((child: any, innerIndex: number) =>
                navItemTemplate(child, innerIndex, item.children, item),
              )}
          </React.Fragment>
        )
      })
    )
  }

  const renderItems = (navItems: any, position?: string) => {
    const items = navItems
      ?.filter((a: any) => a && a.position === position)
      .filter((a: any) => !!a)
      .sort((a: any, b: any) => a.order - b.order || a.label.localeCompare(b.label))
    // if (items.length === 0) {
    //   // return
    //   // return (
    //   // <Box fullHeight style={{ padding: 8, transform: 'translateX(-1px)' }}>
    //   //   <Text>Please advise system administrators to invite you to a project.</Text>
    //   // </Box>
    //   // )
    // }

    /**
     * Return mapped items based on the type property
     */
    return items?.map((item: any, key: number) => {
      if (item) {
        switch (item.type) {
          case 'group':
            return (
              <React.Fragment key={key}>
                {item.children && item.children.length > 0 && (
                  <List component="nav" disablePadding>
                    <ListSubheader
                      className={classes(
                        styleClasses.wwwMenuListSubHeader,
                        // item.hasParent && styleClasses.wwwFirstIndent,
                        // item.children && item.children.length > 0 && styleClasses.wwwSecondIndent,
                        item.isSelected && styleClasses.wwwSelectedItem,
                      )}
                      disableSticky
                    >
                      {item.label}
                    </ListSubheader>

                    {renderItems(item.children, undefined)}
                  </List>
                )}
              </React.Fragment>
            )
          case 'subtitle':
            return (
              <Typography key={key} variant="subtitle2">
                {item.label}
              </Typography>
            )
          case 'break':
            return <Divider key={key} />
          default:
            if (!item.to) {
              const buttonCondition = item.onClick ? true : false
              return (
                <React.Fragment key={key}>
                  <ListItem
                    className={classes(styleClasses.wwwMenuListItem)}
                    onClick={item.onClick ? item.onClick : undefined}
                    button={buttonCondition as true}
                    selected={item.isSelected}
                  >
                    {item.icon && (
                      <ListItemIcon className={styleClasses.wwwMenuItemIcon}>
                        {item.icon}
                      </ListItemIcon>
                    )}
                    <ListItemText primary={item.label} />
                  </ListItem>
                  {renderNestedNavItems(item.schemaItems)}
                  {renderNestedNavItems(item.summaries)}
                </React.Fragment>
              )
            }
            return (
              <React.Fragment key={key}>
                {(() => {
                  if (history.location.pathname === item.to) {
                    return (
                      <>
                        <ListItem
                          className={classes(styleClasses.wwwMenuListItem)}
                          button
                          selected={item.isSelected}
                        >
                          {item.icon && (
                            <ListItemIcon className={styleClasses.wwwMenuItemIcon}>
                              {item.icon}
                            </ListItemIcon>
                          )}
                          <ListItemText primary={item.label} />
                        </ListItem>
                        {renderNestedNavItems(item.schemaItems)}
                        {renderNestedNavItems(item.summaries)}
                      </>
                    )
                  } else {
                    return (
                      <>
                        <ListItem
                          className={classes(styleClasses.wwwMenuListItem)}
                          component={RouterLink}
                          to={item.to}
                          button
                          selected={item.isSelected}
                        >
                          {item.icon && (
                            <ListItemIcon className={styleClasses.wwwMenuItemIcon}>
                              {item.icon}
                            </ListItemIcon>
                          )}
                          <ListItemText primary={item.label} />
                        </ListItem>
                        {renderNestedNavItems(item.schemaItems)}
                        {renderNestedNavItems(item.summaries)}
                      </>
                    )
                  }

                  // return <div />
                })()}
              </React.Fragment>
            )
        }
      }

      return <div key={key} />
    })
  }

  return (
    <div className={styleClasses.wwwMenuList}>
      {!orgId && (
        <Box>
          <Circle size="sm" baseline="background" style={{ pointerEvents: 'none' }}></Circle>
          <Typography>{domain}</Typography>
        </Box>
      )}
      {/* // <ListItem
        // style={{
        //   padding: '8px 8px',
        //   height: 'fit-content',
        //   zIndex: 1,
        //   background: 'white',
        // }}
        // >
          {app.payload && app.payload.data && (
            <Select
              value={selected}
              options={app.payload.data || []}
              renderValue={(option: any) => {
                if (typeof option == 'string') {
                  return option
                }
                return option.name
              }}
              renderOption={(option: any) => {
                if (typeof option == 'string') {
                  return option
                }
                return option.name
              }}
              onChange={org => {
                if (org) {
                  if (selected && selected._id !== org._id) {
                    $selected.set(org._id)
                    setTimeout(() => {
                      history.push(`/${org._id}`)
                    }, 300)
                  }
                }
              }}
            />
          )}
          */}

      {/* <Border position="bottom" contrast="light" />
          <Border position="right" contrast="light" /> */}
      {/* </ListItem>
      ) : (
        <ListItem>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '0px 8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
              background: 'white',
            }}
          >
            <div
              style={{
                width: '100%',
                height: 30,
                padding: '2px 0px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Circle size="md" className={SET.mr16}>
                <Circle size="sm" baseline="background"></Circle>
              </Circle>
              <Typography>{domain}</Typography>
            </div>
          </div>
          <Border position="bottom" contrast="light" />
        </ListItem> */}
      {/* // )} */}
      {/* <NavTopContent
        style={{
          height: 'fit-content',
          boxShadow: '1px 1px 10px 2px rgba(0,0,0,0.05)',
          zIndex: 0,
        }}
      > */}
      {/* <ListItem>{renderItems(items, 'upper')}</ListItem> */}
      {/* </NavTopContent> */}
      {/* <NavDivider className={SET.mt8}> */}
      {renderItems(items)}
      {/* </NavDivider> */}
      {/* <NavBottomContent
        style={{
          height: 'fit-content',
        }}
      > */}
      {renderItems(items, 'bottom')}
      {/* </NavBottomContent> */}
    </div>
  )
}
