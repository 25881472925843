import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { OrganizationsStyled } from './organizations.styled'

const Organizations: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <OrganizationsStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M12 8V7C11.4477 7 11 7.44772 11 8H12ZM20 8H21C21 7.44772 20.5523 7 20 7V8ZM20 13V14C20.5523 14 21 13.5523 21 13H20ZM12 13H11C11 13.5523 11.4477 14 12 14V13ZM12 16V15C11.4477 15 11 15.4477 11 16H12ZM12 21H11C11 21.5523 11.4477 22 12 22V21ZM20 16H21C21 15.4477 20.5523 15 20 15V16ZM20 21V22C20.5523 22 21 21.5523 21 21H20ZM13 24V23C12.4477 23 12 23.4477 12 24H13ZM19 24H20C20 23.4477 19.5523 23 19 23V24ZM19 30V31C19.5523 31 20 30.5523 20 30H19ZM13 30H12C12 30.5523 12.4477 31 13 31V30ZM12 2V1C11.4477 1 11 1.44772 11 2H12ZM20 2H21C21 1.44772 20.5523 1 20 1V2ZM20 5V6C20.5523 6 21 5.55228 21 5H20ZM12 5H11C11 5.55228 11.4477 6 12 6V5ZM8 5V4C7.44772 4 7 4.44772 7 5H8ZM24 5H25C25 4.44772 24.5523 4 24 4V5ZM24 30V31C24.5523 31 25 30.5523 25 30H24ZM8 30H7C7 30.5523 7.44772 31 8 31V30ZM9 26C9 25.4477 8.55228 25 8 25C7.44772 25 7 25.4477 7 26H9ZM7 20C7 20.5523 7.44772 21 8 21C8.55228 21 9 20.5523 9 20H7ZM25 13C25 12.4477 24.5523 12 24 12C23.4477 12 23 12.4477 23 13H25ZM23 7C23 7.55228 23.4477 8 24 8C24.5523 8 25 7.55228 25 7H23ZM28 31C28.5523 31 29 30.5523 29 30C29 29.4477 28.5523 29 28 29V31ZM4 29C3.44772 29 3 29.4477 3 30C3 30.5523 3.44772 31 4 31V29ZM19 8V13H21V8H19ZM13 13V8H11V13H13ZM13 21V16H11V21H13ZM19 16V21H21V16H19ZM13 25H19V23H13V25ZM18 24V30H20V24H18ZM19 29H13V31H19V29ZM14 30V24H12V30H14ZM12 3H20V1H12V3ZM19 2V5H21V2H19ZM20 4H12V6H20V4ZM13 5V2H11V5H13ZM8 6H24V4H8V6ZM24 29H8V31H24V29ZM9 30V26H7V30H9ZM9 20V5H7V20H9ZM12 9H16V7H12V9ZM16 9H20V7H16V9ZM20 12H16V14H20V12ZM16 12H12V14H16V12ZM17 13V8H15V13H17ZM12 17H16V15H12V17ZM16 17H20V15H16V17ZM20 20H16V22H20V20ZM16 20H12V22H16V20ZM17 21V16H15V21H17ZM25 30V20H23V30H25ZM25 20V13H23V20H25ZM23 5V7H25V5H23ZM28 29H4V31H28V29Z"
        className="fill"
      />
    </OrganizationsStyled>
  )
}

export default Organizations
