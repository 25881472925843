import styled from 'styled-components'
import { drawStroke } from '../animate'

export const ArrowDownStyled = styled.svg`
  .stroke {
    stroke: ${props => props.theme};
  }

  &.is-animated {
    &:hover {
      .draw-stroke-path {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: ${drawStroke} 1s linear forwards;
      }
    }
  }
`
