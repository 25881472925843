import React from 'react'
import { classes, style as _style } from 'typestyle'

/* Styles ======================================================================================= */
import { tableBodyClass } from './table-body.class'
// import { Text } from '../text/text.component'
import { ComponentSizeType } from '../__core/component.types'
// import { Icon } from '../icons'
import { parseWidth } from './table.utils'
import { Header } from '.'
// import { Box } from '../box/box.component'
// import useStyles from './table.styles'

/* Material UI ======================================================================================== */
import { TableBody as MaterialTableBody } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

/* Styles ======================================================================================== */
import { useStyles } from './table-body.styled'

/* Types ======================================================================================== */
type TableBodyProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  data: any[]
  headers: Header[]
  size?: ComponentSizeType
  float?: boolean
  handleSelect?: any
  selected?: any
  selectKey?: any
  freeSize?: boolean
  noLimit?: boolean
}

const defaultProps: {} = {}

/* <TableBody /> ================================================================================= */
const TableBodyComponent: React.FC<TableBodyProps> = props => {
  const {
    className,
    children,
    style,
    data,
    headers,
    size = 'md',
    float,
    handleSelect,
    selected,
    selectKey,
    freeSize,
    noLimit,
  } = props

  const styleClasses = useStyles(props)

  // const { base, themed, row, rowProped, column, columnProped, content } = tableBodyClass.setProps({
  //   size,
  //   freeSize,
  //   noLimit,
  // })

  return (
    // <div
    //   className={classes('www-table-body', base, themed, className)}
    //   style={{
    //     ...style,
    //     ...(float && {
    //       width: '100%',
    //       display: 'flex',
    //       flexDirection: 'column',
    //       // justifyContent: 'flex-end',
    //     }),
    //   }}
    // >
    <MaterialTableBody>
      {data.map((item, key1) => {
        if (item) {
          return (
            <TableRow
              key={key1}
              className={
                styleClasses.wwwTableRow
                // _style({
                //   // background: 'red',
                //   // $nest: {
                //   //   '&:hover': {
                //   //     background: 'rgb(240,240,240)',
                //   //   },
                //   // },
                // })
              }
            >
              {/* // <div
            //   key={key1}
            //   className={classes(row, rowProped.highlight)}
            //   style={{
            //     ...(float && {
            //       display: 'flex',
            //       flexDirection: 'row',
            //       justifyContent: 'flex-end',
            //     }),
            //   }}
            // > */}
              {headers.map((header, key2) => {
                const { name, label, align, width, minWidth, renderColumn, fontWeight } = header
                const { highlight, handle } = handleSelect(item, key1)
                return (
                  <TableCell
                    key={key2}
                    className={classes(highlight && styleClasses.wwwTableRowHighlight, styleClasses.wwwTd)}
                    {...handle}
                    style={{
                      width: `${width * 100}%`,
                      minWidth: parseWidth(minWidth),
                      fontWeight: fontWeight,
                    }}
                    align={align}
                  >
                    {renderColumn ? renderColumn(item[name], item, key1, name) : item[name] || '-'}
                  </TableCell>
                )
              })}
              {/* </div> */}
            </TableRow>
          )
        }
      })}
    </MaterialTableBody>
    // </div>
  )
}

TableBodyComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const TableBodyContent = TableBodyComponent

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     wwwTableRow: {
//       cursor: (props: any) => (props.handleSelect ? 'pointer' : 'default'),
//     },
//   }),
// )
