import React, { FC, useEffect, useContext } from 'react'
// import styled from 'styled-components'

/* Hooks ======================================================================================== */
import useObject from '../../hooks/use-object'
import useArray from '../../hooks/use-array'

/* Common ======================================================================================= */
import { merge } from '../../share/obj-equal'
import AppLayoutClass1, { ComponentKeys } from './app-layout.controller'

/* Constants ==================================================================================== */
import { NAVBAR, HEADER, TOOLBAR, SIDEBAR, DRAWER_WIDTH } from './app-layout.constants'
import { GLOBAL_COMPONENTS } from '../../global-components'

/* Components =================================================================================== */
import { Sidebar } from './sidebar.component'
import { Link as RouterLink, useHistory } from 'react-router-dom'

/* Material UI ======================================================================================== */
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { blue, blueGrey } from '@material-ui/core/colors'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'

/* Types ======================================================================================== */
type AppLayoutRenderProps = {
  config?: any
  components?: any
  content?: any
  header?: any
  headerProps?: any
  toolbarProps?: any
  toolbar?: any
  navbar?: any
  navbarProps?: any
  sidebar?: any
  sidebarProps?: any
  topBar?: any
}

type test = {
  active: boolean
  $active: any
  hide: any
}

type AppLayoutComponents = {
  topbar: test
  header: test
  menubar: test
  toolbar: test
  navbar: test
}

/* AppLayoutClass =============================================================================== */
// I wanted a factory context. Using context don't have significant meaning within app layout components but is not when other component uses it
export class AppLayoutClass extends AppLayoutClass1 {
  renderComponent = (key: ComponentKeys, props: any) => {
    const Component = this.components[key]
    if (this.components[key]) {
      return <Component {...{ ...this.props[key], ...props, ...this }} />
    }
  }

  render: FC<AppLayoutRenderProps> = ({
    config,
    content,
    header,
    headerProps,
    navbar,
    navbarProps,
    sidebar,
    sidebarProps,
    toolbar,
    toolbarProps,
  }) => {
    // Merge default config with config prop
    config = merge({ ...this.config }, config)
    const [components, $components] = useObject<AppLayoutComponents>({
      ...config,
    } as any)
    const initialValues = this.getInitialValues(config)
    const styleClasses = useStyles()

    let prev = 0
    let id: number
    const handleScroll = (e: Event) => {
      if (id) {
        clearTimeout(id)
      }

      id = setTimeout(() => {
        if (window.scrollY === 0) {
          // components.header.$active.set(true)

          if (config.toolbar.active) {
            // components.toolbar.$active.set(true)
          }
        } else if (window.scrollY > prev && window.scrollY > 100) {
          // components.header.$active.set(false)
          // components.toolbar.$active.set(false)
        } else {
          // components.header.$active.set(true)
          // if (config.toolbar.active) {
          //   components.toolbar.$active.set(true)
          // }
        }
        prev = window.scrollY
      }, 100)
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [components])

    const Provider = this.context.Provider
    if (this.context) {
      const renderBreadcrumbs = (breadcrumbItem: any) => {
        /**
         *
         * @param crumbItem
         * @returns breadcrumbs template
         */
        const breadcrumbsTemplate = (crumbItem: any) => {
          if (crumbItem.to) {
            return (
              <Link className={styleClasses.wwwLink} component={RouterLink} to={crumbItem.to}>
                <span className="link-label">{crumbItem.label}</span>
              </Link>
            )
          } else {
            return <Typography>{crumbItem.label}</Typography>
          }
        }

        // Return breadcrumb items
        if (breadcrumbItem) {
          switch (breadcrumbItem.type) {
            case 'breadcrumb':
              return breadcrumbsTemplate(breadcrumbItem)
            default:
              return (
                <Breadcrumbs>
                  {breadcrumbItem.parentItem && breadcrumbsTemplate(breadcrumbItem.parentItem)}
                  {breadcrumbsTemplate(breadcrumbItem)}
                  {/* Display first child of breadcrumb */}
                  {breadcrumbItem.children &&
                    breadcrumbItem.children.length &&
                    breadcrumbItem.children.length === 1 &&
                    breadcrumbsTemplate(breadcrumbItem.children.find((child: any) => !!child))}
                </Breadcrumbs>
              )
          }
        }
      }
      return (
        <div className={styleClasses.root}>
          <Provider
            value={{
              config,
              components,
              $components,
              initialValues,
            }}
          >
            {/* <Topbar context={this.context}>
              {toolbar || this.renderComponent(TOOLBAR, toolbarProps)}
            </Topbar> */}
            <AppBar className={styleClasses.wwwAppBar} position="fixed" color="default">
              <Toolbar variant="dense">
                {/* <Header context={this.context}> */}
                {header || this.renderComponent(HEADER, headerProps)}
                {/* </Header> */}
              </Toolbar>
            </AppBar>
            <Drawer className={styleClasses.wwwDrawer} variant="permanent" anchor="left">
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                bgcolor={blueGrey[200]}
                alignItems="center"
                height="56px"
              >
                <Box mr={1}>{GLOBAL_COMPONENTS.logo.md}</Box>
                {GLOBAL_COMPONENTS.appName.md}
              </Box>
              {/* <Navbar context={this.context}> */}
              {navbar || this.renderComponent(NAVBAR, this.props[NAVBAR] || navbarProps)}
              {/* </Navbar> */}
            </Drawer>
            {/* <Toolbar context={this.context}>
            {toolbar || this.renderComponent(TOOLBAR, toolbarProps)}
          </Toolbar> */}

            {/* <Content context={this.context}> */}
            <main className={styleClasses.wwwContent}>
              {headerProps && headerProps.breadcrumbsNav?.length > 0 && (
                <Box bgcolor="#ffffff" pt={4}>
                  <Container maxWidth="xl">
                    <Breadcrumbs aria-label="breadcrumb">
                      {headerProps.breadcrumbsNav &&
                        headerProps.breadcrumbsNav?.length > 0 &&
                        headerProps.breadcrumbsNav?.map((item: any, key: any) => {
                          return (
                            item.label && (
                              <Breadcrumbs key={key}>{renderBreadcrumbs(item)}</Breadcrumbs>
                            )
                          )
                        })}
                    </Breadcrumbs>
                  </Container>
                </Box>
              )}
              {content}
            </main>
            <Sidebar context={this.context}>
              {sidebar || this.renderComponent(SIDEBAR, sidebarProps)}
            </Sidebar>
            {/* <Footer /> */}
          </Provider>
        </div>
      )
    }

    return <div></div>
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
    wwwAppBar: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
      backgroundColor: '#ffffff',
      boxShadow: '1px 1px 8px 1px rgba(0,0,0,0.07)',
      '& .MuiToolbar-dense': {
        minHeight: theme.spacing(7),
      },
    },
    wwwDrawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      '& .MuiPaper-root': {
        width: 'inherit',
        backgroundColor: blueGrey[100],
        color: theme.palette.text.secondary,
        borderRight: 'none',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: blueGrey[300],
        },
        '&::-webkit-scrollbar-track': {
          background: blueGrey[200],
        },
      },
    },
    wwwContent: {
      paddingTop: `${theme.spacing(7)}px`,
      flexGrow: 1,
    },
    wwwLink: {
      display: 'inline-flex',
      '& svg': {
        width: '12px',
        marginRight: '5px',
      },
    },
  }),
)
