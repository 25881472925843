import React, { useCallback } from 'react'
import { ButtonCore } from '../button'

/* <Submit /> =================================================================================== */
export const Submit: React.FC<{
  className?: string
  icon?: any
  label: string
  loading?: boolean
  onClick?: (e: any) => void
  secondary?: boolean
  success?: boolean
  compact?: boolean
}> = ({ className, icon, label, loading, onClick, success }) => {
  const renderLabel = useCallback(() => {
    // Need to take prop for these messages
    if (success === false) {
      return 'Oh No'
    } else if (success === true) {
      return 'Successful!'
    }

    return label
  }, [label, success])

  const handleClick = useCallback(
    (event: any) => {
      if (loading || success) {
        event.preventDefault()
      } else {
        if (onClick) {
          onClick(event)
        }
      }
    },
    [loading, success],
  )

  return (
    <ButtonCore
      type="submit"
      className={className}
      icon={icon}
      label={renderLabel()}
      onClick={handleClick}
      state={loading}
      success={success}
      baseline={'primary'}
    >
      {(() => {
        if (typeof success !== 'boolean') {
          return loading ? <i className="material-icons loading">hourglass_empty</i> : icon
        }
      })()}

      {
        renderLabel()
      }
    </ButtonCore>
  )
}
