import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
import { ComponentBaselineType } from '../__core/component.types'

type ClassNames = {
  /**
   * Base class for text field
   */
  base: string
  /**
   * Themed class for text field
   */
  themed: string
  /**
   * Proped class for text field
   */
  proped: {
    focused: string
  }
}

type ClassProps = {
  baseline: ComponentBaselineType
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { baseline } = props
  const base = style({
    border: '1px solid rgb(230,230,230)',
    background: theme.getBaseline('surface').color.medium,
    outline: 'none',
    padding: '0 8px',
    fontSize: 12,
    letterSpacing: 0,
    width: '100%',
    $nest: {
      '& input': {
        width: '100%',
        height: '100%',
        background: 'none',
        border: 'none',
        outline: 'none',
      },
    },
  })

  const themed = style({
    background: theme.getBaseline(baseline).color.medium,
    color: theme.getBaseline(baseline).contrast.medium,
  })

  const proped = {
    focused: style({
      // background: theme.getBaseline('background').color.dark,
    }),
  }

  return {
    base,
    themed,
    proped,
  }
}

/* Export ======================================================================================= */
export const textFieldClass = new StyleClass<ClassNames, ClassProps>(getNames)
