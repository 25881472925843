import React, { useState, useEffect, useCallback, useRef, FC } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { FloatDialog } from './float-dialog.component'

export const Float: React.FC<{
  anchorPosition?: any
  close: () => void
  shouldCloseOnClick?: boolean
  dialogPosition?: any
  fullWidth?: any
  offsetY?: number
  open?: boolean
  ref: any
}> = React.forwardRef(
  (
    {
      anchorPosition,
      children,
      close,
      dialogPosition,
      fullWidth,
      offsetY,
      open,
      shouldCloseOnClick,
    },
    ref,
  ) => {
    const handleScroll = () => {
      if (!open && close) {
        close()
      }
    }

    useEffect(() => {
      // window.addEventListener('scroll', handleScroll)
      return () => {
        // window.removeEventListener('scroll', handleScroll)
      }
    }, [])

    if (open && (ref as any).current) {
      const parentRect = (ref as any).current.getBoundingClientRect()

      return (
        <OutsideClickHandler onOutsideClick={close}>
          <FloatDialog
            anchorPosition={anchorPosition}
            dialogPosition={dialogPosition}
            fullWidth={fullWidth}
            offsetY={offsetY}
            onClick={shouldCloseOnClick && close}
            open={open}
            parentRef={ref}
            parentRect={parentRect}
          >
            {children}
          </FloatDialog>
        </OutsideClickHandler>
      )
    }

    return <></>
  },
)
