import React, { FC, useEffect, useContext } from 'react'
import { classes } from 'typestyle'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'

/* Config ======================================================================================= */

/* Hooks ======================================================================================== */
import useAxios, { parseStates } from '../../../hooks/use-axios'
import useObject, { UseObjectCallbacks } from '../../../hooks/use-object'

/* Components =================================================================================== */
import AppLayout from '../layout'
import { TableTemplate } from '../../../components/table/template'
import { TableContext } from '../../../components/table/context'
import { Icon } from '../../../modules/icons'
import { APIWrapperContext } from '../../../api-wrapper'
import useFetch from '../../../hooks/use-fetch'
import { Box } from '../../../modules/box/box.component'
import SET from '../../../styles/set'
import { Text } from '../../../modules/text/text.component'
import { Button } from '../../../modules/button'
import { NoAuth } from '../../../components/no-auth'
import { LensTwoTone } from '@material-ui/icons'
import { TutorialsModal } from '../../../components/tutorials/modal'

/* <AppTable /> ================================================================================= */
const AppTable: FC<RouteComponentProps> = ({ match, history }) => {
  const { orgId, schemaId, parentEntryId } = match.params as any
  const { layout, schema } = useContext(APIWrapperContext)

  const [templates, $templates] = useFetch<{
    count: number
    data: any[]
    page: number
    totalPage: number
  }>({
    count: 0,
    data: [],
    page: 1,
    totalPage: 1,
  })

  useEffect(() => {
    if (schemaId && templates.loaded) {
      $templates.get({
        url: `${process.env.REACT_APP_API}/templates`,
        search: {
          searchStrict: {
            schema: schemaId,
            organization: orgId,
            parent: parentEntryId,
          },
        },
      })
    }
  }, [schemaId, templates.loaded])

  const url = {
    conditional: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries`,
    api: parentEntryId
      ? `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/parent-entry/${parentEntryId}/entries`
      : `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries`,
    view: `/${orgId}/table/${schemaId}/entries`,
    new: parentEntryId
      ? `/${orgId}/table/${schemaId}/parent-entry/${parentEntryId}/new`
      : `/${orgId}/table/${schemaId}/new`,
    summary: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/summary`,
  }

  if (schema.meta.status === 403) {
    return <NoAuth data={schema.error} />
  }

  return (
    <>
      <TableTemplate
        value={{
          layoutComponent: AppLayout,
          layout,
          name: schema.payload ? schema.payload.name : { plural: '', singular: '' },
          url,
          headers: schema.payload ? schema.payload.headers : [],
          search: {
            /**
             * Default query will not affect url search parameters
             */
            default: {
              limit: 9,
              page: 1,
            },
            presets: [],
          },
          history,
          schemaUrl: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}`,
          templates: templates,
          loaded: schema.loading,
          isWaitingForSchema: true,
        }}
      />
      <TutorialsModal
        match={match}
        name={schema.payload ? schema.payload.name : { plural: '', singular: '' }}
      />
    </>
  )
}

export default withRouter(AppTable)
