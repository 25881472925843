/**
 * <RepeaterField
 *   label="Cups"
 *   name="cup"
 *   itemLabel="Cup"
 *   render={() => (
 *     <>
 *        // Fields come here
 *     </>
 *   )}
 * />
 */

import React, { useEffect, FC, createRef, useRef, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import Cookies from 'js-cookie'
import Axios from 'axios'
import path from 'path'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'
import useArray from '../../hooks/use-array'
import useBoolean from '../../hooks/use-boolean'

/* Components =================================================================================== */
// import { Box } from '../box/box.component'
import { Button, ButtonCore } from '../button'
import { FieldArray, FieldArrayItem, EmptyPad } from '../field-array'
import { Icon } from '../icons'
import { Text } from '../text'
import { Wrapper } from '../wrapper'
import { withField, FieldContextProps } from '../field/field.component'
import { APIWrapperContext } from '../../api-wrapper'
import { FormActions } from '../form'
import { StaticField } from '../static-field'
import { Checkbox } from '../checkbox/checkbox.component'
import { Line } from '../line'
import { TextField } from '../text-field'
import { useStyles } from './upload.styled'

/* Material UI  ======================================================================================== */
// Core
import grey from '@material-ui/core/colors/grey'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import LinearProgress from '@material-ui/core/LinearProgress'
import { fade } from '@material-ui/core/styles/colorManipulator'

const DIVIDER_HEIGHT = 8

export const FileAxios: FC<{
  url: any
  onMetaLoad?: any
  baseUrl: string
  displayFileName?: boolean
}> = ({ url, onMetaLoad, baseUrl, displayFileName }) => {
  const [file, $file] = useValue()
  const [meta, $meta] = useValue()
  const [blob, $blob] = useValue()
  const ref = useRef(null)
  const reader = new FileReader()
  const styleClasses = useStyles()

  useEffect(() => {
    const cookie = Cookies.get('token')

    // console.log(url)
    if (url) {
      Axios.get(`${baseUrl}/files/${url.split('/')[url.split('/').length - 1]}`, {
        headers: {
          authorization: cookie,
        },
      }).then(res => {
        $meta.set(res.data)

        if (onMetaLoad) {
          onMetaLoad(res.data)
        }

        Axios.get(`${baseUrl}${url}`, {
          headers: {
            authorization: cookie,
          },
          responseType: 'blob',
        }).then(res => {
          $file.set(res.data)

          reader.onloadend = function () {
            if (ref.current) {
              ; (ref.current as any).src = reader.result as any
            }

            $blob.set(reader.result)
          }

          reader.readAsDataURL(res.data)
        })
      })
    }
  }, [url])

  const handleOpen = () => {
    const base64ImageData = (ref.current as any) ? (ref.current as any).src : blob
    const contentType = meta.original.mimetype

    const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length))
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }
    const _blob = new Blob(byteArrays, { type: contentType })
    const blobUrl = URL.createObjectURL(_blob)

    window.open(blobUrl, '_blank')
  }

  const previewOverlay = (
    <Box
      position="absolute"
      top="0"
      right="0"
      bottom="0"
      left="0"
      textAlign="center"
      visibility="hidden"
      className="www-preview-overlay"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bgcolor={fade(grey[900], 0.8)}
    >
      <Icon.Search color="#ffffff" />
      <Typography variant="subtitle2">
        <Box ml={1} color="#ffffff">
          Preview
        </Box>
      </Typography>
    </Box>
  )
  return (() => {
    if (meta && meta.original) {
      const fileName = meta.original.fileName2
      const fileExt = path.extname(fileName)
      const fileBaseName = path.basename(fileName, fileExt)
      const truncatedName =
        fileBaseName.length > 20 ? fileBaseName.substring(0, 20) + '..' : fileBaseName
      const formattedFileName = truncatedName + fileExt

      if (meta.original.mimetype && meta.original.mimetype.includes('image')) {
        return (
          <Box
            onClick={handleOpen}
            width="80px"
            height="80px"
            bgcolor="#ffffff"
            borderColor="grey.300"
            border={1}
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="center"
            className={styleClasses.wwwPreview}
          // mr={2}
          >
            <img ref={ref} className={styleClasses.wwwPreviewImg} />
            {previewOverlay}
          </Box>
        )
      } else {
        return (
          <Box
            onClick={handleOpen}
            // width="80px"
            height="80px"
            bgcolor="#ffffff"
            borderColor="grey.300"
            border={1}
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="center"
            p={1}
            className={styleClasses.wwwPreview}
          // mr={2}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              // flexDirection="row"
              textAlign="center"
              overflow="hidden"
            >
              <Box pr={1}>
                <Icon.PDF style={{ width: '50px', height: '50px' }} color={grey[500]} />
              </Box>
              {displayFileName && (
                <Box flex="100px" textAlign="initial">
                  <Typography variant="caption" style={{ wordBreak: 'break-word' }}>
                    <Box lineHeight="1.2" color="grey.500">
                      {formattedFileName}
                    </Box>
                  </Typography>
                </Box>
              )}
            </Box>
            {previewOverlay}
          </Box>
        )
      }
    }

    return <></>
  })()
}

const File: FC<{
  FieldArrayInstance: any
  index: number
  max?: number
  parseUploaded: any
  uploadUrl: string
  value: any
  baseUrl: string
  onUpload: any
  $deleteProcesses?: any
}> = ({
  FieldArrayInstance,
  index,
  max = 200,
  uploadUrl,
  parseUploaded,
  $deleteProcesses,
  value,
  baseUrl,
  onUpload,
}) => {
    const [progress, $progress] = useValue(0)
    const [fileName, $fileName] = useValue('Processing...')
    const [fileType, $fileType] = useValue('')
    const { org } = useContext(APIWrapperContext)

    const styleClasses = useStyles()

    useEffect(() => {
      if (value?.constructor?.name === 'File') {
        upload(value)
      } else {
        $progress.set(100)
      }
    }, [])

    const upload = async (file: any) => {
      const cookie = Cookies.get('token')
      const formData = new FormData()
      formData.append('asda', file)

      const res = await Axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookie,
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          $progress.set(percent - 1)
        },
      })

      parseUploaded(res.data, index, (parsedValue: any) => {
        setTimeout(() => {
          $progress.set(100)
          onUpload((instance: any) => {
            instance.setValue(index, { url: parsedValue.replace('{{orgId}}', org.payload.org._id) })
          })
        }, 1000)
      })
    }

    return (
      <>
        {(() => {
          const url = FieldArrayInstance.getValue()[index] && FieldArrayInstance.getValue()[index].url
          // console.log(url)
          // if (typeof url === 'string') {
          return (
            <Box>
              {max > 1 ? (
                <Box display="flex" alignItems="center">
                  {typeof url === 'string' ? (
                    <FileAxios
                      url={url}
                      onMetaLoad={(meta: any) => {
                        $fileType.set(meta.original.mimetype)
                        $fileName.set(meta.original.fileName2)
                      }}
                      baseUrl={baseUrl}
                    />
                  ) : (
                    ''
                  )}

                  <Box flexGrow={1} ml={2}>
                    <Typography>{fileName}</Typography>
                    <Box pt={2}>
                      <LinearProgress
                        className={styleClasses.wwwProgressBar}
                        variant="determinate"
                        value={progress}
                        color="secondary"
                      />
                      {progress < 100 ? (
                        <Typography variant="caption">
                          <Box textAlign="center" pt={1}>
                            Uploading {progress}%
                          </Box>
                        </Typography>
                      ) : (
                        <Typography variant="caption">
                          <Box textAlign="center" pt={1}>
                            Uploaded
                          </Box>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: 140,
                      overflow: 'hidden',
                    }}
                  >
                    <FileAxios
                      url={url}
                      baseUrl={baseUrl}
                      onMetaLoad={(meta: any) => {
                        $fileType.set(meta.original.mimetype)
                        $fileName.set(meta.original.fileName2)
                      }}
                    />
                  </div>
                  {/* <Button
                      icon={<Icon.Delete />}
                      onClick={() => {
                        FieldArrayInstance.pop(index)
                      }}
                      variant="contained"
                      size="small"
                    /> */}
                </>
              )}
            </Box>
          )
          // }
          // else {
          //   return (
          //     <Typography>
          //       <Box>Processing ...</Box>
          //     </Typography>
          //   )
          // }
        })()
          // )}
        }
      </>
    )
  }

/* <UploadComponent /> =================================================================== */
export const UploadComponent: FC<FieldContextProps & {
  itemLabel: string
  label: string
  name: string
  allow: string[]
  render: (data: any, index?: number, field?: any) => any
  uploadUrl: any
  parseUploaded: (res: any, onChange: any) => {}
  fileBeforeSubmit?: any
  max?: number
  onChange?: any
  baseUrl: string
  helperText?: string
}> = props => {
  const { label, name, uploadUrl, parseUploaded, max = 40, baseUrl, dispatch, helperText } = props
  const [FieldArrayInstance, $FieldArrayInstance] = useValue(null)
  const [inputs, $inputs] = useArray<any>([])
  const [uploadProcesses, $uploadProcesses] = useArray<any>([])
  const [deleteProcesses, $deleteProcesses] = useArray<any>([])
  const [dragEntered, $dragEntered] = useBoolean(false)

  const { org, app } = useContext(APIWrapperContext)

  const dropRef = useRef(null)

  const styleClasses = useStyles()

  useEffect(() => {
    $FieldArrayInstance.set(new FieldArray())
  }, [])

  useEffect(() => {
    if (dispatch) {
      dispatch({
        type: FormActions.FIELD,
        param: { name, value: { validate: props.validate, beforeSubmit: props.beforeSubmit } },
      })
    }
  }, [dispatch])

  useEffect(() => {
    const dropDiv = dropRef.current

    if (dropDiv) {
      ; (dropDiv as any).addEventListener('drop', handleDrop)
    }

    return () => {
      if (dropDiv) {
        ; (dropDiv as any).removeEventListener('drop', handleDrop)
      }
    }
  }, [dropRef.current])

  useEffect(() => {
    if (inputs.length > 0) {
      ; (inputs[inputs.length - 1].current as any).click()
    }
  }, [inputs])

  useEffect(() => {
    if (uploadProcesses.length !== 0) {
      const callback = uploadProcesses[uploadProcesses.length - 1]
      callback(FieldArrayInstance)
      // $FieldArrayInstance.set(FieldArrayInstance)
      // $uploadProcesses.pop()
    }
  }, [uploadProcesses, FieldArrayInstance])

  useEffect(() => {
    if (deleteProcesses.length !== 0) {
      FieldArrayInstance.pop(deleteProcesses[deleteProcesses.length - 1])
      $FieldArrayInstance.set(FieldArrayInstance)
      // $uploadProcesses.pop()
    }
  }, [deleteProcesses, FieldArrayInstance])

  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const files = []
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      if (FieldArrayInstance.size() < max) {
        files.push(e.dataTransfer.files[i])
      }
    }

    FieldArrayInstance.push(files)

    $dragEntered.set(false)
  }

  const handleDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    $dragEntered.set(true)
  }

  const handleDragEnter = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    $dragEntered.set(true)
  }

  const handleDragExit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    $dragEntered.set(false)
  }

  const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (FieldArrayInstance.size() < max) {
        FieldArrayInstance.push(e.target.files[0])
      }
    }
  }

  return (
    FieldArrayInstance && (
      <Box>
        <Box>
          {label && (
            <Typography variant="subtitle1">
              <Box fontWeight="fontWeightMedium">{label}</Box>
            </Typography>
          )}
        </Box>
        <RepeaterFieldContainer
          ref={dropRef}
          dragEntered={dragEntered}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragExit={handleDragExit}
        >
          {inputs.map((input, key) => (
            <input ref={input} type="file" key={key} onChange={handleChange(key)} />
          ))}
          <FieldArrayInstance.render
            name={name}
            itemContainer={
              <Card className={styleClasses.wwwUploadedItem} variant="outlined"></Card>
            }
            onUpdate={(instance: any) => {
              // $FieldArrayInstance.set(instance)
            }}
            itemContent={<Box flexGrow={1} p={2} bgcolor="grey.50"></Box>}
            itemHeader={(index: number) => (
              <Box order={3} p={1} borderLeft={1} bgcolor="grey.50" borderColor="grey.300">
                <Button
                  icon={<Icon.Delete />}
                  onClick={() => {
                    $deleteProcesses.push(index)
                  }}
                  size="large"
                  variant="contained"
                  className={styleClasses.wwwDeleteButton}
                />
              </Box>
            )}
            itemRender={(index: number, bind: any) => {
              const value = FieldArrayInstance.getValue()[index]

              return (
                <>
                  <Box>
                    <File
                      value={FieldArrayInstance.getValue()[index]}
                      FieldArrayInstance={FieldArrayInstance}
                      index={index}
                      max={max}
                      baseUrl={baseUrl}
                      uploadUrl={`${baseUrl}/files/${org.payload.org._id}/upload`}
                      parseUploaded={parseUploaded}
                      onUpload={(callback: any) => {
                        $uploadProcesses.push(callback)
                      }}
                      $deleteProcesses={$deleteProcesses}
                    />
                  </Box>
                  <Box pt={2}>
                    <TextField
                      label="Description"
                      name={`${name}.${index}.description`}
                      placeholder="Description"
                    />
                    <StaticField
                      name={`${name}.${index}.url`}
                      beforeSubmit={props.fileBeforeSubmit}
                    />
                    {!value?.signature ? (
                      <Box mt={2}>
                        <Checkbox
                          label="Sign and Approve Attachment"
                          name={`${name}.${index}.signature`}
                          onChange={() => {
                            FieldArrayInstance.setValue(index, {
                              ...value,
                              signedBy: `${org.payload.user.firstName} ${org.payload.user.lastName}`,
                              signedDate: new Date().toISOString(),
                            })
                          }}
                        />
                      </Box>
                    ) : (
                      <Box mt={1}>
                        <Typography>Signed by {value.signedBy}</Typography>
                      </Box>
                    )}
                  </Box>
                </>
              )
            }}
          />
          {FieldArrayInstance && FieldArrayInstance.size() === 0 && (
            <EmptyPad height={DIVIDER_HEIGHT} />
          )}
          {(() => {
            if (FieldArrayInstance) {
              if (FieldArrayInstance.size() < max) {
                return (
                  <>
                    <Button
                      label="Upload"
                      color="secondary"
                      variant="contained"
                      startIcon={<Icon.Upload />}
                      size="small"
                      onClick={() => {
                        $inputs.push(createRef())
                      }}
                    />
                    <EmptyPad height={DIVIDER_HEIGHT} />
                  </>
                )
              }
            }
          })()}
        </RepeaterFieldContainer>
        {helperText ? <FormHelperText variant="filled">{helperText}</FormHelperText> : null}
      </Box>
    )
  )
}

export const Upload = withField(UploadComponent, null, true)

const RepeaterFieldContainer = styled.div<{ dragEntered?: boolean }>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  border: 1px solid rgb(230, 230, 230);
  background: rgb(244, 244, 244);

  ${FieldArrayItem} {
    width: 100%;
  }

  ${Wrapper.Flex} {
    align-items: center !important;
  }

  ${Text.l2} {
    white-space: nowrap;
    width: fit-content;
  }

  > input {
    display: none;
  }
`

// const PreviewWrapper = styled.div``
// const PreviewContainer = styled.div`
//   align-items: center;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 100%;

//   ${Wrapper.Flex} {
//     justify-content: end;
//   }

//   ${PreviewWrapper} {
//     background: white;
//     border-radius: 4px;
//     height: 48px;
//     margin-right: 8px;
//     overflow: hidden;
//     position: relative;
//     width: 48px;
//     align-items: center;
//     justify-content: center;
//     display: flex;

//     > img {
//       left: 50%;
//       position: absolute;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       width: 100%;
//     }
//   }
// `

// const UploadComponentContainer = styled.div<{ max: number }>`
//   width: ${({ max }) => (max > 1 ? '100%' : '160px')};

//   > input {
//     display: none;
//   }

//   ${({ max }) =>
//     max == 1 &&
//     `
//     ${RepeaterFieldContainer} {
//       height: 160px;
//       justify-content: center;
//     }

//     ${PreviewWrapper} {
//       width: 100%;
//       height: 100%;
//     }

//     ${PreviewContainer} {
//       > img {
//         width: 100%;
//       }

//       > ${ButtonCore} {
//         position: absolute;
//         right: 4px;
//         top: 4px;
//       }
//     }
//   `}
// `
