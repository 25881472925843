export const buttonSizes: any = {
  xs: {
    fontSize: 11,
    boxSize: 20,
    iconSize: 10,
    iconLeft: {
      paddingLeft: 14,
      paddingRight: 2,
    },
    iconRight: {
      paddingLeft: 2,
      paddingRight: 14,
    },
    shrink: 0.95,
  },
  sm: {
    fontSize: 13,
    boxSize: 28,
    iconSize: 14,
    iconLeft: {
      paddingLeft: 16,
      paddingRight: 4,
    },
    iconRight: {
      paddingLeft: 4,
      paddingRight: 16,
    },
    shrink: 0.95,
  },
  md: {
    fontSize: 14,
    boxSize: 32,
    iconSize: 14,
    iconLeft: {
      paddingLeft: 20,
      paddingRight: 6,
    },
    iconRight: {
      paddingLeft: 6,
      paddingRight: 20,
    },
    shrink: 0.95,
  },
  lg: {
    fontSize: 15,
    boxSize: 36,
    iconSize: 18,
    iconLeft: {
      paddingLeft: 28,
      paddingRight: 8,
    },
    iconRight: {
      paddingLeft: 8,
      paddingRight: 28,
    },
    shrink: 0.95,
  },
  xl: {
    fontSize: 16,
    boxSize: 42,
    iconSize: 24,
    iconLeft: {
      paddingLeft: 32,
      paddingRight: 10,
    },
    iconRight: {
      paddingLeft: 10,
      paddingRight: 32,
    },
    shrink: 0.95,
  },
  small: {
    fontSize: 11,
    boxSize: 20,
    iconSize: 13,
  },
  default: {
    fontSize: 12,
    boxSize: 24,
    iconSize: 14,
  },
}
