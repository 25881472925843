import React, { useEffect, FC, useRef, useContext } from 'react'

/* Components =================================================================================== */
import { Checkbox } from '../../modules/checkbox/checkbox.component'
import { RepeaterField } from '../../modules/repeater-field'
import { Select } from '../../modules/select/select.component'
import { TextField } from '../../modules/text-field'
import { TableSelect } from '../table-select'
import { DateField } from '../date-field'
// import { Text } from '../../modules/text/text.component'
// import SET from '../../styles/set'
import { Textarea } from '../../modules/textarea'
import { Upload } from '../../modules/upload'
import Axios from 'axios'
// import { Box } from '../../modules/box/box.component'
import { Icon } from '../../modules/icons'
// import { FieldWrapper } from '../field-wrapper'
import { Collapsible } from '../collapsible'
import { APIWrapperContext } from '../../api-wrapper'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'

import { getKeyThenIncreaseKey } from 'antd/lib/message'
import { formatPrice } from '../../common/format'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'

/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import { Divider } from '@material-ui/core'
import { MultiCheckbox } from '../../modules/checkbox/multi-checkbox.component'

/* Constants ==================================================================================== */
const FIELD_TYPES = {
  MultiCheckbox: 'MultiCheckbox',
  Checkbox: 'Checkbox',
  Date: 'Date',
  RepeaterField: 'RepeaterField',
  Select: 'Select',
  TableSelect: 'TableSelect',
  TextField: 'TextField',
  Textarea: 'Textarea',
  Upload: 'Upload',
  NumberField: 'NumberField',
  MoneyField: 'MoneyField',
  Collapsible: 'Collapsible',
  Break: 'Break',
}

export const renderForm = (
  field: any,
  key: any,
  parent?: any,
  entryUrl?: string,
  orgId?: string,
) => {
  const name = parent ? `${parent}.${field.name}` : field.name || ''

  return (
    <React.Fragment key={key}>
      {(() => {
        switch (field.type) {
          case FIELD_TYPES.Collapsible: {
            return (
              <Box className="www-field-item" mb={3}>
                <Collapsible {...field} name={name} renderForm={renderForm} />
              </Box>
            )
          }
          case FIELD_TYPES.Checkbox: {
            return (
              <Box className="www-field-item" ml={2}>
                <Checkbox name={name} label={field.label} key={key} helperText={field.helperText} />
              </Box>
            )
          }
          case FIELD_TYPES.MultiCheckbox: {
            return (
              <Box className="www-field-item">
                <MultiCheckbox name={name} label={field.label} key={key} items={field.items} />
              </Box>
            )
          }
          case FIELD_TYPES.Date: {
            return (
              <Box className="www-field-item" mb={3}>
                <DateField
                  name={name}
                  label={field.label}
                  key={key}
                  helperText={field.helperText}
                  allowDefault={field.allowDefault}
                />
              </Box>
            )
          }
          case FIELD_TYPES.RepeaterField: {
            return (
              <Box className="www-field-item" mb={3}>
                <RepeaterField
                  name={name}
                  label={field.label}
                  itemLabel={field.itemLabel}
                  renderItems={field.renderItems}
                  render={(data: any, index: any, name: any, bind: any) => {
                    return (
                      <>
                        {(() => {
                          return [...(field.renderItems || [])].map((field: any, key2: any) => {
                            return renderForm(field, key2, `${name}.${index}`, entryUrl, orgId)
                          })
                        })()}
                      </>
                    )
                  }}
                  key={key}
                />
              </Box>
            )
          }
          case FIELD_TYPES.Select: {
            return (
              <Box className="www-field-item" mb={3}>
                <Select
                  name={name}
                  key={key}
                  label={field.label}
                  options={field.items.map((item: any) => item.value)}
                  allowEmpty
                  renderValue={(data: any) => {
                    if (typeof data == 'string') {
                      return data
                    }
                    return data.value
                  }}
                  helperText={field.helperText}
                />
              </Box>
            )
          }
          case FIELD_TYPES.TableSelect: {
            let renderOption

            if (field.renderOption) {
              renderOption = eval(field.renderOption)
            }

            return (
              <Box className="www-field-item" mb={3}>
                <TableSelect
                  name={name}
                  fieldModel={field}
                  entryUrl={entryUrl}
                  key={key}
                  renderOption={renderOption}
                />
              </Box>
            )
          }
          case FIELD_TYPES.TextField: {
            return (
              <Box className="www-field-item" mb={3}>
                <TextField
                  name={name}
                  key={key}
                  label={field.label}
                  helperText={field.helperText}
                />
              </Box>
            )
          }
          case FIELD_TYPES.MoneyField: {
            return (
              <Box className="www-field-item" mb={3}>
                <TextField
                  name={name}
                  key={key}
                  label={field.label}
                  style={{
                    margin: 0,
                  }}
                  sanitize={(value: any) => {
                    const regex = /\d*\.?\d{0,2}/g
                    value = regex.exec(value)

                    return value[0]
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    // onChange: (e: any) => {
                    //   // console.log(e)
                    // },
                  }}
                  helperText={field.helperText}
                />
              </Box>
            )
          }
          case FIELD_TYPES.NumberField: {
            return (
              <Box className="www-field-item" mb={3}>
                <TextField
                  name={name}
                  label={`${field.label} #`}
                  style={{
                    margin: 0,
                  }}
                  key={key}
                  sanitize={(value: any) => {
                    const regex = /\d*\.?\d{0,2}/g
                    value = regex.exec(value)

                    return value[0]
                  }}
                  InputProps={
                    {
                      // onChange: (e: any) => {
                      //   // console.log(e)
                      // },
                    }
                  }
                  helperText={field.helperText}
                />
              </Box>
            )
          }
          case FIELD_TYPES.Textarea: {
            return (
              <Box className="www-field-item" mb={3}>
                <Textarea name={name} label={field.label} key={key} helperText={field.helperText} />
              </Box>
            )
          }
          case FIELD_TYPES.Break: {
            return (
              <Box className="www-field-item" mb={3}>
                <Divider />
              </Box>
            )
          }
          case FIELD_TYPES.Upload: {
            return (
              <Box className="www-field-item" mb={3}>
                <Upload
                  name={name}
                  label={field.label}
                  key={key}
                  baseUrl={process.env.REACT_APP_API}
                  allow={['png', 'jpeg', 'jpg', 'pdf']}
                  parseUploaded={(data: any, index: number, onChange: any) => {
                    onChange(`/files/{{orgId}}/${data[0]._id}`)
                  }}
                  fileBeforeSubmit={(data: any = []) => {
                    if (data && typeof data === 'string') {
                      const id = data.split('/')[data.split('/').length - 1].split('.')[0]

                      Axios.put(`${process.env.REACT_APP_API}/files/${orgId}/upload/save/${id}`)
                    }

                    return data
                  }}
                  helperText={field.helperText}
                />
              </Box>
            )
          }
        }
      })()}
    </React.Fragment>
  )
}
