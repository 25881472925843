import React, { useEffect, FC, useRef, useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useFetch from '../../hooks/use-fetch'
import useValue from '../../hooks/use-value'

/* Common ======================================================================================= */
import { flat } from '../../share/obj-equal'

/* Components =================================================================================== */
import { FormContext, FormActions } from '../../modules/form'
import { StaticField } from '../../modules/static-field'
import { selectClass } from '../../modules/select/select.class'
import { textFieldClass } from '../../modules/text-field/text-field.class'
import { withField } from '../../modules/field/field.component'
import { Icon } from '../../modules/icons'
import { useStyles } from './table-select.styled'

/* Material UI =================================================================================== */
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

let debounceId: any
/* <TableSelectComponent/ > ===================================================================== */
const TableSelectComponent: FC<{
  name: string
  fieldModel: any
  entryUrl?: string
  renderOption?: any
  dispatch?: any
  key?: any
  helperText?: string
} & RouteComponentProps> = props => {
  const { name, fieldModel, entryUrl, match, dispatch, key } = props

  const formContext = useContext(FormContext)
  const { form, raw } = formContext.value
  const inputRef = useRef(null)
  const [open, $open] = useValue(undefined)
  const [searchValue, $searchValue] = useValue('')
  const [value, $value] = useValue()
  const [cost, $cost] = useValue()
  const [note, $note] = useValue()
  const [entries, $entries] = useFetch<{
    data: any[]
  }>(
    {
      data: [],
    },
    {
      updateGetParams: false,
      url: `${process.env.REACT_APP_API}/${fieldModel.apiUrl
        .replace('{{orgId}}', (match as any).params.orgId)
        .replace('{{parent-entry}}', (match as any).params.parentEntryId)}`,
    },
  )
  const styleClasses = useStyles()

  useEffect(() => {
    const initialSearchKey = (form as any)[`${name}.${fieldModel.labelKey}`]
    const initialValueKey = (form as any)[`${name}.${fieldModel.valueKey}`]

    if (initialSearchKey) {
      $searchValue.set(initialSearchKey)
      $value.set({
        [fieldModel.labelKey]: initialSearchKey,
        [fieldModel.valueKey]: initialValueKey,
      })
    }
  }, [(form as any)[`${name}.${fieldModel.labelKey}`]])

  useEffect(() => {
    if ((form as any)[name]) {
      if ((form as any)[name][fieldModel.labelKey] !== searchValue) {
        const initialSearchKey = (form as any)[name][fieldModel.labelKey]
        const initialValueKey = (form as any)[name][fieldModel.valueKey]

        if (initialSearchKey) {
          $searchValue.set(initialSearchKey)
          $value.set({
            [fieldModel.labelKey]: initialSearchKey,
            [fieldModel.valueKey]: initialValueKey,
          })
        }
      }
    }
  }, [(form as any)[`${name}`]])

  useEffect(() => {
    if (searchValue) {
      clearTimeout(debounceId)
      debounceId = setTimeout(() => {
        $entries.cancel()
        $entries.get({
          search: {
            limit: 50,
            sort: {
              [fieldModel.searchKey]: 1,
            },
            search: {
              [fieldModel.searchKey]: searchValue,
            },
            ...(() => {
              if (fieldModel.populateItems) {
                return {
                  populate: fieldModel.populateItems,
                }
              }
            })(),
          },
        })
      }, 400)
    }
  }, [searchValue])

  useEffect(() => {
    if (open === false) {
      if (value) {
        const flattenedValue = flat(value)
        $searchValue.set(flattenedValue[fieldModel.labelKey] || '')
      } else {
        $searchValue.set('')
      }
    }
  }, [open])

  const { base, themed } = textFieldClass.setProps({ baseline: 'background' })
  const { option } = selectClass.setProps({ baseline: 'background' })

  const onInputChangeHandler = (event: any, newInputValue: any, reason: any) => {
    if (newInputValue) {
      $searchValue.set(newInputValue || '')
    }
  }

  const onChangeHandler = (event: any, newValue: any, reason: any) => {
    // SET & DELETE VALUES
    if (newValue) {
      const flatItem = flat(newValue)
      $value.set(newValue || '')
      $searchValue.set(flatItem[fieldModel.labelKey] || '')

      if (fieldModel.costTarget) {
        const cb = eval(fieldModel.costTarget)
        dispatch({
          type: FormActions.SET,
          param: {
            name: cb(name),
            value: flatItem['General Information.cost'],
          },
        })
      }
    } else if (!newValue && reason === 'clear') {
      $searchValue.set('')
      $value.set('')

      dispatch({
        type: FormActions.DELETE,
        param: {
          name: name,
        },
      })
      if (fieldModel.costTarget) {
        const cb = eval(fieldModel.costTarget)
        dispatch({
          type: FormActions.DELETE,
          param: {
            name: cb(name),
          },
        })
      }
    }
  }

  // console.log(value)
  // console.log(searchValue)

  return (
    <>
      <Autocomplete
        options={entries.payload.data ? entries.payload.data : []}
        noOptionsText="Search for entry by typing the name and click on the option to set value"
        getOptionLabel={option => {
          const flatItem = flat(option)
          // console.log(option)
          if (typeof option === 'string') {
            return option
          } else {
            return flatItem[fieldModel.labelKey]
          }
        }}
        renderOption={option => {
          const flatItem: any = flat(option)
          const optionLabel = props.renderOption
            ? props.renderOption(flat(option))
            : flatItem[fieldModel.labelKey]
          return <Typography className={styleClasses.selectItem}>{optionLabel}</Typography>
        }}
        value={searchValue}
        inputValue={searchValue}
        renderInput={params => {
          // console.log(params)
          return <TextField variant="outlined" {...params} label={fieldModel.label} size="small" />
        }}
        onKeyPress={(e: any) => {
          e.key === 'Enter' && e.preventDefault()
        }}
        onChange={onChangeHandler}
        onBlur={() => {
          if (!value) {
            $searchValue.set('')
            $note.set('You must select an option from the search.')
          } else {
            $note.set('')
          }
        }}
        onInputChange={onInputChangeHandler}
        popupIcon={<Icon.ArrowDown size="sm" />}
        closeIcon={<Icon.CloseCircle size="sm" />}
        fullWidth
      />
      {note}
      {value !== undefined && (
        <StaticField
          name={name}
          value={value && Object.keys(value).length > 0 ? value : ''}
          beforeSubmit={data => {
            if (value) {
              const flattenData = flat(data)
              return flattenData[fieldModel.valueKey]
            }

            return ''
          }}
        />
      )}
      {fieldModel.costTarget && <StaticField name={fieldModel.costTarget} value={cost} />}
      {fieldModel.helperText ? (
        <FormHelperText variant="filled">{fieldModel.helperText}</FormHelperText>
      ) : null}
    </>
  )
}

export const TableSelect = withRouter(withField(TableSelectComponent))
