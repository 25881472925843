import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { ConstructionStyled } from './construction.styled'

const Construction: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <ConstructionStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M16 20.2222L15.5 13L10 9.5L5 13.5L16 20.2222ZM16 20.2222L23 24.5M13 30L11.9474 22.6667L6.15789 19L3 30M19 6C19 7.65685 17.6569 9 16 9C14.3431 9 13 7.65685 13 6C13 4.34315 14.3431 3 16 3C17.6569 3 19 4.34315 19 6ZM25.2727 22L22.3182 26L20 25L17 30H30L25.2727 22Z"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ConstructionStyled>
  )
}

export default Construction
