import { LAYOUT_XL, LAYOUT_SM, LAYOUT_MD, LAYOUT_LG } from './constants'
import { media } from 'typestyle'

/** Media queries per sizes */
const DEVICE_SIZES: { [key: string]: (key: any, value: any) => any } = {
  '': (key: any, value: any) => ({ [key]: value }),
  sm: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_SM }, { [key]: value })
  },
  smi: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_SM }, { [key]: `${value} !important` })
  },
  md: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_MD }, { [key]: value })
  },
  mdi: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_MD }, { [key]: `${value} !important` })
  },
  lg: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_LG }, { [key]: value })
  },
  lgi: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_LG }, { [key]: `${value} !important` })
  },
  xl: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_XL }, { [key]: value })
  },
  xli: (key: any, value: any) => {
    return media({ maxWidth: LAYOUT_XL }, { [key]: `${value} !important` })
  },
}

type StyleSetProps = {
  key: {
    [key: string]: string
  }
  getValue: (i?: number) => string
  size?: number
  incremental?: boolean
}

export const generateSet: <P>(props: StyleSetProps) => P = ({
  key,
  size = 10,
  incremental = true,
  getValue,
}) => {
  const styleSet: any = {}

  if (incremental) {
    for (let i = 0; i <= size; i++) {
      for (const name in key) {
        for (const deviceSize in DEVICE_SIZES) {
          styleSet[`${name}${8 * i}${deviceSize}`] = DEVICE_SIZES[deviceSize](
            key[name],
            getValue(i),
          )
        }
      }
    }
  } else {
    for (const name in key) {
      for (const deviceSize in DEVICE_SIZES) {
        styleSet[`${name}${deviceSize}`] = DEVICE_SIZES[deviceSize](key[name], getValue())
      }
    }
  }

  return styleSet
}

export const generateTypes = ({ keys = [], size = 10, incremental = true }) => {
  let type = '/** Below type is generated by `generateTypes` in set.ts */\n'
  keys.map(function(key: number) {
    if (incremental) {
      for (let i = 0; i <= size; i++) {
        type += `${key}${8 * i}: {}\n`
        type += `${key}${8 * i}i: {}\n`
        type += `${key}${8 * i}sm: {}\n`
        type += `${key}${8 * i}smi: {}\n`
        type += `${key}${8 * i}md: {}\n`
        type += `${key}${8 * i}mdi: {}\n`
        type += `${key}${8 * i}lg: {}\n`
        type += `${key}${8 * i}lgi: {}\n`
        type += `${key}${8 * i}xl: {}\n`
        type += `${key}${8 * i}xli: {}\n`
      }
    } else {
      type += `${key}: {}\n`
      type += `${key}i: {}\n`
      type += `${key}sm: {}\n`
      type += `${key}smi: {}\n`
      type += `${key}md: {}\n`
      type += `${key}mdi: {}\n`
      type += `${key}lg: {}\n`
      type += `${key}lgi: {}\n`
      type += `${key}xl: {}\n`
      type += `${key}xli: {}\n`
    }
  })

  return type
}
