import { ComponentBaselineType } from '../__core/component.types'

let _DefaultTheme: any = {
  baselines: {
    primary: {
      color: {
        light: '#e3f2fd',
        medium: '#42a5f5',
        dark: '#1e88e5',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    secondary: {
      color: {
        light: '#42a5f5',
        medium: '#e3f2fd',
        dark: '#1e88e5',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    background: {
      color: {
        light: 'rgb(244,244,244)',
        medium: 'rgb(238,238,238)',
        dark: 'rgb(220,220,220)',
      },
      contrast: {
        light: 'black',
        medium: 'black',
        dark: 'black',
      },
    },
    backgroundAlt: {
      color: {
        light: 'rgb(95, 96, 127)',
        medium: 'rgb(95, 96, 127)',
        dark: 'rgb(95, 96, 127)',
      },
      contrast: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
    },
    surface: {
      color: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
      contrast: {
        light: 'black',
        medium: 'black',
        dark: 'black',
      },
    },
    error: {
      color: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
      contrast: {
        light: 'red',
        medium: 'red',
        dark: 'red',
      },
    },
    disabled: {
      color: {
        light: 'white',
        medium: 'white',
        dark: 'white',
      },
      contrast: {
        light: 'grey',
        medium: 'grey',
        dark: 'grey',
      },
    },
    none: {
      color: {},
      contrast: {},
    },
  },
  alt: {
    color: {
      light: 'rgb(95, 96, 127)',
      medium: 'rgb(95, 96, 127)',
      dark: 'rgb(95, 96, 127)',
    },
    contrast: {
      light: 'white',
      medium: 'white',
      dark: 'white',
    },
  },
  boxes: {
    border: {
      light: 'rgb(240,240,240)',
      medium: 'rgb(230,230,230)',
      dark: 'rgb(220,220,220)',
    },
    shadow: {
      light: 'rgba(0,0,0,0.05)',
      medium: 'rgba(0,0,0,0.07)',
      dark: 'rgba(0,0,0,0.1)',
    },
  },
  transition: {
    slow: '0.5s cubic-bezier(0.19, 1, 0.22, 1)',
    medium: '0.35s cubic-bezier(0.19, 1, 0.22, 1)',
    fast: '0.2s cubic-bezier(0.19, 1, 0.22, 1)',
  },
}

_DefaultTheme = {
  ..._DefaultTheme,
  getBaseline: (name: ComponentBaselineType, alt?: boolean) => {
    const baseline: any = {}

    if (alt && _DefaultTheme.baselines[`${name}Alt`]) {
      return _DefaultTheme.baselines[`${name}Alt`]
    }

    return _DefaultTheme.baselines[name]
  },
}

export const DefaultTheme = _DefaultTheme
