import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { VerticalEllipsisStyled } from './vertical-ellipsis.styled'

const VerticalEllipsis: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <VerticalEllipsisStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <circle cx="16" cy="5" r="3" className="stroke" strokeWidth="2" />
      <circle cx="16" cy="16" r="3" className="stroke" strokeWidth="2" />
      <circle cx="16" cy="27" r="3" className="stroke" strokeWidth="2" />
    </VerticalEllipsisStyled>
  )
}

export default VerticalEllipsis
