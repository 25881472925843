import React, { FC } from 'react'
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom'
import Auth from './auth'

import APIWrapper from './api-wrapper'
import './modals'
import { DialogWrapper } from './modules/dialog-wrapper'

/* Routes ======================================================================================= */
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { routes } from './routes'

/* <MyRouter /> =============================================================================== */
const Router: FC = ({ ...others }) => {
  return (
    <BrowserRouter {...others}>
      <Switch>
        {routes.map(({ Component, path, exact, props, visibility }, key) => {
          return (
            <Route
              path={path}
              exact={exact}
              key={`${path}-${key}`}
              // component={Auth(Component, visibility, props)}
              component={() => {
                if (visibility === 'public') {
                  return <Component {...props} key={window.location.pathname} />
                }

                return (
                  <APIWrapper key={window.location.pathname}>
                    <Component {...props} key={window.location.pathname} />
                  </APIWrapper>
                )
              }}
            />
          )
        })}
      </Switch>
      <DialogWrapper.render />
    </BrowserRouter>
  )
}

export default Router
