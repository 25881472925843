export const combineClassNames: (classNames?: string[]) => string = classNames => {
  let combinedClassNames = ''

  if (classNames) {
    classNames.map((item: string) => {
      combinedClassNames += ` ${item}`
    })
  }

  return combinedClassNames
}
