import React, { useEffect, FC, useContext } from 'react'
import { withRouter, useHistory } from 'react-router-dom'

/* Routes ======================================================================================= */
import routes from '../'

/* Hooks ======================================================================================== */
import useAxios, { parseStates } from '../../../hooks/use-axios'

/* Components =================================================================================== */
import AppLayout from '../layout'
import { ViewTemplate } from '../../../components/view/tab-template'
import { Icon } from '../../../modules/icons'
import { flat } from '../../../share/obj-equal'
import { APIWrapperContext } from '../../../api-wrapper'
import { NoAuth } from '../../../components/no-auth'
import useFetch from '../../../hooks/use-fetch'
import { formatDate } from '../../../components/date-field'
import { TutorialsModal } from '../../../components/tutorials/modal'

/* <UserView /> =============================================================================== */
const UserView: FC<{ match: any }> = ({ match }) => {
  const { entryId, orgId, schemaId } = match.params as any
  const { layout, org, schema } = useContext(APIWrapperContext)
  const history = useHistory()

  const { payload = {}, error, loading } = schema

  const apiUrl = `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}`

  const search = payload.populateItems
    ? {
      populate: [...payload.populateItems],
    }
    : {}

  const [entry, $entry] = useFetch(
    {
      stage: {
        name: '',
      },
      schema: '',
    },
    { url: apiUrl, search },
  )

  useEffect(() => {
    if (entry.mounted) {
      $entry.get({ url: apiUrl, search })
    }
  }, [entry.mounted, schema.payload])

  const { schemaData } = parseStates(
    {
      schemaData: schema,
    },
    {
      name: {},
      views: [],
    },
  )

  if (entry.meta.status === 403) {
    return <NoAuth data={entry.error} />
  }

  return (
    <>
      <ViewTemplate
        search={
          payload.populateItems
            ? {
              populate: [
                ...payload.populateItems,
                {
                  key: 'parent',
                  from: 'entries',
                },
                {
                  key: 'parent.schema',
                  from: 'schema',
                },
                {
                  key: 'schema',
                  from: 'schema',
                },
              ],
            }
            : {
              populate: [
                {
                  key: 'parent',
                  from: 'entries',
                },
                {
                  key: 'parent.schema',
                  from: 'schema',
                },
                {
                  key: 'schema',
                  from: 'schema',
                },
              ],
            }
        }
        AppLayout={AppLayout}
        match={match}
        history={history}
        apiUrl={`${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}`}
        parseHeading={(model: any, data: any) => {
          const headers = model.headers && model.headers.filter((header: any) => header.name === model.primaryField)
          const currentHeader = headers && headers.reduce((obj: any, item: any) => Object.assign(obj, { item }), {})
          const primaryField = flat(data)[model.primaryField]


          switch (currentHeader?.item.format) {
            case 'date':
              return `${schemaData.payload.name.singular} ${primaryField ? `- ${formatDate(new Date(primaryField))}` : ''}`
            default:
              return `${schemaData.payload.name.singular} ${primaryField ? `- ${primaryField}` : ''}`
          }
        }}
        parseDescription={(data: any) => {
          const flatten = flat(data)

          let text = ''

          if (flatten.updatedAt) {
            text += `Updated at ${formatDate(new Date(data.updatedAt))} `

            if (flatten['updatedBy.fullName']) {
              text += ` by ${flatten['updatedBy.fullName']}`
            } else if (flatten['createdBy.fullName']) {
              text += ` by ${flatten['createdBy.fullName']}`
            }
          }

          return text
        }}
        layoutProps={layout}
        model={schemaData.payload}
        entry={entry}
        refresh={() => {
          $entry.get({ url: apiUrl, search })
        }}
      />
      <TutorialsModal
        match={match}
        name={schema.payload ? schema.payload.name : { plural: '', singular: '' }}
      />
    </>
  )
}

export default withRouter(UserView)
