export const API_URL = {
  app: () => `${process.env.REACT_APP_API}/`,
  org: (props: { orgId: string }) => `${process.env.REACT_APP_API}/${props.orgId}`,
  schema: (props: { orgId: string; schemaId: string }) =>
    `${process.env.REACT_APP_API}/${props.orgId}/schema/${props.schemaId}`,
  schemaChildren: (props: { orgId: string; schemaId: string }) =>
    `${process.env.REACT_APP_API}/${props.orgId}/schema/${props.schemaId}/children`,
  entryRaw: (props: { entryId: string; orgId: string; schemaId: string }) =>
    `${process.env.REACT_APP_API}/${props.orgId}/schema/${props.schemaId}/entries/${props.entryId}`,
}

export const WEB_URL = {
  table: (props: { orgId: string; schemaId?: string; parentEntryId?: string }) => {
    const { orgId, schemaId, parentEntryId } = props
    let url = `/${orgId}`

    if (schemaId) {
      url += `/table/${schemaId}`

      if (parentEntryId) {
        url += `/parent-entry/${parentEntryId}/entries`
      } else {
        url += '/entries'
      }
    }

    return url
  },
  entry: (props: { orgId: string; schemaId: string; entryId?: string }) => {
    const { orgId, schemaId, entryId } = props
    const url = `/${orgId}/table/${schemaId}/entries/${entryId}`

    return url
  },
}
