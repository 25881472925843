/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

/* Styled Components ============================================================================ */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwDateButtonGroup: {
      '& .MuiButtonGroup-groupedOutlined': {
        backgroundColor: '#ffffff',
      },
    },
  }),
)
