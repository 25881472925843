export const DEFAULT_SEARCH = {
  sort: {
    createdAt: -1,
  },
  limit: 20,
  page: 1,
  populate: [
    {
      key: 'schema',
      from: 'schema',
    },
    {
      key: 'parent',
      from: 'entries',
    },
    {
      key: 'parent.schema',
      from: 'schema',
    },
  ],
}
