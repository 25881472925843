import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { MapMarkerStyled } from './map-marker.styled'

const MapMarker: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <MapMarkerStyled
      viewBox={'0 0 33 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M17.8303 27.7402C16.7459 29.1272 16 30 16 30C16 30 6 18.299 6 12.5C6 6.70101 10.4772 2 16 2C21.5228 2 26 6.70101 26 12.5C26 15.2663 23.7243 19.3758 21.3442 22.9174"
        className="stroke draw-stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </MapMarkerStyled>
  )
}

export default MapMarker
