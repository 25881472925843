/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

/* Styled Components ============================================================================ */
export const useStyles = makeStyles({
  selectItem: {
    display: 'flex',
    width: '100%',
    // '& .name': {
    //   width: '100%',
    // },
  },
  // animateIcon: {
  //   transform: 'rotate(180deg)',
  //   transition: 'all ease-in-out 0.25s',
  //   transformOrigin: 'center',
  // },
})
