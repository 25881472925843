import Organizations from './components/organizations'
import App from './components/app'
import Table from './components/table'
import Form from './components/form'
import View from './components/view'
import Summary from './components/summary'
import Tutorials from './components/tutorials'

export default [
  {
    Component: Form,
    title: 'Form',
    path: '/:orgId/table/:schemaId/entries/:entryId/edit',
  },
  {
    Component: Form,
    title: 'Form',
    path: '/:orgId/table/:schemaId/:entryId/edit',
  },
  // with parent
  {
    Component: Form,
    title: 'Form',
    path: '/:orgId/table/:schemaId/parent-entry/:parentEntryId/entries/:entryId/edit',
  },
  {
    Component: View,
    title: 'Form',
    path: '/:orgId/table/:schemaId/parent-entry/:parentEntryId/entries/:entryId',
  },
  {
    Component: View,
    title: 'Form',
    path: '/:orgId/table/:schemaId/entries/:entryId',
  },
  {
    Component: Summary,
    title: '',
    path: '/:orgId/table/:schemaId/parent-entry/:parentEntryId/summary/:summarySlug',
    key: 'schemaId',
  },
  {
    Component: Form,
    title: 'Form',
    path: '/:orgId/table/:schemaId/parent-entry/:parentEntryId/new/:templateId',
  },
  {
    Component: Form,
    title: 'Form',
    path: '/:orgId/table/:schemaId/parent-entry/:parentEntryId/new',
  },
  // without parent
  {
    Component: Form,
    title: 'Form',
    path: '/:orgId/table/:schemaId/new/:templateId',
  },
  {
    Component: Form,
    title: 'Form',
    path: '/:orgId/table/:schemaId/new',
  },
  {
    Component: Table,
    title: '',
    path: '/:orgId/table/:schemaId/parent-entry/:parentEntryId',
    key: 'schemaId',
  },
  {
    Component: Summary,
    title: '',
    path: '/:orgId/table/:schemaId/summary/:summarySlug',
    key: 'schemaId',
  },
  {
    Component: Table,
    title: '',
    path: '/:orgId/table/:schemaId',
    key: 'schemaId',
  },
  {
    Component: Tutorials,
    title: 'Tutorials',
    path: '/:orgId/tutorials',
    key: 'schemaId',
  },
  {
    Component: App,
    title: 'Organization',
    path: '/:orgId',
    key: 'orgId',
    hide: true,
  },
  {
    Component: Organizations,
    title: '',
    path: '/',
    key: 'orgId',
  },
]
