import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { HorizontalEllipsisStyled } from './horizontal-ellipsis.styled'

const HorizontalEllipsis: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <HorizontalEllipsisStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <circle
        cx="5"
        cy="16"
        r="3"
        transform="rotate(-90 5 16)"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
      />
      <circle
        cx="16"
        cy="16"
        r="3"
        transform="rotate(-90 16 16)"
        className="stroke draw-stroke-path stroke-path-2"
        strokeWidth="2"
      />
      <circle
        cx="27"
        cy="16"
        r="3"
        transform="rotate(-90 27 16)"
        className="stroke draw-stroke-path stroke-path-3"
        strokeWidth="2"
      />
    </HorizontalEllipsisStyled>
  )
}

export default HorizontalEllipsis
