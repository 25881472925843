import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { SaveStyled } from './save.styled'

const Save: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <SaveStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M2 26V30H30V18M2 20V2H23L30 9V12M7 2H19V9H7V2Z"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 19C22 22.3137 19.3137 25 16 25C12.6863 25 10 22.3137 10 19C10 15.6863 12.6863 13 16 13C17.792 13 19.4006 13.7856 20.5 15.0313"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SaveStyled>
  )
}

export default Save
