/*
 * Import all icon components here
 */
import AddCircle from './add-circle/add-circle-icon'
import Alert from './alert/alert-icon'
import ArrowDown from './arrow-down/arrow-down-icon'
import ArrowLeft from './arrow-left/arrow-left-icon'
import ArrowRight from './arrow-right/arrow-right-icon'
import ArrowUp from './arrow-up/arrow-up-icon'
import Cheque from './cheque/cheque-icon'
import ChevronRight from './chevron-right/chevron-right-icon'
import Close from './close/close-icon'
import CloseCircle from './close-circle/close-circle-icon'
import Comments from './comments/comments-icon'
import ConcretePour from './concrete-pour/concrete-pour-icon'
import ConcretePourReportDetailed from './detailed/concrete-pour/concrete-pour-detailed-icon'
import Construction from './construction/construction-icon'
import Cost from './cost/cost-icon'
import CostReport from './cost-report/cost-report-icon'
import CostReportDetailed from './detailed/cost-report/cost-report-detailed-icon'
import Daily from './daily/daily-icon'
import DailyReportDetailed from './detailed/daily-report/daily-report-detailed-icon'
import Delete from './delete/delete-icon'
import Document from './document/document-icon'
import Download from './download/download-icon'
import Edit from './edit/edit-icon'
import Help from './help/help-icon'
import HorizontalEllipsis from './horizontal-ellipsis/horizontal-ellipsis-icon'
import Info from './info/info-icon'
import Lumber from './lumber/lumber-icon'
import MapLine from './map-line/map-line-icon'
import MapMarker from './map-marker/map-marker-icon'
import Menu from './menu/menu-icon'
import Minus from './minus/minus-icon'
import Notification from './notification/notification-icon'
import Organizations from './organizations/organizations-icon'
import OrganizationsDetailed from './detailed/organizations/organizations-detailed-icon'
import PDF from './pdf/pdf-icon'
import Pencil from './pencil/pencil-icon'
import Plus from './plus/plus-icon'
import Projects from './projects/projects-icon'
import ProjectsDetailed from './detailed/projects/projects-detailed-icon'
import Safety from './safety/safety-icon'
import SafetyReportDetailed from './detailed/safety-report/safety-report-detailed-icon'
import Save from './save/save-icon'
import Schema from './schema/schema-icon'
import SchemaDetailed from './detailed/schema/schema-detailed-icon'
import Search from './search/search-icon'
import Sort from './sort/sort-icon'
import Support from './support/support-icon'
import Upload from './upload/upload-icon'
import User from './user/user-icon'
import Users from './users/users-icon'
import UsersDetailed from './detailed/users/users-detailed-icon'
import VerticalEllipsis from './vertical-ellipsis/vertical-ellipsis-icon'
import View from './view/view-icon'
import ViewDetailed from './detailed/view/view-detailed-icon'

export const Icon: any = {
  AddCircle,
  Alert,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Cheque,
  ChevronRight,
  Close,
  CloseCircle,
  Comments,
  ConcretePour,
  ConcretePourReportDetailed,
  Construction,
  Cost,
  CostReport,
  CostReportDetailed,
  Daily,
  DailyReportDetailed,
  Delete,
  Document,
  Download,
  Edit,
  Help,
  HorizontalEllipsis,
  Info,
  Lumber,
  MapLine,
  MapMarker,
  Menu,
  Minus,
  Notification,
  Organizations,
  OrganizationsDetailed,
  PDF,
  Pencil,
  Plus,
  Projects,
  ProjectsDetailed,
  Safety,
  SafetyReportDetailed,
  Save,
  Schema,
  SchemaDetailed,
  Search,
  Sort,
  Support,
  Upload,
  User,
  Users,
  UsersDetailed,
  VerticalEllipsis,
  View,
  ViewDetailed,
}
