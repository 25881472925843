import React, { createContext, useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { flat } from './share/obj-equal'
import { API_URL, WEB_URL } from './common/url'

/* Hooks =================================================================================== */
import useFetch from './hooks/use-fetch'
import useObject from './hooks/use-object'
import useValue from './hooks/use-value'

/* Components =================================================================================== */
import { Icon } from './modules/icons'
import { NoOrg } from './components/no-org/'
import { formatDate } from './components/date-field'
import { formatPrice } from './common/format'
import { Helmet } from 'react-helmet'

export const APIWrapperContext: any = createContext({
  org: {
    payload: {
      org: {
        logo: [],
      },
    },
  },
  schema: {},
  parentEntry: {},
  childrenSchema: {},
  entry: {},
  layout: {},
})

type schemaAPI = {
  _id?: string
  isChildPerEntry?: boolean
  icon?: string
  name: { plural: string; singular: string }
  parent?: { _id: string; name: { plural: string; singular: string } }
  populateItems?: any[]
  summaries: any[]
  headers?: any[]
}

const APIWrapperComponent = (props: any) => {
  const { children, match } = props
  const { orgId, schemaId, parentEntryId, entryId, summarySlug } = match.params
  const currentUrl = window.location.pathname
  const newPath = match.url.split('/').find((path: any) => path === 'new')
  const editPath = match.url.split('/').find((path: any) => path === 'edit')

  /**
   * app is only request at first page selecting organizations
   * returns array of organization to populate select items
   */
  const [app, $app] = useFetch({}, { url: API_URL.app() })

  const [org, $org] = useFetch(
    {
      org: {
        name: '',
        logo: [],
      },
      schemas: {
        data: [],
      },
      access: {
        isAdmin: false,
      },
    },
    { url: API_URL.org({ orgId }) },
  )

  const [schema, $schema] = useFetch<schemaAPI>(
    {
      name: { plural: '', singular: '' },
      summaries: [],
    },
    {
      url: API_URL.schema({ orgId, schemaId }),
      search: {
        populate: [
          {
            key: 'parent',
            from: 'schema',
          },
        ],
      },
    },
  )

  const [childrenSchema, $childrenSchema] = useFetch<{
    data: schemaAPI[]
  }>(
    {
      data: [],
    },
    {
      url: API_URL.schemaChildren({ orgId, schemaId }),
    },
  )

  const [entry, $entry] = useFetch<any>(
    {},
    {
      url: API_URL.entryRaw({ entryId, orgId, schemaId }),
      search: {
        populate: [
          {
            key: 'schema',
            from: 'entries',
          },
        ],
      },
    },
  )

  const [parentEntry, $parentEntry] = useFetch<any>(
    {},
    {
      url: API_URL.entryRaw({ entryId: parentEntryId, orgId, schemaId }),
    },
  )

  const [users, $users] = useFetch<any>(
    {
      data: [],
    },
    {
      url: `${process.env.REACT_APP_API}/${orgId}/users`,
      search: {
        page: 1,
        limit: 8,
      },
    },
  )
  const [currentHeader, $currentHeader] = useObject({ format: '', name: '' })
  const [mostParentSchemaId, $mostParentSchemaId] = useValue()
  const [setName, $setName] = useObject({})

  useEffect(() => {
    if (app.loaded && org.loaded && childrenSchema.loaded && entry.loaded && parentEntry.loaded) {
      $app.get()

      if (orgId) {
        $org.get()
      }

      if (schemaId) {
        $schema.get({
          search: {
            populate: [
              {
                key: 'parent',
                from: 'schema',
              },
            ],
          },
        })
        $childrenSchema.get()
      }
    }
  }, [app.loaded, org.loaded, childrenSchema.loaded, entry.loaded, parentEntry.loaded])

  useEffect(() => {
    if (app.error && app.error.type == 'NOT_LOGGED_IN') {
      props.history.push('/login')
    }
  }, [app.error])

  useEffect(() => {
    if (entryId && entry.loaded && schema.payload) {
      if (schema.payload.populateItems) {
        $entry.get({
          search: {
            populate: [
              ...schema.payload.populateItems,
              {
                key: 'General Information.costCode',
                from: 'entries',
              },
              {
                key: 'user',
                from: 'users',
              },
            ],
          },
        })
      } else {
        $entry.get({
          search: {
            populate: [
              {
                key: 'General Information.costCode',
                from: 'entries',
              },
              {
                key: 'user',
                from: 'users',
              },
            ],
          },
        })
      }
    }
  }, [schema.payload, entry.loaded])

  useEffect(() => {
    if (parentEntryId && parentEntry.loaded && schema.payload) {
      if (schema.payload.populateItems) {
        $parentEntry.get({
          search: {
            populate: [
              ...schema.payload.populateItems,
              {
                key: 'parent',
                from: 'entries',
              },
              {
                key: 'parent.schema',
                from: 'schema',
              },
              {
                key: 'schema',
                from: 'schema',
              },
            ],
          },
        })
      } else {
        $parentEntry.get({
          search: {
            populate: [
              {
                key: 'parent',
                from: 'entries',
              },
              {
                key: 'parent.schema',
                from: 'schema',
              },
              {
                key: 'schema',
                from: 'schema',
              },
            ],
          },
        })
      }
    }
  }, [schema.payload, parentEntry.loaded])

  useEffect(() => {
    if (users.mounted) {
      $users.get()
    }
  }, [users.mounted])

  /**
   * Set current header using useObject hook
   */
  useEffect(() => {
    if (schema.payload.headers) {
      schema.payload.headers.map((header: any) => {
        $currentHeader.set({ format: header.format, name: header.name })
      })
    }
  }, [schema.payload.headers])

  /**
   *
   * @returns Most parent schema ID
   *
   */
  useEffect(() => {
    if (parentEntry.payload) {
      if (typeof parentEntry.payload.schema !== 'undefined' && parentEntry.payload.schema) {
        $mostParentSchemaId.set(parentEntry.payload.schema._id)
      }
    }
  }, [parentEntry.payload])

  /**
   * Return formatted label. Format date otherwise return current value
   */
  const formatLabel = (value: any, model?: any) => {
    if (value) {
      // Format date YYYY/MM/DD TT:HH
      if (currentHeader.name == model && currentHeader.format == 'date') {
        return formatDate(new Date(value))
      }
      return value
    }
    return
  }

  /**
   *
   * @param model
   * @param data
   * @returns Parsed data from field model
   */
  const parseHeading = (model: any, data: any) => {
    // if (!model.name) {
    //   return undefined
    // }
    const primaryField = flat(data)[model.primaryField]

    if (model.name.plural !== '' && model.name.singular !== '' && data && primaryField) {
      // return `${model.name.singular} / ${primaryField}`
      return formatLabel(primaryField, model.primaryField)
    }
    return
  }

  /**
   *
   * Find and extract current summary
   *
   * @param summaries
   * @param currentSlug
   * @returns Current summary field name
   */
  const parseSummaries = (summaries: any, currentSlug: string) => {
    if (summaries && summaries.length > 0) {
      const currentSummary = summaries.find((item: any) => item.slug === currentSlug)

      return currentSummary.fieldName
    }
  }

  /**
   * Use this to match schema path with current url
   */
  const matchPath = (path: string, matchAdditionalPath?: string) => {
    const optionalPath = typeof matchAdditionalPath !== 'undefined' ? matchAdditionalPath : ''
    return path === `${match.url}${optionalPath}` ? true : false
  }

  const getTitle = () => {
    const title: any = ['JAMS']
    const temp = renderBreadcrumbs()

    temp.map((a: any) => {
      if (a.label !== 'Dashboard') {
        if (a.parentItem) {
          title.push(a.parentItem.label)
        }
        title.push(a.label)
      }
    })

    return title.filter((a: any) => a).join(' | ')
  }

  /**
   * Render breadcrumbs based on path
   */
  const renderBreadcrumbs = () => {
    if (schema.payload) {
      const breadcrumbItems: any = []

      /**
       * Top most parent breadcrumb
       */
      if (schema.payload.name.plural && match.url.split('/').length >= 4) {
        const mostParentItem = {
          type: 'breadcrumb',
          label: 'Dashboard',
          to: WEB_URL.table({ orgId }),
        }
        breadcrumbItems.push(mostParentItem)
      }
      /**
       * Application item breadcrumb
       */
      if (schema.payload.name.plural && !parentEntryId && schema) {
        const applicationItem = {
          type: 'breadcrumb',
          label: `${schema.payload.name.plural}`,
          ...((summarySlug || entryId || newPath || editPath) && {
            to: WEB_URL.table({ orgId, schemaId }),
          }),
        }
        breadcrumbItems.push(applicationItem)
      } else if (parentEntryId && parentEntry.payload.schema) {
        const applicationItem = {
          type: 'breadcrumb',
          label: `${parentEntry.payload.schema.name.plural}`,
          to: WEB_URL.table({ orgId, schemaId: parentEntry.payload.schema._id }),
        }
        breadcrumbItems.push(applicationItem)
      }
      /**
       * Nested nav breadcrumbs
       */
      breadcrumbItems.push(
        ...renderNestedNav('breadcrumbs'),
        ...renderNavigationChildrenPerEntrySchema('breadcrumbs'),
        ...renderNavigationChildrenSchema('breadcrumbs'),
      )

      /**
       *  Summary breadcrumb
       */
      if (schema.payload.name && match.url.split('/').length > 3 && summarySlug) {
        const summaryItem = {
          label: `${parseSummaries(schema.payload.summaries, summarySlug)}`,
        }
        breadcrumbItems.push(summaryItem)
      }

      /**
       * Static breadcrumbs
       */
      if (newPath) {
        const newItem = {
          label: 'Create',
        }
        breadcrumbItems.push(newItem)
      } else if (editPath) {
        const editItem = {
          label: 'Edit',
        }
        breadcrumbItems.push(editItem)
      }

      return breadcrumbItems
    }

    return []
  }

  /**
   * Render property definitions:
   *
   * @type - used to identify different type of nav sections
   * @parentItem - display parent item
   * @hasParent - boolean to identify whether it has a parent & used for indentation
   * @label - text used to display nav item
   * @to - used to link items
   * @isSelected - used to highlight current page based on url path
   * @children - render children items
   *
   */
  /**
   * @return Most Parent nav items
   */
  const renderNavigationApplication = () => {
    return [
      {
        type: 'group',
        label: 'Application',
        children:
          org.payload.schemas &&
          org.payload.schemas.data &&
          org.payload.schemas.data
            .filter((a: any) => !a.hidden)
            .map((orgSchema: any) => {
              if (orgSchema) {
                const AppIcon =
                  orgSchema.icon && Icon[orgSchema.icon] ? Icon[orgSchema.icon] : Icon.Document
                const orgEntriesPath = `/${match.params.orgId}/table/${orgSchema._id}/entries`

                return {
                  _id: orgSchema._id,
                  label: orgSchema.name.plural,
                  order: orgSchema.order,
                  icon: <AppIcon />,
                  to: !matchPath(orgEntriesPath, '/entries') ? orgEntriesPath : null,
                  isSelected: matchPath(orgEntriesPath, '/entries'),
                  schemaItems:
                    orgSchema?._id === schemaId || orgSchema?._id === mostParentSchemaId
                      ? [
                        ...renderNestedNav('sidebar'),
                        ...renderNavigationChildrenPerEntrySchema('sidebar'),
                        ...renderNavigationChildrenSchema('sidebar'),
                      ]
                      : [],
                  summaries:
                    orgSchema?._id === schemaId || orgSchema?._id === mostParentSchemaId
                      ? [...renderNavigationSummaries('sidebar')]
                      : [],
                }
              }
            }),
      },
    ]
  }

  /**
   *
   * @returns Nested nav parent schemas
   *
   */
  const renderNestedNav = (navType?: string) => {
    if (schema.payload && match.url.split('/').length > 3) {
      if (schema.payload.parent || (parentEntry.payload && parentEntryId)) {
        // Conditional icon for nav items
        const CurrentAppIcon =
          schema.payload.icon && Icon[schema.payload.icon]
            ? Icon[schema.payload.icon]
            : Icon.Document

        const entriesPath = `/${orgId}/table/${schemaId}/parent-entry/${parentEntryId}/entries`
        const firstParentSchemaPath = `/${orgId}/table/${mostParentSchemaId}/entries/${parentEntryId}`
        // const entryPath = `/${orgId}/table/${schemaId}/parent-entry/${parentEntryId}/entries/${entryId}`
        return [
          {
            _id: schema.payload._id,
            parentItem: parentEntry.payload.schema
              ? {
                _id: parentEntry.payload._id,
                type: 'parent',
                label: parseHeading(parentEntry.payload.schema, parentEntry.payload),
                hasParent: true,
                to: !matchPath(firstParentSchemaPath) ? firstParentSchemaPath : '',
              }
              : null,
            label: formatLabel(schema.payload.name.plural),
            icon: <CurrentAppIcon />,
            hasParent: true,
            to: !matchPath(entriesPath, '/entries') ? entriesPath : '',
            isSelected: matchPath(entriesPath, '/entries') ? true : false,
            children: [...renderNavigationEntry(navType)],
            summaries: [...renderNavigationSummaries()],
          },
        ]
      } else if (childrenSchema.payload.data.length == 0) {
        return [...renderNavigationEntry(navType)]
      }
    }
    return []
  }

  /**
   *
   * @returns Nav children schema per entry
   *
   */
  const renderNavigationChildrenPerEntrySchema = (navType?: string) => {
    if (schema.payload && match.url.split('/').length > 3) {
      if (
        childrenSchema.payload.data &&
        childrenSchema.payload.data.length > 0 &&
        Object.keys(entry.payload).length > 0
      ) {
        const childrenPerEntry = childrenSchema.payload.data
          .filter((schema: any) => schema.isChildPerEntry)
          .sort((a: any, b: any) => a.order - b.order)
        const entryPath = `/${orgId}/table/${schemaId}/entries/${entryId}`
        // Check isChildPerEntry property is true
        if (childrenPerEntry.length > 0) {
          return [
            {
              _id: schema.payload._id,
              type: 'group',
              label: parseHeading(schema.payload, entry.payload),
              hasParent: true,
              to: !matchPath(entryPath) ? entryPath : '',
              isSelected: matchPath(entryPath),
              children: childrenPerEntry.map((data: any) => {
                if (entryId && data.isChildPerEntry) {
                  // Conditional icon for nav items
                  const AppIcon = data.icon && Icon[data.icon] ? Icon[data.icon] : Icon.Document

                  const childrenEntriesPath = `/${orgId}/table/${data._id}/parent-entry/${entryId}/entries`
                  return {
                    _id: data._id,
                    hasParent: true,
                    isChildPerEntry: data.isChildPerEntry,
                    type: 'child',
                    label: formatLabel(data.name.plural),
                    to: !matchPath(childrenEntriesPath) ? childrenEntriesPath : '',
                    icon: <AppIcon />,
                    order: data.order,
                    isSelected: matchPath(childrenEntriesPath),
                  }
                }
              }),
            },
          ]
        }
      }
    }

    return []
  }

  /**
   *
   * @returns Nav Children schemas
   *
   */
  const renderNavigationChildrenSchema = (navType?: string) => {
    if (schema.payload && match.url.split('/').length > 3) {
      if (
        childrenSchema.payload &&
        childrenSchema.payload.data &&
        childrenSchema.payload.data.length > 0
      ) {
        let isChildPerEntry
        const childrenPerEntry = childrenSchema.payload.data
          // .sort((a: any, b: any) => a.order < b.order)
          .filter((schema: any) => {
            isChildPerEntry = schema.isChildPerEntry

            return !isChildPerEntry
          })
          .sort((a: any, b: any) => a.order - b.order || a.label.localeCompare(b.label))
        // const entryPath = `/${orgId}/table/${schemaId}/entries/${entryId}`
        // const entriesPath = `/${orgId}/table/${schemaId}/parent-entry/${parentEntryId}/entries`
        if (childrenPerEntry.length > 0) {
          return [
            {
              _id: schema.payload._id,
              type: 'group',
              children: childrenPerEntry.map((data: any) => {
                // Conditional icon for nav items
                const AppIcon = data.icon && Icon[data.icon] ? Icon[data.icon] : Icon.Document
                const childrenSchemaPath = parentEntryId
                  ? `/${orgId}/table/${data._id}/parent-entry/${parentEntryId}/entries`
                  : `/${orgId}/table/${data._id}/entries`
                return {
                  _id: data._id,
                  hasParent: true,
                  type: 'child',
                  isChildPerEntry: data.isChildPerEntry,
                  label: formatLabel(data.name.plural),
                  order: data.order,
                  to: !matchPath(childrenSchemaPath, '/entries') ? childrenSchemaPath : '',
                  icon: <AppIcon />,
                  isSelected: matchPath(childrenSchemaPath, '/entries'),
                }
              }),
            },
          ]
        }
      }
    }

    return []
  }

  /**
   *
   * @returns Nav Entry
   *
   */
  const renderNavigationEntry = (navType?: string) => {
    if (schema.payload && match.url.split('/').length > 3) {
      const isStaticPath = currentUrl.split('/').pop() === editPath || newPath
      if (entry.payload && entryId) {
        const entryPath = parentEntryId
          ? `/${orgId}/table/${schemaId}/parent-entry/${parentEntryId}/entries/${entryId}`
          : `/${orgId}/table/${schemaId}/entries/${entryId}`

        return [
          {
            _id: entry.payload?._id,
            hasParent: true,
            type: 'entry',
            label: parseHeading(schema.payload, entry.payload),
            ...(isStaticPath
              ? {
                to: isStaticPath ? entryPath : '',
              }
              : { to: !matchPath(currentUrl) ? entryPath : '' }),
            isSelected: matchPath(currentUrl) && !isStaticPath,
          },
        ]
      }
    }
    return []
  }

  /**
   *
   * @returns Nav Summaries
   *
   */
  const renderNavigationSummaries = (navType?: string) => {
    if (schema.payload && match.url.split('/').length > 3) {
      if (schema.payload.summaries && schema.payload.summaries.length) {
        const summaries: any = []
        const AppIcon =
          schema.payload.icon && Icon[schema.payload.icon]
            ? Icon[schema.payload.icon]
            : Icon.Document

        for (const key in schema.payload.summaries) {
          const schemaPath = parentEntryId
            ? `/${orgId}/table/${schemaId}/parent-entry/${parentEntryId}/summary/${schema.payload.summaries[key].slug}`
            : `/${orgId}/table/${schemaId}/summary/${schema.payload.summaries[key].slug}`

          summaries.push({
            label: formatLabel(schema.payload.summaries[key].fieldName),
            to: !matchPath(schemaPath) ? schemaPath : '',
            icon: <AppIcon />,
            isSelected: matchPath(schemaPath),
            hasParent: parentEntryId ? true : false,
          })
        }

        return [
          {
            type: 'summary',
            label: `${schema.payload.name.plural} Summary`,
            hasParent: parentEntryId ? true : false,
            children: [...summaries],
          },
        ]
      }
    }

    return []
  }

  /**
   * Custom nav items
   */
  const userManagement = {
    _id: '5f873d093dec5954c44ca8c8',
    type: 'custom',
    position: 'bottom',
    label: 'User Management',
    name: {
      plural: 'User Management',
      singular: 'User Management',
    },
    to: !matchPath(`/${orgId}/table/5f873d093dec5954c44ca8c8/entries`, '/entries')
      ? `/${orgId}/table/5f873d093dec5954c44ca8c8/entries`
      : '',
    isSelected: matchPath(`/${orgId}/table/5f873d093dec5954c44ca8c8/entries`, '/entries'),
    icon: <Icon.Users />,
    schemaItems:
      '5f873d093dec5954c44ca8c8' === schemaId || '5f873d093dec5954c44ca8c8' === mostParentSchemaId
        ? [
          ...renderNestedNav('sidebar'),
          ...renderNavigationChildrenPerEntrySchema('sidebar'),
          ...renderNavigationChildrenSchema('sidebar'),
        ]
        : [],
    summaries:
      '5f873d093dec5954c44ca8c8' === schemaId || '5f873d093dec5954c44ca8c8' === mostParentSchemaId
        ? [...renderNavigationSummaries('sidebar')]
        : [],
  }

  /**
   * Custom name
   */
  if (schema.payload && userManagement._id === schemaId) {
    schema.payload.name = {
      plural: userManagement.name.plural,
      singular: userManagement.name.singular,
    }
  }

  if (org.meta.status === 403) {
    return <NoOrg data={org.error} />
  }

  if (app.meta.status === 403) {
    return <NoOrg data={app.error} />
  }

  return (
    <APIWrapperContext.Provider
      value={{
        app,
        org,
        schema: schema,
        parentEntry: parentEntry,
        childrenSchema: childrenSchema,
        layout: {
          headerProps: {
            logo: org.payload.org && org.payload.org.logo && org.payload.org.logo[0],
            breadcrumbsNav:
              org.payload && schema.payload && !schema.meta.isLoading
                ? [...renderBreadcrumbs()]
                : [],
            mostParentSchemaId,
            orgId,
          },
          navbarProps: {
            domain: org.payload.org && org.payload.org.name,
            app,
            orgId,
            items:
              org.payload &&
                schema.payload &&
                !schema.meta.isLoading &&
                (parentEntryId ? !parentEntry.payload.name : true)
                ? [
                  ...renderNavigationApplication(),
                  org.payload &&
                  org.payload.access &&
                  org.payload.access.isAdmin && {
                    ...userManagement,
                  },
                ]
                : [],
          },
        },
      }}
    >
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      {children}
    </APIWrapperContext.Provider>
  )
}

export default withRouter(APIWrapperComponent)
