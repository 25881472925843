import React from 'react'
import { classes } from 'typestyle'

/* Styles ======================================================================================= */
import { boxClass } from './box.class'
import { ComponentBaselineType } from '../__core/component.types'

/* Types ======================================================================================== */
export type BoxProps = {
  tag?: any

  style?: React.CSSProperties
  children?: any
  className?: string

  onClick?: any
  onFocus?: any
  onBlur?: any

  baseline?: ComponentBaselineType
  onlyContrast?: boolean
  alt?: boolean

  alignLeft?: boolean
  alignRight?: boolean
  alignBottom?: boolean
  alignTop?: boolean
  alignCenter?: boolean
  alignCenterX?: boolean
  alignCenterY?: boolean
  spaceBetween?: boolean

  center?: boolean
  centerX?: boolean
  centerY?: boolean
  centerAbsolute?: boolean
  centerAbsoluteX?: boolean
  centerAbsoluteY?: boolean

  fullWidth?: boolean
  fullHeight?: boolean
  relative?: boolean
  sticky?: boolean
  absolute?: boolean
  fixed?: boolean
  flex?: boolean

  maxWidth?: number
  minWidth?: number
  maxHeight?: number
  minHeight?: number

  scroll?: boolean
  border?: boolean
  borderContrast?: 'light' | 'medium' | 'dark'
  shadow?: boolean
  shadowContrast?: 'light' | 'medium' | 'dark'
  rounded?: boolean
  overflow?: boolean
  row?: boolean
  column?: boolean
}

const defaultProps: {
  baseline?: ComponentBaselineType
  onlyContrast?: boolean
  borderContrast?: 'light' | 'medium' | 'dark'
  shadowContrast?: 'light' | 'medium' | 'dark'
} = {
  baseline: 'surface',
  onlyContrast: false,
  borderContrast: 'medium',
  shadowContrast: 'medium',
}

// class BoxPresetController<P> {
//   presets: P | undefined

//   setPreset<T>(preset: T): T {
//     // (this.presets as T) = preset

//     return preset
//   }
// }

// const BoxPreset = new BoxPresetController()

/* <Box /> ================================================================================= */
const BoxComponent: React.FC<BoxProps> = props => {
  const {
    tag = <div />,
    className,
    onClick,
    onFocus,
    onBlur,
    children,
    style,
    alt,
    baseline,
    onlyContrast,
    fullWidth,
    fullHeight,
    border,
    borderContrast,
    rounded,
    shadow,
    shadowContrast,
    centerX,
    centerY,
    centerAbsoluteX,
    centerAbsoluteY,
    alignLeft,
    alignRight,
    alignCenterX,
    alignCenterY,
    alignBottom,
    alignTop,
    spaceBetween,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    row,
    column,
    flex,
    sticky,
    relative,
    scroll,
  } = props

  const { base, flexed, themed, boxed } = boxClass.setProps({
    alt,
    baseline,
    onlyContrast,

    fullWidth,
    fullHeight,

    border,
    borderContrast,
    rounded,
    shadow,
    shadowContrast,
    centerX,
    centerY,
    centerAbsoluteX,
    centerAbsoluteY,
    alignLeft,
    alignRight,
    alignCenterX,
    alignCenterY,
    alignBottom,
    alignTop,
    spaceBetween,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    row,
    column,
    flex,
    sticky,
    relative,
    scroll,
  })

  return React.cloneElement(tag, {
    className: classes('www-box', base, themed, flexed, boxed, className),
    children,
    onClick,
    style,
    onBlur,
    onFocus,
    ...tag.props,
  })
}

BoxComponent.defaultProps = defaultProps

/* Export ======================================================================================= */
export const Box = BoxComponent
