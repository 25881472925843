import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../../exports'

/* Styles ======================================================================================== */
import { UsersDetailedStyled } from './users-detailed.styled'

const UsersDetailed: FC<IconProps> = props => {
  const { size = 'xxl', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <UsersDetailedStyled
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M31.6573 32.9501V30.6692C31.6573 25.6302 27.5724 21.5453 22.5334 21.5453C17.4945 21.5453 13.4096 25.6302 13.4096 30.6692V32.9501C15.3083 35.116 18.9662 35.9434 22.5311 35.9439C26.0959 35.9443 29.661 34.9464 31.6573 32.9501Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M28.2359 14.7024C28.2359 17.8518 25.6828 20.4048 22.5334 20.4048C19.3841 20.4048 16.831 17.8518 16.831 14.7024C16.831 11.5531 19.3841 9 22.5334 9C25.6828 9 28.2359 11.5531 28.2359 14.7024Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M15.6771 27.0499V25.7663C15.6771 22.9307 13.3784 20.6319 10.5428 20.6319C7.70712 20.6319 5.40839 22.9307 5.40839 25.7663V27.0499C6.47685 28.2687 8.53534 28.7344 10.5414 28.7346C12.5475 28.7349 14.5537 28.1733 15.6771 27.0499Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M13.7517 16.7812C13.7517 18.5534 12.315 19.9901 10.5428 19.9901C8.77048 19.9901 7.33377 18.5534 7.33377 16.7812C7.33377 15.0089 8.77048 13.5722 10.5428 13.5722C12.315 13.5722 13.7517 15.0089 13.7517 16.7812Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M39.6407 27.4262V26.1426C39.6407 23.307 37.3419 21.0083 34.5063 21.0083C31.6707 21.0083 29.3719 23.307 29.3719 26.1426V27.4262C30.4404 28.645 32.4989 29.1107 34.505 29.1109C36.511 29.1112 38.5173 28.5496 39.6407 27.4262Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M37.7153 17.1575C37.7153 18.9297 36.2786 20.3665 34.5063 20.3665C32.734 20.3665 31.2973 18.9297 31.2973 17.1575C31.2973 15.3852 32.734 13.9485 34.5063 13.9485C36.2786 13.9485 37.7153 15.3852 37.7153 17.1575Z"
        className="fill-bg primary-fill-color"
      />

      <path
        d="M37 9H38"
        className="stroke-bg stroke-bg-1 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 13H45"
        className="stroke-bg stroke-bg-2 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 31.1761H4"
        className="stroke-bg stroke-bg-3 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 37.1761H13"
        className="stroke-bg stroke-bg-4 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M18.9209 36.7408C18.1965 36.3508 17.5537 35.8901 17.0222 35.3585V33.0776C17.0222 28.0386 21.1071 23.9537 26.146 23.9537C31.185 23.9537 35.2699 28.0386 35.2699 33.0776V35.3585C33.2736 37.3548 29.7084 38.3527 26.1436 38.3523"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8484 17.1108C30.8484 19.7079 28.7431 21.8132 26.146 21.8132V23.8132C29.8477 23.8132 32.8484 20.8124 32.8484 17.1108H30.8484ZM26.146 21.8132C23.549 21.8132 21.4436 19.7079 21.4436 17.1108H19.4436C19.4436 20.8124 22.4444 23.8132 26.146 23.8132V21.8132ZM21.4436 17.1108C21.4436 14.5137 23.549 12.4084 26.146 12.4084V10.4084C22.4444 10.4084 19.4436 13.4092 19.4436 17.1108H21.4436ZM26.146 12.4084C28.7431 12.4084 30.8484 14.5137 30.8484 17.1108H32.8484C32.8484 13.4092 29.8477 10.4084 26.146 10.4084V12.4084ZM40.3287 19.1779C40.3287 20.3972 39.3403 21.3855 38.1211 21.3855V23.3855C40.4449 23.3855 42.3287 21.5017 42.3287 19.1779H40.3287ZM38.1211 21.3855C36.9019 21.3855 35.9135 20.3972 35.9135 19.1779H33.9135C33.9135 21.5017 35.7973 23.3855 38.1211 23.3855V21.3855ZM35.9135 19.1779C35.9135 17.9587 36.9019 16.9703 38.1211 16.9703V14.9703C35.7973 14.9703 33.9135 16.8541 33.9135 19.1779H35.9135ZM38.1211 16.9703C39.3403 16.9703 40.3287 17.9587 40.3287 19.1779H42.3287C42.3287 16.8541 40.4449 14.9703 38.1211 14.9703V16.9703ZM9.96336 19.1779C9.96336 21.5017 11.8472 23.3855 14.171 23.3855V21.3855C12.9517 21.3855 11.9634 20.3972 11.9634 19.1779H9.96336ZM14.171 23.3855C16.4948 23.3855 18.3786 21.5017 18.3786 19.1779H16.3786C16.3786 20.3972 15.3902 21.3855 14.171 21.3855V23.3855ZM18.3786 19.1779C18.3786 16.8541 16.4948 14.9703 14.171 14.9703V16.9703C15.3902 16.9703 16.3786 17.9587 16.3786 19.1779H18.3786ZM14.171 14.9703C11.8472 14.9703 9.96336 16.8541 9.96336 19.1779H11.9634C11.9634 17.9587 12.9517 16.9703 14.171 16.9703V14.9703Z"
        className="fill stroke-path"
      />
      <path
        d="M17.875 24.6172C17.875 24.6172 16.4688 23 14.1328 23C10.0312 23.2344 9 26.5625 9 28.1328V29.4141C10.25 30.7813 12.8438 31.1016 14 31.1016"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3783 24.6172C34.3783 24.6172 35.7845 23 38.1205 23C42.222 23.2344 43.2533 26.5625 43.2533 28.1328V29.4141C42.0033 30.7813 39.4095 31.1016 38.2533 31.1016"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </UsersDetailedStyled>
  )
}

export default UsersDetailed
