/**
 * related to Something.file > Category A
 */
import React, { useEffect, FC, useContext } from 'react'
import { withRouter, useHistory } from 'react-router-dom'

/* Routes ======================================================================================= */
import routes from '..'

/* Hooks ======================================================================================== */
import useAxios, { parseStates } from '../../../hooks/use-axios'
import useValue from '../../../hooks/use-value'

/* Components =================================================================================== */
import AppLayout from '../layout'
// import { DialogWrapper } from '../../../modules/dialog'
// import { TextField } from '../../../modules/text-field'
// import { Form } from '../../../modules/form'
// import { Field } from '../../../modules/field'
// import { Tabs, Tab } from '../../../modules/tab'
// import { Submit } from '../../../modules/submit'
// import { RepeaterField } from '../../../modules/repeater-field'
import { renderForm } from '../../../components/render-form'
// import { Search } from '../../../modules/search'
import { FormTemplate } from '../../../components/form/tab-template'
// import { Box } from '../../../modules/box/box.component'
import SET from '../../../styles/set'
// import { classes } from 'typestyle'
// import { Border } from '../../../modules/border/border.component'
import { Button } from '../../../modules/button'
// import { Icon } from '../../../modules/icons'
import { StaticField } from '../../../modules/static-field'
import { APIWrapperContext } from '../../../api-wrapper'
import useFetch from '../../../hooks/use-fetch'
import { NoAuth } from '../../../components/no-auth'
import { TutorialsModal } from '../../../components/tutorials/modal'

/* Material UI ======================================================================================== */
// Core
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

// Lab
import TabContext, { useTabContext } from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

/* <AppForm /> =============================================================================== */
const AppForm: FC<{ match: any }> = ({ match }) => {
  const { orgId, schemaId, templateId, parentEntryId } = match.params as any
  const { layout, org, schema, childrenSchema } = useContext(APIWrapperContext)

  const [template, $template] = useFetch({}, {})

  const [schema2, $schema2] = useFetch<{ populateItems?: any[] }>(
    {},
    {
      url: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}`,
      updateGetParams: false,
    },
  )

  const [entry, $entry] = useFetch<{}>(
    {},
    {
      updateGetParams: false,
    },
  )

  const [accessible, $accessible] = useFetch<{}>(
    {},
    {
      updateGetParams: false,
    },
  )

  const [tabNumber, $setTabNumber] = useValue('0')
  const [saved, $saved] = useValue(false)

  const classes = useStyles()

  useEffect(() => {
    if ($schema2.get && schema2.loaded) {
      // console.log('loaded?')
      $schema2.get()
    }
  }, [$schema2.get, schema2.loaded])

  useEffect(() => {
    if (accessible.loaded && $accessible.get) {
      if (entryId) {
        $accessible.get({
          url: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}/edit`,
        })
      } else {
        $accessible.get({
          url: `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/new`,
        })
      }
    }
  }, [accessible.loaded, $accessible.get])

  useEffect(() => {
    if (Object.keys(schema2.payload).length > 0 && $entry.get && entry.loaded) {
      if (entryId) {
        const entryUrl = entryId
          ? `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}`
          : parentEntryId
            ? `${process.env.REACT_APP_API}/${orgId}/entries/schema/${schemaId}/parent-entry/${parentEntryId}`
            : `${process.env.REACT_APP_API}/${orgId}/entries/schema/${schemaId}`

        $entry.get({
          url: entryUrl,
          search: { populate: schema2.payload.populateItems },
        })
      }
    }
  }, [schema2.payload, $entry.get, entry.loaded])

  const { entryId } = match.params
  const [entries, $entries] = useFetch(
    {},
    {
      url: entryId
        ? `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries/${entryId}`
        : parentEntryId
          ? `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/parent-entry/${parentEntryId}/entries`
          : `${process.env.REACT_APP_API}/${orgId}/schema/${schemaId}/entries`,
    },
  )

  const { error } = entries

  const { schemaData } = parseStates(
    {
      entryData: entries,
      schemaData: schema,
    },
    {
      result: {},
    },
  )

  const { templateData } = parseStates(
    {
      templateData: template,
    },
    {
      data: null,
    },
  )

  const history = useHistory()

  useEffect(() => {
    if (Object.keys(schema2.payload).length > 0 && templateId && $template.get && template.loaded) {
      if (schema2.payload.populateItems) {
        const populate = schema2.payload.populateItems.map((item: any) => {
          return {
            ...item,
            key: `data.${item.key}`,
          }
        })

        $template.get({
          url: `${process.env.REACT_APP_API}/templates/${templateId}`,
          search: {
            populate,
          },
        })
      }
    }
  }, [$template.get, template.loaded, schema2.payload])

  useEffect(() => {
    if (entryId) {
      // $entries.get()
    }
  }, [schemaData.payload])

  const parseStatusValue = (stages: any) => {
    if (!entryId) {
      if (schemaData.payload.stages) {
        return stages.find((a: any) => a.isDefault)
      }
    }
  }

  const parseTemplate = (templateValue: any) => {
    if (templateValue) {
      delete templateValue.stage
      return {
        ...templateValue,
        stage: parseStatusValue(schemaData.payload.stages),
      }
    }
  }

  const handleTabChange = (event: any, newValue: number) => {
    $setTabNumber.set(newValue)
  }

  const renderAction = (
    <>
      <Box>
        <Button label="Cancel" onClick={history.goBack} />
      </Box>
      <Box ml={1}>
        <Button label="Save" type="submit" color="secondary" variant="contained" onClick={(e: any) => {
          if (saved) {
            e.preventDefault()
          } else {
            $saved.set(true)
          }
        }} />

      </Box>
    </>
  )

  if (accessible.meta.status === 403) {
    return <NoAuth data={accessible.error} />
  }

  return (
    <>
      <FormTemplate
        AppLayout={AppLayout}
        config={{
          name: schemaData.payload.name || {},
          primaryField: schemaData.payload?.primaryField,
          description: '',
        }}
        loading={entryId ? entry.meta.isLoading : false}
        layoutProps={layout}
        formProps={{
          name: 'entries',
          values: parseTemplate(templateData.payload.data) || (entry.payload as any),
          onSubmit: async (data: any) => {
            if (saved) {
              delete data._id
              const res: any = {}

              if (data.createdBy && data.createdBy._id) {
                data.createdBy = data.createdBy._id
              }

              if (data.updatedBy && data.updatedBy._id) {
                data.updatedBy = data.updatedBy._id
              }

              if (entryId) {
                data.schema = data.schema && data.schema._id ? data.schema._id : data.schema
                if (data.parent) {
                  data.parent = data.parent._id ? data.parent._id : data.parent
                }

                if (data.organization) {
                  data.organization = data.organization._id
                    ? data.organization._id
                    : data.organization
                }

                $entries.post({ body: data }).then(() => {
                  history.goBack()
                }).catch(() => {
                  $saved.set(false)
                })
              } else {
                $entries.post({ body: data }).then(() => {
                  history.goBack()
                }).catch(() => {
                  $saved.set(false)
                })
              }
            }
          },
        }}
        renderAction={renderAction}
      >
        <StaticField name="stage" value={parseStatusValue(schemaData.payload.stages)} />
        {(() => {
          if (schema.payload && schema.payload.fields && schema.payload.fields.length > 0) {
            return (
              <Box p={3}>
                {schema.payload.fields.map((field: any, key2: any) => {
                  return renderForm(field, key2, null, `${process.env.REACT_APP_API}/app`, orgId)
                })}
              </Box>
            )
          } else if (
            schema.payload &&
            schema.payload.sections &&
            schema.payload.sections.length > 0
          ) {
            return (
              <Box bgcolor="#ffffff" border={1} borderColor="grey.300">
                <TabContext value={tabNumber}>
                  <TabList
                    className={classes.wwwTabList}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                  >
                    {schema &&
                      schema.payload.sections &&
                      schema.payload.sections.map((section: any = { fields: [] }, key: any) => {
                        return (
                          <Tab
                            label={`${key + 1} - ${section.name}`}
                            value={key.toString()}
                            key={key}
                          />
                        )
                      })}
                  </TabList>
                  <Divider style={{ marginTop: '-1px' }} />
                  {schema &&
                    schema.payload.sections &&
                    schema.payload.sections.map((section: any = { fields: [] }, key: any) => {
                      return (
                        <Box
                          className={classes.wwwTabPanel}
                          key={key}
                          p={2}
                          pb={4}
                          pt={4}
                          hidden={tabNumber == key ? false : true}
                        >
                          {section.fields &&
                            section.fields.map((field: any, key2: any) => {
                              // console.log(field)
                              return renderForm(
                                field,
                                key2,
                                section.name,
                                `${process.env.REACT_APP_API}/app`,
                                orgId,
                              )
                            })}
                        </Box>
                      )
                    })}
                </TabContext>
              </Box>
            )
          }
        })()}
      </FormTemplate>
      <TutorialsModal
        className={classes.wwwTutorialButton}
        match={match}
        name={schema.payload ? schema.payload.name : { plural: '', singular: '' }}
      />
    </>
  )
}

export default withRouter(AppForm)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwTabList: {
      '& .MuiTabs-scrollButtons': {
        width: theme.spacing(3),
      },
    },
    wwwTabPanel: {
      '& .www-field-item:last-child': {
        marginBottom: 0,
      },
    },
    wwwTutorialButton: {
      marginBottom: '48px',
    },
  }),
)

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     wwwFieldItem: {
//       '&:last-child': {
//         marginBottom: '0',
//       },
//     },
//   }),
// )
