import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { blue, blueGrey } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwTd: {
    },
    wwwTableRow: {
      cursor: (props: any) => (props.handleSelect ? 'pointer' : 'default'),
      '&:hover': {
        backgroundColor: 'rgb(236 239 241 / 70%)',
      }
    },
    wwwTableRowHighlight: {
      backgroundColor: 'rgb(236 239 241 / 70%)',
    }
  }),
)

export default useStyles
