/* Material UI  ======================================================================================== */
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

/* Styled Components ============================================================================ */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      pointerEvents: 'none',
    },
  }),
)
