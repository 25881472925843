/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwTabList: {
      '& .MuiTabs-scrollButtons': {
        width: theme.spacing(3),
      },
    },
    wwwTabPanel: {
      '& .www-box-item:last-child': {
        marginBottom: 0,
        paddingBottom: 0,
        borderBottom: 0,
      },
    },
    wwwRepeaterBoxItem: {
      '& .www-repeater-box-item:last-child': {
        marginBottom: 0,
      },
    },
    wwwDivider: {
      marginTop: '-1px',
    },
  }),
)
