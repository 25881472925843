import React, { FC } from 'react'
import { IconProps, SIZES, SVG as SVGTEMP } from '../exports'
import styled from 'styled-components'

/* Styles ======================================================================================== */
import { SortStyled } from './sort.styled'

const Sort: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, index, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <SortStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        className={`class-${index}`}
        d="M1 16L16 16L31 16"
        stroke="#383649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SortStyled>
  )
}

const SVG = styled(SVGTEMP)`
  path {
    transition: d 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }

  path.class-0 {
    d: path('M28 16L16 16L4 16');
  }

  path.class-1 {
    d: path('M28 20L16 8L4 20');
  }

  path.class-2 {
    d: path('M28 12L16 24L4 12');
  }

  &:active:hover {
    transform: scale(0.8);
  }
`

export default Sort
