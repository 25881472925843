import Cookies from 'js-cookie'
import React, { FC, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useValue from '../../../hooks/use-value'
import useFetch, { parseSearch } from '../../../hooks/use-fetch'

/* Components =================================================================================== */
import AuthenticationLayout from './authentication.layout'
// import { Text } from '../../../modules/text/text.component'
import { Social } from '../../../modules/social-signin'
import { Select } from '../../../modules/select/select.component'
import useBoolean from '../../../hooks/use-boolean'
import { Icon } from '../../../modules/icons'

/* Material UI  ======================================================================================== */
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

/* <LoginForm /> ================================================================================ */
const LoginForm = () => {
  const [selected, $selected] = useValue(null)
  const [loading, $loading] = useBoolean(false)
  const history = useHistory()
  const queryParams = parseSearch(history.location, null)

  const [users, $users] = useFetch(
    { token: '' },
    { url: `${process.env.REACT_APP_API}/users/signin` },
  )
  const [orgs, $orgs]: any = useFetch({}, { url: `${process.env.REACT_APP_API}/orgs` })

  useEffect(() => {
    if (users.payload && users.payload.token) {
      Cookies.set('token', users.payload.token)
      history.replace('/')
    }
  }, [users.payload])

  useEffect(() => {
    if (orgs.loaded && $orgs.get) {
      $orgs.get()
    }
    if (users.loaded && $users.post) {
      if (queryParams && queryParams.hasOwnProperty('code') && queryParams.hasOwnProperty('org')) {
        if (!loading) {
          signInWithtoken(queryParams.code, queryParams.org)
          $loading.set(true)
        }
      }
    }
  }, [users.loaded, $users.post, orgs.loaded, $orgs.get])

  const signInWithtoken = async (code: string, org: string) => {
    const res = await $users.post({
      body: {
        code: code,
        type: 'office',
        org: org,
      },
    })

    return res
  }
  const organizations = orgs.payload.data
  const orgData: any = []
  if (organizations && organizations.length) {
    organizations.map((val: any) => {
      if (val.officeTenetKey && val.officeClientKey) {
        orgData.push({
          name: val.name,
          tenetId: val.officeTenetKey,
          clientId: val.officeClientKey,
        })
      }
    })
  }

  return (
    <AuthenticationLayout title="" subtitle="">
      {organizations && organizations.length ? (
        <Select
          placeholder="Select sign in option"
          label="Select Organization"
          options={orgData}
          renderValue={(option: any) => <Typography>{option.name}</Typography>}
          renderOption={(option: any) => <Typography>{option.name}</Typography>}
          onChange={val => {
            if (val) {
              $selected.set(val)
            }
          }}
        />
      ) : (
        <Box textAlign="center">
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Box>
      )}
      {selected ? (
        <Social
          label="Sign in with Microsoft"
          tenetId={selected.tenetId}
          clientId={selected.clientId}
          redirectUri={
            (process.env.REACT_APP_OFFICE_REDIRECT_URL || 'http://localhost:3000/login') +
            `?org=${selected.name}`
          }
          type="office"
        />
      ) : (
        <></>
      )}
    </AuthenticationLayout>
  )
}

export default LoginForm
