import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { blue, blueGrey } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwTh: {
      backgroundColor: blueGrey[100],
      '&.MuiTableCell-stickyHeader': {
        top: theme.spacing(7),
      },
      '& .MuiTableSortLabel-root': {
        '&.MuiTableSortLabel-active': {
          color: theme.palette.text.secondary,
          '& .MuiTableSortLabel-icon': {
            color: 'inherit !important'
          }
        }
      }
    },
  }),
)

export default useStyles
