import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { NotificationStyled } from './notification.styled'

const Notification: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }

  return (
    <NotificationStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M8 13C8 8.58172 11.5817 5 16 5C20.4183 5 24 8.58172 24 13V20L27 22V24H5V22L8 20V19M19 27C19 28.6569 17.6569 30 16 30C14.3431 30 13 28.6569 13 27H19ZM15.5 2H16.5C17.3284 2 18 2.67157 18 3.5C18 4.32843 17.3284 5 16.5 5H15.5C14.6716 5 14 4.32843 14 3.5C14 2.67157 14.6716 2 15.5 2Z"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </NotificationStyled>
  )
}

export default Notification
