import React, { FC } from 'react'
import styled from 'styled-components'
import { stylesheet } from 'typestyle'

/* Components =================================================================================== */
// import { Box } from '../../../modules/box/box.component'
import { Field } from '../../../modules/field'
import { Form } from '../../../modules/form'
import { GLOBAL_COMPONENTS } from '../../../global-components'
import { Layout, layoutStyles } from '../../../modules/layout'
import { Text } from '../../../modules/text'

/* Styles ======================================================================================= */
import SET from '../../../styles/set'

/* Material UI  ======================================================================================== */
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

/* <AuthenticationLayout /> ===================================================================== */
const AuthenticationLayout: FC<{ children: JSX.Element[]; title: string; subtitle?: string }> = ({
  children,
  title,
  subtitle,
}) => {
  return (
    // <Layout className={STYLES.mainContainer} xs={{ size: 100, row: false }}>
    //   <Layout xs={{ size: 100, row: true }} className={STYLES.formContainer}>
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <Container maxWidth="xs">
        <Box pt={4} pb={4}>
          {children}
        </Box>
      </Container>
    </Box>
    //   </Layout>
    // </Layout>
  )
}

/* Styled Component ============================================================================= */
// const Container = styled.div`
//   min-width: 320px;

//   ${layoutStyles({
//     sm: `
//       min-width: 100%;
//       padding: 16px;
//     `,
//   })}
// `

// const LogoContainer = styled.div`
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   width: 100%;

//   ${Text.h6} {
//     margin-left: 8px;
//   }
// `

export const StyledForm = styled(Form)`
  align-items center;
  display: flex;
  width: 100%;

  ${Field} {
    margin-bottom: 8px;
  }

  ${Text.p3} {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    a {
      margin-left: 4px;
    }
  }
`

/* Typestyle ==================================================================================== */
const STYLES = stylesheet({
  mainContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
  },
  imageContainer: {
    alignItems: 'center',
    backgroundColor: '#AEC6CF',
    color: 'white',
    height: '100vh',
    justifyContent: 'center',
  },
  formContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'center',
  },
})

export default AuthenticationLayout
