import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { DeleteStyled } from './delete.styled'

const Delete: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <DeleteStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M6.34375 14.875L6.00781 10.1328H25.9766L24.6562 29.0703H7.35938L6.67188 19.6094"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4545 14.4119L11.4545 24.7756"
        className="stroke draw-stroke-path stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 15.2091L16 23.9784"
        className="stroke draw-stroke-path stroke-path-3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5455 14.4119L20.5455 24.7756"
        className="stroke draw-stroke-path stroke-path-4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8V6ZM29 8C29.5523 8 30 7.55228 30 7C30 6.44772 29.5523 6 29 6V8ZM12.5179 3V2C12.0729 2 11.6815 2.29399 11.5575 2.72132L12.5179 3ZM19.4821 3L20.4425 2.72132C20.3185 2.29399 19.9271 2 19.4821 2V3ZM19.6825 7.27868C19.8364 7.80909 20.3911 8.1143 20.9215 7.96038C21.4519 7.80647 21.7572 7.25172 21.6032 6.72132L19.6825 7.27868ZM10.3968 6.72132C10.2428 7.25172 10.5481 7.80647 11.0785 7.96038C11.6089 8.1143 12.1636 7.80909 12.3175 7.27868L10.3968 6.72132ZM3 8H29V6H3V8ZM12.5179 4H19.4821V2H12.5179V4ZM18.5218 3.27868L19.6825 7.27868L21.6032 6.72132L20.4425 2.72132L18.5218 3.27868ZM12.3175 7.27868L13.4782 3.27868L11.5575 2.72132L10.3968 6.72132L12.3175 7.27868Z"
        className="fill draw-stroke-path stroke-path-5"
      />
    </DeleteStyled>
  )
}

export default Delete
