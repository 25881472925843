const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const PM = 'PM'
const AM = 'AM'

/**
 * Parse value with given unit
 */
export const formatUnit = (value: number, unit: string) => {
  return `${value} ${unit}`
}

/**
 * Format float to given precision
 */
export const formatFloat = (value: string, fixed: number) => {
  if (value == null) {
    return null
  }

  return parseFloat(value).toFixed(fixed)
}

/**
 * Turn float to formatted price
 */
export const formatPrice = (price: string | number, sign = true) => {
  return `${sign ? '$' : ''}${Number(price)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`
}

/**
 * Turn boolean in to yes or no
 */
export const formatBool = (bool: boolean) => {
  return bool ? 'Yes' : 'No'
}

export const getCost = (cost: any[], date: string) => {
  const today = date ? new Date(date) : new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)

  cost.sort((a: any, b: any) => {
    if (new Date(a.value.dateInEffect).getTime() < new Date(b.value.dateInEffect).getTime()) {
      return 1
    } else {
      return -1
    }
  })

  for (let i = 0; i < cost.length; i++) {
    const dateInEffect = new Date(cost[i].value.dateInEffect).getTime()

    if (dateInEffect <= today.getTime()) {
      return cost[i].value.cost
    }
  }

  return cost[cost.length - 1].value.cost
}

export const formatDate = (dateString: string, display: any) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const monthIndex = date.getMonth()

  display = {
    year: true,
    month: true,
    day: true,
    time: false,
    seconds: false,
    ...display,
  }

  let day: any = date.getDate()
  let hours: any = date.getHours()
  let minutes: any = date.getMinutes()
  let seconds: any = date.getSeconds()
  let meridiem = AM
  let formattedDate = ''

  if (hours > 12) {
    hours = Math.round(hours - 12)
    meridiem = PM
  }

  if (day < 10) {
    day = `0${day}`
  }

  if (hours < 10) {
    hours = `0${hours}`
  }

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  if (display.month) {
    formattedDate += `${MONTH_NAMES[monthIndex]} `
  }
  if (display.day) {
    formattedDate += `${day}, `
  }
  if (display.year) {
    formattedDate += `${year} `
  }
  if (display.time) {
    if (display.seconds) {
      formattedDate += `${hours}:${minutes}:${seconds} ${meridiem}`
    } else {
      formattedDate += `${hours}:${minutes} ${meridiem}`
    }
  }

  return formattedDate
}

export const formatCostCode = (category: any, code: any, description: any, type = '') => {
  let costCode
  const newCategory = category > 10 ? category : category.toString().padStart(2, '0')
  const newCode = code > 1000 ? code : code.toString().padStart(4, '0')
  switch (type) {
    case 'costCode':
      costCode = `${newCategory}.${newCode}`
      return costCode
    case 'costCodeDesc':
      costCode = `${newCategory}.${newCode} - ${description}`
      return costCode
    default:
      costCode = `${newCategory}.${newCode} - ${description}`
      return costCode
  }
}
