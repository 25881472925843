/**
 * Flat deep object to a single deep
 * @param obj
 */
export const flat = (obj: { [key: string]: any }, exclude?: any[]) => {
  const output: { [key: string]: any } = {}

  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue

    // console.log(i)
    if (typeof obj[i] == 'object' && obj[i] !== null) {
      const flattenObj = flat(obj[i])

      if (Object.keys(flattenObj).length === 0) {
        output[i] = flattenObj
      } else {
        for (const x in flattenObj) {
          if (!flattenObj.hasOwnProperty(x)) continue

          // if (!(exclude && exclude.includes(i + '.' + x))) {
          output[i + '.' + x] = flattenObj[x]
          // }
        }
      }
    } else {
      output[i] = obj[i]
    }
  }

  if (exclude) {
    exclude.map(key => {
      let toStructure: any
      let parentKey: any

      for (const key2 in output) {
        const parentMatch = key2.match(key)
        if (parentMatch) {
          parentKey = parentMatch[0]

          if (!toStructure) {
            toStructure = {}
          }

          if (!toStructure[parentKey]) {
            toStructure[parentKey] = {}
          }

          toStructure[parentKey][key2.split(key)[1].replace('.', '')] = output[key2]

          delete output[key2]
        }
      }

      Object.assign(output, toStructure)
    })
  }

  return output
}

/**
 * Compare two single deep object
 * @param obj1
 * @param obj2
 */
export const objEqual = (obj1: any, obj2: any) => {
  obj1 = flat(obj1)
  obj2 = flat(obj2)

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        return false
      }
    }
  }
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        return false
      }
    }
  }
  return true
}

export const isObject: (item: any) => boolean = item => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export const isArray = (obj: { [key: string]: any } = {}) => {
  if (!obj || Object.keys(obj).length === 0) {
    return false
  }

  if (obj && typeof obj === 'object') {
    return !isNaN(Object.keys(obj).reduce((a: any, b: any) => parseInt(a) + parseInt(b), 0))
  }

  return false
}

export const convertToArray = (obj: any) => {
  const indexes = Object.keys(obj).sort((a, b) => parseInt(b) - parseInt(a))
  // console.log(indexes)
  const toReturn = []
  for (let i = 0; i < parseInt(indexes[0]) + 1; i++) {
    // console.log('loop', i, obj[`${i}`])
    if (!obj[`${i}`]) {
    } else {
      toReturn[i] = obj[`${i}`]
    }
  }
  return toReturn
}

const structureCallback: (obj: any, keys: any, value: any) => any = (obj, keys, value) => {
  if (keys.length > 1) {
    const currentKey = keys.shift()

    if (!obj) {
      obj = {}
    }

    obj[currentKey] = { ...obj[currentKey], ...structureCallback(obj[currentKey], keys, value) }

    if (isArray(obj[currentKey])) {
      obj[currentKey] = convertToArray(obj[currentKey])
    }
  } else {
    if (obj) {
      obj[keys[0]] = value
    } else {
      obj = {
        [keys[0]]: value,
      }
    }
  }

  if (isArray(obj)) {
    return convertToArray(obj)
  }

  return obj
}

export const structureArray = (obj: { [key: string]: any }) => {
  const newObj = obj
  for (const key in newObj) {
    if (isObject(newObj[key])) {
      structureArray(newObj[key])
    }

    if (isArray(newObj[key])) {
      newObj[key] = convertToArray(newObj[key])
    }

    if (isArray(newObj[key])) {
      newObj[key] = structureArray(newObj[key])
      newObj[key] = convertToArray(newObj[key])
    }
  }

  return newObj
}

export const structureObj = (obj: { [key: string]: any }) => {
  const structuredObj = {}
  const keys = Object.keys(obj).sort(function(a, b) {
    return b.length - a.length || a.localeCompare(b)
  })

  keys.map((key: string) => {
    const keys = key.split('.')
    structureCallback(structuredObj, keys, obj[key])
  })

  // for (const key in obj) {
  //   const keys = key.split('.')
  //   structureCallback(structuredObj, keys, obj[key])
  // }

  return structuredObj
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const merge: (target: any, source: any) => any = (target, source) => {
  const flatTarget = flat(target)
  const flatSource = flat(source)

  const merged = { ...flatTarget, ...flatSource }

  return structureObj(merged)
}
