/**
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * WILL BE DELETED
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

/* Hooks ======================================================================================== */
import useAxios from '../../../hooks/use-axios'
import useValue from '../../../hooks/use-value'

/* Components =================================================================================== */
import AuthenticationLayout, { StyledForm } from './authentication.layout'
import SET from '../../../styles/set'
import { DialogWrapper } from '../../../modules/dialog-wrapper'
import { Link } from 'react-router-dom'
import { Submit } from '../../../modules/submit'
import { Text } from '../../../modules/text'
import { TextField } from '../../../modules/text-field'

/* Constants ==================================================================================== */
import { APP_CONFIG } from '../../../app-config'

/* Material UI ======================================================================================== */
import Typography from '@material-ui/core/Typography'

/* <ForgetPasswordForm /> ======================================================================= */
const ForgetPasswordForm = () => {
  const [, $users] = useAxios(`${process.env.REACT_APP_API}/users/forget-password`)
  const [error, $error] = useValue()
  const history = useHistory()
  let timeoutId: number

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId)
    }
  })

  const empty = (value: any) => {
    if (value === '' || value === undefined) {
      return 'Cannot be empty'
    }
    return undefined
  }

  const handleSubmit = async (data: any) => {
    const { error } = await $users.post(data)

    if (error) {
      return $error.set('Some error occured!')
    }

    DialogWrapper.push({
      render: (
        <div>
          {`
            Successfully send password reset email!

            You will be redirected to login page.
          `}
        </div>
      ),
      disableAction: true,
      label: 'Congratulations',
    })
    timeoutId = setTimeout(() => {
      DialogWrapper.pop()
      history.push('/login')
    }, APP_CONFIG.redirectWaitTime)
  }

  return (
    <AuthenticationLayout title="Reset your password" subtitle="You'll recieve password reset link">
      <StyledForm name="users" onSubmit={handleSubmit}>
        <TextField name="email" label="Email" className={SET.mb24} validate={[empty]} />
        {error && (
          <>
            <hr />
            <Typography>{error}</Typography>
            <hr />
          </>
        )}
        <Submit label="Submit" className={SET.classes(SET.mb8, SET.mt24, SET.fw)} />
        <Typography>
          Already have an account?{' '}
          <Link className={SET.underline} to="/login">
            Click here
          </Link>
        </Typography>
      </StyledForm>
      {/* <DialogWrapper.render /> */}
    </AuthenticationLayout>
  )
}

export default ForgetPasswordForm
