import React from 'react'

import logo from './assets/jams-logo.png'

import { Circle } from './modules/circle'
import { Text } from './modules/text'

import { APP_CONFIG } from './app-config'
import styled from 'styled-components'

const Image = styled.img`
  width: 100%;
`

export const GLOBAL_COMPONENTS = {
  appName: {
    small: <Text.h6 style={{ font: 'Fredoka One' }}>{APP_CONFIG.name}</Text.h6>,
    md: <Text.h6 style={{ font: 'Fredoka One' }}>{APP_CONFIG.name}</Text.h6>,
    lg: <Text.h6 style={{ font: 'Fredoka One' }}>{APP_CONFIG.name}</Text.h6>,
  },
  logo: {
    small: (
      <Circle size="sm" baseline="background">
        <Image src={logo} />
      </Circle>
    ),
    md: (
      <Circle size="md" baseline="background">
        <Image src={logo} />
      </Circle>
    ),
    lg: (
      <Circle size="xl" baseline="background">
        <Image src={logo} />
      </Circle>
    ),
  },
}
