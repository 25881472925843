import { generateSet } from './set.init'

export const border: Border = {
  ...generateSet({
    key: { bn: 'border' },
    getValue: () => 'none',
    incremental: false,
  }),
  ...generateSet({
    key: { bl: 'borderLeft' },
    getValue: () => '1px solid rgb(235, 235, 235)',
    incremental: false,
  }),
  ...generateSet({
    key: { bb: 'borderBottom' },
    getValue: () => '1px solid rgb(235, 235, 235)',
    incremental: false,
  }),
  ...generateSet({
    key: { br: 'borderRight' },
    getValue: () => '1px solid rgb(235, 235, 235)',
    incremental: false,
  }),
  ...generateSet({
    key: { bt: 'borderTop' },
    getValue: () => '1px solid rgb(235, 235, 235)',
    incremental: false,
  }),
}

/** Below type is generated by `generateTypes` in set.ts */
type Border = {
  bn: {}
  bni: {}
  bnsm: {}
  bnsmi: {}
  bnmd: {}
  bnmdi: {}
  bnlg: {}
  bnlgi: {}
  bnxl: {}
  bnxli: {}
  bl: {}
  bli: {}
  blsm: {}
  blsmi: {}
  blmd: {}
  blmdi: {}
  bllg: {}
  bllgi: {}
  blxl: {}
  blxli: {}
  bb: {}
  bbi: {}
  bbsm: {}
  bbsmi: {}
  bbmd: {}
  bbmdi: {}
  bblg: {}
  bblgi: {}
  bbxl: {}
  bbxli: {}
  br: {}
  bri: {}
  brsm: {}
  brsmi: {}
  brmd: {}
  brmdi: {}
  brlg: {}
  brlgi: {}
  brxl: {}
  brxli: {}
  bt: {}
  bti: {}
  btsm: {}
  btsmi: {}
  btmd: {}
  btmdi: {}
  btlg: {}
  btlgi: {}
  btxl: {}
  btxli: {}
}
