/* Constants ======================================================================================== */
import { APPBAR_HEIGHT, DRAWER_WIDTH } from '../../modules/app-layout/app-layout.constants'

/* Material UI ======================================================================================== */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

/* Styled Components ============================================================================ */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wwwPagination: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      padding: theme.spacing(2),
    },
    wwwBackdrop: {
      zIndex: 2,
      left: `${DRAWER_WIDTH}px`, // match drawer width
      top: `calc(${APPBAR_HEIGHT}px + 136px)`, // match app bar, page header, back button container height
    },
    wwwNoScroll: {
      overflowY: 'hidden',
    },
  }),
)
