import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

/* Components =================================================================================== */
import { useStyles } from './breadcrumb.styled'

/* Material UI =================================================================================== */
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

/* <Breadcrumb /> =============================================================================== */
export const Breadcrumb: React.FC<{
  className?: string
  routes: any[]
  match?: any
  paramMap?: { [key: string]: any }
}> = ({ className, routes = [], match = {}, paramMap = {} }) => {
  const { pathname } = useLocation()
  const { path, params } = match

  const styleClasses = useStyles()

  const filteredRoutes = routes
    .sort((a, b) => {
      if (a.path > b.path) return 1
      if (a.path < b.path) return -1

      return 0
    })
    .filter(a => a.hide !== true)
    .filter(a => {
      if (path) {
        return path.includes(a.path)
      }

      return pathname.includes(a.path)
    })
    .map(a => {
      for (const paramKey in params) {
        if (a.link) {
          a.link = a.link.replace(`:${paramKey}`, params[paramKey])
        } else {
          a.link = a.path.replace(`:${paramKey}`, params[paramKey])
        }
      }

      if (paramMap[a.key]) {
        a.title = paramMap[a.key]
      }

      return a
    })

  if (filteredRoutes.length > 2) {
    filteredRoutes.splice(0, 1)
  }

  return (
    <Box className={styleClasses.wwwBreadcrumbContainer}>
      {filteredRoutes.map((route, key) => {
        return (
          <React.Fragment key={`${route.path}-${key}`}>
            <Typography>
              {route.link ? <Link to={route.link}>{route.title}</Link> : route.title}
            </Typography>
            {key < filteredRoutes.length - 1 && route.title && (
              <span style={{ marginRight: 8, fontSize: 13 }}>/</span>
            )}
          </React.Fragment>
        )
      })}
    </Box>
  )
}
