/* Constants ==================================================================================== */
import * as colors from './colors'
import { Color } from './baseline'

export type Box = {
  border?: Color
  shadow?: Color
}

export const getDefaultBox: () => Box = () => ({
  border: colors.GREY,
  shadow: colors.GREY,
})
