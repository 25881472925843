import React, { FC } from 'react'
import { classes } from 'typestyle'

import { ComponentBaselineType, ComponentProps } from '../__core/component.types'
import { withComponent } from '../__core/component'

import { textClass } from './text.class'

type TextComponentProps = {
  alt?: boolean
  baseline?: ComponentBaselineType
  children?: any
  className?: string
  tag?: any

  semiBold?: boolean
  bold?: boolean
  italic?: boolean
  link?: boolean
  uppercase?: boolean
} & ComponentProps

const defaultProps: {
  baseline?: ComponentBaselineType
  tag?: JSX.Element
} = {
  baseline: 'none',
  tag: <span />,
}

export const TextComponent: FC<TextComponentProps> = props => {
  const {
    alt,
    baseline,
    className,
    children,
    tag,
    bold,
    italic,
    link,
    semiBold,
    uppercase,
    size,
  } = props

  const {
    base,
    baseThemed,
    baseSemiBold,
    baseBold,
    baseItalic,
    baseLink,
    baseUppercase,
    baseSized,
  } = textClass.setProps({ alt, baseline })

  return React.cloneElement(tag, {
    className: classes(
      base,
      baseThemed,
      // baseSized[size || 'md'],
      semiBold && baseSemiBold,
      bold && baseBold,
      italic && baseItalic,
      link && baseLink,
      uppercase && baseUppercase,
    ),
    children,
    ...tag.props,
  })
}

TextComponent.defaultProps = defaultProps

export const Text = withComponent(TextComponent, textClass.names.component)
