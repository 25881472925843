import React, { FC } from 'react'
import { IconProps, SIZES, SVG } from '../../exports'

/* Styles ======================================================================================== */
import { ViewDetailedStyled } from './view-detailed.styled'

const ViewDetailed: FC<IconProps> = props => {
  const { size = 'xxl', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <ViewDetailedStyled
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M42 6L43 6"
        className="stroke-bg stroke-bg-1 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 10L45 10"
        className="stroke-bg stroke-bg-2 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 34H3"
        className="stroke-bg stroke-bg-3 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 41H8"
        className="stroke-bg stroke-bg-4 secondary-stroke-color"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7C8 6.44772 8.44772 6 9 6H32.1429C32.6951 6 33.1429 6.44772 33.1429 7V37C33.1429 37.5523 32.6951 38 32.1429 38H9C8.44771 38 8 37.5523 8 37V7Z"
        className="fill-bg primary-fill-color"
      />
      <path
        d="M26.9091 22.5714L16.5454 22.5714"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 27.1429H16.5454"
        className="stroke draw-stroke-path stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3636 31.7143H16.5454"
        className="stroke draw-stroke-path stroke-path-3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37 42H12V21M30.2692 10H12V14M30.2692 10L37 17.4667M30.2692 10V17.4667H37M37 17.4667V21.5"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9928 38.4358C40.6023 38.0452 39.9691 38.0452 39.5786 38.4358C39.1881 38.8263 39.1881 39.4594 39.5786 39.85L40.9928 38.4358ZM41.2929 41.5643C41.6834 41.9548 42.3166 41.9548 42.7071 41.5643C43.0976 41.1737 43.0976 40.5406 42.7071 40.15L41.2929 41.5643ZM38.7143 31.7143C38.7143 34.9491 36.092 37.5714 32.8571 37.5714V39.5714C37.1965 39.5714 40.7143 36.0537 40.7143 31.7143H38.7143ZM32.8571 37.5714C29.6223 37.5714 27 34.9491 27 31.7143H25C25 36.0537 28.5178 39.5714 32.8571 39.5714V37.5714ZM27 31.7143C27 28.4795 29.6223 25.8571 32.8571 25.8571V23.8571C28.5178 23.8571 25 27.3749 25 31.7143H27ZM32.8571 25.8571C36.092 25.8571 38.7143 28.4795 38.7143 31.7143H40.7143C40.7143 27.3749 37.1965 23.8571 32.8571 23.8571V25.8571ZM39.5786 39.85L41.2929 41.5643L42.7071 40.15L40.9928 38.4358L39.5786 39.85Z"
        className="fill stroke-path"
      />
    </ViewDetailedStyled>
  )
}

export default ViewDetailed
