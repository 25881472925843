import React, { useContext, useEffect, memo, useMemo } from 'react'
import { classes } from 'typestyle'

/* Hooks ======================================================================================== */
import useBoolean from '../../hooks/use-boolean'

/* Styles ======================================================================================= */
import { fieldClass } from './field.class'
import SET from '../../styles/set'

/* Components =================================================================================== */
import { FormContext, FormActions } from '../form'
import { withComponent } from '../__core/component'
import { Text } from '../text/text.component'

/* Types ======================================================================================== */
import { ComponentProps, ComponentSizeType } from '../__core/component.types'
import useValue from '../../hooks/use-value'
export type FieldProps = {
  className?: string
  compact?: boolean
  data?: string
  disableError?: boolean
  label?: string
  name?: string
  focused?: boolean
  onChange?: (data: any, form?: any) => void
  onFocus?: () => void
  onBlur?: () => void
  validate?: any
  sanitize?: any
  formValue?: any
  value?: any
  placeholder?: string
  defaultValue?: any
  style?: React.CSSProperties
  beforeValue?: (value: any) => {}
  beforeSubmit?: (value: any, update: any) => {}
  size?: ComponentSizeType
  start?: any
  end?: any
  wrapper?: any
  wrapperProps?: any
  InputProps?: any
  helperText?: string
} & ComponentProps

export type FieldContextProps = {
  error?: string
  meta?: any
  /** Followings are set required so there is no extra validation  */
  onChange: (data: any, form: any) => void
  onFocus: () => void
  onBlur: () => void
  dispatch: (props: { type: string; param?: any }) => any
  field: {
    focused: boolean
    validate: any
  }
  handleValidate: any
  handleValue: any
} & FieldProps

const defaultProps: {} = {}
/* <Field /> ==================================================================================== */
export const withField: (
  FieldElement: any,
  componentClass?: string | null,
  isArray?: boolean,
) => React.FC<any> = (FieldElement, componentClass, isArray) => {
  const ComposedField: React.FC<FieldProps> = props => {
    const { value, dispatch } = useContext(FormContext)

    useEffect(() => {
      if (dispatch) {
        ;(dispatch as any)({
          type: FormActions.FIELD,
          param: {
            name: props.name,
            value: {
              isArray,
              validate: props.validate,
              beforeSubmit: props.beforeSubmit,
            },
          },
        })
      }
    }, [])

    const handleValidate = (value: any, validate?: any) => {
      const validators = validate || []
      let error: any

      if (typeof validators === 'function') {
        return validators(value)
      } else {
        for (let i = 0; i < validators.length; i++) {
          if (value && typeof value === 'object' && value.error) {
            error = validators[i](value.value)
          } else {
            error = validators[i](value)
          }

          if (error) {
            if (typeof value === 'object' && value.error) {
              return value
            } else {
              return { value, error }
            }
          } else {
            return value
          }
        }
      }

      return value
    }

    const handleValue = (value: any) => {
      if (value.value !== undefined) {
        if (props.sanitize) {
          return props.sanitize(value.value)
        }
        return value.value
      }

      if (props.sanitize) {
        return props.sanitize(value)
      }
      return value
    }

    const getValue = () => {
      let _value = (value.form as any)[props.name as any]

      if (props.beforeValue) {
        _value = props.beforeValue(_value)
      }
      return [_value]
    }

    return useMemo(() => {
      let _value = (value.form as any)[props.name as any]

      if (props.beforeValue) {
        _value = props.beforeValue(_value)
      }

      const component = (
        <FieldElement
          {...props}
          formValue={props.value || (props.name && _value)}
          field={props.name && (value.fields as any)[props.name as any]}
          dispatch={dispatch}
          handleValidate={handleValidate}
          handleValue={handleValue}
        />
      )

      if (props.wrapper) {
        const Wrapper = props.wrapper

        return (
          <Wrapper
            formValue={props.value || (props.name && (value.form as any)[props.name])}
            field={props.name && (value.fields as any)[props.name as any]}
            dispatch={dispatch}
            handleValidate={handleValidate}
            handleValue={handleValue}
            {...props.wrapperProps}
          >
            <FieldElement
              {...props}
              formValue={props.value || (props.name && (value.form as any)[props.name])}
              field={props.name && (value.fields as any)[props.name as any]}
              dispatch={dispatch}
              handleValidate={handleValidate}
              handleValue={handleValue}
            />
          </Wrapper>
        )
      }

      return component
    }, [
      ...getValue(),
      (value.fields as any)[props.name as any],
      props.value,
      props.label,
      props.InputProps,
      props.onChange,
      props.name,
    ])
  }

  ComposedField.defaultProps = defaultProps

  return withComponent(ComposedField, fieldClass.names.component)
}
