import Cookies from 'js-cookie'
import React, { useEffect, FC } from 'react'
import { useHistory } from 'react-router-dom'
import useBoolean from '../../hooks/use-boolean'
import useFetch, { parseSearch } from '../../hooks/use-fetch'
import { Button } from '../button'
import { Text } from '../../modules/text/text.component'

/* Context ====================================================================================== */
import { ComponentProps } from '../__core/component.types'

import microsoft from '../../assets/microsoft/ms-symbollockup_mssymbol_19.svg'
// import { Box } from '../box/box.component'

/* Material UI  ======================================================================================== */
import { useStyles } from './social-signin.styled'
import Box from '@material-ui/core/Box'

/* Types ======================================================================================== */
type SocialProps = {
  label?: string
  tenetId: string
  clientId: string
  redirectUri: string
  type: string
} & ComponentProps

/* <SocialSigninComponent /> ======================================================================= */
const SocialSigninComponent: React.FC<{
  tenetId: string
  label?: string
  clientId: string
  redirectUri: string
  type: string
}> = ({ tenetId, label, clientId, redirectUri, type }) => {
  const [loading, $loading] = useBoolean(false)
  const history = useHistory()
  const queryParams = parseSearch(history.location, null)
  const [users, $users] = useFetch(
    { token: '' },
    { url: `${process.env.REACT_APP_API}/users/signin` },
  )
  const providorMap: any = {
    office: {
      url: `https://login.microsoftonline.com/${tenetId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=email%20openid`,
      icon: 'Sign in with Microsoft',
    },
  }
  const classes = useStyles()

  const signInWithtoken = async (code: string) => {
    const res = await $users.post({
      body: {
        code: code,
        type: type,
      },
    })

    return res
  }

  useEffect(() => {
    if (users.loaded && $users.post) {
      if (queryParams && queryParams.hasOwnProperty('code')) {
        if (!loading) {
          signInWithtoken(queryParams.code)
          $loading.set(true)
        }
      }
    }
  }, [users.loaded, $users.post])

  useEffect(() => {
    if (users.payload && users.payload.token) {
      Cookies.set('token', users.payload.token)
      history.replace('/')
    }
  }, [users.payload])

  const redirect = (url: string) => {
    window.location.href = url
  }

  return (
    <Box mt={2}>
      <Button
        className={classes.root}
        onClick={() => {
          redirect(providorMap[type]['url'])
        }}
        startIcon={<img src={microsoft} style={{ width: 14 }} />}
        size="medium"
        variant="outlined"
        icon={<img src={microsoft} style={{ width: 14 }} />}
        // baseline="surface"
        // float
        // size="md"
        // square
        fullWidth
        label={providorMap[type]['icon']}
      />
    </Box>
  )
}

export const Social: FC<SocialProps> = SocialSigninComponent
