import React, { FC, CSSProperties } from 'react'
import { IconProps, SIZES, SVG } from '../exports'

/* Styles ======================================================================================== */
import { SearchStyled } from './search.styled'

const Search: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <SearchStyled
      viewBox={'0 0 33 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        className="stroke"
        d="M25 25L30 30M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SearchStyled>
  )
}

export default Search
