import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { classes } from 'typestyle'

/* Hooks ======================================================================================== */
import useValue from '../../hooks/use-value'
import useArray from '../../hooks/use-array'
import useBoolean from '../../hooks/use-boolean'

/* Styles ======================================================================================= */
import { tableClass } from './table.class'

/* Common ======================================================================================= */
import { flat } from '../../share/obj-equal'
import { calcPrimaryHeadersWidth } from './table.utils'

/* Components =================================================================================== */
import { TableBodyContent } from './table-body.component'
import { TableHeader } from './table-header.component'

/* Types ======================================================================================== */
import { ComponentSizeType } from '../__core/component.types'

/* Material UI  ======================================================================================== */
import { Table as MaterialTable } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'

/* Styles  ======================================================================================== */
import { useStyles } from './table.styled'

export type Header = {
  handleClick?: () => void
  label: string
  minWidth?: number
  name: string
  primary?: boolean
  renderColumn?: (column: any, row: any, key: number, name: string) => any
  renderHeader?: (header: any, key: number, renderSortIcon?: any) => any
  sort?: number
  width: number
  align?: any
  fontWeight?: any
}

type TableProps = {
  style?: React.CSSProperties
  children?: any
  className?: string
  headers: Header[]
  data: any
  selected?: any
  selectKey?: string
  onSort?: (header: Header) => void
  onSelect?: (data: any, key?: number) => void
  onMouseDown?: (event: any, selected?: any) => void
  onContextMenu?: (event: any, selected?: any) => void
  topOffset?: number
  size?: ComponentSizeType | any
  loaded?: boolean
  freeSize?: boolean
  footer?: any
  noLimit?: boolean
}

/* <Table /> ================================================================================= */
const TableComponent: React.FC<TableProps> = props => {
  const {
    children,
    className,
    data,
    footer,
    headers,
    onContextMenu,
    onMouseDown,
    onSelect,
    onSort,
    selectKey,
    selected = [],
    size = 'xl',
    style,
    loaded,
    topOffset = 0,
    freeSize,
    noLimit,
  } = props

  const tableParentRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const [left, $left] = useValue(0)
  const [top, $top] = useValue(0)
  const [internalHeaders, $internalHeaders] = useArray<Header>(headers)
  const [primaryHeaders, $primaryHeaders] = useArray<Header>([])
  const [internalData, $internalData] = useArray<any>(data)
  const [, $forceUpdate] = useValue(false)
  const styleClasses = useStyles()

  useEffect(() => {
    handleScrollLeft()
    tableParentRef.current.addEventListener(
      'contextmenu',
      function (evt: any) {
        evt.preventDefault()
      },
      false,
    )
  }, [])

  useEffect(() => {
    $internalHeaders.set(
      headers
        .sort((x, y) => (x.primary === y.primary ? 0 : x.primary ? -1 : 1))
        .map((a, key) => {
          a.handleClick = handleSort(headers, key)

          return a
        }),
    )
  }, [headers, onSort])

  useEffect(() => {
    const sortBy: any = internalHeaders.filter(header => header.sort)[0]
    let sortedData = data
      ? [
        ...data.map((dat: any) => {
          return flat(dat)
        }),
      ]
      : []

    if (!onSort) {
      if (sortBy) {
        sortedData = sortedData.sort((x: any, y: any) => {
          if (x[sortBy.name] > y[sortBy.name]) {
            return sortBy.sort === 1 ? 1 : -1
          }

          if (x[sortBy.name] < y[sortBy.name]) {
            return sortBy.sort === 1 ? -1 : 1
          }

          return 0
        })
      }
    }

    sortedData = sortedData.map(data => {
      return flat(data)
    })

    $internalData.set(sortedData)
  }, [data, internalHeaders])

  useEffect(() => {
    $primaryHeaders.set(internalHeaders.filter(a => a.primary))
  }, [internalHeaders])

  useLayoutEffect(() => {
    tableParentRef.current.addEventListener('scroll', handleScrollLeft)
    window.addEventListener('scroll', handleScrollTop)
    window.addEventListener('resize', handleResize)
    window.addEventListener('resize', handleResize)
    return () => {
      clearTimeout(debounceId)
      tableParentRef.current.removeEventListener('scroll', handleScrollLeft)
      window.removeEventListener('scroll', handleScrollTop)
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleResize = () => {
    handleScrollLeft()
    handleScrollTop()
    tableParentRef.current.scrollLeft = 0
  }

  const handleSort = (headers: Header[], key: number) => () => {
    const newHeaders = [...headers]

    for (let i = 0; i < newHeaders.length; i++) {
      const header = newHeaders[i]

      if (i !== key) {
        header.sort = 0
      } else {
        let sort = header.sort || 0
        sort = (sort % 2) + 1
        header.sort = sort

        if (onSort) {
          onSort(header)
        }
      }
    }

    $internalHeaders.set(newHeaders)
  }

  let debounceId: any = null

  const handleScrollLeft = () => {
    $left.set(0)

    if (debounceId) {
      clearTimeout(debounceId)
    }

    debounceId = setTimeout(function () {
      if (tableParentRef.current && tableParentRef.current.scrollLeft > 0) {
        $left.set(tableParentRef.current.scrollLeft)
      } else {
        $left.set(0)
      }
    }, 25)

    $forceUpdate.set((prev: any) => !prev)
  }

  const handleScrollTop = () => {
    $top.set(0)

    
    if (debounceId) {
      clearTimeout(debounceId)
    }

    debounceId = setInterval(function () {
      if (window.pageYOffset > 0) {
        $top.set(-tableParentRef.current.getBoundingClientRect().top)
      } else {
        $top.set(0)
      }

    }, 500)

    $forceUpdate.set((prev: any) => !prev)
  }

  const handleSelect: (row: any, key: number) => any = (row, key) => {
    return {
      highlight: selected.includes(row && row[selectKey || '']),
      handle: {
        onClick: (event: any) => {
          if (onSelect) onSelect(internalData[key])
          if (onMouseDown) onMouseDown(event, internalData[key])
        },
        onContextMenu: (event: any) => {
          if (onSelect) onSelect(internalData[key])

          if (onContextMenu) {
            if (event.type === 'contextmenu') {
              onContextMenu(event, internalData[key])
            }
          }
        },
      },
    }
  }

  // const {
  //   base,
  //   sideFloatingHeader,
  //   sideFloatingHeaderProped,
  //   floatingHeader,
  // } = tableClass.setProps({})
  return (
    <TableContainer
      component={Paper}
      className={classes(className, styleClasses.wwwTableContainer)}
      ref={tableParentRef}
      square
    >
      {/* <div className={classes('www-table', base, className)} style={style} ref={tableParentRef}> */}
      <MaterialTable size="small" stickyHeader>
        {/* <div
            className={classes(floatingHeader)}
            style={{
              top: top > 0 ? topOffset : 0,
              transform: `translateY(${top > 0 ? top : 0}px)`,
              height: 'fit-content',
            }}
          > */}

        <TableHeader headers={internalHeaders} size={size} />
        {/* <div
            className={classes(sideFloatingHeader, loaded && sideFloatingHeaderProped.loaded)}
            style={{
              left: calcPrimaryHeadersWidth(tableParentRef, left, primaryHeaders)(),
              boxShadow: left !== 0 ? '5px 0px 10px 2px rgba(0,0,0,0.06)' : '',
            }}
          > */}
        {/* <TableHeader headers={primaryHeaders} size={size} float /> */}
        {/* </div> */}
        {/* </div> */}
        <TableBodyContent
          freeSize={freeSize}
          headers={internalHeaders}
          data={[...internalData, ...(footer ? footer : [])]}
          size={size}
          selected={selected}
          selectKey={selectKey}
          noLimit={noLimit}
          handleSelect={handleSelect}
        />
        {/* <div
            className={classes(sideFloatingHeader, loaded && sideFloatingHeaderProped.loaded)}
            style={{
              left: calcPrimaryHeadersWidth(tableParentRef, left, primaryHeaders)(),
              boxShadow: left !== 0 ? '5px 0px 10px 2px rgba(0,0,0,0.06)' : '',
            }}
          > */}
        {/* <TableBodyContent
          freeSize={freeSize}
          headers={primaryHeaders}
          data={[...internalData, ...(footer ? footer : [])]}
          size={size}
          noLimit={noLimit}
          float
          handleSelect={handleSelect}
        /> */}
        {/* </div> */}
      </MaterialTable>
      {/* </div> */}
    </TableContainer>
  )
}

/* Export ======================================================================================= */
export const Table = TableComponent
