import { style } from 'typestyle'

/* Styles ======================================================================================= */
import { StyleClass } from '../style-class'

/* Types ======================================================================================== */
type ClassNames = {
  /**
   * Base class for grid item
   */
  base: string
  /**
   * Proped class for grid item
   */
  proped: {
    bottom: string
    left: string
    right: string
    top: string
  }
}

type ClassProps = {
  contrast?: 'light' | 'medium' | 'dark'
}

const getNames: (props: ClassProps, theme: any) => ClassNames = (props, theme) => {
  const { contrast = 'medium' } = props
  const base = style({ position: 'absolute' })
  const proped = {
    bottom: style({
      borderBottom: `1px solid ${theme.boxes.border[contrast]}`,
      bottom: 0,
      height: 1,
      left: 0,
      width: '100%',
    }),
    left: style({
      borderLeft: `1px solid ${theme.boxes.border[contrast]}`,
      top: 0,
      left: 0,
      height: '100%',
      width: 1,
    }),
    right: style({
      borderRight: `1px solid ${theme.boxes.border[contrast]}`,
      top: 0,
      right: 0,
      height: '100%',
      width: 1,
    }),
    top: style({
      borderTop: `1px solid ${theme.boxes.border[contrast]}`,
      top: 0,
      left: 0,
      height: 1,
      width: '100%',
    }),
  }

  return {
    base,
    proped,
  }
}

/* Export ======================================================================================= */
export const borderClass = new StyleClass<ClassNames, ClassProps>(getNames)
