import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { AlertStyled } from './alert.styled'

const Alert: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }

  return (
    <AlertStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M14 13V17C14 18.1046 14.8954 19 16 19C17.1046 19 18 18.1046 18 17L18 13C18 11.8954 17.1046 11 16 11C14.8954 11 14 11.8954 14 13Z"
        className="stroke draw-stroke-path stroke-path-1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22Z"
        className="stroke draw-stroke-path stroke-path-2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 23.9688L2.14547 27.3535C1.75413 28.0678 2.19833 29 2.93001 29H16H22.535H25.8025H29.07C29.8017 29 30.2459 28.0678 29.8545 27.3535L28.2208 24.3717L26.587 21.3899L23.3195 15.4263L16.7845 3.49897C16.42 2.83368 15.58 2.83368 15.2155 3.49897L14.1881 5.3741L13.1607 7.24923L11.1059 10.9995L6.99635 18.5"
        className="stroke draw-stroke-path stroke-path-3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </AlertStyled>
  )
}

export default Alert
