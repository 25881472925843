import React, { FC } from 'react'
import { IconProps, SIZES } from '../exports'

/* Styles ======================================================================================== */
import { ConcretePourStyled } from './concrete-pour.styled'

const ConcretePour: FC<IconProps> = props => {
  const { size = 'md', isAnimated, color, ...others } = props
  const theme = {
    color: `${color ? color : 'currentColor'}`,
  }
  return (
    <ConcretePourStyled
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isAnimated !== undefined ? 'is-animated' : ''}
      theme={theme.color}
      {...SIZES[size]}
      {...others}
    >
      <path
        d="M10 10C10 7.96875 12.0312 4 17 4C18.875 4.07292 21.0312 4.65625 22.625 6.25C24.75 5.83333 29 6 29 10"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 20.3542C22.733 19.5238 23.8053 19 25 19C26.9924 19 28.6445 20.4567 28.9496 22.3631M21.031 23.5C21.277 25.4732 22.9602 27 25 27C26.2632 27 27.3896 26.4145 28.1227 25.5M10.4063 20.375L11.0961 25.4706C11.185 26.4132 12.1485 27.0933 13.2482 26.9896C14.3479 26.8858 14.6946 26.3509 15.5762 25.0481L17.125 22.375M3.14492 18.4725C2.34472 18.2581 1.86985 17.4356 2.08426 16.6354C2.29867 15.8352 3.12118 15.3603 3.92138 15.5747L28.9627 22.2845C29.7629 22.4989 30.2378 23.3214 30.0234 24.1216C29.8089 24.9218 28.9864 25.3967 28.1862 25.1823L3.14492 18.4725Z"
        className="stroke stroke-path"
        strokeWidth="2"
      />
      <path
        d="M9.48438 13.5L9 10H30L27 19.5"
        className="stroke stroke-path"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ConcretePourStyled>
  )
}

export default ConcretePour
