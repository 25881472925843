import { DialogWrapper } from '../modules/dialog-wrapper'

import { TemplateForm } from './template-form'
import { UserManagement } from './user-management'
import { TableModal } from '../components/table/modal'

DialogWrapper.setComponents({
  TemplateForm,
  UserManagement,
  TableModal,
})
