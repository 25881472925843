import React, { FC } from 'react'

import SET from '../styles/set'

/* Hooks ======================================================================================== */
import useAxios from '../hooks/use-axios'
import useValue from '../hooks/use-value'
import useBoolean from '../hooks/use-boolean'

/* Components ======================================================================================== */
// import { Box } from '../modules/box/box.component'
import { Button } from '../modules/button'
// import { Text } from '../modules/text/text.component'
import { TextField } from '../modules/text-field'
import { flat, structureObj } from '../share/obj-equal'
import { DialogWrapper } from '../modules/dialog-wrapper'

/* Material UI ======================================================================================== */
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

export const TemplateForm: FC<{}> = (props: any) => {
  const {
    pop,
    data,
    label,
    name,
    organizationId,
    parentEntryId,
    schemaId,
    templateId,
    open,
  } = props
  const [tempName, $tempName] = useValue(name)
  const [, $postApi] = useAxios(
    templateId
      ? `${process.env.REACT_APP_API}/templates/${templateId}`
      : `${process.env.REACT_APP_API}/templates/`,
  )
  const [isOpen, $isOpen] = useBoolean(open)

  const sanitizeData = (data: any) => {
    const temp: any = { ...data.form }
    const fields: any = { ...data.fields }

    for (const key in temp) {
      if (!temp[key]) {
        delete temp[key]
      }

      if (temp[key] && Object.keys(temp[key]).length === 0) {
        delete temp[key]
      }
    }

    for (const key in fields) {
      const value: any = temp[key]

      if (!(fields as any)[key]) {
        continue
      }

      const beforeSubmit = fields[key].beforeSubmit
      if (beforeSubmit && value && beforeSubmit(value)) {
        temp[key] = beforeSubmit(value)
      }

      if (temp[key] === undefined) {
        delete temp[key]
      }

      if (temp[key] && typeof temp[key] === 'object' && Object.keys(temp[key]).length === 0) {
        delete temp[key]
      }
    }

    return structureObj(flat(temp))
  }

  return (
    <>
      {/* <Dialog open={isOpen}> */}
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <TextField
          label="Name of the template"
          value={tempName}
          onChange={value => {
            $tempName.set(value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button label={'Cancel'} onClick={pop} />
        <Button
          label="Save"
          variant="contained"
          color="secondary"
          onClick={() => {
            if (templateId) {
              $postApi
                .put({
                  name: tempName,
                  data: sanitizeData(data),
                  schema: schemaId,
                  organization: organizationId,
                  parent: parentEntryId,
                })
                .then(() => {
                  pop()
                  DialogWrapper.push({
                    componentProps: {
                      label: 'Template is saved!',
                    },
                  })
                })
            } else {
              $postApi
                .post({
                  name: tempName,
                  data: sanitizeData(data),
                  schema: schemaId,
                  organization: organizationId,
                  parent: parentEntryId,
                })
                .then(() => {
                  pop()
                  DialogWrapper.push({
                    componentProps: {
                      label: 'Template is saved!',
                    },
                  })
                })
            }
          }}
        />
      </DialogActions>
      {/* </Dialog> */}
    </>
  )
}
